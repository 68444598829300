import {
  UserCardWrapper,
  CardBlock,
  UserRow,
  CardButtons,
  RestBlock,
  ToggleButton,
  CompanyOverflowBlock,
} from './index.style'
import { useRef } from 'react'
import { Caption, Label, TextMedium } from 'components/common/text/index.style'
import Icon from 'components/Icon'
import { useTranslation } from 'react-i18next'
import CustomButton from 'components/common/customButton'
import { format } from 'date-fns'
import { connect, useDispatch } from 'react-redux'
import {
  licenseUserToggleAccess,
  setCurrentCustomerId,
} from 'store/actions/adminPanel'
import { useOverflow } from 'hooks/useOverflow'
import { useTheme } from 'styled-components'
import { Tooltip as ReactTooltip } from 'react-tooltip'
import { push } from 'connected-react-router'
import { formatUtcDigitDateByFormat } from 'utils/dateTime/formatUtcDate'

const UserCard = ({ card, isCompany = false, role }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const theme = useTheme()

  const textRef = useRef(null)
  const containerRef = useRef(null)

  const isOverflow = useOverflow(textRef, containerRef)
  const getName = (name, surname) => {
    if (name && surname) {
      return name.concat(' ', surname)
    }
    if (!name && surname) return surname
    if (name && !surname) return name
    return t('admin.no_name')
  }
  const handleToggleAccess = () => {
    dispatch(
      licenseUserToggleAccess({
        isCurrentCustomer: isCompany,
        user_id: card.user_id,
        license_id: card?.license_id,
        customer_id: card?.customer_id,
      })
    )
  }

  const handleOpenCompany = () => {
    if (card.customer_id && card.license_id) {
      dispatch(
        setCurrentCustomerId({
          customerId: card.customer_id,
          licenseId: card.license_id,
        })
      )
      dispatch(push(`/admin-panel/company`))
    }
  }

  return (
    <UserCardWrapper>
      <RestBlock>
        <CardBlock
          width={isCompany ? '25%' : '22%'}
          paddingLeft="0rem"
          paddingRight="0.375rem"
        >
          <Label id={`user-label-${card.id}`} name="ellipsis">
            {getName(card.name, card.surname)}
          </Label>
          <UserRow>
            <Icon iconType="profile16" size="1rem" />
            <Caption color="inherit" ellipsis>
              {t(`admin.${card.role}`)}
            </Caption>
          </UserRow>
          {!isCompany && (
            <UserRow>
              <Icon iconType="email16" />
              <Caption color="inherit" ellipsis>
                {card.email}
              </Caption>
            </UserRow>
          )}
        </CardBlock>
        <CardBlock
          width={isCompany ? '25%' : '22%'}
          row
          paddingLeft="0.375rem"
          paddingRight="0.375rem"
          ref={containerRef}
          id={`outerref-${card.user_id}`}
        >
          {!isCompany && (
            <CompanyOverflowBlock onClick={handleOpenCompany}>
              <Label
                name="smiles"
                ref={textRef}
                id={`textref-${card.user_id}`}
                color="inherit"
                data-tooltip-id={`company-hint-${card.user_id}`}
              >
                {card.company ? card.company : t('admin.no_company')}
              </Label>{' '}
              {card.company && (
                <CustomButton
                  type="icon"
                  defaultColor="inherit"
                  noAnimation={true}
                >
                  <Icon iconType="arrowRight" size="1rem" />
                </CustomButton>
              )}
              {isOverflow && (
                <ReactTooltip
                  id={`company-hint-${card.user_id}`}
                  place={'top'}
                  className="c-tooltip c-tooltip-base"
                  classNameArrow="c-tooltip-arrow"
                >
                  <TextMedium
                    color={theme.colors.text.white}
                    fontWeight={theme.fontWeight.light}
                  >
                    {card.company}
                  </TextMedium>
                </ReactTooltip>
              )}
            </CompanyOverflowBlock>
          )}
          {isCompany && <Label name="ellipsis">{card.email}</Label>}
        </CardBlock>
        {!isCompany && (
          <CardBlock
            width="14%"
            paddingLeft="0.375rem"
            paddingRight="0.375rem"
            alignItems="center"
            textAlign="center"
          >
            <Label>
              {!card.is_activated
                ? t('admin.not_active')
                : card.license_is_active
                ? t('admin.license')
                : t('admin.test_period')}
            </Label>
          </CardBlock>
        )}

        <CardBlock
          width={isCompany ? '25%' : '14%'}
          paddingLeft="0.375rem"
          paddingRight="0.375rem"
          alignItems="center"
        >
          <Label>
            {card.date_register
              ? formatUtcDigitDateByFormat(
                  new Date(card.date_register),
                  'dd.MM.yyyy'
                )
              : '—'}
          </Label>
        </CardBlock>

        {!isCompany && (
          <CardBlock
            width="14%"
            paddingLeft="0.375rem"
            paddingRight="0.375rem"
            alignItems="center"
          >
            <Label>
              {card.license_expired_at
                ? formatUtcDigitDateByFormat(
                    new Date(card.license_expired_at),
                    'dd.MM.yyyy'
                  )
                : '—'}
            </Label>
          </CardBlock>
        )}
        <CardBlock
          alignItems="center"
          width={isCompany ? '25%' : '14%'}
          paddingLeft="0.375rem"
          paddingRight="0rem"
        >
          <Label>
            {card.date_last_enter
              ? `${formatUtcDigitDateByFormat(
                  new Date(card.date_last_enter),
                  'dd.MM.yyyy'
                )}`
              : '—'}
          </Label>
          {card.date_last_enter && (
            <Label>
              {formatUtcDigitDateByFormat(
                new Date(card.date_last_enter),
                'HH:mm'
              )}
            </Label>
          )}
        </CardBlock>
      </RestBlock>
      <CardButtons width="4rem" gap="0.5rem" row>
        {role === 'syn_admin' && card?.company && card?.customer_id && (
          <ToggleButton
            type="icon_tertiary"
            defaultPadding="0.375rem"
            borderRadius="0.25rem"
            height="1.75rem"
            id={`card-button-2-${card.id}`}
            isGreen={card?.license_id}
            onClick={handleToggleAccess}
          >
            <Icon iconType={card?.license_id ? 'unlock' : 'lock'} size="1rem" />
          </ToggleButton>
        )}
      </CardButtons>
    </UserCardWrapper>
  )
}

export default connect()(UserCard)
