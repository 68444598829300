const AdminSvg = ({ headColor, bodyColor }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Type=Admin">
        <path
          d="M9.99984 0L1.6665 3.63636V9.09091C1.6665 14.1364 5.22206 18.8545 9.99984 20C14.7776 18.8545 18.3332 14.1364 18.3332 9.09091V3.63636L9.99984 0Z"
          fill={bodyColor}
        />
        <path
          d="M9.99959 3.33344C10.5765 3.33344 11.1404 3.49864 11.62 3.80817C12.0996 4.11769 12.4735 4.55763 12.6942 5.07235C12.915 5.58707 12.9728 6.15345 12.8602 6.69987C12.7477 7.2463 12.4699 7.74822 12.062 8.14217C11.6541 8.53612 11.1344 8.8044 10.5686 8.91309C10.0028 9.02178 9.41638 8.966 8.88343 8.75279C8.35048 8.53959 7.89496 8.17854 7.57447 7.71531C7.25399 7.25207 7.08293 6.70745 7.08293 6.15033C7.08293 5.40324 7.39022 4.68675 7.9372 4.15848C8.48418 3.63021 9.22604 3.33344 9.99959 3.33344ZM9.99959 10.7512C11.944 10.7512 15.8329 11.7747 15.8329 13.6433C15.1944 14.5729 14.3276 15.3356 13.3097 15.8631C12.2919 16.3907 11.1547 16.6668 9.99959 16.6668C8.84445 16.6668 7.70727 16.3907 6.68944 15.8631C5.67161 15.3356 4.80479 14.5729 4.16626 13.6433C4.16626 11.7747 8.05515 10.7512 9.99959 10.7512Z"
          fill={headColor}
        />
      </g>
    </svg>
  )
}

export default AdminSvg
