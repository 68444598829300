import { memo } from 'react'
import { connect } from 'react-redux'

import { resetPagination, setSearchSortingParam } from 'store/actions/search'

import { setLiteratureListView } from 'store/actions/literature'
import SortPanel from 'components/SortPanel'
import TripleToggleSwitch from 'components/common/customTripleSwitch'

import { setLiteratureSearch } from 'store/actions/crud'

import { LiteratureSwitchWrapper, Panel } from './index.style'
import { SORT_TYPE_CONFIG, litListViewOptions, valueSelector } from './config'

const PanelWithSort = memo(
  ({
    sortType,
    setSearchSortingParam,
    sortDirection,
    litSearchStatus,
    litData,
    setLiteratureSearch,
    showOnlySort,
    totalLitFound,
    setLiteratureListView,
    resetPagination,
  }) => {
    const handleChangeSorting = (type, value) => {
      if (showOnlySort || (type === 'direction' && value === sortDirection))
        return
      setSearchSortingParam(type, value)
      if (value === 'relevance' && sortDirection === 'asc')
        setSearchSortingParam('direction', 'desc')
      setLiteratureSearch({})
      resetPagination()
    }

    const handleListView = (value) => {
      const valueToUse = valueSelector?.[value]
      valueToUse && setLiteratureListView(valueToUse)
    }

    if (!litData?.length) return null

    return (
      <Panel showOnlySort={showOnlySort}>
        <SortPanel
          {...{ sortType, showOnlySort }}
          onChangeSorting={handleChangeSorting}
          sortOptions={SORT_TYPE_CONFIG}
          switcherID="litSearchDirection"
        />

        {(litSearchStatus === 'done' || litSearchStatus === 'loading') &&
          totalLitFound > 0 && (
            <LiteratureSwitchWrapper>
              <TripleToggleSwitch
                switcherID="literature"
                options={litListViewOptions}
                onChange={handleListView}
              />
            </LiteratureSwitchWrapper>
          )}
      </Panel>
    )
  }
)

const mapStateToProps = (state) => {
  return {
    sortType: state.search.sorting.type,
    sortDirection: state.search.sorting.direction,
    litSearchStatus: state.crud.litSearch.status,
    litData: state.crud.litSearch.result.data,
    totalLitFound: state.crud.litSearch.totalLitFound,
  }
}

const mapDispatchToProps = {
  setSearchSortingParam,
  setLiteratureSearch,
  setLiteratureListView,
  resetPagination,
}

PanelWithSort.displayName = 'PanelWithSort'
export default connect(mapStateToProps, mapDispatchToProps)(PanelWithSort)
