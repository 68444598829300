import styled, { css } from 'styled-components'

import Icon from 'components/Icon'
import { Label } from 'components/common/text/index.style'

export const SelectContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: ${({ width }) => (width ? width : '11.75rem')};
  height: 2.25rem;
  border-radius: 0.75rem;
  padding: 0.625rem 1rem;
  outline: none;
  background: ${({ theme, withWhiteBackground }) =>
    withWhiteBackground
      ? theme.colors.backgrounds.primary
      : theme.colors.backgrounds.secondary};

  color: ${({ disabled, theme }) =>
    disabled ? theme.colors.text.tertiary : theme.colors.text.primary};

  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1rem;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};

  &:hover {
    & > svg {
      color: ${({ disabledOpening, theme }) =>
        disabledOpening
          ? theme.colors.icons.tertiary
          : theme.colors.icons.accentPrimary};
    }
  }

  & > svg {
    color: ${({ isOpen, theme }) =>
      isOpen ? theme.colors.icons.accentPrimary : theme.colors.icons.tertiary};
  }
`

export const SelectList = styled.ul`
  position: absolute;
  margin: 0;
  padding: ${({ padding }) =>
    padding ? padding : '0.25rem 0 0.25rem 0.25rem'};
  list-style: none;
  background: ${({ theme }) => theme.colors.backgrounds.contextMenu};

  box-shadow: ${({ theme }) => theme.shadow.default};

  backdrop-filter: blur(30px);
  transform: ${({ withSelectListUp }) =>
    withSelectListUp &&
    css`translateY(calc(-100% - 3.25rem))
		`};

  border: ${({ theme }) => `1.2px solid ${theme.colors.icons.white}`};

  display: flex;
  flex-direction: column;
  align-items: center;

  border-radius: 0.75rem;
  width: 100%;
  left: 0;
  top: calc(100% + 0.5rem);
  z-index: 100;
  cursor: default;
`

export const SelectItem = styled.li`
  padding: 0.625rem 0.75rem;
  cursor: pointer;
  border-radius: 0.5rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  &:hover {
    color: #42c1bf;
    background: ${({ theme }) => theme.colors.backgrounds.contextMenuHover};
  }

  color: ${({ selected, theme }) =>
    selected ? theme.colors.text.accentPrimary : theme.colors.text.secondary};
`

export const ArrowDownSvg = styled(Icon)`
  transform: ${({ open }) => (open ? 'rotate(180deg)' : 'none')};
  transition: all 0.3s ease;
  color: inherit;
`

export const IconWrapper = styled.div`
  position: absolute;
  right: 0.25rem;
  border-radius: 0.5rem;
  padding: 0.375rem;
  max-width: 1.75rem;
  height: 1.75rem;
  background: ${({ theme, withWhiteBackground }) =>
    withWhiteBackground
      ? theme.colors.backgrounds.secondary
      : theme.colors.backgrounds.primary};
  color: ${({ isOpen, theme }) =>
    isOpen ? theme.colors.icons.accentPrimary : theme.colors.icons.tertiary};

  &:hover {
    color: ${({ theme }) => theme.colors.icons.accentPrimary};
  }
`
export const InputWrapper = styled.div`
  width: 100%;
  overflow: hidden;
  padding: 0.75rem;
  padding-left: 0.5rem;
  padding-bottom: ${({ withValues }) => (withValues ? '0.75rem' : 0)};
`
export const SelectedLabel = styled(Label)`
  width: 100%;
  white-space: nowrap;
  word-break: nobreak;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 1rem;
`
export const SelectItems = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: ${({ withSelectListUp }) =>
    withSelectListUp ? '23rem' : '14rem'};
`
