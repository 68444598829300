import { format } from 'date-fns'
import Latex from 'react-latex-next'
import { useTheme } from 'styled-components'
import { useTranslation } from 'react-i18next'
import { connect, useDispatch } from 'react-redux'
import { useCallback, useMemo, useState } from 'react'

import CustomButton from 'components/common/customButton'
import { handleShowContextMenu } from 'store/actions/contextMenu'
import { addSimpleNotification } from 'store/actions/notifications'
import { Caption, Label, Separator } from 'components/common/text/index.style'
import { setLitDialogConfig, setOpenLitDialog } from 'store/actions/literature'
import {
  LANGUAGES_KEYS,
  footerOptions,
  getLanguageKeys,
  languages,
} from './config'
import {
  CardHeader,
  TypeRow,
  TypeNumber,
  CardInfo,
  InfoRow,
  CardFooter,
  CardAbstract,
  CaptionAuthors,
  FooterButtons,
  Header,
  CardRightButtons,
  CardButton,
  CardWrapper,
} from './index.style'
import Icon from '../Icon'

const LiteratureCard = ({ data, litSearchSmiles }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const theme = useTheme()
  const documentCode = data.type === 'patent' ? data.patent_number : data.doi
  const [currentLanguage, setCurrentLanguage] = useState(
    data?.language ||
      Object.keys(data).filter((k) => LANGUAGES_KEYS.includes(k))?.[0]
  )

  const handleOpenContextMenu = (e) => {
    e.stopPropagation()
    dispatch(
      handleShowContextMenu({
        e,
        menu: 'literatureCardMenu',
        item: {
          dataType: data?.type,
          documentCode,
          disabledActions: {
            copy_literature_link: true,
          },
        },
      })
    )
  }

  const handleOpenDialog = useCallback(
    (e) => {
      e?.stopPropagation()
      if (e.target.tagName === 'A') return
      dispatch(setOpenLitDialog(true))
      dispatch(
        setLitDialogConfig({
          id: data?.[currentLanguage]?.id ?? data?.['en']?.id, // ?? data?.['en']?.id временная мера, убрать, как только бэк начнет отдавать правильные ключи в data (data.languge "ru"->есть ключ data.ru:{}, data.languge "de"->есть ключ de:{},)
          type: data?.type,
          litSearchSmiles,
          language: currentLanguage,
          title: data?.[currentLanguage]?.title,
          abstract: data?.[currentLanguage]?.abstract,
        })
      )
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentLanguage, dispatch]
  )

  const handleOpenIndividualLitPage = () =>
    window.open(
      `/literature/${data.type}-${
        data.type === 'patent' ? currentLanguage : ''
      }-${data?.[currentLanguage]?.id ?? data?.['en']?.id}`
    )

  const authors = useMemo(() => {
    if (
      !data?.[currentLanguage]?.authors ||
      !data?.[currentLanguage].authors?.length
    )
      return ''
    return data[currentLanguage].authors.join(', ')
  }, [data, currentLanguage])

  const owners = useMemo(() => {
    if (
      !data?.[currentLanguage]?.company ||
      !data?.[currentLanguage].company?.length
    )
      return ''
    return data[currentLanguage].company.join(', ')
  }, [data, currentLanguage])

  const changeLanguage = (e) => {
    e.stopPropagation()
    setCurrentLanguage(currentLanguage === 'en' ? 'ru' : 'en')
  }

  const isLanguageChangeable = useMemo(
    () => getLanguageKeys(data)?.length > 1,
    [data]
  )

  return (
    <>
      <CardWrapper
        isFullMode={false}
        onClick={handleOpenIndividualLitPage}
        padding="1rem"
        gap="1rem"
        minHeight="15.5rem"
      >
        <CardHeader>
          <Header className="header-title">
            <Latex>
              {data?.[currentLanguage]?.title
                ? String.raw({
                    raw: data?.[currentLanguage]?.title,
                  }).replaceAll(/\\/g, '\\')
                : ''}
            </Latex>
          </Header>
          <TypeRow>
            <Label>
              {data.type === 'article'
                ? t('literatureCard.doi')
                : t('literatureCard.patentNumber')}
            </Label>
            <TypeNumber isLink={data.type === 'article'}>
              {data.type === 'article' ? (
                <a
                  target="_blank"
                  href={`https://doi.org/${documentCode}`}
                  rel="noreferrer"
                >
                  {documentCode}
                </a>
              ) : (
                documentCode
              )}
            </TypeNumber>
            {data.type === 'article' && <Icon iconType="share" size="1rem" />}
            <Icon
              className="copy-name-icon"
              iconType="copy"
              size="1rem"
              dataTest="literature-card-copy-btn"
              onClick={(e) => {
                e.stopPropagation()
                navigator.clipboard.writeText(documentCode)
                navigator.clipboard.writeText(documentCode)
                dispatch(
                  addSimpleNotification(
                    `notification.${
                      data.type === 'article'
                        ? 'DOI_copied'
                        : 'patent_number_copied'
                    }`
                  )
                )
              }}
            />
          </TypeRow>
          <CardAbstract>
            <Latex>
              {data?.[currentLanguage]?.abstract
                ? String.raw({
                    raw: data?.[currentLanguage]?.abstract,
                  }).replaceAll(/\\/g, '\\')
                : ''}
            </Latex>
          </CardAbstract>
          {isLanguageChangeable && (
            <CustomButton
              type={'text_accent'}
              gap={'0.25rem'}
              onClick={changeLanguage}
            >
              <div>
                {currentLanguage === data?.language
                  ? t('literatureCard.translate')
                  : t('literatureCard.show_original')}
              </div>
              <Icon iconType={'translate'} size={'1rem'} />
            </CustomButton>
          )}
        </CardHeader>
        <CardFooter>
          <CardInfo>
            {authors && (
              <CaptionAuthors color={theme.colors.text.secondary}>
                {`${t('literatureCard.authors')} ${authors}`}
              </CaptionAuthors>
            )}
            {data?.[currentLanguage]?.company && (
              <CaptionAuthors color={theme.colors.text.secondary}>
                {`${t('literatureCard.owners')}: ${owners}`}
              </CaptionAuthors>
            )}
            <InfoRow>
              {data?.language && (
                <Caption
                  fontWeight={theme.fontWeight.medium}
                  color={theme.colors.text.secondary}
                >
                  {languages[data.language]}
                </Caption>
              )}
              {!!data?.[currentLanguage]?.journal?.length && (
                <>
                  {data?.language && (
                    <Separator>
                      <Icon iconType="dotSeparator" size="1rem" />
                    </Separator>
                  )}
                  <Caption
                    fontSize={theme.text[11]}
                    fontWeight={theme.fontWeight.medium}
                    color={theme.colors.text.secondary}
                  >
                    {data[currentLanguage].journal?.map((el) => el)}
                  </Caption>
                </>
              )}
              {!!data?.[currentLanguage]?.publisher && (
                <>
                  {(data?.language ||
                    !!data?.[currentLanguage]?.journal?.length) && (
                    <Separator>
                      <Icon iconType="dotSeparator" size="1rem" />
                    </Separator>
                  )}
                  <Caption
                    fontWeight={theme.fontWeight.medium}
                    color={theme.colors.text.secondary}
                  >
                    {data?.[currentLanguage]?.publisher}
                  </Caption>
                </>
              )}
              {!!data?.published_date && (
                <>
                  {(data?.language ||
                    !!data?.[currentLanguage]?.journal?.length ||
                    !!data?.[currentLanguage]?.publisher) && (
                    <Separator>
                      <Icon iconType="dotSeparator" size="1rem" />
                    </Separator>
                  )}
                  <Caption
                    fontWeight={theme.fontWeight.medium}
                    color={theme.colors.text.secondary}
                  >
                    {format(new Date(data?.published_date), 'dd.MM.yyyy')}
                  </Caption>
                </>
              )}
            </InfoRow>
          </CardInfo>
          <FooterButtons>
            {footerOptions.map((el, key) => {
              return (
                <CustomButton
                  type="text"
                  key={`${el.value}-${key}`}
                  disabled={el.isDisabled}
                  onClick={(e) => {
                    if (el.isDisabled) e.stopPropagation()
                  }}
                  gap="0.25rem"
                >
                  {' '}
                  {t(el.value)}{' '}
                  {!!data[el.numberPointer] && `(${data[el.numberPointer]})`}
                  <Icon iconType={'arrowRight'} size="1rem" />
                </CustomButton>
              )
            })}
          </FooterButtons>
        </CardFooter>
        <CardRightButtons>
          <CardButton type="text" onClick={handleOpenDialog}>
            <Icon iconType="eyeOpen" size="1.25rem" />
          </CardButton>
          <CardButton
            type="text"
            onClick={handleOpenContextMenu}
            data-test="literature-card-context-menu-btn"
          >
            <Icon iconType="threeDots" size="1.25rem" />
          </CardButton>
        </CardRightButtons>
      </CardWrapper>{' '}
    </>
  )
}

export default connect()(LiteratureCard)
