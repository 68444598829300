import styled from 'styled-components'
import { Subheadline } from 'components/common/text/index.style'

export const Container = styled.div`
  user-select: none;

  width: 100%;
  gap: ${({ gap }) => (gap ? gap : '1.25rem')};
  display: flex;
  flex-direction: column;
  align-items: ${({ alignItems }) => alignItems && alignItems};
  height: ${({ height }) => height && height};
  justify-content: ${({ justify }) => justify && justify};
`
export const DialogActions = styled.div`
  width: 100%;
  gap: 0.75rem;
  display: flex;
`
export const DropzoneWrapper = styled.div`
  gap: 0.25rem;
  display: flex;
  flex-direction: column;
  svg {
    color: ${({ theme }) => theme.colors.text.primary};
  }
`
export const MyDropZoneContainer = styled.div`
  margin-right: auto;
  color: ${({ theme }) => theme.colors.text.primary};
  svg {
    color: ${({ theme }) => theme.colors.text.primary} !important;
    &:hover {
      color: ${({ theme }) => theme.colors.text.accentPrimary} !important;
    }
  }
  &:hover {
    color: ${({ theme }) => theme.colors.text.accentPrimary};
    svg {
      color: ${({ theme }) => theme.colors.text.accentPrimary} !important;
    }
  }
`

export const ThumbnailsBlock = styled.div`
  gap: 1rem;
  display: flex;
  position: relative;
  background: ${({ theme, isOnlyPDF }) =>
    isOnlyPDF
      ? theme.colors.backgrounds.secondary
      : theme.colors.backgrounds.primary} !important;
  gap: 1rem;
  padding:  ${({ isOnlyPDF }) => isOnlyPDF && '1rem'};
  border-radius: 0.75rem;
    height: ${({ isOnlyPDF }) => (isOnlyPDF ? '8rem' : '10rem')}};

    &:hover{
    #pdf-remove-icon {
      opacity:100%;
    color:yellow !important;
    ${({ isOnlyPDF }) =>
      isOnlyPDF &&
      `
     opacity:100%;
    color:yellow !important;
    `}
  }
    }
`

export const ThumbnailContainer = styled.div`
  gap: 0.25rem;
  display: flex;
  position: relative;
  flex-direction: column;
  width: ${({ isOnlyPDF }) => isOnlyPDF && '100%'}};

   #image-delete-icon {
     ${({ isOnlyPDF }) =>
       isOnlyPDF &&
       `
       position: absolute;
       right:0;
       top:0;
     `}
   }
`

export const Thumbnail = styled.img`
  width: 10.25rem;
  height: 7rem;
  border-radius: 0.75rem;
`
export const ThumbnailPDF = styled.div`
  // width: 100%;
  // height: 2rem;
  // border-radius: 0.75rem;
  // position: relative;

  // #pdf-remove-icon {
  //   position: absolute;
  //   top: 0.5rem;
  //   right: 0.5rem;
  // }
  width: 8.25rem;
  height: 4.5rem;
  border-radius: 0.75rem;
`

export const FileInfo = styled.div`
  gap: 0.5rem;
  display: grid;
  width:  ${({ isOnlyPDF }) => (isOnlyPDF ? '100%' : '10.25rem')}};
  cursor: pointer;
  justify-content: space-between;
  grid-template-columns: 1fr 1rem;

  div {
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &:hover {
    svg {
      transition: all 0.3s ease;
      color: ${({ theme }) => theme.colors.statuses.error} !important;
    }
  }
`

export const OpenedMessage = styled.div`
  display: grid;
  padding: 0.75rem;
  row-gap: 0.75rem;
  background-color: lightblue;
`

export const DataNameBlock = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: 0.75rem;
  justify-content: space-between;
`
export const SuccessText = styled.div`
  gap: 0.5rem;
  display: flex;
  flex-direction: column;
`
export const AdditionalText = styled(Subheadline)`
  white-space: pre-line;
  font-size: ${({ theme }) => theme.text[14]};
  line-height: ${({ theme }) => theme.text[18]};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
`
export const FeedbackSection = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  row-gap: ${({ rowGap }) => (rowGap ? rowGap : '0.5rem')};

  svg {
    color: ${({ theme }) => theme.colors.icons.tertiary};
    transition: color 0.3s;
    &:hover {
      color: ${({ theme }) => theme.colors.icons.accentPrimary};
      cursor: pointer;
    }
  }
`
export const Modules = styled.div`
  gap: 0.5rem;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  overflow-y: auto;
  align-items: center;
`
export const SourceLinkWrapper = styled.div`
  gap: 0.5rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: start;
`
export const AgreementBlock = styled.div`
  margin: 0.75rem 0 1rem 0;
  row-gap: 0.75rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  color: ${({ theme }) => theme.colors.text.secondary};
`

export const AgreementBlockItem = styled.div`
  column-gap: 0.5rem;
  width: 100%;
  display: flex;
  align-items: ${({ align }) => (align ? align : 'center')};
  justify-content: start;
`
export const FilesScrollbarWrapper = styled.div`
  position: relative;
  background: ${({ theme, isOnlyPDF }) =>
    isOnlyPDF
      ? theme.colors.backgrounds.secondary
      : theme.colors.backgrounds.primary};
  border-radius: 0.75rem;
  height: ${({ isOnlyPDF }) => (isOnlyPDF ? '8rem' : '10rem')}};

   ${({ isOnlyPDF, isScrollRight }) =>
     !isOnlyPDF &&
     !isScrollRight &&
     `
     &:after {
   right: 0;
   content: '';
   height: 100%;
   width: 1rem;
   z-index:0;
   bottom: 0;
   position: absolute;
   background: linear-gradient(
     270deg,
     #fff 66.67%,
     rgba(255, 255, 255, 0) 100%
   );
 }
  `}
  ${({ isOnlyPDF, isScrollLeft }) =>
    !isOnlyPDF &&
    !isScrollLeft &&
    `
   &:before {
     left: 0;
     content: '';
     height: 100%;
     width: 1rem;
     z-index: 1;
     bottom: 0;
     position: absolute;
     background: linear-gradient(
       90deg,
       #fff 66.67%,
       rgba(255, 255, 255, 0) 100%
     );
   }
 `}
`

export const ExperimentalInfoText = styled.div`
  margin-top: 0.25rem;
  display: flex;
  align-items: center;
  width: 100%;
  column-gap: 0.5rem;
`
export const ExperimentalInfoTextIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 1.75rem;
  > svg {
    color: ${({ theme }) => theme.colors.icons.accentPrimary};
    cursor: auto !important;
  }
`

export const FeedbackFilewHeader = styled.div`
  display: flex;
  flex-direction: ${({ isOnlyPDF }) => (isOnlyPDF ? 'column' : 'row')};
  justify-content: ${({ isOnlyPDF }) => !isOnlyPDF && 'space-between'};
  row-gap: ${({ isOnlyPDF }) => isOnlyPDF && '0.75rem'};
`
