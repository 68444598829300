import { useLayoutEffect, useState } from 'react'

export const useOverflow = (ref, outerRef) => {
  const [isOverflow, setIsOverflow] = useState(undefined)

  useLayoutEffect(() => {
    if (ref.current && outerRef.current) {
      const trigger = () => {
        const hasOverflow =
          ref.current.scrollWidth > outerRef.current.clientWidth

        setIsOverflow(hasOverflow)
      }
      trigger()
    }
  }, [ref, outerRef])

  return isOverflow
}
