export const linksList = [
  {
    id: 0,
    name: 'sidebar.search',
    link: '/search',
    img: 'search',
    demo: true,
    tips: [
      'hot_tips.molecules.left_click',
      'hot_tips.molecules.select',
      'hot_tips.molecules.select_all',
    ],
    onMobile: true,
  },
  {
    id: 11,
    name: 'sidebar.individual',
    link: '/moleditor',
    img: 'molecularEditor',
    demo: true,
    onMobile: false,
  },
  {
    id: 1,
    name: 'sidebar.molecules',
    link: '/datasets',
    img: 'datasets',
    demo: true,
    tips: ['hot_tips.datasets.calculate'],
    onMobile: true,
  },
  {
    id: 4,
    name: 'sidebar.mmap',
    link: '/synmap',
    img: 'synmap',
    demo: true,
    tips: ['hot_tips.synmap.deselect'],
    onMobile: false,
  },
  {
    id: 2,
    name: 'sidebar.reactions',
    link: '/reactions',
    img: 'reactions',
    demo: true,
    tips: ['hot_tips.reactions.model_confidence'],
    onMobile: true,
  },
  {
    id: 16,
    name: 'sidebar.spectra_prediction',
    link: '/spectra',
    img: 'predictSpectra',
    demo: true,
    onMobile: true,
  },
  {
    id: 19,
    name: 'sidebar.synth_cost',
    link: '/synthcost',
    img: 'forecast',
    demo: true,
    onMobile: true,
  },
  {
    id: 15,
    name: 'sidebar.pdf2smi',
    link: '/pdf2smiles',
    img: 'document',
    demo: true,
    tips: ['hot_tips.pdf2smiles.drag-n-drop'],
    onMobile: true,
    tag: 'pdf2SmilesTag',
  },
  {
    id: 14,
    name: 'sidebar.smiles2iupac',
    link: '/smiles2iupac',
    img: 'iupac',
    demo: true,
  },

  {
    id: 18,
    name: 'sidebar.qsar',
    link: '/statistics',
    img: 'statistics',
    onMobile: true,
    demo: true,
  },
  {
    id: 13,
    name: 'sidebar.admin',
    link: '/admin-panel',
    img: 'admin_settings',
    admin: true,
    synAdmin: true,
    demo: true,
    onMobile: true,
  },
  {
    id: 20,
    name: 'sidebar.admin',
    link: '/admin-panel/company',
    img: 'admin_settings',
    admin: true,
    synAdmin: false,
    demo: true,
    onMobile: true,
  },
]
