import { useTranslation } from 'react-i18next'
import { useTheme } from 'styled-components'
import CompanyCard from '../companyCard'
import { v4 as uuidv4 } from 'uuid'
import { AdminCardsWrapper, Cards } from './index.style'
import { connect, useDispatch } from 'react-redux'
import SkeletonComponent from 'components/common/skeleton'
import { useEffect } from 'react'
import { getCustomers } from 'store/actions/adminPanel'

const Customers = ({ items, getItemsLoading, getItemsError, firstRequest }) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const dispatch = useDispatch()

  useEffect(() => {
    if (firstRequest) dispatch(getCustomers({ limit: 24, offset: 0 }))
  }, [firstRequest])

  return (
    <AdminCardsWrapper>
      <Cards>
        {getItemsLoading &&
          Array.from({ length: 12 }).map((_, i) => (
            <SkeletonComponent
              height="9rem"
              width="100%"
              variant="dark"
              key={`skeleton-index-${i}`}
            />
          ))}
        {!!items.length &&
          !getItemsLoading &&
          !getItemsError &&
          items.map((company) => (
            <CompanyCard key={uuidv4()} company={company} />
          ))}
      </Cards>
    </AdminCardsWrapper>
  )
}

const mapStateToProps = (state) => {
  return {
    items: state.adminPanel.customers.items,
    getItemsLoading: state.adminPanel.customers.loading,
    getItemsError: state.adminPanel.customers.error,
    firstRequest: state.adminPanel.customers.firstRequest,
  }
}

export default connect(mapStateToProps)(Customers)
