import { scrollbarStyles } from 'components/common/scrollbar/scrollbar.style'
import styled from 'styled-components'

export const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
`
export const PdfWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  column-gap: 1rem;
`

export const PdfPreview = styled.div`
  display: flex;
  flex-direction: column;
  width: 4.5rem;
  max-width: 5rem;
  min-width: 5rem;
  height: 100%;
  overflow: auto;
  row-gap: 0.25rem;
  max-height: ${({ isDocumentHeaderCollapsed }) =>
    isDocumentHeaderCollapsed ? 'calc(100% - 2.25rem)' : '100%'};
  margin-top: ${({ isDocumentHeaderCollapsed }) =>
    isDocumentHeaderCollapsed ? '1.5rem' : '-2.25rem'};
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
  transition: max-height, margin-top 0.5s;
`

export const PdfDocument = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: auto;

  ${scrollbarStyles}

  & .textLayer {
    display: none;
  }

  & .page {
    border-radius: 12px;
    overflow: hidden;
    margin-bottom: 16px;
    position: relative;
  }

  & .highlight-layer {
    position: absolute;
    overflow: hidden;
    z-index: 1;
    inset: 0;
  }

  & .highlight-inview-container {
    width: 100%;
    height: 100%;
    z-index: -1;
    position: absolute;
  }
`

export const PageIdentificator = styled.div`
  position: absolute;
  bottom: 0.25rem;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
  padding: 0.25rem;
  width: 1.25rem;
  height: 1.25rem;
  border-radius: 0.5rem;
  font-size: 0.75rem;
  line-height: 1rem;
  text-align: center;
  background: ${({ selected, theme }) =>
    selected
      ? theme?.colors?.backgrounds?.accent
      : theme?.colors?.backgrounds?.primary};
  color: ${({ selected, theme }) =>
    selected ? theme?.colors?.text?.white : theme?.colors?.text?.secondary};
  box-shadow: ${({ theme }) => theme.shadow.default};
`

export const CanvasPreviewItem = styled.div`
  position: relative;
  display: flex;
  border-radius: 0.75rem;
  box-shadow: ${({ selected, theme }) =>
    selected ? theme.shadow.default : 'none'};
  &::before {
    content: '';
    z-index: 1;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: ${({ selected }) =>
      selected ? 'none' : 'rgba(45, 55, 69, 0.06)'};
    border-radius: 0.75rem;
    cursor: pointer;
  }

  :hover {
    ${PageIdentificator} {
      color: ${({ theme, selected }) =>
        !selected && theme?.colors?.text?.accentPrimary};
    }

    &::before {
      background: none;
    }
  }
`
