import { useTheme } from 'styled-components'
import { useTranslation } from 'react-i18next'

import { Caption, Label } from 'components/common/text/index.style'
import { getScoreBackground } from 'components/ReactionViewer/utils/utils'

import { INDICATORS_CONFIG } from './config/config'
import { Container, Indicator, IndicatorRow, Indicators } from './index.style'

const ScoreHotTip = () => {
  const theme = useTheme()
  const { t } = useTranslation()

  return (
    <Container>
      <Label
        fontWeight={theme.fontWeight.light}
        color={theme.colors.text.whiteSecondary}
      >
        {t('reactions_prediction.model_confidence_indicator_description')}
      </Label>
      <Indicators>
        {INDICATORS_CONFIG.map(({ value, description }, i) => {
          const background = theme.colors.statuses[getScoreBackground(value)]
          return (
            <IndicatorRow key={i}>
              <Indicator {...{ background }}>
                <Caption color={theme.colors.text.white}>{value}%</Caption>
              </Indicator>
              <Label color={theme.colors.text.white}>
                {t(`reactions_prediction.${description}`)}
              </Label>
            </IndicatorRow>
          )
        })}
      </Indicators>
    </Container>
  )
}

export default ScoreHotTip
