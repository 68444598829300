import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { WindowContainer, Window, CloseButton, Header } from './index.style'

const CustomWindow = ({ children, isOpen, config, handleClose, window_id }) => {
  const { t } = useTranslation()

  const { header, width } = config || {}

  return (
    <WindowContainer id={window_id} isOpen={isOpen} onClick={handleClose}>
      <Window
        data-test="custom-window-inner"
        isOpen={isOpen}
        onClick={(e) => e.stopPropagation()}
        width={width}
      >
        <Header>{t(header)}</Header>
        <CloseButton iconType="close" size="1.25rem" onClick={handleClose} />
        {children}
      </Window>
    </WindowContainer>
  )
}

const mapStateToProps = (state) => {
  return {}
}

CustomWindow.displayName = 'CustomWindow'

export default connect(mapStateToProps)(CustomWindow)
