import { useTranslation } from 'react-i18next'
import { AdminPanelWrapper, SwitchWrapper, ScrollTopBtn } from './index.style'
import AdminHeader from './adminHeader/index'
import Customers from './customers'
import { useEffect, useRef, useState } from 'react'
import {
  openAddCompanyDialog,
  setAdminPanelFilterType,
} from 'store/actions/adminPanel'
import { connect, useDispatch } from 'react-redux'
import CustomSwitch from 'components/common/customSwitch'
import { SCROLL_OFFSET, SWITCH_FILTER_TYPES } from './config'
import Users from './users'
import CustomScrollbar from 'components/CustomScrollbar'
import Pagination from 'pages/MoleculesList/Pagination'
import Icon from 'components/Icon'
import CustomButton from 'components/common/customButton'
import AddCompanyDialog from './addCompanyDialog'
import { readStorage } from 'utils/storage/storage'
import { push } from 'connected-react-router'

const AdminPanelPage = ({
  tasks,
  filterType,
  notifications,
  usersPagination,
  addCompanyDialogOpen,
  customersPagination,
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const scrollRef = useRef(null)
  const [state, setState] = useState({
    scrollTopEnable: false,
    isBottom: false,
  })

  const { scrollTopEnable, isBottom } = state || {}
  const handleSwitch = (type) => {
    dispatch(setAdminPanelFilterType(type))
  }

  const { notificationsList, isHidden } = notifications

  const isNotificationsButtonShow =
    isHidden && notificationsList.length + tasks.length > 0

  const handleScroll = () => {
    const d = document.getElementById('adminContainer')
    const isNotTop = scrollRef.current?.scrollValues?.scrollTop > SCROLL_OFFSET

    const isBottomCurrent =
      scrollRef.current?.scrollValues?.scrollTop +
        d?.getBoundingClientRect()?.height +
        36 >=
      scrollRef.current?.scrollValues?.scrollHeight

    if (isNotTop === scrollTopEnable && isBottomCurrent === isBottom) {
      return
    }

    setState((prev) => ({
      ...prev,
      scrollTopEnable: isNotTop && !isBottomCurrent,
      isBottom: isBottomCurrent,
    }))
  }
  const handleScrollTop = () => scrollRef?.current?.scrollTo(0, 0)

  useEffect(() => {
    handleScrollTop()
  }, [usersPagination?.activePage, customersPagination?.activePage])

  useEffect(() => {
    const adminRole = readStorage('role')
    adminRole !== 'syn_admin' && dispatch(push('/admin-panel/company'))
  }, [])

  return (
    <AdminPanelWrapper id="adminContainer">
      <CustomScrollbar innerRef={scrollRef} onScroll={handleScroll}>
        <AdminHeader />
        <SwitchWrapper>
          <CustomSwitch
            items={SWITCH_FILTER_TYPES}
            keyTemplate="admin-panel-switch"
            active={filterType}
            btnWidth="7rem"
            bg="white"
            onClick={handleSwitch}
          />
          {filterType === 'companies' && (
            <CustomButton
              type="primary"
              gap="0.25rem"
              nowrap={true}
              onClick={() => dispatch(openAddCompanyDialog())}
            >
              <Icon iconType="add" size={'16px'} />
              {t('admin.filter.add_company')}
            </CustomButton>
          )}
        </SwitchWrapper>

        {filterType === 'companies' && (
          <>
            <Customers />
          </>
        )}
        {filterType === 'users' && <Users />}
        {isBottom && (
          <Pagination
            pageType={
              filterType === 'users' ? 'adminAllUsers' : 'adminAllCustomers'
            }
          />
        )}
        {addCompanyDialogOpen && <AddCompanyDialog />}
        <ScrollTopBtn
          visible={scrollTopEnable}
          onClick={handleScrollTop}
          isNotificationsButtonShow={isNotificationsButtonShow}
        >
          <Icon iconType="arrowTop" size="1.25rem" />
        </ScrollTopBtn>
      </CustomScrollbar>
    </AdminPanelWrapper>
  )
}

const mapStateToProps = (state) => {
  return {
    notifications: state.notifications,
    filterType: state.adminPanel.filter.type,
    tasks: state.tasks,
    usersPagination: state.adminPanel.users.pagination,
    customersPagination: state.adminPanel.customers.pagination,
    addCompanyDialogOpen: state.adminPanel.addCompanyDialogOpen,
  }
}

export default connect(mapStateToProps)(AdminPanelPage)
