import { v4 as uuidv4 } from 'uuid'
import { useDispatch, useSelector } from 'react-redux'
import { memo, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import {
  settingsFreeReactionsInc,
  settingsFreeReactionsReset,
} from 'store/actions/settings'
import {
  setMode,
  resetCalculations,
  addMoleculeToReaction,
} from 'store/actions/reactions'
import LoadingOverlay from 'components/LoadingOverlay'
import Synthesis from './components/Synthesis'
import { Container, Content } from './index.style'
import Retrosynthesis from './components/Retrosynthesis'
import { addNotification } from '../../store/actions/notifications'
import { calcSynth, calcRetro } from '../../store/actions/reactions'
import { Warning } from '../../components/IndividualPage/index.style'
import HeaderWithInstruments from './components/HeaderWithInstruments'
import { BEAM, FREEREACTIONS_MAX, MODEL_PARAM, NAUG } from './config/config'
import { readStorage } from 'utils/storage/storage'
import {
  REDIRECT_REACTIONS_TYPE,
  REDIRECT_SMILES,
} from 'components/MoleculeViewer/config/config'
import CustomScrollbar from 'components/CustomScrollbar'
import { handleDeleteKeys } from 'utils/common/common'
import { ScrollTopBtn } from 'pages/MoleculesList/index.style'
import Icon from 'components/Icon'

const ReactionListNew = memo(() => {
  const { t } = useTranslation()

  const dispatch = useDispatch()
  const scrollRef = useRef(null)

  const reaction = useSelector((store) => store.reactions)
  const notifications = useSelector((store) => store.notifications)
  const tasks = useSelector((store) => store.tasks)
  const authId = useSelector((store) => store.auth.userdata.id)
  const viewSize = useSelector((store) => store.settings.viewSize)
  const freeReactions = useSelector((store) => store.settings.freeReactions)

  const [isScrollUpPossible, setIsScrollUpPosible] = useState(false)

  const { mode, list, error, calculation, loading } = reaction || {}

  const { notificationsList, isHidden } = notifications

  const isNotificationsButtonShow =
    isHidden && notificationsList.length + tasks.length > 0

  /** EFFECTS - START */
  useEffect(() => {
    if (authId) dispatch(settingsFreeReactionsReset())

    const redirectSmiles = readStorage(REDIRECT_SMILES)
    const redirectReactionType = readStorage(REDIRECT_REACTIONS_TYPE)

    if (!redirectSmiles || !redirectReactionType) return

    dispatch(setMode(redirectReactionType))
    dispatch(addMoleculeToReaction(redirectSmiles))
    handleDeleteKeys([REDIRECT_SMILES, REDIRECT_REACTIONS_TYPE])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (calculation) dispatch(settingsFreeReactionsInc())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calculation?.length])

  useEffect(() => {
    if (error) {
      const id = uuidv4()
      const notify = {
        id,
        name: 'notification.error',
        text: `error.${
          error?.endsWith('is not a smiles')
            ? 'not_valid_smiles'
            : 'error_occured'
        }`,

        notification_type: 'error',
        autoRemove: true,
        timeout: 5000,
      }
      mode === 'forward' && addNotification(notify)
      mode === 'forward'
        ? calcSynth(list, MODEL_PARAM, NAUG, BEAM)
        : calcRetro(list[0])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error])

  /** EFFECTS - END */

  /** METHODS - START */
  const handleScrollTop = () => scrollRef?.current?.scrollTo(0, 0)

  const handleChangeMode = (value) => {
    if (!value) return
    if (value === 'reverse' && list.length > 1) {
      const id = uuidv4()
      const notify = {
        id,
        name: 'notification.retrosynthesis_warning',
        text: 'notification.retrosynthesis_warning_small',
        notification_type: 'warning',
        autoRemove: true,
        timeout: 5000,
      }
      return dispatch(addNotification(notify))
    }
    if (calculation) dispatch(resetCalculations())
    dispatch(setMode(value))
  }

  const handleCalcReaction = async () => {
    // 2.07.24 commented out until the inputs are prepared
    // if (mode === 'reverse') {
    //   const validateSmiles = await isValidSMILES(list[0]?.toString())
    //   if (!validateSmiles) {
    //     const id = uuidv4()
    //     const notify = {
    //       id,
    //       name: 'notification.error',
    //       notification_type: 'error',
    //       text: 'pdf2smiles.viewer.invalid_smiles',
    //       autoRemove: true,
    //       timeout: 5000,
    //     }
    //     return dispatch(addNotification(notify))
    //   }
    // }
    const id = uuidv4()
    const notify = {
      id,
      name:
        mode === 'forward'
          ? 'notification.synthesis_start'
          : 'notification.retrosynthesis_start',
      notification_type: 'success',
      autoRemove: true,
      timeout: 5000,
    }
    dispatch(addNotification(notify))
    if (mode === 'forward') dispatch(calcSynth(list, MODEL_PARAM, NAUG, BEAM))
    if (mode === 'reverse') dispatch(calcRetro(list[0]))
  }
  /** METHODS - END */

  if (!authId && freeReactions > FREEREACTIONS_MAX) {
    return <Warning>{t('individual.warn_text')}</Warning>
  }

  const handleScroll = () =>
    setIsScrollUpPosible(
      scrollRef?.current && scrollRef.current?.scrollValues?.scrollTop > 0
    )

  return (
    <CustomScrollbar
      className="margin-12-right"
      innerRef={scrollRef}
      onScroll={handleScroll}
    >
      <Container>
        <Content mode={mode}>
          <HeaderWithInstruments
            {...{ t, mode, viewSize }}
            onClickToggleBtn={handleChangeMode}
          />
          {mode === 'forward' && (
            <Synthesis onCalcReaction={handleCalcReaction} />
          )}
          {mode === 'reverse' && (
            <Retrosynthesis onCalcReaction={handleCalcReaction} />
          )}
          {loading && <LoadingOverlay />}
          {calculation?.length === 0 && (
            <div>{t('reactions_prediction.prediction_failed')}</div>
          )}
        </Content>
      </Container>
      <ScrollTopBtn
        visible={isScrollUpPossible}
        onClick={handleScrollTop}
        isNotificationsButtonShow={isNotificationsButtonShow}
      >
        <Icon iconType="arrowTop" size="1.25rem" />
      </ScrollTopBtn>
    </CustomScrollbar>
  )
})

ReactionListNew.displayName = 'ReactionListNew'
export default ReactionListNew
