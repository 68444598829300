import { Label } from 'components/common/text/index.style'

import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
  justify-content: space-between;
`

export const Content = styled.div`
  gap: 1.25rem;
  display: flex;
  flex-direction: column;
`
export const Info = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  white-space: pre-line;

  span {
    color: ${({ theme }) => theme.colors.text.accentPrimary};
  }
  ${Label} {
    position: relative;
    width: 100%;
  }
`

export const Footer = styled.div`
  gap: 2rem;
  width: 100%;
  display: flex;
  margin-top: auto;
  flex-direction: column;
`
export const SurveySection = styled.div`
  display: flex;
  gap: 2rem;
  padding: 0.5rem 0.5rem 0.5rem 0.75rem;
  gap: 0.75rem;
  border-radius: 0.75rem;

  align-items: center;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.colors.backgrounds.accentTertiary};
`

export const LicenseKeyBlock = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: flex-end;
`
export const Buttons = styled.div`
  gap: 0.5rem;
  display: flex;
  align-items: center;
`
