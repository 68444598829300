import {
  REMOVE_NOTIFICATION,
  ADD_NOTIFICATION,
  HIDE_NOTIFICATIONS,
  ADD_SIMPLE_NOTIFICATION,
  HIDE_SIMPLE_NOTIFICATION,
  REMOVE_SIMPLE_NOTIFICATION,
  UPDATE_NOTIFICATION,
} from '../constants/notifications'
const initialState = {
  notificationsList: [],
  isHidden: false,
  simpleNotification: {
    text: null,
    visible: false,
  },
}

const notificationsReducer = (state = initialState, action) => {
  let newState
  switch (action.type) {
    case REMOVE_NOTIFICATION:
      newState = {
        ...state,
        notificationsList: state.notificationsList.filter(
          (task) => task.id !== action.id
        ),
      }
      return newState

    case ADD_NOTIFICATION:
      return {
        ...state,
        notificationsList: [...state.notificationsList, action.task],
      }

    case HIDE_NOTIFICATIONS:
      return { ...state, isHidden: !state.isHidden }

    case ADD_SIMPLE_NOTIFICATION:
      return {
        ...state,
        simpleNotification: { visible: true, text: action.text },
      }

    case HIDE_SIMPLE_NOTIFICATION:
      return {
        ...state,
        simpleNotification: { ...state.simpleNotification, visible: false },
      }

    case REMOVE_SIMPLE_NOTIFICATION:
      return {
        ...state,
        simpleNotification: { visible: false, text: null },
      }
    case UPDATE_NOTIFICATION:
      return {
        ...state,
        notificationsList: state.notificationsList.map((notification) => {
          if (notification.id === action.id) {
            return {
              ...notification,
              ...action.updated_data,
            }
          }
          return notification
        }),
      }
    default:
      return state
  }
}

export default notificationsReducer
