import { Subheadline } from 'components/common/text/index.style'
import styled from 'styled-components'

export const Content = styled.div`
  gap: 24px;
  display: flex;
  max-width: 600px;
  min-width: 360px;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  & > svg {
    width: 64px;
    height: 64px;
  }
`

export const TextContent = styled.div`
  gap: ${({ gap }) => (gap ? gap : '8px')};
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 100%;
`

export const Buttons = styled.div`
  gap: 12px;
  width: 100%;
  display: grid;
  align-items: center;
  grid-template-columns: ${({ withConfirm }) =>
    withConfirm ? '1fr 1fr' : '1fr'};
  div {
    width: unset;
    &:first-of-type {
      order: ${({ withConfirm, confirmFirst }) =>
        withConfirm && confirmFirst && '2'};
    }
  }
`
export const Text = styled(Subheadline)`
  width: 100%;
  overflow: hidden;
  text-align: center;
  white-space: nowrap;
  text-overflow: ellipsis;
  white-space: pre-line;
`
