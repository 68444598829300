export const getScore = (initScore) => {
  const score = initScore * 100
  if (score === 100) return 100
  if (score > 99) return 99
  return Math.round(score)
}

export const getScoreBackground = (score) => {
  if (score <= 79) return 'error'
  if (score <= 94) return 'attention'
  return 'success'
}
