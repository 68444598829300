import styled from 'styled-components'

import { TitleSecondary } from 'components/common/text/index.style'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 1.5rem 1.25rem 1.5rem 0;
`

export const HeaderText = styled(TitleSecondary)`
  margin-bottom: -0.5rem;
  padding-left: 0.75rem;
  z-index: 6;
`

export const CardsContainer = styled.div`
  display: flex;
  gap: 0.5rem;
  padding-left: 0.75rem;
  padding-right: 0.25rem;
  flex-direction: column;
`

export const StatisticCard = styled.div`
  border-radius: 0.75rem;
  background-color: ${({ theme }) => theme.colors.backgrounds.primary};
  mim-height: 2.75rem;
  box-shadow: ${({ theme, isCardExpanded }) =>
    isCardExpanded ? theme.shadow.default : 'none'};

  transition: all 0.2s ease;
  cursor: pointer;

  &:hover {
    div {
      color: ${({ theme }) => theme.colors.text.accentPrimary};
    }
  }
`

export const StatisticCardHeader = styled.div`
  padding: 0.25rem 0.25rem 0.25rem 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;

  color: ${({ theme, isActive }) =>
    isActive ? theme.colors.text.accentPrimary : theme.colors.text.primary};
  transition: all 0.2s ease;
  svg {
    transform: ${({ isActive }) => (isActive ? 'rotate(180deg)' : 'none')};
  }
`

export const StyledTable = styled.table`
  height: 100%;
  padding: 0 0.25rem 0.25rem;
  width: 100%;
  border-radius: 0.5rem;
  border-spacing: 0;
  tr {
    td {
      white-space: nowrap;
      padding: 0.625rem 0.75rem;
      padding-left: 2rem;
      font-size: 0.75rem;
      font-weight: ${({ theme }) => theme.fontWeight.medium};
      line-height: 1rem;

      text-align: center;
      &:first-child {
        white-space: wrap;
        padding-left: 0.75rem;
        min-width: 16.875rem;
        max-width: 16.875rem;
        width: 16.875rem;
      }

      &:nth-child(2) {
        min-width: 7rem;
        max-width: 7rem;
        width: 7rem;
        color: ${({ theme }) => theme.colors.text.accentPrimary};
      }

      &:nth-child(3) {
        min-width: 8.75rem;
        max-width: 8.75rem;
        width: 8.75rem;
      }
    }
  }

  tbody {
    tr {
      td {
        color: ${({ theme }) => theme.colors.text.primary};
        &:first-child {
          text-align: start;
        }

        &:last-child {
          td:first-child {
            border-bottom-left-radius: 0.5rem;
          }
          td:last-child {
            border-bottom-right-radius: 0.5rem;
          }
        }
      }
    }
  }

  thead {
    font-size: 0.6875rem;
    font-weight: ${({ theme }) => theme.fontWeight.medium};
    line-height: 1rem;

    color: ${({ theme }) => theme.colors.text.secondary};
    background: ${({ theme }) => theme.colors.backgrounds.secondary};

    tr {
      background: ${({ theme }) => theme.colors.backgrounds.secondary};

      td {
        color: ${({ theme }) => theme.colors.text.secondary};
        &:first-child {
          border-top-left-radius: 0.5rem;
          border-bottom-left-radius: 0.5rem;
          text-align: start;
        }
        &:nth-child(2),
        &:nth-child(3) {
          white-space: wrap;
        }
        &:last-child {
          border-top-right-radius: 0.5rem;
          border-bottom-right-radius: 0.5rem;
        }
      }
    }
  }
`

export const CategoriesCard = styled.div`
  font-size: 0.75rem;
  line-height: 1rem;
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  padding: 0.625rem 0.75rem;

  border-radius: 0.75rem;
  background-color: ${({ theme, active }) =>
    active ? theme.colors.buttons.accent : theme.colors.buttons.primary};
  white-space: nowrap;

  color: ${({ active, theme }) =>
    active ? theme.colors.text.white : theme.colors.text.secondary};

  cursor: pointer;
  transition: all 0.15s ease;

  &:hover {
    color: ${({ theme, active }) =>
      active ? theme.colors.text.white : theme.colors.buttons.accent};
  }
`

export const CategoriesSwiper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  min-width: 100%;

  overflow-y: scroll;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
`

export const CategoriesSwiperWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;

  top: 0;

  position: sticky;
  padding: 1.5rem 0;
  padding-left: 0.75rem;

  opacity: 1;
  background: linear-gradient(
    180deg,
    ${({ theme }) => theme.colors.buttons.secondary} 83.93%,
    ${({ theme }) => theme.colors.buttons.secondary}00 100%
  );
  z-index: 5;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: ${({ leftGradient }) =>
      `${leftGradient > 64 ? 64 : leftGradient}px`};
    height: 100%;
    background: linear-gradient(
      270deg,
      ${({ theme }) => theme.colors.buttons.primary}00,
      ${({ theme, leftGradient }) =>
        leftGradient > 0
          ? theme.colors.buttons.secondary
          : theme.colors.buttons.primary}00
    );
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: ${({ rightGradient }) =>
      `${rightGradient > 64 ? 64 : rightGradient}px`};
    height: 100%;
    background: linear-gradient(
      90deg,
      ${({ theme }) => theme.colors.buttons.primary}00,
      ${({ theme }) => theme.colors.buttons.secondary}
    );
  }
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`

export const IconWrapper = styled.div`
  border-radius: 0.5rem;
  padding: 0.625rem;
  height: 2.25rem;
  width: 2.25rem;
  background: ${({ theme }) => theme.colors.buttons.secondary};
`
