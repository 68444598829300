import styled from 'styled-components'

import Icon from 'components/Icon'

export const Container = styled.div`
  display: flex;
  flex-direction: ${({ direction }) => direction};
  gap: ${({ direction }) => (direction === 'column' ? '1rem' : '0.75rem')};
  align-items: center;
  width: ${({ width }) => width};
  div {
    white-space: pre-line;
  }
`
export const NetworksIcons = styled.div`
  display: flex;
  gap: 0.5rem;
`

export const NetworkIcon = styled(Icon)`
  cursor: pointer;
  display: ${({ hidden }) => hidden && 'none'};
  color: ${({ theme }) => theme.colors.icons.tertiary};
  &:hover {
    color: ${({ theme }) => theme.colors.icons.accentPrimary};
  }
  transition: all 0.3s ease;
`
