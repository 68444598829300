import { t } from 'i18next'

export const getRenderData = (count) => {
  if (count === 5)
    return {
      icon: 'mail2',
      days: t('account.many_days', {
        value: 5,
      }),
    }

  if (count === 3)
    return {
      icon: 'mail3',
      days: t('account.few_days', {
        value: 3,
      }),
    }

  return {
    icon: 'mail4',
    days: t('account.day', {
      value: 1,
    }),
  }
}
