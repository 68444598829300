import store from 'store'
import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

i18n.use(initReactI18next).init({
  debug: true,
  lng: store.getState().settings.lang,
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false, // not needed for react as it escapes by default
  },
  resources: {
    en: {
      translation: {
        common: {
          back: 'Return',
        },
        devBanner: {
          hello: 'Hello!',
          mobileInDev:
            'Our mobile version is under development, not all features work correctly. If possible, use the desktop version in your browser.',
          ok: 'OK',
        },
        notifications: {
          hide: 'Hide',
          remove_completed_tasks: 'Delete completed',
          cancel: 'Cancel',
          continue: 'Continue',
          admit: {
            confirm: 'Confirm',
            cancel: 'Cancel',
          },
          error: {
            close: 'Close',
            repeat: 'Repeat',
            add_again: 'Add again',
            write_about_an_error: 'Write about an error',
          },
          maxCount: 'Exceeded the maximum number of molecules on Synmap',
          loading_error: 'Loading error',
          calculation_of_3d_coordinates_in_progress:
            'Calculation of 3D coordinates in progress',
          calculation_of_3d_coordinates_success:
            '3D coordinates successfully calculated',
          failed_to_load_3d_coordinates: 'Failed to load 3D coordinates',
          enterMolecule: 'Please enter molecule!',
          copyProcessWrong: 'Copy proccess went wrong :(',
          please_login: 'Please log-in to create generators!',
          go_to_set: 'Go to dataset',
          continue_task: {
            calculating: 'Continue calculating',
            calculation: 'Continue calculation',
            coping: 'Continue coping',
            search: 'Continue search',
            continue: 'Continue',
          },
          limits: {
            max_file_size: 'Maximum file size exceeded',
            max_available_file_upload:
              'The maximum available file upload is{{maxSize}}',
            mol_max_number: 'Maximum number of molecules exceeded',
            max_available_mol_count_move:
              'The maximum number of molecules available for movement is{{maxCount}}',
            max_available_mol_count_copy:
              'The maximum number of molecules available for copying is{{maxCount}}',
            mol_max_number_by_day:
              'Maximum number of molecules per day exceeded',
            'You have exceeded the daily limit on the number of structures you can upload':
              'You have exceeded the daily limit on the number of structures you can upload',
            mol_max_number_by_month:
              'Maximum number of molecules per month exceeded',
            'You have exceeded the monthly limit on the number of structures you can upload':
              'You have exceeded the monthly limit on the number of structures you can upload',
            period: {
              day: 'Daily limit',
              month: 'Monthly limit',
            },
            period_text: {
              day: 'Daily request limit reached',
              month: 'Monthly request limit reached',
            },
          },
        },
        notFound: 'Page not found!',
        synmap: {
          instruments: {
            pan: 'Pan',
            box_select: 'Box Select',
            lasso_select: 'Lasso Select',
            autoscale: 'Autoscale',
            '2d_mode': '2D mode',
            '3d_mode':
              'Please note: calculating coordinates in 3D mode can take a long time',
            orbit: 'Orbital rotation',
            turntable: 'Turntable rotation',
            reset_camera_to_default: 'Reset camera to default',
            clear_select: 'Сlear select',
          },
          copy_molecules: 'Molecules copy to basket ',
          molecules_copied: 'Molecules copied to basket ',
          copy_molecules_failed: 'Failed to copy molecules to basket {{name}}',
          cancel_copy_molecules: 'Сancel copying of molecules?',
        },
        moleditor: {
          search: 'Syntelly ID, Trivial name, IUPAC name',
        },
        filter: {
          title: 'Filter',
          collapse: 'Collapse',
          reset: 'Reset',
          min: 'min.',
          max: 'max.',
          label_min: 'Minimum',
          label_max: 'Maximum',
          from: 'from',
          to: 'to',
          from_rus: 'from',
          to_rus: 'to',
          pieces: 'pcs',
          include: 'Include',
          exclude: 'Exclude',
          applyFilters: 'Apply',
          author_surname: 'Author surname',
          notify_sub: 'Use 2 or fewer undefined substituents',
          notify_not_ext_smiles: 'Entered SMILES is not Markush structure',
          notify_not_both_weights:
            'Minimum and maximum molecular mass must be specified',
          notify_not_min_weight: 'Minimum molecular mass must be specified',
          notify_min_weight_not_less:
            'The minimum molecular mass cannot be less than {{value}}',
          notify_mol_weight_error:
            'The difference between the maximum and minimum mass is greater than 100',
          notify_min_and_max_equal:
            'The maximum molecular mass must be greater than the minimum',
          structure: {
            match_type: 'Match type',
            exact_match: 'Exact match',
            substructural_search: 'Substructural search',
            similar_structures: 'Similar structures',
            markush_structures: 'Markush structures',
            similarity: 'Similarity, %',
            molecular_weight: 'Molecular weight',
            ALogP: 'ALogP',
            elements: 'Elements',
            published_date: 'Publication date',
            author: 'Author',
            article: 'Article',
            patent: 'Patent',
            ru: 'Russian',
            en: 'English',
            de: 'Deutsch',
          },
          literature: {
            text: 'Full text',
            claims: 'Claims',
            document_type: 'Document type',
            header: 'Adding a condition to a query',
            addCondition: 'Add condition',
            reset: 'Reset',
            confirm: 'Confirm',
            authors: 'Author',
            doi: 'DOI',
            patent_number: 'Patent number',
            abstract: 'Abstract',
            journal: 'Journal',
            publisher: 'Publisher',
            language: 'Language',
            published_date: 'Published Date',
            must_lowercase: 'and',
            should_lowercase: 'or',
            must: 'And',
            should: 'Or',
            add: 'Add',
            delete: 'Delete',
            changeRequest: 'Change your request',
            requestNotFound:
              'The request was not found in the current database',
            title: 'Title',
            owner: 'Owner',
            applicant: 'Applicant',
            from: 'from',
            to: 'to',
            name_warning:
              'Choosing a DOI or patent number will reset other conditions.',
          },
        },
        literatureCard: {
          full_text: 'Full text',
          show: 'Show',
          copyLink: 'Copy link',
          copyDOI: 'Copy DOI',
          copyPatentNumber: 'Copy patent number',
          doi: 'Article DOI:',
          patentNumber: 'Patent number:',
          authors: 'Authors:',
          authorsNotColon: 'Authors',
          author: 'Author',
          onIndividualPage: 'On an individual page',
          articleStructures: 'Article structures',
          articleReactions: 'Article reactions',
          similarArticles: 'Similar Articles',
          structures: 'Structures',
          reactions: 'Reactions',
          error: 'Internal server error',
          returnToResults: 'To results',
          patent: 'Patent',
          article: 'Article',
          source: 'Source',
          published_date: 'Year',
          owner: 'Owner',
          owners: 'Owners',
          journal: 'Journal',
          more: 'More',
          article_authors: 'Article authors',
          patent_authors: 'Patent authors',
          translate: 'Translate',
          show_original: 'Show original',
        },
        confirmation: {
          ok: 'OK',
          cancel: 'Cancel',
          areYouSure: 'Are you sure?',
          deleteThatService: 'Do you want to delete that service?',
          deleteIp: 'Are you sure you want to delete {{ip}} from ban list?',
          deleteLayer: 'Are you sure you want delete layer {{name}}?',
          russianSymbols: 'Russian symbols and not allowed',
          cantConvert: "Can't convert SMILES to coordinates",
          no_smile: 'Incorrect SMILES',
          delete_this_document:
            'Are you sure you want to delete this document?',
          yes: 'Yes',
          no: 'No',
          confirm_action: 'Confirm the action',
          add_condition: 'Are you sure you want to add this condition?',
          delete_generator:
            'Are you sure you want delete the generator and all of its descendants?',
        },
        registration: {
          signUp: 'Sign Up',
          name: 'Name',
          surname: 'Surname',
          password: 'Password',
          company: 'Company',
          retypePassword: 'Retype Password',
          position: 'Position',
          inviteToken: 'Invite Token',
          alreadyRegistered: 'Already registered?',
          contactUs: 'Contact us',
          registeringToThisWebsite:
            'Registering to this website, you accept our',
          registering: 'Registering, you agree to',
          user_agreement: 'The terms of the user agreement',
          and: ' and ',
          privacyPolicy: 'personal data processing policy',
          product_key: 'Product key',
          license_key: 'License key',
          license_key_placeholder: 'Enter a license key',
          if_no_key:
            'If you do not have a key from the company, we will provide you with access for {{count}} days for testing',
          to_personal_data: 'To personal data',
          complete_registration: 'Complete registration',
          confirm_email: 'Сonfirm email',
          last_step_left: 'Last step left',
          confirm_your_email:
            'Confirm your mail by clicking on the activation link in the email. The letter is already in your mailbox',
          activation:
            'An email has been sent to your email with a link to activate your account.',
          activationFailed:
            'Your account is not activated. Please confirm your email address. The activation link has been sent to the email address provided during registration.',
          'Invite token is invalid. Please ask for a new one or contact our support team(admin@syntelly.com)':
            'Invite token is invalid. Please ask for a new one or contact our support team(admin@syntelly.com)',

          activation_failed_title: 'Your account is not activated',
          activation_failed_description:
            'Please confirm your email address.\nAn activation link has been sent to the email address specified during registration',
          registration: 'Registration',
          we_offer:
            'We offer new users the opportunity to test our platform for <span>{{count}} days</span>',
          if_you_have_key:
            'If you have a company key, you can enter it further and get licensed access',
          start_registration: 'Start registration',
          return: 'Return',
          namePlaceholder: 'Your name',
          surnamePlaceholder: 'Your surname',
          companyPlaceholder: 'Your company',
          positionPlaceholder: 'Your position',
          personal_data: 'Personal data',
          enter_personal_data:
            'Enter your personal details, company and position',
          to_email_and_password: 'To email and password',
          fill_all_fields: 'Please fill in all fields',
          email_placeholder: 'Enter email',
          password_placeholder: 'Create a password',
          password2_placeholder: 'Repeat password',
          email_and_password: 'Email and password',
          enter_email_and_create_password:
            'Enter your email and create a strong password',
          registered_success_title: 'You are successful registered',
          registered_success_description:
            'We are pleased to welcome you to a community of professionals who use advanced technologies to achieve outstanding results in their work.',
          registered_success_description_2:
            'Instant access to reliable scientific information, a large number of tools to improve the efficiency of your research.',
          innovation_here: 'Innovation is here',
          password_security_requirements: 'Password security requirements',
          includes_no_less_8: 'Contains at least 8 characters',
          includes_a_z:
            'Contains both lowercase (a-z) and uppercase letters (A-Z)',
          includes_symb: 'Contains at least one number (0-9) or symbol',
          no_includes_email: 'Does not contain your email address',
          fullName: 'Full Name',
          invite_token_is_invalid:
            'Invite token is invalid. Please ask for a new one or contact our support team(admin@syntelly.com)',
          invite_token_is_required: 'Invite token is required for registration',
          email_error: 'User with email {{email}} already exists',
          confirm_not_a_robot: 'Confirm that you are not a robot',
          providing_quality_services:
            'We are committed to providing you with quality services and ensuring the stability of the platform',
          protection:
            'One of the protection methods is captcha, which helps prevent automatic access to our service',
          back: 'Back',
          registration_failed: 'Failed to complete registration',
          invalid_license_key: 'License key is invalid',
          invalid_token:
            'Invite token is invalid. Please ask for a new one or contact our support team(admin@syntelly.com)',
        },
        account: {
          name: 'Name',
          position: 'Position',
          company: 'Company',
          email: 'E-mail',
          password: 'Password',
          changePassword: 'Change password',
          saveInfo: 'Save info',
          resetInfo: 'Reset',
          logout: 'Logout',
          signIn: 'Sign in',
          signUp: 'Sign up',
          signInOrSignUp: 'Sign in or sign up',
          forgotPassword: 'Forgot your password?',
          resetPassword: 'Reset password',
          reEnterPassword: 'Re-enter your password',
          resetSended:
            'Reset link successfully sended. Please check your e-mail!',
          passwordChanged:
            'Password changed successfully. You can log-in with your new password',
          enterEmail: 'Enter your e-mail to reset your password',
          enterNewPassword: 'Enter new password below to change your password',
          passwordPlaceholder: 'At least 8 characters',
          password2Error: 'The repeated password was entered incorrectly',
          emailError: 'Incorrect e-mail',
          retypePassword: 'Retype Password',
          dontChange: 'Don`t change',
          change: 'Change',
          emailPlaceholder: 'Enter your E-mail',
          tokenPlaceholder: 'Enter token',
          namePlaceholder: 'Enter your name',
          companyPlaceholder: 'Enter your company name',
          hasNoAccount: 'Has no account?',
          copyRight: '© Syntelly 2018-',
          wrongEmailOrPassword: 'Wrong email or password',
          somethingWentWrong: 'Something went wrong',
          messageSuccess: 'Save successed!',
          messageFailed: 'Save failed',
          welcome: 'Welcome',
          enter_your_data: 'Please enter your login and password to log in',
          remember_data: 'Remember login details',
          password_placeholder: 'Enter your password',
          tips: 'Tips',
          about_each_module: 'About each module',
          password_changed: 'Password changed successfully',
          ok: 'Ok',
          trial_period: 'Your trial period',
          license: 'Your license',
          your_trial_period_will_end: '{{value}} will end\nafter',
          day: '{{value}} day',
          many_days: '{{value}} days',
          few_days: '{{value}} days',
          skip: 'Skip',
          contact_us: 'Contact us',
          we_would_be_grateful:
            'We would be grateful if you could share\nyour impressions of our product',
          purchase: 'purchase',
          extend: 'extend',
          you_can_purchase_a_license:
            'You can {{value}} a license by writing to us via the contact form\nor email',
          email_copied: 'E-mail address is copied',
          take_a_survey: 'Take a survey',
          follow_the_platform:
            'Follow the platform functionality updates\non our social networks',
          we_would_be_grateful_2:
            'We will be grateful if you share your impressions',
          already_have_key: 'I already have the key',
          unfortunately: 'Unfortunately, ',
          no_license: `you don't have a license`,
          trial_period_expired: 'your trial period is over',
          license_expired: 'your license has expired',
          license_invalid: 'your license is invalid',
          enter_license_key: 'Enter the license key',
          activate: 'Activate',
          invalid_license_key: 'License key is invalid',
          license_activation_failed: 'Failed to activate the license key',
          buy_a_license: 'Buy a license',
          license_is_invalid:
            'The license is invalid. Please contact the customer admin',
        },
        recovery: {
          password_reset: 'Password reset',
          enter_email: 'Enter your email to which your account was registered',
          email_placeholder: 'Enter your email',
          restore_password: 'Restore password',
          user_not_found:
            'We do not have an account registered under this email. Please check that you entered your email address correctly.',
          check_your_email: 'Check your mail',
          link_sended: 'We have sent you a link to reset your password',
          new_password: 'New password',
          enter_new_password: 'Enter a new password to log into your account',
          confirm: 'Confirm',
          password_changed: 'Password successfully changed',
          search_predict_explore:
            'Search, predict and explore the compounds you need, and enjoy quick access to information about any properties and compounds',
          login: 'Login to account',
          something_went_wrong: 'Something went wrong. Please try again.',
        },

        promo: {
          search_title: 'Search',
          search_description:
            'Quick search for relevant and reliable information related to chemistry: structures, literature, patents, experimental data',
          molecular_editor_title: 'Molecular Editor',
          molecular_editor_description:
            'Enter structures and view predicted properties for structures that are not in the Syntelly database: physicochemical, biological properties, carcinogenicity and drug similarity parameters',
          datasets_title: 'Datasets',
          datasets_description:
            'Collect and store your own chemical datasets. Import and export is available in the most popular formats: SDF, CSV, SMI. We also provide a collection of thematic datasets',
          synmap_title: 'SynMap 2D/3D',
          synmap_description:
            'Navigating the chemical space. Analyzing clusters of bioactive compounds. Generation of new compounds with specified properties',
          predicting_reactions_title: 'Predicting reactions',
          predicting_reactions_description:
            'Prediction of possible products of chemical reactions and search for reactions for the synthesis of the desired molecule based on a neural network model',
          spectra_title: 'Spectra Prediction',
          spectra_description:
            'Prediction of spectra: tandem mass spectrometry (QToF-MS/MS), infrared spectroscopy and nuclear magnetic resonance (1H, 13C, 15N, 19F)',
          synthcost_title: 'Synthesis cost',
          synthcost_description:
            'Choosing the optimal synthesis path with calculation of economic efficiency. TOP 5 known reaction schemes, including all stages and references to literary sources',
          pdf2smiles_title: 'PDF2SMILES',
          pdf2smiles_description:
            'Tool for optical recognition of molecular structures and Markush structures from PDF. Export structures from documents to a separate dataset for further analysis',
          smiles2iupac_title: 'SMILES2IUPAC',
          smiles2iupac_description:
            'Generation of systematic nomenclature names according to the IUPAC',
          statistics_title: 'Statistics',
          statistics_description:
            'To determine the accuracy of the models or calculate the possible error in terms of indicators, we use the following metrics: RMSE, ROC AUC.',
        },
        feedback: {
          only_one_pdf: 'Only one PDF file can be attached',
          file: 'File',
          image: 'Image',
          label: 'Feedback form',
          textFieldPlaceholder: 'Provide your feedback',
          emailLabel: 'E-mail',
          emailPlaceholder: 'Your email',
          dragMsg: 'Drop files here...',
          addFileButton: 'Add file',
          closeButton: 'Close',
          sendButton: 'Send',
          header: 'Feedback List',
          attach_image: 'Attach jpeg or png',
          attach_file: 'Attach image in pdf format',
          feedback_received: 'We have received your feedback',
          we_will_answer:
            'We will respond within the next few days\n to the e-mail you provided',
          we_will_answer_data:
            'We will test them soon\n and add them to the platform',
          ok: 'Ok',
          required_field_tip: '* - required field',
          theme: 'Select topic',
          choose_module: 'Choose module',
          comment: 'Comment',
          your_message: 'Your message',
          add_file_btn: 'File',
          add_image_btn: 'Image',
          got_link: 'Have a link to an open source',
          enter_link: 'Enter link',
          agreement_terms:
            'I hereby give my consent to Syntelly LLC to quote the information derived from the data I upload in the manner required by applicable law.',
          agreement_cite_terms:
            'The quote should not include the name of the company',
          provide_file_or_link:
            'Please provide the file or a link to the source, or both, if you are able to do so',
          experimental_data_text:
            'Add experimental data obtained in accredited laboratories or presented in scientific publications',
          data_received: 'Thank you for sharing your data',
          message_received: 'We received your message',
          select_multiple_modules: 'Several modules can be selected',
          themes: {
            license: 'Buy license',
            error: 'Error',
            improve: 'Suggestion for improvement',
            experimental_data: 'Provide experimental data',
            literature_source: 'Provide literature source',
            feedback: 'Feedback',
          },
          modules: {
            search: 'Search',
            props: 'Property Prediction',
            moleditor: 'Molecular Editor',
            datasets: 'Datasets',
            synmap: 'SynMap',
            reactions: 'Reaction Prediction',
            spectra: 'Spectra',
            synth_cost: 'Synthesis cost',
            pdf2smi: 'PDF in SMILES',
            smi2iupac: 'SMILES in IUPAC',
            statistics: 'Statistics',
            common: 'Common',
          },
        },
        baskets: {
          enter_dataset_name: 'Enter dataset name (minimum of 3 characters)',
          delimiter: 'Delimiter in document',
          tab: 'Tabulation',
          comma: 'Comma',
          semicolon: 'Semicolon',
          space: 'Space',
          bar: 'Pipe',
          other: 'Other',
          write_delimiter: 'Write your own delimiter',
          export_success: 'Export successfully finished',
          export_error: 'Error during export',
          name_taken: 'Name is already taken, try another',
          toplabel: 'Datasets',
          selected: 'Area',
          compound: 'Compound',
          compounds_few: 'Compounds',
          compounds_many: 'Compounds',
          calculating: 'Сalculation of dataset properties',
          calculating_failed: 'Failed to calculate {{name}}',
          calculated: '{{name}} successfully calculated',
          topblock: {
            create: 'Create Set',
            delete: 'Delete Set',
            copy: 'Duplicate Sets',
            merge: 'Merge Sets',
            mmap: 'Transfer to SynMap',
            export: 'Export Set',
            calc: 'Calculate properties',
            refresh: 'Refresh status',
            undo: 'Undo',
            mmap_empty:
              'Add molecules to the selected set to transfer it to SynMap',
            export_empty: 'Add molecules to the selected set to export it',
            calc_empty:
              'Add molecules to the selected set to calculate its properties',
          },
          modal: {
            choose_set_text: 'Choose dataset',
            placeholder: 'Search or enter new set name to create',
            placeholder_filter: 'Enter the name of the dataset',
            create_new_basket: 'Create new',
            choose_from_list: 'Or choose from the list',
            move: 'Choose',
            create_or_move: 'Create or move',
            create_or_add: 'Create or add',
          },
          dataset_type_selectors: {
            thematic: 'Thematic',
            private: 'Private',
          },
          basket_tools_menu: {
            viewing_log_messages: 'Viewing log messages',
            edit: 'Edit',
            select: 'Select',
            deselect: 'Deselect',
            delete: 'Delete',
          },
          join_copy_dialog: {
            joined_dataset_creation: 'Merged dataset creation',
            joined_dataset_name: 'Merged dataset name',
            joined_dataset_desc: 'Merged dataset description',
            copied_dataset_creation: 'Creation of dataset copy',
            copied_dataset_name: 'New dataset name',
            copied_dataset_desc: 'New dataset description',
          },
          add_create_dialog: {
            dataset_editing: 'Dataset name editing',
            dataset_creation: 'Dataset creation',
            name: 'Name',
            description: 'Description',
            name_of_your_set: 'Name of your set',
            description_of_your_set: 'Description of your set',
            save: 'Save',
            create: 'Create',
          },
          log_dialog: {
            log_messages_viewer: 'Log messages viewer',
            logs_are_empty: 'Logs are empty',
            failed_to_fetch_log_messages: 'Failed_to_fetch_log_messages',
          },
          export_dialog: {
            set_export: 'Set export',
            set: 'Set',
            has_uncalculated_molecules: 'has uncalculated molecules',
            uncalculated_warning_export:
              'If you are only interested in SMILES, you can export the set in this form. If you want to get all the properties, first calculate the dataset',
            uncalculated_warning_analysis:
              'To analyze a dataset, we need to calculate its properties.',
            select_the_desired_download_file_format:
              'Select the desired download file format',
            export_in_CSV: 'Export in CSV',
            export_in_SDF: 'Export in SDF',
            exporting_set_in_csv_format: 'Exporting a set in CSV format',
            csv_warning:
              'Please note that in our file the delimiter is a comma. To display the data correctly in Excel, you need to open the file in the "Data" tab',
            ok: 'Ok',
            calculation: 'Calculation',
            calculate: 'Calculate',
            export: 'Export',
            analysis: 'Analyze',
          },
          export_in_progress: 'Export in progress',
          export_in_progress_small:
            'It may take a while, downloading will start automatically',
          tasks_status: {
            cancel_set_calculation:
              'Cancel the calculation of the properties of the dataset {{name}}?',
          },
          failed_to_calculate_dataset: 'Failed to calculate dataset',
          sortTypes: {
            relevance: 'By relevance',
            date: 'By creation date',
            name: 'By name',
            molecules_count: 'By molecules count',
          },
        },
        sidebar: {
          search: 'Search',
          molecules: 'Datasets',
          reactions: 'Reaction prediction',
          spectra_prediction: 'Spectra prediction',
          mmap: 'SynMap',
          individual: 'Molecular editor',
          pdf2smi: 'PDF2SMILES',
          admin: 'Admin',
          qsar: 'Statistics',
          en: 'English',
          ru: 'Русский',
          login: 'Sign in or sign up',
          user: 'User',
          language: 'Change language',
          synth_cost: 'Synthesis cost',
          smiles2iupac: 'SMILES2IUPAC',
          telegram: 'Us in Telegram',
          open_filter: 'Open filter',
        },
        header: {
          about: 'About us',
          tlgrm: 'Telegram',
          profile: 'Profile',
          exit: 'Exit',
          user_guide: 'User Guide',
        },
        search: {
          draw: 'Draw',
          edit: 'Edit',
          structure: 'By structures',
          reaction: 'By reactions',
          reaction_search_in_development:
            'Search by reactions is under development',
          literature: 'By literature',
          placeholder:
            'or enter Syntelly ID, SMILES, trivial name, IUPAC name, CAS number',
          placeholder_short:
            'or enter Syntelly ID, SMILES, trivial name, IUPAC name',
          placeholder_synth: 'Syntelly ID, SMILES, trivial name, IUPAC name',
          placeholder_lit: 'or enter Syntelly ID, SMILES, text',
          search: 'Search',
          history: 'History',
          fullTextSearch: 'Full Text Search',
          fromDateSearch: 'From Date Search',
          toDateSearch: 'To Date Search',
          draw_markush:
            'Markush structures are searched only through the “Draw” button',
          predict: 'Predict',
          search_history: {
            history: 'Search history',
            history_empty_title: 'You have no request history',
            history_preview:
              'Your search history by structures, reactions and literature',
            history_empty:
              'Start searching by structures,\n reactions or literature',
            repeat_request: 'Repeat request',
            back_to_history: 'Back to history',
            back_to_search: 'Back to Search',
            search_results: 'Search results for the query {{text}}',
            literature: 'Literature',
            molecules: 'Molecules',
            structures: 'Structures',
            reactions: 'Reactions',
            all: 'All',
            repeat: 'Repeat',
            view: 'View',
          },
          sortTypes: {
            relevance: 'By relevance',
            registrationNumber: 'By registration number',
            date: 'By publication date',
            structures: 'By structures count',
          },
          sortDirection: {
            asc: 'Ascending',
            desc: 'Descending',
          },
          literatureFilters: {
            settings: 'Set up conditions',
            reset: 'Reset',
            sources: 'Found sources: ',
            sources_hint:
              'Literature searches are limited to yielding 10,000 sources per query, whereas there may be more sources in our database that match the query',
          },
          search_may_take_some_time: 'Search may take some time',
          structure_search_in_progress:
            'While the structure search is in progress, you cannot search the literature and reactions',
          search_by_structures_in_progress: 'Search by structures in progress',
          cancel_search_by_structures: 'Cancel search by structures?',
          search_by_structures_failed: 'Search by structures failed',
          search_by_markush_failed: 'Search by Markush structures failed',
        },
        moleculeslist: {
          topblock: {
            add: 'Add molecules',
            calculate: 'Calculate properties',
            delete: 'Delete',
          },
          select_the_column_heading:
            'Select the column heading that contains SMILES',
          add_molecules: 'Add molecules',
          add_dataset: 'Add dataset',
          select_method: 'Select Method',
          smiles_string: 'SMILES',
          visual_editor: 'Visual Editor',
          load_from_file: 'Load from file (SDF, CSV, SMI)',
          search_in_synonyms: 'Search in synonyms',
          enter_smiles: 'Enter SMILES...',
          upload: 'Upload',
          enter_synonym: 'Enter synonym',
          select_file: 'Select file',
          to_structure_page: 'On a separate page',
          error: 'An error occurred while searching',
          dataIsUploading: `The data is uploading to current basket. Please re-open it in a few minutes.`,
          areYouSureDelete: `Are you sure you want to delete`,
          thisItem: 'this item',
          theseItems: 'this items',
          calcStarted: 'Calculation started...',
          return_to_datasets: 'Return to datasets',
          models_applicability: 'Applicability of models to molecule',
          not_applicable:
            'The model is not applicable to predict the properties of a given molecule',
          applicability_indicator_label: 'The model applicability indicator',
          applicability_indicator_description:
            'Located next to the predicted value and helps you assess the reliability of the results',
          low_reliability: `0-20% - low reliability of prediction.\n There were few molecules similar to the selected molecule in the model training data`,
          middle_reliability: '20-50% - average forecasting reliability',
          high_reliability: '50-100% - high forecasting reliability',
          request_not_found:
            'The request was not found in the current database',
          see_molecule_props:
            'You can see the predictive properties of this molecule by drawing it in the molecular editor',
          open_editor: 'Open molecular editor',
          similar_results: 'Similar results',
          best_match: 'Best match',
        },
        reactions_prediction: {
          label: 'Planning the synthesis of organic compounds',
          predict_btn: 'Predict',
          forward: 'Synthesis',
          reverse: 'Retrosynthesis',
          results: 'Results',
          score: 'Model confidence',
          model_confidence_indicator: 'Model confidence indicator',
          model_confidence_indicator_description:
            'Several parallel predictions are performed, then those responses that have the highest model confidence estimates in the outcome are added to the final results. ',
          low_reliability: '0-79% - low prediction reliability',
          medium_reliability: '80-94% - average reliability of prediction',
          high_reliability: '95-100% - high reliability of forecasting',
          reset_btn: 'Reset',
          yield: 'Yield:',
          forward_description:
            'Allows prediction of reaction products using deep transformer neural networks. Multiple reactant structures can be input, and the model will show product variants for them based on its confidence in the prediction. When entering molecules, only reactants need to be added - solvents, catalysts, etc. are not considered.',
          reverse_description:
            'Allows you to predict up to 5 schemes for multistage synthesis of a small organic molecule using neural networks. The tool will build a retrosynthetic tree until commercially available molecules are reached or until a limit of 5 stages is reached. The predicted retrosynthetic pathways also have a model confidence score.',
          retrosynth: {
            to_molecule_page: 'To molecule page',
            stages: 'Stages',
            schemas: 'Schemes',
            is_solved:
              'All molecules that are in the zinc database are commercially available',
            is_not_solved:
              'There are molecules whose availability is questionable',
            save_to_dataset: 'Save to dataset',
            download: 'Download',
            solutionFound: 'Solution Found',
            solutionNotFound: 'Solution Not Found',
          },
          nothing_found: 'Nothing found',
          synthesis_failed: 'Failed to calculate synthesis',
          retrosynthesis_failed: 'Failed to calculate retrosynthesis',
          prediction_failed: 'Prediction failed',
        },
        molecule_viewer: {
          add_text: 'Add molecule',
          copy_smiles: 'Copy SMILES',
          copy_baseid: 'Copy Syntelly ID',
          open_in_individual_mode_text: 'Open in molecular editor',
          download_png_text: 'PNG',
          download_mol: 'MOL',
          download_pdf: 'PDF',
          literature: 'Literature',
          copy_link: 'Copy link',
          add_to_dataset: 'Add to dataset',
          molecule_information: 'Molecule information',
          download: 'Download',
          edit_in_molecular_editor: 'Edit in molecular editor',
          fast_view: 'Fast view',
          molecule_generated: 'Generated',
          molecule_generated_description:
            'The molecule is not in our database and all its properties are predicted',
          link: 'Link',
          to_dataset: 'To dataset',
          transfer_to: 'Transfer to',
          go_to_section: {
            molecular_editor: 'Molecular editor',
            synthesis: 'Synthesis',
            retrosynthesis: 'Retrosynthesis',
            nmr_spectra: 'NMR spectra',
            mass_spectra: 'Mass spectra',
            ir_spectra: 'IR spectra',
            synthesis_cost: 'Synthesis cost',
          },
        },
        mmap: {
          clear_text: 'Are you sure you want clear data from map?',
          choose_text: 'Choose',
          layers: 'Add layer',
          generators: 'Add generator',
          no_more: 'You can select no more than 50,000 molecules',
          compliance: 'Compliance with specified parameters',
          opacity: 'Opacity',
          save_selection_to_dataset: 'Save selection to dataset',
          to_add_a_generator_turn_on_2D_mode:
            'To add a generator turn on 2D mode',
          clear_all: 'Clear all',
        },
        pdf2smiles: {
          drag_pdf: 'Drag .pdf file into this area',
          dashboard: {
            pdf2smiles: 'PDF2SMILES',
            upload_pdf: 'Upload PDF',
            failed_to_load_documents: 'Failed to load documents',
            document_preview: 'Document preview',
            structure: {
              one: '{{count}} structure',
              few: '{{count}} structures',
              many: '{{count}} structures',
            },
            page: {
              one: '{{count}} page',
              few: '{{count}} pages',
              many: '{{count}} pages',
            },
            menu: {
              edit_name: 'Edit name',
              delete: 'Delete',
            },
            edit_dialog: {
              document_editing: 'Document editing',
              name: 'Name',
              name_of_your_document: 'Name of your document',
              save: 'Save',
            },
            you_can_only_recognize_one_document_at_a_time:
              'You can only recognize one document at a time',
            recognition_of: 'Recognition of',
            recognition_success: 'recognized successfully',
            recognition_failed: 'Failed to recognize {{name}}',
          },
          viewer: {
            back: 'Back',
            score: 'Score {{score}}',
            smiles_in_pdf: 'Smiles in pdf',
            structures: 'Structures',
            save_all: 'All to dataset',
            save_selected: 'Selected to dataset',
            add_to_dataset: 'Add to dataset',
            reset_selection: 'Reset selection',
            delete_selected: 'Delete selected',
            failed_to_load_document: 'Failed to load document',
            failed_to_open_document: 'Failed to open document',
            invalid_smiles: 'Invalid SMILES',
            failed_to_edit_smiles: 'Failed to edit SMILES',
            failed_to_edit_filename: 'Failed to edit filename',
            failed_to_delete_document: 'Failed to delete document',
            failed_to_delete_molecule: 'Failed to delete molecule',
            failed_to_delete_molecules: 'Failed to delete molecules',
            download: 'Download',
            failed_to_download_structures: 'Failed to download structures',
            export_in_progress: 'Export in progress',
            export_in_progress_small:
              'It may take a while, downloading will start automatically',
            edit: 'Edit',
            to_dataset: 'To dataset',
            delete: 'Delete',
            molecule_delete_confirmation:
              'Are you sure you want to delete the selected molecule?\n It will be impossible to return the molecule',
            molecules_delete_confirmation:
              'Are you sure you want to delete the selected molecules?\n It will be impossible to return the molecules',
          },
        },
        individual: {
          smiles_btn: 'Enter SMILES',
          warn_text:
            'To continue using Syntelly platform please log-in or sign-up.',
          set: 'Set',
        },
        ketcher: {
          calc_btn: 'Calculate',
          save: 'Save',
          close: 'Close',
          search: 'Search',
        },
        categoriesFiler: {
          all: 'All',
          lethal_dose_models: 'Animal lethal dose models',
          general_toxicity_models: 'Effect-based models',
        },
        molviewer: {
          blur_value:
            'The model is not applicable for predicting <br/>the properties of this molecule. <br/>We do not recommend using this value',
          no_synonyms: 'Not present in this molecule',
          why_that: 'Why so',
          why_that_tip:
            'A molecule may not have usable synonyms, <br /> if it is not well known and used or the synonyms <br />were not included in the Syntelly database',
          solubility: 'Water Solubility',
          smiles: 'SMILES',
          rdkit_count_chemical_formula: 'Brutto formula',
          rdkit_count_molw: 'Molecular weight',
          inchi: 'InChI',
          cyp1a2: 'CYP1A2',
          cyp2c19: 'CYP2C19',
          cyp2d6: 'CYP2D6',
          cyp2c9: 'CYP2C9',
          cyp3a4: 'CYP3A4',
          inchikey: 'InChI key',
          bp: 'Boiling point',
          fp: 'Flash point',
          flash_point: 'Flash point',
          density: 'Density',
          viscosity: 'Viscosity',
          mp: 'Melting point',
          logp: 'LogP octanol-water',
          dmso: 'Soluble in DMSO',
          rt: 'Retention time',
          devtox: 'Developmental toxicity',
          lc50dm: '48 hour Daphnia magna LC50',
          igc50: '40 hour Tetrahymena pyriformis IGC50',
          bcf: 'Bioconcentration factor',
          ames: 'Ames test',
          external: 'External DBs',
          ratoral: 'Rat Oral',
          scscore: 'Complexity (SCScore)',
          rabbitintravenous: 'Rabbit Intravenous',
          ratintravenous: 'Rat Intravenous',
          mouseintravenous: 'Mouse Intravenous',
          iupac: 'IUPAC name',
          baseid: 'Syntelly ID',
          vapor_pressure: 'Vapor Pressure',
          structural: 'Structural',
          bio: 'Bio',
          synonyms: 'Synonyms',
          mut: 'Mutagenicity',
          eco: 'Ecology',
          synt: 'Synthesis',
          nmr: 'NMR',
          phys: 'Physical',
          drug: 'Drug-likeness',
          toxy: 'Toxicity',
          reactions: 'Protocols',
          externaldbs: 'External DBs',
          scscore_comp: 'Complexity',
          scscore_syba: 'SYBA',
          user_params: 'User parameters',
          qsar_log10_bcf_: 'Bioconcentration factor',
          qsar_boiling_point: 'Boiling point',
          qsar_boiling_point__normal_: 'Boiling point Normal',
          qsar_decomposition_point: 'Decomposition Point',
          qsar_density: 'Density',
          qsar_melting_point: 'Melting point',
          qsar_refractive_index: 'Refractive Index',
          qsar_retention_time: 'Retention time',
          qsar_ames_test: 'Ames test',
          qsar_log10_water_solubility_: 'Water Solubility',
          qsar_mouse_intraperitoneal_ld50: 'Mouse Intraperitoneal LD50',
          qsar_mouse_intraperitoneal_ldlo: 'Mouse Intraperitoneal LDLo',
          qsar_mouse_intravenous_ld50: 'Mouse Intravenous LD50' /**/,
          qsar_rat_intraperitoneal_ld50: 'Rat Intraperitoneal LD50',
          qsar_mouse_oral_ld50: 'Mouse Oral LD50',
          qsar_mouse_subcutaneous_ld50: 'Mouse Subcutaneous LD50',
          qsar_rat_subcutaneous_ld50: 'Rat Subcutaneous LD50',
          qsar_mouse_unreported_ldlo: 'Mouse Unreported LDLo',
          qsar_rat_unreported_ld50: 'Rat Unreported LD50',
          qsar_mouse_unreported_ld50: 'Mouse Unreported LD50',
          qsar_rat_intraperitoneal_ldlo: 'Rat Intraperitoneal LDLo',
          qsar_rabbit_skin_ld50: 'Rabbit Skin LD50',
          qsar_rat_oral_ld50: 'Rat Oral LD50',
          qsar_rat_oral_ldlo: 'Rat Oral LDLo',
          qsar_rabbit_oral_ld50: 'Rabbit Oral LD50',
          qsar_rabbit_intravenous_ld50: 'Rabbit Intravenous LD50',
          qsar_rat_intravenous_ld50: 'Rat Intravenous LD50',
          qsar_mouse_oral_ldlo: 'Mouse Oral LDLo',
          qsar_rat_skin_ld50: 'Rat Skin LD50',
          qsar_mammal__species_unspecified__subcutaneous_ld50:
            'Mammal (species unspecified) Subcutaneous LD50',
          qsar_guinea_pig_oral_ld50: 'Guinea Pig Oral LD50',
          qsar_heat_capacity__cp_: 'Heat capacity',
          qsar_density__normal_: 'Density (normal)',
          qsar_enthalpy_of_fusion: 'Enthalpy of Fusion',
          qsar_enthalpy_of_sublimation: 'Enthalpy of Sublimation',
          qsar_enthalpy_of_vaporization: 'Enthalpy of Vaporization',
          qsar_vapour_pressure: 'Vapor Pressure',
          qsar_dmso_solubility: 'DMSO Solubility',
          view_all: 'View all',
          'toxy-cbm': 'Toxy-CBM',
          reproductive_toxicity: 'Reproductive Toxicity',
          'nr-er': 'Estrogen Receptor Alpha, full length',
          hepatotoxicity: 'Hepatotoxicity',
          mouse_oral_ld50: 'Mouse Oral LD50',
          'nr-ar': 'Androgen Receptor, full length',
          dili: 'DILI',
          cat_intravenous_ld50: 'Cat intravenous LD50',
          'sr-atad5': 'ATAD5',
          mouse_intraperitoneal_ld50: 'Mouse Intraperitoneal LD50',
          ames_test: 'Ames Test',
          dog_intravenous_ld50: 'Dog Intravenous LD50',
          rabbit_intravenous_ldlo: 'Rabbit Intravenous LDLo',
          bird_wild_oral_ld50: 'Bird Wild Oral LD50',
          quail_oral_ld50: 'Quail Oral LD50',
          eye_irritation: 'Eye Irritation',
          developmental_toxicity: 'Developmental Toxicity',
          rat_oral_ldlo: 'Rat Oral LDLo',
          '40_h_tetrahymena_pyriformis_igc50':
            '40 hour Tetrahymena pyriformis IGC50',
          mouse_intramuscular_ld50: 'Mouse Intramuscular LD50',
          'nr-er-lbd': 'Estrogen Receptor Ligand-Binding Domain',
          rabbit_oral_ld50: 'Rabbit Oral LD50',
          mouse_intravenous_ld50: 'Mouse Intravenous LD50',
          'sr-mmp': 'Mitochondrial Membrane Potential',
          'nr-ahr': 'Aryl Hydrocarbon Receptor',
          chicken_oral_ld50: 'Chicken Oral LD50',

          rat_oral_ld50: 'Rat Oral LD50',
          rat_subcutaneous_ld50: 'Rat Subcutaneous LD50',
          rabbit_skin_ld50: 'Rabbit Skin LD50',
          rat_intraperitoneal_ld50: 'Rat Intraperitoneal LD50',
          rat_intravenous_ld50: 'Rat Intravenous LD50',
          dog_oral_ld50: 'Dog Oral LD50',
          guinea_pig_oral_ld50: 'Guinea Pig Oral LD50',
          mouse_intraperitoneal_ldlo: 'Mouse Intraperitoneal LDLo',
          cardiotoxicity: 'Cardiotoxicity',
          'nr-ppar-gamma': 'PPAR-Gamma',
          mouse_skin_ld50: 'Mouse Skin LD50',
          'sr-hse': 'Heat Shock Factor Response Element',
          'nr-aromatase': 'Aromatase',
          rabbit_intravenous_ld50: 'Rabbit Intravenous LD50',
          daphnia_magna_lc50: 'Daphnia Magna LC50',
          dog_intravenous_ldlo: 'Dog Intravenous LDLo',
          '96_h_fathead_minnow_lc50': '96 hours Fathead Minnow LC50',
          rat_intraperitoneal_ldlo: 'Rat Intraperitoneal LDLo',
          rat_skin_ld50: 'Rat Skin LD50',
          'sr-are': 'SR-ARE (Antioxidant Response Element)',
          eye_corrosion: 'Eye Corrosion',
          'sr-p53': 'p53',
          mouse_subcutaneous_ld50: 'Mouse Subcutaneous LD50',
          carcinogenicity: 'Carcinogenicity',
          bbbp: 'Blood-Brain Barrier Penetration',
          guinea_pig_intraperitoneal_ld50: 'Guinea Pig Intraperitoneal LD50',
          logbb: 'LogBB',
          'selective_target-organ_or_system_toxicity_single_exposure_58479-2019':
            'Specific Target Organ Toxicity',
          'acute_toxicity_swallowed_56957-2016': 'Acute Toxicity Oral',
          human_vdss: 'Human VDss',
          'human_pharmacological_half-life': 'Human Pharmacological Half-life',
          'acute_aquatic_toxicity_57455-2017': 'Acute Aquatic Toxicity',
          'nr-ar-lbd': 'Androgen Receptor Ligand-Binding Domain',
          "Lipinski's rule of five": `Lipinski's Rule of Five (Ro5)`,
          'Ghose filter': 'Ghose Filter',
          "Oprea's Rule": "Oprea's Rules",
          "Veber's Rule": "Veber's Rule",
          'QED - quantitative estimate of drug-likeness': 'QED',
          PAINS: 'PAINS',
          'Hydrogen bond donors <= 5': 'Hydrogen bond donors <= 5',
          'Hydrogen bond acceptors <= 10': 'Hydrogen bond acceptors <= 10',
          'Molecular weight < 500': 'Molecular weight < 500',
          'LogP octanol-water <= 5': 'LogP octanol-water <= 5',
          'LogP octanol-water [-0.4, 5.6]': 'LogP octanol-water [-0.4, 5.6]',
          'Molecular weight [180, 480]': 'Molecular weight [180, 480]',
          'Number of atoms [20, 70]': 'Number of atoms [20, 70]',
          'Molar refractivity [40, 130]': 'Molar refractivity [40, 130]',
          'Hydrogen bond donors <= 2': 'Hydrogen bond donors <= 2',
          'Hydrogen bond acceptors [2, 9]': 'Hydrogen bond acceptors [2, 9]',
          'Number of rotatable bonds [2, 8]':
            'Number of rotatable bonds [2, 8]',
          'Number of rings [1, 4]': 'Number of rings [1, 4]',
          'TPSA <= 140 Å²': 'TPSA <= 140 Å²',
          pan_assay: 'Pan Assay Interference Compounds',
          topological_polar: 'Topological polar surface area',
          'g/mol': 'g/mol',
          logP: 'logP',
          'm3/mol': 'm3/mol',
          'Å²': 'Å²',
          not_included: 'Not included',
          literature: 'Literature',
          to_reactions: 'Reactions',
          png: 'PNG',
          pdf: 'PDF',
          mol: 'MOL',
          copy_link: 'Сopy link',
          tooltip: {
            'QED - quantitative estimate of drug-likeness':
              'Quantitative estimate of drug-likeness',
            PAINS: 'Pan Assay Interference Compounds',
            article: 'Article',
            'TPSA <= 140 Å²': 'Topological polar surface area',
          },
          failed_to_load_molecule_data: 'Failed to load molecule data',
          molecule_not_found: 'Molecule not found',
          back_to_results: 'To results',
          molecule_page: 'Molecule page',
          molecule_fast_view: 'Molecule fast view',
        },
        statistics: {
          header: 'Statistics',
          categories: {
            ames: 'Ames Test',
            bcf: 'Bioconcentration Factor (BCF)',
            bp: 'Boiling Point',
            density: 'Density',
            density_normal: 'Density (normal)',
            mp: 'Melting Point',
            refractive_index: 'Refractive Index',
            retention_time: 'Retention Time',
            vapour_pressure: 'Vapour Pressure',
            logP: 'LogP',
            toxicity: 'Toxicity',
            water_solubility_2: 'Water Solubility',
            dmso_solubility: 'DMSO Solubility',
            logp: 'LogP',
            physical: 'Physical',
            ecology: 'Ecology',
            mutagenicity: 'Mutagenicity',
            bio: 'Bio',
          },
          table_column: {
            parameter: 'Parameter',
            average: 'Average',
            unit: 'Unit',
            metric: 'Metric',
          },
          table_parameter: {
            'Ames Test': 'Ames Test',
            'Boiling Point': 'Boiling Point',
            Density: 'Density',
            'Density (normal)': 'Density (normal)',
            'Melting Point': 'Melting Point',
            'Refractive Index': 'Refractive Index',
            'Retention Time': 'Retention Time',
            'Vapor Pressure': 'Vapor Pressure',
            'Water Solubility': 'Water Solubility',
            LogP: 'LogP',
            Toxicity: 'Toxicity',
            'Log10(Water Solubility)': 'Water Solubility',
            'DMSO Solubility': 'DMSO Solubility',
            'Log10(BCF)': 'Log10(BCF)',
            'Mouse Intraperitoneal LD50': 'Mouse Intraperitoneal LD50',
            'Mouse Intraperitoneal LDLo': 'Mouse Intraperitoneal LDLo',
            'Mouse Intravenous LD50': 'Mouse Intravenous LD50',
            'Rat Intraperitoneal LD50': 'Rat Intraperitoneal LD50',
            'Mouse Oral LD50': 'Mouse Oral LD50',
            'Mouse Subcutaneous LD50': 'Mouse Subcutaneous LD50',
            'Rat Subcutaneous LD50': 'Rat Subcutaneous LD50',
            'Mouse Unreported LDLo': 'Mouse Unreported LDLo',
            'Rat Unreported LD50': 'Rat Unreported LD50',
            'Mouse Unreported LD50': 'Mouse Unreported LD50',
            'Rat Intraperitoneal LDLo': 'Rat Intraperitoneal LDLo',
            'Rabbit Skin LD50': 'Rabbit Skin LD50',
            'Rat Oral LD50': 'Rat Oral LD50',
            'Rat Oral LDLo': 'Rat Oral LDLo',
            'Rabbit Oral LD50': 'Rabbit Oral LD50',
            'Rabbit Intravenous LD50': 'Rabbit Intravenous LD50',
            'Rat Intravenous LD50': 'Rat Intravenous LD50',
            'Mouse Oral LDLo': 'Mouse Oral LDLo',
            'Rat Skin LD50': 'Rat Skin LD50',
            'Mammal (species unspecified) Subcutaneous LD50':
              'Mammal (species unspecified) Subcutaneous LD50',
            'Guinea Pig Oral LD50': 'Guinea Pig Oral LD50',
            '40 hour Tetrahymena pyriformis IGC50':
              '40 hour Tetrahymena pyriformis IGC50',
            '96 hours Fathead Minnow LC50': '96 hours Fathead Minnow LC50',
            'Bioconcentration factor': 'Bioconcentration factor',
            'Daphnia Magna LC50': 'Daphnia Magna LC50',
            BBBP: 'Blood-Brain Barrier Penetration ',
            'Bird Wild Oral LD50': 'Bird Wild Oral LD50',
            'Selective Target-organ Or System Toxicity Single Exposure 58479-2019':
              'Selective Target-organ Or System Toxicity Single Exposure 58479-2019',
            'Acute Toxicity Swallowed 56957-2016':
              'Acute Toxicity Swallowed 56957-2016',
            'Human pharmacological half-life':
              'Human pharmacological half-life',
            'Human VDss': 'Human VDss',
            logBB: 'logBB',
            'Acute Aquatic Toxicity 57455-2017':
              'Acute Aquatic Toxicity 57455-2017',
            'SR-p53': 'SR-p53',
            Carcinogenicity: 'Carcinogenicity',
            Cardiotoxicity: 'Cardiotoxicity',
            'Cat intravenous LD50': 'Car intravenous LD50',
            'Chicken Oral LD50': 'Chicken Oral LD50',
            DILI: 'DILI',
            'Developmental Toxicity': 'Developmental Toxicity',
            'Dog Intravenous LD50': 'Dog Intravenous LD50',
            'Dog Intravenous LDLo': 'Dog Intravenous LDLo',
            'Dog Oral LD50': 'Dog Oral LD50',
            'Eye Corrosion': 'Eye Corrosion',
            'Eye Irritation': 'Eye Irritation',
            'Guinea Pig Intraperitoneal LD50':
              'Guinea Pig Intraperitoneal LD50',
            Hepatotoxicity: 'Hepatotoxicity',
            'Mouse Intramuscular LD50': 'Mouse Intramuscular LD50',
            'Mouse Skin LD50': 'Mouse Skin LD50',
            'NR-AHR': 'Aryl Hydrocarbon Receptor',
            'NR-AR': 'Androgen Receptor, full length',
            'NR-ER': 'Estrogen Receptor  Alpha, full length',
            'NR-AR-LBD': 'Androgen Receptor Ligand-Binding Domain',
            'NR-ER-LBD': 'Estrogen Receptor Ligand-Binding Domain',
            'NR-Aromatase': 'Aromatase',
            'NR-PPAR-gamma': 'PPAR-gamma',
            'Quail Oral LD50': 'Quail Oral LD50',
            'Rabbit Intravenous LDLo': 'Rabbit Intravenous LDLo',
            'Reproductive Toxicity': 'Reproductive Toxicity',
            'SR-ARE': 'SR-ARE (Antioxidant Response Element)',
            'SR-ATAD5': 'ATAD5',
            'SR-HSE': 'Heat Shock Factor Response Element',
            'SR-MMP': 'Mitochondrial Membrane Potential',
            'SR-P53': 'p53',
            CYP2D6: 'CYP2D6',
            CYP3A4: 'CYP3A4',
            CYP1A2: 'CYP1A2',
            CYP2C9: 'CYP2C9',
            CYP2C19: 'CYP2C19',
          },
          table_units: {
            'Log(L/kg)': 'Log(L/kg)',
            '°C': '°C',
            'g/cm³': 'g/cm³',
            'log10(mg/kg)': 'Log10(mg/kg)',
            s: 's',
            Torr: 'Torr',
            LogP: 'LogP',
            '-Log10(mol/kg)': '-Log10(mol/kg)',
            'Log(mol/l)': 'Log(mol/L)',
            'log10(mmol/l)': 'Log10(mmol/L)',
            'log10(L/kg)': 'Log10(L/kg)',
            'Log10(mmol/l)': 'Log10(mmol/l)',
            'Log10(l/kg)': 'Log10(l/kg)',
          },
        },
        hot_tips: {
          label: 'Hot Tips',
          pdf2smiles: {
            'drag-n-drop':
              'Load a document by dragging it onto the screen area',
          },
          molecules: {
            left_click:
              'Left click on the molecule card to see the properties of the compound',
            select: 'Select a molecule with Ctrl (command) + Click',
            select_all:
              'To select all preceding molecules, press Shift + Click on the molecule you want to select up to',
          },
          datasets: {
            calculate:
              'Please note: the speed of property calculation for large datasets depends on the current platform load. Calculating the properties of datasets that contain more than 1000 molecules is usually time-consuming',
          },
          synmap: {
            deselect: 'To deselect double click outside selected area',
          },
        },
        pdf_print: {
          footer:
            'This document was generated on the website https://app.syntelly.com/. For all questions write to admin@syntelly.com',
        },
        notification: {
          check_spelling: 'Check your spelling or enter SMILES',
          uploading_of: 'Uploading of ',
          dataset_merge_error: 'Error while merging datasets',
          dataset_merge_success: 'Datasets merged successfully',
          dataset_copy_error: 'Error while copying dataset',
          dataset_copy_success: 'Dataset copied successfully',
          dataset_delete_success: 'Dataset successfully removed',
          datasets_delete_success: 'Datasets successfully removed',
          file_recognition: 'File recognition ',
          finished: 'has finished',
          successfully_uploaded: 'successfully uploaded',
          uploading_failed: 'Failed to upload {{name}}',
          molecules_move_error: 'Error during molecules moving',
          unknown_error: 'Unknown error',
          molecule_already_in_dataset:
            'The molecule is already in this dataset. Add another molecule',
          smiles_copied: 'SMILES successfully copied',
          patent_number_copied: 'Patent number successfully copied',
          DOI_copied: 'DOI successfully copied',
          link_copied: 'Link successfully copied',
          molecule_add: 'Molecule added to basket «{{name}}»',
          molecules_add: 'Molecules added to basket «{{name}}»',
          selected_molecule_add: 'Selected molecule added to basket «{{name}}»',
          selected_molecules_add:
            'Selected molecules added to basket «{{name}}»',
          selected_molecule_moved:
            'Selected molecule moved to basket «{{name}}»',
          selected_molecules_moved:
            'Selected molecules moved to basket «{{name}}»',
          unhandled: 'Unhandled error',
          wrong_activation: 'Activation went wrong',
          iupac_copy: 'IUPAC successfully copied',
          baseid_copy: 'Syntelly ID successfully copied',
          inchi_copy: 'InCHI successfully copied',
          inchi_key_copy: 'InCHI key succuessfully copied',
          dataset_synmap_delete: 'Dataset «{{name}}» was removed',
          generator_synmap_delete: 'Generator was removed',
          retrosynthesis_warning: 'Too many molecules',
          retrosynthesis_warning_small:
            'To use reverse model, leave only one molecule',
          synthesis_start: 'Synthesis calculation is in progress',
          retrosynthesis_start: 'Retrosynthesis calculation is in progress',
          retrosynthesis_success: 'Retrosynthesis calculation is finished',
          retrosynthesis_failed: 'Retrosynthesis calculation is failed',
          spectra_start: 'Spectra calculation started',
          error: 'ERROR!',
          file_uploaded_successfully:
            'Recognition of the {{name}} file has begun. This may take some time',
          failed_to_upload_file: 'Failed to download file {{name}}',
          literature_search_started: 'Literature search has been started',
          literature_search_finished: 'Literature search has been finished',
          literature_search_progress: 'Literature search is running',
          source_copied: 'Source copied',
          you_can_only_recognize_one_document_at_a_time:
            'You can only recognize one document at a time',
          molecules_not_added_to_basket:
            'Molecules not added to dataset «{{name}}»',
          molecule_not_added_to_basket:
            'Molecule not added to dataset «{{name}}»',
          not_all_molecules_added_to_basket:
            'Not all molecules added to dataset «{{name}}»',
          more_information: 'More information',
          less_information: 'Less information',
          molecule: 'Molecule',
          molecules: 'Molecules',
          molecule_already_exists_in_basket:
            'The dataset already contains molecule ',
          molecules_already_exist_in_basket:
            'The dataset already contains molecules ',
          structure: 'Structure',
          structures: 'Structures',
          is_not_valid: 'is not valid',
          are_not_valid: 'are not valid',
          we_could_not_find_molecule: 'We could not find desired molecule',
          we_could_not_add_molecule: 'We could not add a molecule',
          we_could_not_add_molecules: 'We could not add molecules',
          wait_or_message:
            'due to an internal server error. Wait or write to us by email',
          too_big_file: 'The file is too big to upload',
          no_more_100_mb:
            'You can upload a document no more than 100 MB per file',
          'User is not source and target owner':
            'You are not source or target dataset owner',
          'At least one of molecules already exists in target or does not exist in source':
            'At least one of molecules already exists in target or does not exist in source',
          cancel: 'Cancel ({{time}}s)',
          search_query_removed: 'Search query {{type}} «{{text}}» removed',
          completed_tasks_removing_failed: 'Failed to remove completed tasks',
          calc_tlight_success:
            'Calculation of molecular properties is finished',
          not_possible_search:
            'It is not possible to search in russian language for IUPAC, SMILES and trivial names',
          not_possible_star:
            'Entering smilies with “*” is only supported in Markush structure and literature searches',
          switch_language: 'Please switch to English and enter your request',
          remove_star: 'Please remove the “*” from the request',
          we_couldnt_found_best_match: `We couldn't find the best match`,
          showed_you_similar_results:
            'Showed you similar results. Check your spelling or enter SMILES',
          we_couldnt_find_desired_structure: `We couldn't find desired structure`,
          check_your_spelling_or_enter_SMILES:
            'Check your spelling or enter SMILES',
          pdf_download_will_start: 'PDF download will start in a few seconds',
          product_key_copied: 'Product key copied',
          invite_link_copied: 'Invite link copied',
          invite_key_copied: 'Invite key copied',
        },
        spectra: {
          title: 'Spectra Prediction',
          calculated_title: 'Calculated results',
          input_params: 'Input parameters',
          header: {
            ms: 'The module predicts QToF MS/MS spectra for small organic molecule. The spectra are calculated for low (10 eV), medium (20 eV), and high (40 eV) collision energy levels. The result is presented as a set of “ion mass – relative intensity” pairs.',
            nmr: 'Module makes it possible to predict nuclear magnetic resonance spectral data (1H, 13C, 15N and 19F) for small organic molecules. The result is presented as a set of "chemical shift -relative intensity". Multiplicity is also predicted for 1H spectra.',
            infraredSpectrometry:
              'Module allows you to predict the IR spectrum for small organic molecules with various registration options (gas phase, KBr, etc.). The result is displayed as a continuous graph in the axes "wavenumber" (cm⁻¹) and "intensity".',
          },
          buttons: {
            nmr: 'Nuclear magnetic resonance',
            ms: 'Mass spectrometry',
            predict: 'Predict',
            reset: 'Reset',
            edit: 'Edit',
            infraredSpectrometry: 'Infrared spectrometry',
          },
          labels: {
            input: 'The original composite structure',
            spectral_type: 'Spectral Type',
            ion_mode: 'Ion Mode',
            adduct_type: 'Adduct Type',
            method: 'Method',
            draw_molecule: 'Draw',
            roundingRI: 'Rounding (RI)',
            roundingMZ: 'Rounding (m/z)',
            isotopic_distribution: 'Isotopic distribution',
            low_energy: 'LE MsMs Spectrum (10eV)',
            medium_energy: 'ME MsMs Spectrum (20eV)',
            high_energy: 'HE MsMs Spectrum (40eV)',
            shooting_method: 'Shooting method',
            input_placeholder: 'Enter SMILES',
          },
          error: {
            not_valid_smiles: 'Invalid SMILES',
            error_occured: 'An error occured',
            error: 'ERROR!',
            no_spectra: 'There are no spectra with this method',
          },
          chart: {
            'm/z': 'm/z',
            intensity: 'Intensity',
            formula: 'Formula',
            relative_intensity: 'Relative Intensity',
            RI: 'RI',
            shift: 'Shift',
            i_intensity: 'I, Intensity',
            wavenumber: 'Wavenumber, cm⁻¹',
          },
          table: {
            atom: 'Atom',
            meas_shift: 'Chem. Shift',
            multiplet: 'Multiplet',
          },
        },
        techWorks: {
          header: 'Technical work is underway',
          desc: 'The platform is under maintenance and will be available soon',
          timerInfo: 'Opening via:',
          contactUs: 'Contact us',
        },
        smilesIupac: {
          header: 'SMILES to IUPAC converter',
          enterMolecule: ' Enter a molecule in',
          smiles: 'SMILES',
          notationHere:
            'notation here, e.g., C=CC(=O)N1CCC[C@H](C1)N2C3=NC=NC(=C3C(=N2)C4=CC=C(C=C4)OC5=CC=CC=C5)N and press enter.',
          youWillSeeResults: `You will see 5 predicted results and indications of whether a
        name is correct or not.`,
          enterSmiles: 'Enter SMILES here',
          convert: 'Convert',
          modelForecasts: 'Model forecasts',
          inRussian: 'In Russian',
          inEnglish: 'In English',
          error: 'Error',
          serverError: 'Server return error',
        },
        timer: {
          days: 'days',
          hours: 'hours',
          minutes: 'minutes',
          seconds: 'seconds',
        },
        billing: {
          serviceName: 'Service name',
          shortName: 'Short name',
          desc: 'Description',
          price: 'Price',
          addNewService: 'Add new service',
          close: 'Close',
        },
        admin: {
          billing: 'Billing',
          recognition: 'Recognition',
          plugins: 'Plugins',
          'molecule params viewer': 'Molecule params viewer',
          'feedback viewer': 'Feedback viewer',
          userLog: 'UserLog',
          users: 'Users',
          'ban list': 'Ban list',
          invite: 'Invite',
          usersCount: 'Users count:',
          name: 'Name',
          email: 'Email',
          company: 'Company',
          position: 'Position',
          baskets: 'Baskets',
          molecules: 'Molecules',
          pdfs: 'PDFs',
          regDate: 'Reg Date',
          lastEnter: 'Last enter',
          newUsers: 'New users',
          loading: 'Loading...',
          addNewPlugin: 'Add new plugin',
          pluginsList: 'Plugins List',
          pluginName: 'Plugin name',
          type: 'Type',
          viewType: 'View type',
          table: 'Table',
          list: 'List',
          pluginsView: 'Plugin`s View',
          address: 'Address',
          addNewFunc: 'Add new functions',
          rpcMethodName: 'RPC method name',
          input: 'Input',
          inchiKey: 'InChi Key',
          default: 'Default',
          defaultValue: '0 is default value',
          serviceTimeout: 'Service timeout',
          outputName: 'Output name',
          exit: 'Exit',
          edit: 'Edit',
          create: 'Create',
          mails: 'Mails',
          mail: 'Mail',
          addMail: 'Add mail',
          addIP: 'Add IP',
          invites: 'Invites',
          generate: 'Generate',
          generated: 'successfully generated',
          generation_failed: 'Generation of {{name}} failed',
          invitation: 'invitation',
          invitations: 'invitations',
          token: 'Token',
          created: 'Created',
          regData: 'Registration data',
          notUsed: 'not used',
          num: 'Num',
          enterIP: 'Enter IP',
          addIPToList: 'Add IP to list',
          clickOnIp: 'Click on IP to delete from ban list',
          // new
          admin_panel: 'Administrator panel',
          from: 'from',
          to: 'to',
          not_active: 'Not active',
          tabs: {
            users_and_companies: 'Users and companies',
            statistics: 'Statistics',
            plugins: 'Plugins',
            molecule_params: 'Molecule Params',
            recognition: 'Recognition',
            user_messages: 'User messages',
          },
          placeholder: {
            enter_company_name: 'Enter company name',
            enter_email: 'Enter email',
            enter_domain: 'Enter domain',
          },
          companies: 'Companies',
          name_email: 'Name, position, e-mail',
          status: 'Status',
          activation: 'Activation',
          ending: 'Ending',
          last_activity: 'Last visit',
          filter: {
            companyType: 'Company Type',
            date_from: 'Date from',
            date_to: 'Date to',
            placeholder:
              'Enter the company name, position, e-mail or first and last name',
            add_user: 'Add user',
            add_company: 'Add company',
            set_filter: 'Set filter',
            options: { all: 'All' },
          },
          test_period: 'Test period',
          license: 'License',
          with_license: 'With license',
          admin: 'Administator',
          user: 'User',
          domain: 'Domain',
          numberOfLicenses: 'Number of licenses',
          registered: 'Registered',
          periodOfValidity: 'Period of Validity',
          addUser: 'Add user',
          inviteLink: 'Invite link',
          limit_access: 'Limit access',
          allow_access: 'Allow access',
          name_position: 'Name, position',
          disable_access: 'Disable access',
          restore_access: 'Restore access',
          edit_company: 'Edit company information',
          toggle_access_error:
            'An error occurred while changing the license status',
          customer_update_error: 'An error occured while updating company',
          get_companies_error: 'An error occurred while retrieving companies',
          get_users_error: 'An error occurred while retrieving users',
          no_name: 'No name',
          no_company: 'No company',
          edit_company_info: 'Edit company info',
          undo_changes: 'Undo changes',
          license_period: 'License period',
          company_name: 'Company name',
          accs_number: 'Number of accs',
          with_domain: 'With domain',
          add_company_error: 'Error when adding a company',
          add_company_error_name_exists:
            'Customer with that name already exists',
          company_added: 'Company added',
          company_added_info:
                'It will appear in the admin panel. Copy the product key for the company below or copy the registration link. You will also find the key and link on the company page',
          domain_hint: 'Provides keyless registration, for users with the given domain'
        },
        generator: {
          removeGenerator: 'Remove Generator',
          createGenerator: 'Create Generator',
          create: 'Choose',
          radius: 'Radius',
          epoch: 'Epoch',
          options: 'Generator Options',
          inputName: 'Name',
          noParent: 'No parent (root)',
          selectParent: 'Select parent',
          epochs: 'Epochs',
          selectAreaOnMMAP: 'Select area on MMap',
          addLayerFirst: 'Have to add layer with molecules first',
          enterBrutto: 'Brutto formula or SMILES',
          minimize: 'Min',
          maximize: 'Max',
          addNewProperty: 'Add new property',
          youWillLose: `You'll lose all descendants of selected parent!`,
          sessionStarted: 'Generation of «{{name}}»',
          genStarted: 'Creating a generator',
          sessionStartedSmall:
            'Generation of «{{name}}» dataset is in progress',
          session_failed: 'Generation of «{{name}}» dataset failed',
          selectAll: 'Select all',
          molecules: 'molecules',
          cancel: 'Cancel',
          mmap: {
            x: 'by X',
            y: 'by Y',
          },
          generation: 'Generation',
          optimization: 'Optimization',
          addParameter: 'Add parameter',
          parameterWeight: 'Parameter weight',
          filtrationTooltip:
            '% of molecules with the highest value of the reward function',
          filtration: 'Filtration',
          create_new: 'Create',
          enterSmiles: 'Enter Smiles',
          generator_failed: 'Generator failed',
        },
        r_selected: {
          brutto: 'Brutto',
          score: 'Score:',
        },
        error: {
          internalError: 'Internal Server Error',
          not_valid_smiles: 'SMILES is not valid',
          error_occured: 'Error occured',
        },
        searchVariants: {
          best_match: 'Best match',
          similar_structures: 'Similar structures',
          prev_searched_structures: 'Search query history',
        },
        synthCost: {
          output: 'Output: ',
          more: 'More',
          less: 'Less',
          no_info: 'No information',
          synthesis_cost: 'Synthesis cost',
          synthesis_cost_description:
            'An analytical tool developed to estimate the cost of synthesizing chemical compounds. You need to enter the parameters of the desired synthesis: product, reagent, desired weight of the synthesized substance and number of reaction steps. The result is the top 5 reaction schemes ordered by increasing cost. This allows you to analyze the known methods and choose the most optimal synthesis pathway with calculation of economic efficiency. The module provides opportunities for detailed analysis of each scheme, editing the cost table and exporting data in various formats',
          product: 'Product',
          reagent_capital: 'Reagent',
          main_component_of_the_reaction: 'Main component of the reaction',
          enter_inchi_or_smiles: 'Enter InChI or SMILES',
          weight_in_grams: 'Desired amount (g)',
          formula: 'Formula of the target compound for synthesis',
          desired_weight:
            'Desired weight of the synthesized substance in grams',
          number_of_stages: 'Number of steps',
          max_number_of_stages:
            'Maximum permissible number of synthesis stages (from 1 to 6)',
          predict: 'Predict',
          reset: 'Reset',
          g: 'g',
          from: 'from 1',
          from_pseudo: 'from',
          to: 'to 6',
          placeholder_from: 'from',
          placeholder_to: 'to',
          to_pseudo: 'to',
          final_stage_cannot_be_less:
            'The final stage cannot be less than the initial stage',
          draw: 'Draw',
          calculated_results: 'Calculated results',
          scheme: 'Scheme',
          stage: 'Stage',
          download: 'Download',
          download_scheme: 'Download scheme',
          all_schemes: 'all schemes',
          overall: 'Overall',
          compound_name: 'Compound name',
          price: 'Price',
          amount: 'Amount',
          source: 'Source',
          estimated_substance_price:
            'Estimated price of the required amount of substance',
          required_amount: 'Required amount of substance',
          add_row: 'Add row',
          edit_table: 'Edit table',
          total_cost: 'Total cost of all stages',
          add_stage: 'Add stage',
          delete_stage: 'Delete stage',
          cancel: 'Cancel',
          return: 'Return',
          save_and_close: 'Save and close',
          changes: 'changes',
          Scheme: 'Scheme',
          calculating: 'Calculating',
          synth_cost: 'the cost of synthesis…',
          calculating_failed: 'Failed to calculate the cost of syntesis',
          synth_cost_calculated: 'The cost of synthesis has been calculated',
          searched_nothing: 'Nothing found',
          is_not_smiles: 'is not a SMILES',
          server_returned_error: 'The server returned an error',
          reaction_scheme_source: 'Reaction scheme source',
          cancel_calculating: 'Cancel calculating the cost of synthesis?',
          edit: 'Edit',
          add_value: 'Need to add value',
          reagent: 'Reagent',
          one_reagent: 'reagent',
          reagents_few: 'reagents',
          reagents_many: 'reagents',
        },
        dataset_table: {
          table_analysis: 'Tabular analysis',
          all_columns: 'All columns',
          table_analysis_of_the_dataset:
            'Tabular analysis of the dataset «{{name}}»',
          contains: 'Сontains',
          doesNotContain: 'Does not contain',
          equals: 'Equals',
          doesNotEqual: 'Does not equal',
          startsWith: 'Starts with',
          endsWith: 'Ends with',
          blank: 'Blank',
          notBlank: 'Not blank',
          greaterThan: 'Greater than',
          greaterThanOrEqual: 'Greater than or equal',
          lessThan: 'Less than',
          lessThanOrEqual: 'Less than or equal',
          inRange: 'In range',
          value: 'Value',
          select_all: 'Select all',
          not_selected: 'Not selected',
          type: 'Type',
          from: 'From',
          to: 'To',
          and: 'And',
          or: 'Or',
          filter: 'Filter',
          paint: 'Paint',
          by_name: 'By name',
          search: 'Search',
          clear: 'Clear',
          apply: 'Apply',
          no_values: 'No values',
          value_not_found: 'Value not found',
          nothing_found: 'Nothing found',
          select_value: 'Select value',
          calculating_data: 'Calculating data for tabular analysis',
          cancel_calculating_data:
            'Cancel calculating data for tabular analysis',
        },
        calendar: {
          select_date: 'Select the license validity period',
        },
      },
    },
    ru: {
      translation: {
        common: {
          back: 'Назад',
        },
        devBanner: {
          hello: 'Добрый день!',
          mobileInDev:
            'Наша мобильная версия находится в разработке, не все функции работают корректно. По возможности, используйте десктопную версию в браузере.',
          ok: 'Хорошо',
        },
        notifications: {
          hide: 'Скрыть',
          remove_completed_tasks: 'Удалить завершенные',
          cancel: 'Отменить',
          continue: 'Продолжить',
          admit: {
            confirm: 'Подтвердить',
            cancel: 'Отменить',
          },
          error: {
            close: 'Закрыть',
            repeat: 'Повторить',
            add_again: 'Добавить заново',
            write_about_an_error: 'Написать об ошибке',
          },
          maxCount: 'Превышено максимальное количество молекул на карте Synmap',
          loading_error: 'Ошибка загрузки',
          calculation_of_3d_coordinates_in_progress:
            'Расчет 3D координат в процессе',
          calculation_of_3d_coordinates_success: 'Расчет 3D координат завершен',
          failed_to_load_3d_coordinates: 'Не удалось загрузить 3D координаты',
          enterMolecule: 'Пожалуйста, введите молекулу!',
          copyProcessWrong: 'В процессе копирования что-то пошло не так :(',
          please_login: 'Пожалуйста войдите, чтобы создать генератор!',
          go_to_set: 'Перейти в датасет',
          continue_task: {
            calculating: 'Продолжить вычисление',
            calculation: 'Продолжить расчет',
            coping: 'Продолжить копирование',
            search: 'Продолжить поиск',
            continue: 'Продолжить',
          },
          limits: {
            max_file_size: 'Превышен максимальный размер файла',
            max_available_file_upload:
              'Доступна загрузка файла не более{{maxSize}}',
            mol_max_number: 'Превышено максимальное количество молекул',
            max_available_mol_count_move:
              'Доступно перемещение молекул не более{{maxCount}}',
            max_available_mol_count_copy:
              'Доступно копирование молекул не более{{maxCount}}',
            mol_max_number_by_day:
              'Превышено максимальное количество молекул за день',
            'You have exceeded the daily limit on the number of structures you can upload':
              'Вы исчерпали дневной лимит количества молекул, доступных для загрузки',
            mol_max_number_by_month:
              'Превышено максимальное количество молекул за месяц',
            'You have exceeded the monthly limit on the number of structures you can upload':
              'Вы исчерпали месячный лимит количества молекул, доступных для загрузки',
            period: {
              day: 'Дневной лимит',
              month: 'Месячный лимит',
            },
            period_text: {
              day: 'Лимит запросов в день был исчерпан',
              month: 'Лимит запросов в месяц был исчерпан',
            },
          },
        },
        moleditor: {
          search: 'Синтелли ID, тривиальное название, название IUPAC',
        },
        notFound: 'Страница не найдена!',
        synmap: {
          instruments: {
            pan: 'Перемещение',
            box_select: 'Прямоугольное выделение',
            lasso_select: 'Выделение лассо',
            autoscale: 'Автоматическое масштабирование',
            '2d_mode': '2D режим',
            '3d_mode':
              'Обратите внимание: расчет координат в 3D режиме может занять длительное время',
            orbit: 'Орбитальное вращение',
            turntable: 'Вращение на плоскости',
            reset_camera_to_default: 'Сбросить камеру к значению по умолчанию',
            clear_select: 'Сбросить выделение',
          },
          copy_molecules: 'Молекулы копируются в датасет ',
          molecules_copied: 'Молекулы скопированы в датасет ',
          copy_molecules_failed:
            'Ошибка копирования молекул в датасет {{name}}',
          cancel_copy_molecules: 'Отменить копирование молекул?',
        },
        filter: {
          title: 'Фильтр',
          collapse: 'Свернуть',
          reset: 'Очистить',
          min: 'мин.',
          max: 'макс.',
          label_min: 'Минимальная',
          label_max: 'Максимальная',
          from: 'от',
          from_rus: 'с',
          to: 'до',
          to_rus: 'по',
          pieces: 'шт',
          include: 'Включить',
          exclude: 'Выключить',
          applyFilters: 'Применить',
          author_surname: 'Фамилия автора',
          notify_sub: 'Используйте 2 или меньше неопределенных заместителя',
          notify_not_ext_smiles:
            'Введенный SMILES не является структурой Маркуша',
          notify_not_both_weights:
            'Необходимо указать минимальную и максимальную молекулярную массу',
          notify_not_min_weight:
            'Необходимо указать минимальную молекулярную массу',
          notify_min_weight_not_less:
            'Минимальная молекулярная масса не может быть менее {{value}}',
          notify_mol_weight_error:
            'Разница между максимальной и минимальной массой больше 100',
          notify_min_and_max_equal:
            'Максимальная молекулярная масса должна быть больше минимальной',
          structure: {
            match_type: 'Тип соответствия',
            exact_match: 'Точное совпадение',
            substructural_search: 'Подструктурный поиск',
            similar_structures: 'Похожие структуры',
            markush_structures: 'Структуры Маркуша',
            similarity: 'Подобие, %',
            molecular_weight: 'Молекулярная масса',
            ALogP: 'ALogP',
            elements: 'Элементы',
            published_date: 'Дата публикации',
            author: 'Автор',
            article: 'Статья',
            patent: 'Патент',
            ru: 'Русский',
            en: 'Английский',
            de: 'Немецкий',
          },
          literature: {
            text: 'Полный текст',
            claims: 'Формула изобретения',
            document_type: 'Тип документа',
            header: 'Добавление условия к запросу',
            addCondition: 'Добавить условие',
            reset: 'Cбросить',
            confirm: 'Подтвердить',
            authors: 'Автор',
            doi: 'DOI',
            patent_number: 'Номер патента',
            abstract: 'Аннотация',
            journal: 'Журнал',
            publisher: 'Издатель',
            language: 'Язык',
            published_date: 'Дата публикации',
            must_lowercase: 'и',
            should_lowercase: 'или',
            must: 'И',
            should: 'Или',
            add: 'Добавить',
            delete: 'Удалить',
            changeRequest: 'Измените свой запрос',
            requestNotFound: 'Запрос не найден в текущей базе данных',
            title: 'Заголовок',
            owner: 'Владелец',
            applicant: 'Заявитель',
            from: 'c',
            to: 'по',
            name_warning:
              'Выбор DOI или номера патента будет сбрасывать другие условия.',
          },
        },
        literatureCard: {
          full_text: 'Полный текст',
          show: 'Показать',
          copyLink: 'Скопировать ссылку',
          copyDOI: 'Скопировать DOI',
          copyPatentNumber: 'Скопировать номер патента',
          doi: 'DOI статьи:',
          patentNumber: 'Номер патента:',
          authors: 'Авторы:',
          authorsNotColon: 'Авторы',
          author: 'Автор',
          onIndividualPage: 'На отдельной странице',
          articleStructures: 'Структуры из статьи',
          articleReactions: 'Реакции из статьи',
          similarArticles: 'Похожие статьи',
          structures: 'Структуры',
          reactions: 'Реакции',
          returnToResults: 'К результатам',
          patent: 'Патент',
          article: 'Статья',
          source: 'Источник',
          published_date: 'Год',
          owner: 'Владелец',
          owners: 'Владельцы',
          journal: 'Журнал',
          more: 'Больше',
          article_authors: 'Авторы статьи',
          patent_authors: 'Авторы патента',
          translate: 'Перевести',
          show_original: 'Смотреть оригинал',
        },

        confirmation: {
          ok: 'OK',
          cancel: 'Отмена',
          areYouSure: 'Вы уверены?',
          deleteThatService: 'Вы хотите удалить этот сервис?',
          deleteIp: 'Вы уверены, что хотите удалить {{ip}} из бан-листа?',
          deleteLayer: 'Вы уверены, что хотите удалить слой {{name}}?',
          russianSymbols: 'Не разрешается использовать кириллицу',
          cantConvert: 'Невозможно конвертировать SMILES в координаты',
          no_smile: 'Некорректный SMILES',
          delete_this_document: 'Вы уверены, что хотите удалить этот документ?',
          yes: 'Да',
          no: 'Нет',
          confirm_action: 'Подтвердите действие',
          add_condition: 'Вы уверены, что хотите добавить данное условие?',
          delete_generator:
            'Вы уверены, что хотите удалить генератор и все его производные?',
        },
        registration: {
          name: 'Имя',
          surname: 'Фамилия',
          password: 'Пароль',
          company: 'Компания',
          retypePassword: 'Повторите пароль',
          position: 'Должность',
          inviteToken: 'Пригласительный токен',
          alreadyRegistered: 'Есть аккаунт?',
          contactUs: 'Свяжитесь с нами',
          registeringToThisWebsite:
            'Регистрируясь на этом веб-сайте, вы соглашаетесь с нашей ',
          registering: 'Регистрируясь, вы соглашаетесь с',
          user_agreement: 'Условиями пользовательского соглашения',
          and: ' и ',
          privacyPolicy: 'политикой обработки персональных данных',
          product_key: 'Ключ продукта',
          license_key: 'Ключ лицензии',
          license_key_placeholder: 'Введите ключ лицензии',
          if_no_key:
            'Если у вас нет ключа от компании, предоставим вам доступ на {{count}} дней для тестирования',
          to_personal_data: 'К личным данным',
          complete_registration: 'Завершить регистрацию',
          signUp: 'Зарегистрироваться',
          confirm_email: 'Подтвердить почту',
          last_step_left: 'Остался последний шаг',
          confirm_your_email:
            'Подтвердите почту, перейдя по ссылке активации в письме. Письмо уже на вашей почте',
          activation:
            'На вашу почту пришло письмо с ссылкой для активации аккаунта',
          activationFailed:
            'Ваш аккаунт не активирован. Пожалуйста, подтвердите адрес эл.почты. Ссылка активации направлена на адрес эл.почты, указанный при регистрации. ',
          'Invite token is invalid. Please ask for a new one or contact our support team(admin@syntelly.com)':
            'Пригласительный токен недействителен. Пожалуйста, запросите новый или свяжитесь с нашей службой поддержки(admin@syntelly.com )',
          activation_failed_title: 'Ваша учетная запись не активирована',
          activation_failed_description:
            'Пожалуйста, подтвердите свой адрес электронной почты.\nСсылка для активации была отправлена на адрес электронной почты, указанный при регистрации',
          registration: 'Регистрация',
          we_offer:
            'Мы предлагаем новым пользователям возможность протестировать нашу платформу в течение <span>{{count}} дней</span>',
          if_you_have_key:
            'Если у вас есть ключ компании, вы можете ввести его дальше и получить лицензионный доступ',
          start_registration: 'Начать регистрацию',
          return: 'Вернуться',
          namePlaceholder: 'Ваше имя',
          surnamePlaceholder: 'Ваша фамилия',
          companyPlaceholder: 'Ваша компания',
          positionPlaceholder: 'Ваша должность',
          personal_data: 'Личные данные',
          enter_personal_data:
            'Введите ваши личные данные, компанию и должность',
          to_email_and_password: 'К почте и паролю',
          fill_all_fields: 'Пожалуйста, заполните все поля',
          email_placeholder: 'Введите email',
          password_placeholder: 'Придумайте пароль',
          password2_placeholder: 'Повторите пароль',
          email_and_password: 'Почта и пароль',
          enter_email_and_create_password:
            'Введите вашу почту и придумайте надежный пароль',
          registered_success_title: 'Вы успешно зарегистрировались',
          registered_success_description:
            'Мы рады приветствовать вас в сообществе профессионалов, использующих передовые технологии для достижения выдающихся результатов в своей работе.',
          registered_success_description_2:
            'Мгновенный доступ к достоверной научной информации, большое количество инструментов для повышения эффективности ваших исследований.',

          innovation_here: 'Инновации здесь',
          password_security_requirements: 'Требования к безопасности пароля',

          includes_no_less_8: 'Содержит не менее 8 символов',
          includes_a_z:
            'Содержит как строчные (a-z), так и прописные буквы (A-Z)',
          includes_symb: 'Содержит по крайней мере одну цифру (0-9) или символ',
          no_includes_email: 'Не содержит ваш адрес электронной почты',
          fullName: 'Полное Имя',
          invite_token_is_invalid:
            'Пригласительный токен недействителен. Пожалуйста, попросите новый или свяжитесь с нашей службой поддержки (admin@syntelly.com)',
          invite_token_is_required:
            'Для регистрации необходим пригласительный токен',
          email_error: 'Пользователь с e-mail {{email}} уже существует',
          confirm_not_a_robot: 'Подтвердите, что вы не робот',
          providing_quality_services:
            'Мы стремимся предоставлять вам качественные услуги и обеспечивать стабильность работы платформы',
          protection:
            'Один из способов защиты — капча, которая помогает предотвратить автоматический доступ к нашему сервису',
          back: 'Назад',
          registration_failed: 'Не удалось завершить регистрацию',
          invalid_license_key: 'Ключ лицензии невалиден',
          invalid_token:
            'Пригласительный токен недействителен. Пожалуйста, запросите новый или свяжитесь с нашей службой поддержки(admin@syntelly.com )',
        },
        account: {
          name: 'Имя',
          position: 'Должность',
          company: 'Компания',
          email: 'E-mail',
          password: 'Пароль',
          changePassword: 'Сменить пароль',
          saveInfo: 'Сохранить',
          resetInfo: 'Сбросить',
          login: 'Вход',
          logout: 'Выйти из аккаунта',
          signIn: 'Войти',
          signUp: 'Зарегистрироваться',
          signInOrSignUp: 'Войти или зарегистрироваться',
          forgotPassword: 'Забыли пароль?',
          reEnterPassword: 'Повторно введите пароль',
          resetSended:
            'Ссылка для сброса успешно отправлена. Пожалуйста, проверьте свою электронную почту!',
          passwordChanged:
            'Пароль успешно изменен. Вы можете войти в систему со своим новым паролем',
          resetPassword: 'Сбросить пароль',
          passwordPlaceholder: 'Минимум 8 символов',
          password2Error: 'Повторный пароль введен неверно',
          enterEmail: 'Введите e-mail, чтобы сбросить пароль',
          enterNewPassword: 'Введите новый пароль',
          emailError: 'Неверный e-mail',
          retypePassword: 'Повторно введите пароль',
          dontChange: 'Не изменять',
          change: 'Изменить',
          emailPlaceholder: 'Введите свой E-mail',
          tokenPlaceholder: 'Введите токен',
          tokenPassword: 'Введите пароль',
          hasNoAccount: 'Нет аккаунта?',
          namePlaceholder: 'Введите ваше имя',
          companyPlaceholder: 'Введите название вашей компании',
          copyRight: '© Синтелли 2018-',
          wrongEmailOrPassword: 'Неверный email или пароль',
          somethingWentWrong: 'Что-то пошло не так',
          messageSuccess: 'Сохранено успешно!',
          messageFailed: 'Не удалось сохранить',
          welcome: 'Добро пожаловать',
          enter_your_data: 'Введите, пожалуйста, ваш логин и пароль для входа',
          remember_data: 'Запомнить данные для входа',
          password_placeholder: 'Введите свой пароль',
          tips: 'Подсказки',
          about_each_module: 'О каждом модуле',
          password_changed: 'Пароль успешно изменен',
          ok: 'Хорошо',
          trial_period: 'Ваш тестовый период',
          license: 'Ваша лицензия',
          your_trial_period_will_end: '{{value}} закончится\nчерез',
          day: '{{value}} день',
          many_days: '{{value}} дней',
          few_days: '{{value}} дня',
          purchase: 'приобрести',
          extend: 'продлить',
          you_can_purchase_a_license:
            'Вы можете {{value}} лицензию, написав нам через форму для связи или на почту',
          skip: 'Пропустить',
          contact_us: 'Связаться с нами',
          we_would_be_grateful:
            'Мы будем признательны, если вы поделитесь\nсвоими впечатлениями о нашем продукте',
          email_copied: 'Адрес электронной почты скопирован',
          take_a_survey: 'Пройти опрос',
          follow_the_platform:
            'Следите за обновлением функционала платформы\nв наших соцсетях',
          we_would_be_grateful_2:
            'Мы будем признательны, если вы\nподелитесь своими впечатлениями',
          already_have_key: 'У меня уже есть ключ',
          unfortunately: 'К сожалению, ',
          no_license: 'у вас нет лицензии',
          trial_period_expired: 'ваш тестовый период закончился',
          license_expired: 'ваша лицензия закончилась',
          license_invalid: 'ваша лицензия не действительна',
          enter_license_key: 'Введите ключ лицензии',
          activate: 'Активировать',
          invalid_license_key: 'Ключ лицензии невалиден',
          license_activation_failed: 'Не удалось активировать ключ лицензии',
          buy_a_license: 'Купить лицензию',
          license_is_invalid:
            'Лицензия не действительна. Пожалуйста, свяжитесь с администратором организации',
        },
        recovery: {
          password_reset: 'Cброс пароля',
          enter_email:
            'Введите свой email, на который был зарегистрирован ваш аккаунт',
          email_placeholder: 'Введите свой E-mail',
          restore_password: 'Восстановить пароль',
          user_not_found:
            'У нас нет учетной записи, зарегистрированной под этим E-mail. Пожалуйста, проверьте правильность ввода E-mail',
          check_your_email: 'Проверьте свою почту',
          link_sended: 'Мы отправили вам ссылку для сброса пароля',
          new_password: 'Новый пароль',
          enter_new_password:
            'Введите новый пароль для входа в вашу учетную запись',
          confirm: 'Подтвердить',
          password_changed: 'Пароль успешно изменён',
          search_predict_explore:
            'Ищите, прогнозируйте и исследуйте необходимые соединения, а также пользуйтесь быстрым доступом к информации о любых свойствах и соединениях',
          login: 'Войти в аккаунт',
          something_went_wrong:
            'Что-то пошло не так. Пожалуйста, попробуйте еще раз.',
        },
        promo: {
          search_title: 'Поиск',
          search_description:
            'Быстрый поиск релевантной и достоверной информации связанной с химией: структуры, литература, патенты, экспериментальные данные',
          molecular_editor_title: 'Молекулярный редактор',
          molecular_editor_description:
            'Ввод структур и расчет прогнозируемых свойств по структурам, которых нет в нашей базе',
          datasets_title: 'Датасеты',
          datasets_description:
            'Сбор и хранение собственных наборов химических данных. Импорт и экспорт доступен в самых распространенных форматах: SDF, CSV, SMI. Также на платформе доступны наборы тематических датасетов по молекулярным мишеням и терапевтическим индикациям',
          synmap_title: 'SynMap 2D/3D',
          synmap_description:
            'Навигация в химическом пространстве. Анализ кластеров биоактивных соединений. Генерация новых соединений с заданными свойствами',
          predicting_reactions_title: 'Предсказание реакции',
          predicting_reactions_description:
            'Прогнозирование возможных продуктов химических реакций и поиск реакций для синтеза искомой молекулы на основе нейросетевой модели',
          spectra_title: 'Прогнозирование спектров',
          spectra_description:
            'Прогнозирование спектров: тандемная масс-спектрометрия (QToF-MS/MS), инфракрасная спектроскопия и ядерный магнитный резонанс по ядрам 1H, 13C, 15N, 19F',
          synthcost_title: 'Cтоимость синтеза',
          synthcost_description:
            'Выбор оптимального пути синтеза с расчетом экономической эффективности. ТОП-5 известных схем реакций, включая все стадии и ссылки на литературные источники',
          pdf2smiles_title: 'PDF в SMILES',
          pdf2smiles_description:
            'Инструмент оптического распознавания химических формул и структур Маркуша из файлов в формате PDF (экспорт структур из статей, патентов, диссертаций)',
          smiles2iupac_title: 'SMILES в IUPAC',
          smiles2iupac_description:
            'Генерация систематических номенклатурных названий согласно правилам IUPAC на русском и английском языках',
          statistics_title: 'Статистика',
          statistics_description:
            'Для определения точности моделей либо расчета возможной погрешности по показателям мы используем метрики: RMSE, ROC AUC.',
        },
        feedback: {
          only_one_pdf: 'Можно прикрепить только один PDF файл',
          file: 'Файл',
          image: 'Изображение',
          label: 'Форма для связи',
          textFieldPlaceholder: 'Напишите ваш отзыв',
          emailLabel: 'E-mail',
          emailPlaceholder: 'Ваш email',
          dragMsg: 'Перенесите файлы сюда...',
          addFileButton: 'Добавить файл',
          closeButton: 'Закрыть',
          sendButton: 'Отправить',
          header: 'Список отзывов',
          attach_image: 'Загрузить jpeg или png',
          attach_file: 'Загрузить файл в формате pdf',
          feedback_received: 'Мы получили Ваш отзыв',
          we_will_answer:
            'Мы ответим в ближайшее время\n на e-mail, который вы указали',
          we_will_answer_data:
            'Мы проверим их в ближайшее время\n и добавим на платформу',
          ok: 'Хорошо',
          required_field_tip: '* - обязательное поле',
          theme: 'Тема обращения',
          choose_module: 'Выберите модуль',
          comment: 'Комментарий',
          your_message: 'Ваше сообщение',
          add_file_btn: 'Файл',
          add_image_btn: 'Изображение',
          got_link: 'Есть ссылка на открытый источник',
          enter_link: 'Введите ссылку',
          agreement_terms:
            'Настоящим предоставляю ООО "Синтелли" согласие на цитирование информации, полученной на основании загружаемых мною данных в порядке, предусмотренным действующим законодательством.',
          agreement_cite_terms:
            'В цитате не должно фигурировать название компании',
          provide_file_or_link:
            'Пожалуйста, предоставьте файл или ссылку на источник, либо и то, и другое, если у вас есть такая возможность',
          experimental_data_text:
            'Добавляем экспериментальные данные, полученные в аккредитованных лабораториях или представленные в научных публикациях',
          data_received: 'Спасибо, что поделились данными',
          message_received: 'Мы получили ваше сообщение',
          select_multiple_modules: 'Можно выбрать несколько модулей',
          themes: {
            license: 'Купить лицензию',
            error: 'Ошибка',
            improve: 'Предложение по улучшению',
            experimental_data: 'Предоставить экспериментальные данные',
            literature_source: 'Предоставить литературный источник',
            feedback: 'Фидбек',
          },
          modules: {
            search: 'Поиск',
            props: 'Прогнозирование свойств',
            moleditor: 'Молекулярный редактор',
            datasets: 'Датасеты',
            synmap: 'SynMap',
            reactions: 'Предсказание реакции',
            spectra: 'Спектры',
            synth_cost: 'Стоимость синтеза',
            pdf2smi: 'PDF в SMILES',
            smi2iupac: 'SMILES в IUPAC',
            statistics: 'Статистика',
            common: 'Общее',
          },
        },
        baskets: {
          enter_dataset_name: 'Введите название датасета (минимум 3 символа)',
          delimiter: 'Разделитель в документе',
          tab: 'Табуляция',
          comma: 'Запятая',
          semicolon: 'Точка с запятой',
          space: 'Пробел',
          bar: 'Вертикальная черта',
          other: 'Другой',
          write_delimiter: 'Напишите свой сепаратор',
          export_success: 'Экспорт успешно завершен',
          export_error: 'Ошибка во время экспорта',
          name_taken: 'Имя уже использовано, попробуйте другое',
          selected: 'Область',
          toplabel: 'Датасеты',
          compound: 'Соединение',
          compounds_few: 'Соединения',
          compounds_many: 'Соединений',
          calculating: 'Расчет свойств датасета',
          calculating_failed: 'Ошибка расчета датасета {{name}}',
          calculated: 'Расчет свойств датасета {{name}} завершен',
          export:
            'Чтобы скачать рассчитанные свойства, сначала воспользуйтесь функцией "Рассчитать свойства"',
          export_limit:
            'Для получения более 1000 расcчитанных записей необходимо приобрести PRO-plan.',
          topblock: {
            create: 'Создать',
            delete: 'Удалить',
            copy: 'Дублировать',
            merge: 'Объединить',
            mmap: 'Показать на SynMap',
            export: 'Экспорт',
            calc: 'Рассчитать свойства',
            refresh: 'Обновить',
            undo: 'Отменить',
            mmap_empty:
              'Добавьте молекулы в выбранный сет, чтобы показать его на SynMap',
            export_empty:
              'Добавьте молекулы в выбранный сет, чтобы экспортировать его',
            calc_empty:
              'Добавьте молекулы в выбранный сет, чтобы рассчитать его свойства',
          },
          modal: {
            choose_set_text: 'Выберите датасет',
            placeholder: 'Найти или ввести имя для создания нового сета',
            placeholder_filter: 'Введите название датасета',
            create_new_basket: 'Создать новый',
            choose_from_list: 'Или выберите из списка',
            move: 'Выбрать',
            create_or_move: 'Создать или перенести',
            create_or_add: 'Создать или добавить',
          },
          dataset_type_selectors: {
            thematic: 'Тематические',
            private: 'Личные',
          },
          basket_tools_menu: {
            viewing_log_messages: 'Просмотр сообщений журнала',
            edit: 'Редактировать',
            select: 'Выделить',
            deselect: 'Снять выделение',
            delete: 'Удалить',
          },
          join_copy_dialog: {
            joined_dataset_creation: 'Создание объединенного сета',
            joined_dataset_name: 'Название объединенного сета',
            joined_dataset_desc: 'Описание объединенного сета',
            copied_dataset_creation: 'Создание копии сета',
            copied_dataset_name: 'Название нового сета',
            copied_dataset_desc: 'Описание нового сета',
          },
          add_create_dialog: {
            dataset_editing: 'Редактирование названия сета',
            dataset_creation: 'Создание сета',
            name: 'Название',
            description: 'Описание',
            name_of_your_set: 'Имя вашего сета',
            description_of_your_set: 'Описание вашего сета',
            save: 'Сохранить',
            create: 'Создать',
          },
          log_dialog: {
            log_messages_viewer: 'Средство просмотра сообщений журнала',
            logs_are_empty: 'Журнал сообщений пуст',
            failed_to_fetch_log_messages: 'Не удалось получить сообщения',
          },
          export_dialog: {
            set_export: 'Экспорт сета',
            set: 'В сете',
            has_uncalculated_molecules: 'не рассчитанные молекулы',
            uncalculated_warning_export:
              'Если вас интересуют только SMILES — вы можете экспортировать сет в таком виде. Если хотите получить все свойства — сперва рассчитайте датасет',
            uncalculated_warning_analysis:
              'Чтобы проанализировать датасет, нужно рассчитать его свойства.',
            select_the_desired_download_file_format:
              'Выберите нужный формат загружаемого файла',
            export_in_CSV: 'Экспортировать в CSV',
            export_in_SDF: 'Экспортировать в SDF',
            exporting_set_in_csv_format: 'Экспорт сета в формате CSV',
            csv_warning:
              'Обращаем ваше внимание, что в нашем файле разделителем является запятая. Для корректного отображения данных в Excel необходимо открыть файл во вкладке «Данные»',
            ok: 'Хорошо',
            calculation: 'Вычисление',
            calculate: 'Рассчитать',
            export: 'Экспортировать',
            analysis: 'Проанализировать',
          },
          export_in_progress: 'Выполняется экспорт',
          export_in_progress_small:
            'Это может занять некоторое время, загрузка начнется автоматически',
          tasks_status: {
            cancel_set_calculation:
              'Отменить расчет свойств датасета {{name}}?',
          },
          failed_to_calculate_dataset: 'Не удалось рассчитать датасет',
          sortTypes: {
            relevance: 'По релевантности',
            date: 'По дате создания',
            name: 'По названию',
            molecules_count: 'По количеству молекул',
          },
        },
        sidebar: {
          search: 'Поиск',
          molecules: 'Датасеты',
          reactions: 'Прогнозирование реакции',
          spectra_prediction: 'Спектры',
          mmap: 'SynMap',
          individual: 'Молекулярный редактор',
          pdf2smi: 'PDF в SMILES',
          admin: 'Админ',
          qsar: 'Статистика',
          login: 'Войти или зарегистрироваться',
          user: 'Пользователь',
          language: 'Сменить язык',
          synth_cost: 'Стоимость синтеза',
          smiles2iupac: 'SMILES в IUPAC',
          telegram: 'Мы в Telegram',
          open_filter: 'Открыть фильтр',
        },
        header: {
          about: 'О нас',
          user_guide: 'Руководство пользователя',
          tlgrm: 'Мы в Telegram',
          profile: 'Профиль',
          exit: 'Выход',
        },
        search: {
          draw: 'Нарисовать',
          edit: 'Изменить',
          structure: 'По структурам',
          reaction: 'По реакциям',
          literature: 'По литературе',
          reaction_search_in_development:
            'Поиск по реакциям находится в разработке',
          placeholder:
            'или ввести Синтелли ID, SMILES, тривиальное название, название IUPAC, CAS номер',
          placeholder_short:
            'или ввести Синтелли ID, SMILES, тривиальное название, название IUPAC',
          placeholder_synth:
            'Синтелли ID, SMILES, тривиальное название, название IUPAC',
          placeholder_lit: 'или ввести Синтелли ID, SMILES, текст',
          search: 'Найти',
          history: 'История',
          draw_markush:
            'Поиск по структурам Маркуша производится только через кнопку «Нарисовать»',
          predict: 'Спрогнозировать',
          search_history: {
            history: 'История поиска',
            history_empty_title: 'У вас нет истории запросов',
            history_preview:
              'Ваша история запросов по структурам, реакциям и литературе',
            history_empty:
              'Начните поиск по структурам, \nреакциям или литературе',
            repeat_request: 'Повторить запрос',
            back_to_history: 'Вернуться к истории',
            back_to_search: 'Вернуться к поиску',
            search_results: 'Результаты поиска по запросу {{text}}',
            literature: 'Литература',
            molecules: 'Молекулы',
            structures: 'Структуры',
            reactions: 'Реакции',
            all: 'Все',
            repeat: 'Повторить',
            view: 'Посмотреть',
          },
          sortTypes: {
            relevance: 'По релевантности',
            registrationNumber: 'По регистрационному номеру',
            date: 'По дате публикации',
            structures: 'По количеству структур',
          },
          sortDirection: {
            asc: 'По возрастанию',
            desc: 'По убыванию',
          },
          literatureFilters: {
            settings: 'Настроить условия',
            reset: 'Сбросить',
            sources: 'Найдено источников: ',
            sources_hint:
              'Поиск по литературе ограничен выдачей 10 000 источников за один запрос, тогда как в нашей базе может быть больше источников, которые соответствуют запросу',
          },
          search_may_take_some_time: 'Поиск может занять некоторое время',
          structure_search_in_progress:
            'Пока идет поиск по структуре, вы не можете совершить поиск по литературе и реакции',
          cancel: 'Отменить',
          search_by_structures_in_progress: 'Выполняется поиск по структурам',
          cancel_search_by_structures: 'Отменить поиск по структурам?',
          search_by_structures_failed:
            'Во время поиска по структурам произошла ошибка',
          search_by_markush_failed:
            'Во время поиска по структурам Маркуша произошла ошибка',
        },
        moleculeslist: {
          topblock: {
            add: 'Добавить',
            calculate: 'Рассчитать свойства',
            delete: 'Удалить',
          },
          select_the_column_heading:
            'Выберите заголовок столбца, который содержит SMILES',
          add_molecules: 'Добавить молекулы',
          add_dataset: 'Добавить датасет',
          select_method: 'Выберите метод',
          smiles_string: 'SMILES',
          visual_editor: 'Молекулярный редактор',
          load_from_file: 'Загрузить из файла (SDF, CSV, SMI)',
          search_in_synonyms: 'Поиск по синонимам',
          enter_smiles: 'Введите SMILES...',
          upload: 'Загрузить',
          enter_synonym: 'Введите синоним',
          select_file: 'Выберите файл',
          to_structure_page: 'На отдельной странице',
          error: 'Произошла ошибка во время поиска',
          dataIsUploading: `Данные загружаются в текущую корзину. Пожалуйста, снова откройте его через несколько минут.`,
          areYouSureDelete: `Вы уверены, что хотите удалить`,
          thisItem: 'эту молекулу',
          theseItems: 'эти молекулы',
          calcStarted: 'Расчет начат...',
          return_to_datasets: 'Вернуться к датасетам',
          models_applicability: 'Применимость моделей к молекуле',
          not_applicable:
            'Модель неприменима для прогнозирования свойств данной молекулы',
          applicability_indicator_label: 'Индикатор применимости модели',
          applicability_indicator_description:
            'Расположен рядом с прогнозным значением и помогает вам оценить надежность результатов',
          low_reliability: `0-20% — низкая надежность прогнозирования.\n В данных для обучения модели было мало молекул, похожих на выбранную`,
          middle_reliability: '20-50% - средняя надежность прогнозирования',
          high_reliability: '50-100% - высокая надежность прогнозирования',
          request_not_found: 'Запрос не найден в текущей базе данных',
          see_molecule_props:
            'Вы можете увидеть прогностические свойства этой молекулы, нарисовав ее в молекулярном редакторе',
          open_editor: 'Открыть молекулярный редактор',
          similar_results: 'Похожие результаты',
          best_match: 'Лучшее совпадение',
        },

        reactions_prediction: {
          label: 'Планирование синтеза органических соединений',
          predict_btn: 'Предсказать',
          forward: 'Синтез',
          results: 'Результаты',
          reverse: 'Ретросинтез',
          score: 'Уверенность модели',
          model_confidence_indicator: 'Индикатор уверенности модели',
          model_confidence_indicator_description:
            'Выполняется несколько параллельных предсказаний, затем в итоговые результаты добавляются те реакции, которые имеют наибольшие оценки уверенности модели в результате. ',
          low_reliability: '0-79% — низкая надежность прогнозирования',
          medium_reliability: '80-94% — средняя надежность прогнозирования',
          high_reliability: '95-100% — высокая надежность прогнозирования',
          reset_btn: 'Сбросить',
          yield: 'Вывод:',
          forward_description:
            'Позволяет спрогнозировать продукты реакции с помощью глубоких нейронных сетей трансформеров. Можно ввести несколько структур реагентов, и модель покажет варианты продуктов для них, основываясь на своей уверенности в прогнозе. При вводе молекул необходимо добавлять только реагенты - растворители, катализаторы и т.п. не учитываются.',
          reverse_description:
            'Позволяет спрогнозировать до 5 схем многостадийного синтеза малой органической молекулы с помощью нейронных сетей. Инструмент будет строить ретросинтетическое дерево до достижения коммерчески доступных молекул или до достижения лимита в 5 стадий. Прогнозируемые ретросинтетические пути также имеют оценку уверенности модели.',
          retrosynth: {
            to_molecule_page: 'На страницу молекулы',
            stages: 'Стадии',
            schemas: 'Схемы',
            is_solved:
              'Коммерчески доступными являются все молекулы, которые есть в базе',
            is_not_solved: 'Есть молекулы, доступность которых под сомнением',
            save_to_dataset: 'Cохранить в датасет',
            download: 'Скачать',
            solutionFound: 'Решение найдено',
            solutionNotFound: 'Решение не найдено',
          },
          nothing_found: 'Ничего не найдено',
          synthesis_failed: 'Не удалось выполнить расчет синтеза',
          retrosynthesis_failed: 'Не удалось выполнить расчет ретросинтеза',
          prediction_failed: 'Прогнозирование не удалось',
        },
        molecule_viewer: {
          add_text: 'Добавить молекулу',
          copy_smiles: 'Скопировать SMILES',
          copy_baseid: 'Скопировать Синтелли ID',
          open_in_individual_mode_text: 'Открыть в молекулярном редакторе',
          download_png_text: 'PNG',
          download_mol: 'MOL',
          download_pdf: 'PDF',
          literature: 'Литература',
          copy_link: 'Скопировать ссылку',
          add_to_dataset: 'Добавить в датасет',
          molecule_information: 'Информация о молекуле',
          download: 'Скачать',
          edit_in_molecular_editor: 'Редактировать в молекулярном редакторе',
          fast_view: 'Быстрый просмотр',
          molecule_generated: 'Спрогнозировано',
          molecule_generated_description:
            'Молекулы нет в нашей базе и все ее свойства спрогнозированы',
          link: 'Ссылка',
          to_dataset: 'В датасет',
          transfer_to: 'Перенести в',
          go_to_section: {
            molecular_editor: 'Молекулярный редактор',
            synthesis: 'Синтез',
            retrosynthesis: 'Ретросинтез',
            nmr_spectra: 'ЯМР-спектры',
            mass_spectra: 'Масс-спектры',
            ir_spectra: 'ИК-спектры',
            synthesis_cost: 'Стоимость синтеза',
          },
        },
        mmap: {
          clear_text: 'Вы точно хотите очистить карту',
          choose_text: 'Выбрать',
          layers: 'Добавить слой',
          generators: 'Добавить генератор',
          compliance: 'Соответствие заданным параметрам',
          opacity: 'Непрозрачность',
          no_more: 'Вы можете выделить не более 50 000 молекул',
          save_selection_to_dataset: 'Сохранить подборку в датасет',
          to_add_a_generator_turn_on_2D_mode:
            'Для добавления генератора включите 2D режим',
          clear_all: 'Очистить все',
        },
        pdf2smiles: {
          drag_pdf: 'Перетащите файл .pdf в эту область',
          dashboard: {
            pdf2smiles: 'PDF в SMILES',
            upload_pdf: 'Загрузить PDF',
            failed_to_load_documents: 'Не удалось загрузить документы',
            document_preview: 'Превью документа',
            structure: {
              one: '{{count}} структура',
              few: '{{count}} структуры',
              many: '{{count}} структур',
            },
            page: {
              one: '{{count}} страница',
              few: '{{count}} страницы',
              many: '{{count}} страниц',
            },
            menu: {
              edit_name: 'Редактировать название',
              delete: 'Удалить',
            },
            edit_dialog: {
              document_editing: 'Редактирование документа',
              name: 'Название',
              name_of_your_document: 'Имя вашего документа',
              save: 'Сохранить',
            },
            you_can_only_recognize_one_document_at_a_time:
              'Доступно распознавание только одного документа в единицу времени',
            recognition_of: 'Распознавание',
            recognition_success: 'успешно распознан',
            recognition_failed:
              'Произошел сбой при распознавании файла {{name}}',
          },
          viewer: {
            back: 'Назад',
            score: 'Надежность {{score}}',
            smiles_in_pdf: 'Smiles в pdf',
            structures: 'Структуры',
            save_all: 'Все в датасет',
            save_selected: 'Выбранное в датасет',
            add_to_dataset: 'Добавить в датасет',
            reset_selection: 'Сбросить выделение',
            delete_selected: 'Удалить выбранное',
            failed_to_load_document: 'Не удалось загрузить документ',
            failed_to_open_document: 'Не удалось открыть документ',
            invalid_smiles: 'Невалидный SMILES',
            failed_to_edit_smiles: 'Не удалось изменить SMILES',
            failed_to_edit_filename: 'Не удалось изменить имя документа',
            failed_to_delete_document: 'Не удалось удалить документ',
            failed_to_delete_molecule: 'Не удалось удалить молекулу',
            failed_to_delete_molecules: 'Не удалось удалить молекулы',
            download: 'Скачать',
            failed_to_download_structures: 'Не удалось скачать структуры',
            export_in_progress: 'Выполняется экспорт',
            export_in_progress_small:
              'Это может занять некоторое время, загрузка начнется автоматически',
            edit: 'Редактировать',
            to_dataset: 'В датасет',
            delete: 'Удалить',
            molecule_delete_confirmation:
              'Вы уверены, что хотите удалить выбранную молекулу?\n Вернуть молекулу будет невозможно',
            molecules_delete_confirmation:
              'Вы уверены, что хотите удалить выбранные молекулы?\n Вернуть молекулы будет невозможно',
          },
        },
        individual: {
          smiles_btn: 'Ввести SMILES',
          warn_text:
            'Войдите или зарегистрируйтесь для продолжения использования платформы Syntelly',
          set: 'Добавить',
        },
        ketcher: {
          calc_btn: 'Рассчитать',
          save: 'Сохранить',
          close: 'Закрыть',
          search: 'Найти',
        },
        categoriesFiler: {
          all: 'Все',
          lethal_dose_models: 'Модели летальной дозы',
          general_toxicity_models: 'Модели общей токсичности',
        },
        molviewer: {
          blur_value:
            'Модель неприменима для прогнозирования <br/>свойств данной молекулы. Мы не советуем <br/>использовать данное значение',
          no_synonyms: 'Отсутствуют у данной молекулы',
          why_that: 'Почему так',
          why_that_tip:
            'У молекулы может не быть употребимых синонимов, <br /> если она не достаточно известна и используема <br /> или синонимы не попали в базу данных Синтелли',
          solubility: 'Растворимость в воде',
          smiles: 'SMILES',
          rdkit_count_chemical_formula: 'Брутто-формула',
          rdkit_count_molw: 'Молекулярный вес',
          inchi: 'InChI',
          cyp1a2: 'CYP1A2',
          cyp2c19: 'CYP2C19',
          cyp2d6: 'CYP2D6',
          cyp2c9: 'CYP2C9',
          cyp3a4: 'CYP3A4',
          inchikey: 'InChI ключ',
          bp: 'Температура кипения',
          fp: 'Температура вспышки',
          flash_point: 'Температура вспышки',
          density: 'Плотность',
          viscosity: 'Вязкость',
          mp: 'Температура плавления',
          logp: 'LogP octanol-water',
          dmso: 'Растворимость в ДМСО',
          rt: 'Время удерживания',
          devtox: 'Эмбриотоксичность',
          lc50dm: '48 часовой Daphnia magna LC50',
          igc50: '40 часовой Tetrahymena pyriformis IGC50',
          bcf: 'Биоконцентрационный фактор',
          ames: 'Тест Эймса',
          external: 'Внешние БД',
          ratoral: 'Крыса орально',
          scscore: 'Сложность',
          rabbitintravenous: 'Кролик внутривенно',
          ratintravenous: 'Крыса внутривенно',
          mouseintravenous: 'Мышь внутривенно',
          iupac: 'Название по IUPAC',
          baseid: 'Синтелли ID',
          vapor_pressure: 'Давление насыщенных паров',
          structural: 'Структурные данные',
          bio: 'Биологическая активность',
          synonyms: 'Синонимы',
          mut: 'Мутагенность',
          eco: 'Экологические свойства',
          synt: 'Оценка сложности синтеза',
          nmr: 'Ядерно-магнитный резонанс',
          phys: 'Физико-химические свойства',
          drug: 'Сходство с лекарственными препаратами',
          toxy: 'Токсичность',
          reactions: 'Протоколы реакций',
          externaldbs: 'Внешние базы данных',
          scscore_comp: 'Complexity (SCScore)',
          scscore_syba: 'SYBA',
          user_params: 'Пользовательские параметры',
          qsar_log10_bcf_: 'Биоконцентрационный фактор',
          qsar_boiling_point: 'Температура кипения',
          qsar_boiling_point__normal_: 'Boiling point Normal',
          qsar_decomposition_point: 'Decomposition Point',
          qsar_density: 'Плотность',
          qsar_melting_point: 'Температура плавления',
          qsar_refractive_index: 'Индекс преломления',
          qsar_retention_time: 'Время удерживания',
          qsar_ames_test: 'Тест Эймса',
          qsar_log10_water_solubility_: 'Растворимость в воде',
          qsar_mouse_intraperitoneal_ld50: 'Мышь внутрибрюшинно LD50',
          qsar_mouse_intraperitoneal_ldlo: 'Мышь внутрибрюшинно LDLo',
          qsar_mouse_intravenous_ld50: 'Мышь внутривенно LD50' /**/,
          qsar_rat_intraperitoneal_ld50: 'Крыса внутрибрюшинно LD50',
          qsar_mouse_oral_ld50: 'Мышь орально LD50',
          qsar_mouse_subcutaneous_ld50: 'Мышь подкожно LD50',
          qsar_rat_subcutaneous_ld50: 'Крыса подкожно LD50',
          qsar_mouse_unreported_ldlo: 'Мышь не указано LDLo',
          qsar_rat_unreported_ld50: 'Крыса не указано LD50',
          qsar_mouse_unreported_ld50: 'Мышь не указано LD50',
          qsar_rat_intraperitoneal_ldlo: 'Крыса внутрибрюшинно LDLo',
          qsar_rabbit_skin_ld50: 'Кролик накожно LD50',
          qsar_rat_oral_ld50: 'Крыса орально LD50',
          qsar_rat_oral_ldlo: 'Крыса орально LDLo',
          qsar_rabbit_oral_ld50: 'Кролик орально LD50',
          qsar_rabbit_intravenous_ld50: 'Кролик внутривенно LD50',
          qsar_rat_intravenous_ld50: 'Крыса внутривенно LD50',
          qsar_mouse_oral_ldlo: 'Мышь орально LDLo',
          qsar_rat_skin_ld50: 'Крыса накожно LD50',
          qsar_mammal__species_unspecified__subcutaneous_ld50:
            'Млекопитающее (вид не указан) подкожно LD50',
          qsar_guinea_pig_oral_ld50: 'Морская свинка орально LD50',
          qsar_heat_capacity__cp_: 'Heat capacity',
          qsar_density__normal_: 'Плотность (нормальная)',
          qsar_enthalpy_of_fusion: 'Enthalpy of Fusion',
          qsar_enthalpy_of_sublimation: 'Enthalpy of Sublimation',
          qsar_enthalpy_of_vaporization: 'Enthalpy of Vaporization',
          qsar_vapour_pressure: 'Давление насыщенных паров',
          qsar_dmso_solubility: 'Растворимость в ДМСО',
          view_all: 'Посмотреть все',
          'toxy-cbm': 'Toxy-CBM',
          reproductive_toxicity: 'Репродуктивная токсичность',
          'nr-er': 'Рецептор эстрогена альфа',
          hepatotoxicity: 'Гепатотоксичность',
          mouse_oral_ld50: 'Мышь орально LD50',
          'nr-ar': 'Андрогеновый рецептор',
          dili: 'DILI',
          cat_intravenous_ld50: 'Кот внутривенно LD50',
          'sr-atad5': 'ATAD5',
          mouse_intraperitoneal_ld50: 'Мышь интраперитонеально LD50',
          ames_test: 'Тест Эймса',
          dog_intravenous_ld50: 'Собака внутривенно LD50',
          rabbit_intravenous_ldlo: 'Кролик внутривенно LDLo',
          bird_wild_oral_ld50: 'Птица дикая орально LD50',
          quail_oral_ld50: 'Перепел орально LD50',
          eye_irritation: 'Раздражение глаз',
          developmental_toxicity: 'Эмбриотоксичность',
          rat_oral_ldlo: 'Крыса орально LDLo',
          '40_h_tetrahymena_pyriformis_igc50':
            '40 часовой Tetrahymena pyriformis IGC50',
          mouse_intramuscular_ld50: 'Мышь внутримышечно LD50',
          'nr-er-lbd': 'Лиганд-связывающий домен эстрогенового рецептора',
          rabbit_oral_ld50: 'Кролик орально LD50',
          mouse_intravenous_ld50: 'Мышь внутривенно LD50',
          'sr-mmp': 'Потенциал митохондриальной мембраны',
          'nr-ahr': 'Ариловый углеводородный рецептор',
          chicken_oral_ld50: 'Курица орально LD50',
          rat_oral_ld50: 'Крыса орально LD50',
          rat_subcutaneous_ld50: 'Крыса подкожно LD50',
          rabbit_skin_ld50: 'Кожа крысы LD50',
          rat_intraperitoneal_ld50: 'Крыса интраперитонеально LD50',
          rat_intravenous_ld50: 'Крыса внутривенно LD50',
          dog_oral_ld50: 'Собака орально LD50',
          guinea_pig_oral_ld50: 'Морская свинка орально LD50',
          mouse_intraperitoneal_ldlo: 'Мышь интраперитонеально LDLo',
          cardiotoxicity: 'Кардиотоксичность',
          'nr-ppar-gamma': 'PPAR-Gamma',
          mouse_skin_ld50: 'Кожа мыши LD50',
          'sr-hse': 'Элемент ответа фактора теплового шока',
          'nr-aromatase': 'Ароматаза',
          rabbit_intravenous_ld50: 'Кролик внутривенно LD50',
          daphnia_magna_lc50: 'Daphnia Magna LC50',
          dog_intravenous_ldlo: 'Собака внутривенно LDLo',
          '96_h_fathead_minnow_lc50': '96 часов Fathead Minnow LC50',
          rat_intraperitoneal_ldlo: 'Крыса интраперитонеально LDLo',
          rat_skin_ld50: 'Кожа крысы LD50',
          'sr-are': 'Элемент антиоксидантного ответа',
          eye_corrosion: 'Коррозия глаз',
          'sr-p53': 'p53',
          mouse_subcutaneous_ld50: 'Мышь подкожно LD50',
          carcinogenicity: 'Канцерогенность',
          bbbp: 'Проницаемость через ГЭБ',
          guinea_pig_intraperitoneal_ld50:
            'Морская свинка интраперитонеально LD50',
          logbb: 'LogBB',
          'selective_target-organ_or_system_toxicity_single_exposure_58479-2019':
            'Селективная токсичность для органов-мишеней',
          'acute_toxicity_swallowed_56957-2016':
            'Острая токсичность при проглатывании',
          human_vdss: 'Человек VDss',
          'human_pharmacological_half-life': 'Человек период полувыведения',
          'acute_aquatic_toxicity_57455-2017':
            'Острая токсичность для водной среды',
          'nr-ar-lbd': 'Лиганд-связывающий домен андрогенового рецептора',
          "Lipinski's rule of five": `Правила пяти Липински`,
          'Ghose filter': 'Фильтры Гозе',
          "Oprea's Rule": 'Правила Опреа',
          "Veber's Rule": 'Правило Вебера',
          'QED - quantitative estimate of drug-likeness': 'QED',
          'Hydrogen bond donors <= 5': 'Доноры водородной связи <= 5',
          'Hydrogen bond acceptors <= 10': 'Акцепторы водородной связи <= 10',
          'Molecular weight < 500': 'Молекулярная масса < 500',
          'LogP octanol-water <= 5': 'ALogP октанол-вода <= 5',
          'LogP octanol-water [-0.4, 5.6]': 'ALogP октанол-вода [-0.4, 5.6]',
          'Molecular weight [180, 480]': 'Молекулярная масса [180, 480]',
          'Number of atoms [20, 70]': 'Число атомов [20, 70]',
          'Molar refractivity [40, 130]':
            'Коэффициент преломления (молярный) [40, 130]',
          'Hydrogen bond donors <= 2': 'Доноры водородной связи <= 2',
          'Hydrogen bond acceptors [2, 9]': 'Акцепторы водородной связи [2, 9]',
          'Number of rotatable bonds [2, 8]': 'Число вращающихся связей [2, 8]',
          'Number of rings [1, 4]': 'Число циклов  [1, 4]',
          topological_polar: 'Топологическая площадь полярной поверхности',
          'g/mol': 'г/моль',
          'm3/mol': 'м³/моль',
          not_included: 'Не включены',
          literature: 'Литература',
          to_reactions: 'Реакции',
          png: 'PNG',
          pdf: 'PDF',
          mol: 'MOL',
          copy_link: 'Скопировать ссылку',
          tooltip: {
            'QED - quantitative estimate of drug-likeness':
              'Количественная оценка сходства с лекарственным средством',
            PAINS: 'Pan Assay Interference Compounds',
            article: 'Статья',
            'TPSA <= 140 Å²': 'Топологическая площадь полярной поверхности',
          },
          failed_to_load_molecule_data: 'Не удалось загрузить данные молекулы',
          molecule_not_found: 'Молекула не найдена',
          back_to_results: 'К результатам',
          molecule_page: 'Страница молекулы',
          molecule_fast_view: 'Быстрый просмотр молекулы',
        },
        statistics: {
          header: 'Статистика',
          categories: {
            ames: 'Тест Эймса',
            bcf: 'Фактор биоконцентрации (BCF)',
            bp: 'Температура кипения',
            density: 'Плотность',
            density_normal: 'Плотность (нормальная)',
            mp: 'Температура плавления',
            refractive_index: 'Показатель преломления',
            retention_time: 'Время удержания',
            vapour_pressure: 'Давление насыщенных паров',
            logP: 'LogP',
            toxicity: 'Токсичность',
            water_solubility_2: 'Растворимость в воде',
            dmso_solubility: 'Растворимость в ДМСО',
            physical: 'Физические свойства',
            ecology: 'Экологические свойства',
            mutagenicity: 'Мутагенность',
            bio: 'Биологические свойства ',
          },
          table_column: {
            parameter: 'Параметр',
            average: 'Средний показатель',
            unit: 'Единицы измерения',
            metric: 'Метрика',
          },
          table_parameter: {
            'Ames Test': 'Тест Эймса',
            'Boiling Point': 'Температура кипения',
            Density: 'Плотность',
            'Density (normal)': 'Плотность (нормальная)',
            'Melting Point': 'Температура плавления',
            'Refractive Index': 'Показатель преломления',
            'Retention Time': 'Время удержания',
            'Vapor Pressure': 'Давление насыщенных паров',
            'Water Solubility': 'Растворимость в воде',
            LogP: 'LogP',
            Toxicity: 'Токсичность',
            'Log10(Water Solubility)': 'Растворимость в воде',
            'DMSO Solubility': 'Растворимость в ДМСО',
            'Log10(BCF)': 'Log10(BCF)',
            'Mouse Intraperitoneal LD50': 'Мышь внутрибрюшинно LD50',
            'Mouse Intraperitoneal LDLo': 'Мышь внутрибрюшинно LDLo',
            'Mouse Intravenous LD50': 'Мышь внутривенно LD50',
            'Rat Intraperitoneal LD50': 'Крыса внутрибрюшинно LD50',
            'Mouse Oral LD50': 'Мышь орально LD50',
            'Mouse Subcutaneous LD50': 'Мышь подкожно LD50',
            'Rat Subcutaneous LD50': 'Крыса подкожно LD50',
            'Mouse Unreported LDLo': 'Мышь не указано LDLo',
            'Rat Unreported LD50': 'Крыса не указано LD50',
            'Mouse Unreported LD50': 'Мышь не указано LD50',
            'Rat Intraperitoneal LDLo': 'Крыса внутрибрюшинно LDLo',
            'Rabbit Skin LD50': 'Кролик накожно LD50',
            'Rat Oral LD50': 'Крыса орально LD50',
            'Rat Oral LDLo': 'Крыса орально LDLo',
            'Rabbit Oral LD50': 'Кролик орально LD50',
            'Rabbit Intravenous LD50': 'Кролик внутривенно LD50',
            'Rat Intravenous LD50': 'Крыса внутривенно LD50',
            'Mouse Oral LDLo': 'Мышь орально LDLo',
            'Rat Skin LD50': 'Крыса накожно LD50',
            'Mammal (species unspecified) Subcutaneous LD50':
              'Млекопитающее (вид не указан) подкожно LD50',
            'Guinea Pig Oral LD50': 'Морская свинка орально LD50',
            '40 hour Tetrahymena pyriformis IGC50':
              '40 часов Tetrahymena pyriformis IGC50',
            '96 hours Fathead Minnow LC50': '96 часов Fathead Minnow LC50',
            'Bioconcentration factor': 'Фактор биоконцентрации',
            'Daphnia Magna LC50': 'Daphnia Magna LC50',
            BBBP: 'Проницаемость через ГЭБ ',
            'Bird Wild Oral LD50': 'Птица дикая орально LD50',
            'Selective Target-organ Or System Toxicity Single Exposure 58479-2019':
              'Селективная токсичность для органа-мишени или системы, однократное воздействие 58479-2019',
            'Acute Toxicity Swallowed 56957-2016':
              'Острая токсичность при проглатывании 56957-2016',
            'Human pharmacological half-life':
              'Фармакологический период полувыведения у человека',
            'Human VDss': 'Человеческий VDss',
            logBB: 'logBB',
            'Acute Aquatic Toxicity 57455-2017':
              'Acute Aquatic Toxicity 57455-2017',
            'SR-p53': 'SR-p53',
            Carcinogenicity: 'Канцерогенность',
            Cardiotoxicity: 'Кардиотоксичность',
            'Cat intravenous LD50': 'Кошка внутривенно LD50',
            'Chicken Oral LD50': 'Курица орально LD50',
            DILI: 'DILI',
            'Developmental Toxicity': 'Эмбриотоксичность',
            'Dog Intravenous LD50': 'Собака внутривенно LD50',
            'Dog Intravenous LDLo': 'Собака внуривенно LDLo',
            'Dog Oral LD50': 'Собака орально LD50',
            'Eye Corrosion': 'Коррозия глаз',
            'Eye Irritation': 'Раздражение глаз',
            'Guinea Pig Intraperitoneal LD50':
              'Морская свинка интраперитонеально LD50',
            Hepatotoxicity: 'Гепатотоксичность',
            'Mouse Intramuscular LD50': 'Мышь внутримышечно LD50',
            'Mouse Skin LD50': 'Мышь накожно LD50',
            'NR-AHR': 'Ариловый углеводородный рецептор',
            'NR-AR': 'Андрогеновый рецептор',
            'NR-ER': 'Рецептор эстрогена альфа',
            'NR-AR-LBD': 'Лиганд-связывающий домен андрогенового рецептора',
            'NR-ER-LBD': 'Лиганд-связывающий домен эстрогенового рецептора',
            'NR-Aromatase': 'Ароматаза',
            'NR-PPAR-gamma': 'PPAR-gamma',
            'Quail Oral LD50': 'Перепел орально LD50',
            'Rabbit Intravenous LDLo': 'Кролик внутривенно LDLo',
            'Reproductive Toxicity': 'Репродуктивная токсичность',
            'SR-ARE': 'Элемент антиоксидантного ответа',
            ATAD5: 'ATAD5',
            'SR-HSE': 'Элемент ответа фактора теплового шока',
            'SR-MMP': 'Потенциал митохондриальной мембраны',
            'SR-P53': 'p53',
          },
          table_units: {
            'Log(L/kg)': 'Log(л/кг)',
            '°C': '°C',
            'g/cm³': 'г/см³',
            s: 'с',
            Torr: 'Торр',
            LogP: 'LogP',
            '-Log10(mol/kg)': '-Log10(моль/кг)',
            'Log(mol/l)': 'Log(моль/л)',
            'log10(mmol/l)': 'Log10(ммоль/л)',
            'log10(L/kg)': 'Log10(л/кг)',
            'log10(mg/kg)': 'Log10(мг/кг)',
            'Log10(mmol/l)': 'Log10(ммоль/л)',
            'Log10(l/kg)': 'Log10(л/кг)',
          },
        },
        hot_tips: {
          label: 'Подсказка',
          pdf2smiles: {
            'drag-n-drop':
              'Загружайте документ перетягиванием в область экрана',
          },
          molecules: {
            left_click:
              'Щелкните левой кнопкой мыши по карточке молекулы, чтобы увидеть свойства соединения',
            select: 'Ctrl (command) + Click, чтобы выбрать одно соединение',
            select_all:
              'Чтобы выделить все предшествующие молекулы нажмите Shift + Click по той молекуле, до которой хотите выбрать',
          },
          datasets: {
            calculate:
              'Обратите внимание: скорость расчета свойств для больших датасетов зависит от текущей загрузки платформы. Расчет свойств датасетов, которые содержат больше 1000 молекул, как правило, занимает много времени.',
          },
          synmap: {
            deselect:
              'Для отмены выделения кликните дважды ЗА пределами выбранной области',
            desc: 'Эта функция предсказывает спектры QToF MS/MS для множественных энергий столкновений для заданной входной малой молекулы. Спектры вычисляются для низкого (10 эВ), среднего (20 эВ) и высокого (40 эВ) уровней энергии столкновения и представлены списком пар "массовая интенсивность", каждая из которых соответствует пику в спектре.',
          },
        },
        pdf_print: {
          footer:
            'Этот документ был сформирован на сайте https://app.syntelly.com/. По всем вопросам пишите на admin@syntelly.com',
        },
        notification: {
          check_spelling: 'Проверьте правильность написания или введите SMILES',
          uploading_of: 'Загрузка ',
          dataset_merge_error: 'Ошибка при объединении датасетов',
          dataset_merge_success: 'Датасеты успешно объединены',
          dataset_copy_error: 'Ошибка при копировании датасета',
          dataset_copy_success: 'Датасет успешно скопирован',
          dataset_delete_success: 'Датасет успешно удален',
          datasets_delete_success: 'Датасеты успешно удалены',
          file_recognition: 'Распознавание файла ',
          finished: 'завершено',
          successfully_uploaded: 'успешно загружен',
          uploading_failed: 'Ошибка загрузки {{name}}',
          molecules_move_error: 'Ошибка при перемещении молекул',
          unknown_error: 'Неизвестная ошибка',
          molecule_already_in_dataset:
            'Молекула уже есть в этом датасете. Добавьте  другую молекулу',
          smiles_copied: 'Cтруктура в виде SMILES успешно скопирована',
          patent_number_copied: 'Номер патента успешно скопирован',
          DOI_copied: 'DOI успешно скопирован',
          link_copied: 'Ссылка успешно скопирована',
          molecule_add: 'Молекула добавлена в датасет «{{name}}»',
          molecules_add: 'Молекулы добавлены в датасет «{{name}}»',
          selected_molecule_add:
            'Выбранная молекула добавлена в датасет «{{name}}»',
          selected_molecules_add:
            'Выбранные молекулы добавлены в датасет «{{name}}»',
          selected_molecule_moved:
            'Выбранная молекула перенесена в датасет «{{name}}»',
          selected_molecules_moved:
            'Выбранные молекулы перенесены в датасет «{{name}}»',
          wrong_activation: 'Активация прошла неверно',
          unhandled: 'Необработанная ошибка',
          iupac_copy: 'IUPAC успешно скопирован',
          baseid_copy: 'Синтелли ID успешно скопирован',
          inchi_copy: 'InCHI успешно скопирован',
          inchi_key_copy: 'InCHI key успешно скопирован',
          dataset_synmap_delete: 'Датасет «{{name}}» был удален',
          generator_synmap_delete: 'Генератор удален',
          retrosynthesis_warning: 'Слишком много молекул',
          retrosynthesis_warning_small:
            'Чтобы использовать ретросинтез, оставьте только одну молекулу',
          synthesis_start: 'Расчет синтеза в процессе',
          retrosynthesis_start: 'Расчет ретросинтеза в процессе',
          retrosynthesis_success: 'Расчет ретросинтеза завершен',
          retrosynthesis_failed: 'Ошибка расчета ретросинтеза',
          spectra_start: 'Начался расчет спектров',
          error: 'ОШИБКА!',
          file_uploaded_successfully:
            'Началось распознавание файла {{name}}. Это может занять некоторое время',
          failed_to_upload_file: 'Не удалось загрузить файл {{name}}',
          literature_search_started: 'Поиск по литературе запущен',
          literature_search_finished: 'Поиск по литературе завершен',
          literature_search_progress: 'Поиск по литературе выполняется',
          source_copied: 'Источник скопирован',
          you_can_only_recognize_one_document_at_a_time:
            'Доступно распознавание только одного документа в единицу времени',
          molecules_not_added_to_basket:
            'Молекулы не добавлены в датасет «{{name}}»',
          molecule_not_added_to_basket:
            'Молекула не добавлена в датасет «{{name}}»',
          not_all_molecules_added_to_basket:
            'Не все молекулы добавлены в датасет «{{name}}»',
          more_information: 'Больше информации',
          less_information: 'Меньше информации',
          molecule: 'Молекула',
          molecules: 'Молекулы',
          molecule_already_exists_in_basket: 'В датасете уже есть молекула ',
          molecules_already_exist_in_basket: 'В датасете уже есть молекулы ',
          structure: 'Структура',
          structures: 'Структуры',
          is_not_valid: 'не валидна',
          are_not_valid: 'не валидны',
          we_could_not_find_molecule: 'Мы не смогли найти искомую молекулу',
          we_could_not_add_molecule: 'Мы не смогли добавить молекулу',
          we_could_not_add_molecules: 'Мы не смогли добавить молекулы',
          wait_or_message:
            'из-за внутренней ошибки сервера. Подождите или напишите нам на почту',
          too_big_file: 'Слишком большой файл для загрузки',
          no_more_100_mb:
            'Загружать документ можно не более 100 мб — на один файл',
          'User is not source and target owner':
            'Вы не являетесь владельцем исходного или целевого датасета',
          'At least one of molecules already exists in target or does not exist in source':
            'Как минимум одна из молекул уже присутствует в целевом датасете, либо отсутствует в исходном',
          cancel: 'Вернуть ({{time}}с)',
          search_query_removed: 'Удален поисковой запрос {{type}} «{{text}}»',
          completed_tasks_removing_failed:
            'Не удалось удалить завершенные задания',
          calc_tlight_success: 'Расчет свойств молекул завершен',
          not_possible_search:
            'На русском языке поиск по IUPAC, SMILES и тривиальному названию невозможен',
          not_possible_star:
            'Ввод SMILES со "*" поддерживается только в поиске по литературе и структурам Маркуша',
          switch_language:
            'Пожалуйста, переключитесь на английский язык и введите ваш запрос',
          remove_star: 'Пожалуйста, удалите "*" из запроса',
          we_couldnt_found_best_match: 'Мы не смогли найти лучшее совпадение',
          showed_you_similar_results:
            'Показали вам похожие результаты. Проверьте правильность написания или введите SMILES ',
          we_couldnt_find_desired_structure: `Мы не смогли найти искомую структуру`,
          check_your_spelling_or_enter_SMILES:
            'Проверьте правильность написания или введите SMILES ',
          pdf_download_will_start:
            'Загрузка PDF начнется через несколько секунд',
          product_key_copied: 'Ключ продукта скопирован',
          invite_link_copied: 'Инвайт ссылка скопирована',
          invite_key_copied: 'Инвайт ключ скопирован',
        },
        spectra: {
          title: 'Предсказание спектра',
          calculated_title: 'Вычисленные результаты',
          desc: 'Эта функция предсказывает спектры QToF MS/MS для множественных энергий столкновений для заданной входной малой молекулы. Спектры вычисляются для низкого (10 эВ), среднего (20 эВ) и высокого (40 эВ) уровней энергии столкновения и представлены списком пар "массовая интенсивность", каждая из которых соответствует пику в спектре.',
          input_params: 'Входные параметры',
          header: {
            ms: 'Модуль прогнозирует спектры QToF MS/MS для малых органических молекул. Спектры рассчитаны для низких (10 эВ), средних (20 эВ) и высоких (40 эВ) уровней энергии столкновений. Результат представлен в виде набора пар “масса иона – относительная интенсивность“.',
            nmr: 'Модуль позволяет прогнозировать спектральные данные ядерного магнитного резонанса (1H, 13C, 15N и 19F) для малых органических молекул. Результат представлен в виде набора "химический сдвиг - относительная интенсивность". Для спектров 1H также прогнозируется мультиплетность.',
            infraredSpectrometry:
              'Модуль позволяет прогнозировать ИК-спектр для малых органических молекул при различных вариантах регистрации (газовая фаза, KBr и т.д.). Результат отображается в виде непрерывного графика в осях "волновое число" (см⁻¹) и "интенсивность".',
          },
          buttons: {
            nmr: 'Ядерный магнитный резонанс',
            ms: 'Масс-спектрометрия',
            predict: 'Предсказать',
            reset: 'Сбросить',
            edit: 'Изменить',
            infraredSpectrometry: 'Инфракрасная спектрометрия',
          },
          labels: {
            input: 'Исходная составная структура',
            spectral_type: 'Спектральный тип',
            ion_mode: 'Ионный режим',
            adduct_type: 'Тип аддукта',
            method: 'Метод',
            draw_molecule: 'Нарисовать',
            roundingRI: 'Округление (RI)',
            roundingMZ: 'Округление (m/z)',
            isotopic_distribution: 'Изотопное распределение',
            low_energy: 'LE MsMs Spectrum (10eV)',
            medium_energy: 'ME MsMs Spectrum (20eV)',
            high_energy: 'HE MsMs Spectrum (40eV)',
            shooting_method: 'Метод съемки',
            input_placeholder: 'Введите SMILES',
          },
          error: {
            not_valid_smiles: 'Невалидный SMILES',
            error_occured: 'Произошла ошибка',
            error: 'ОШИБКА!',
            no_spectra: 'Нет спектров с данным методом',
          },
          chart: {
            'm/z': 'm/z',
            intensity: 'Интенсивность',
            formula: 'Формула',
            relative_intensity: 'Относительная интенсивность',
            RI: 'RI',
            shift: 'Смещение',
            i_intensity: 'I, Интенсивность',
            wavenumber: 'Волновое число, см⁻¹',
          },
          table: {
            atom: 'Атом',
            meas_shift: 'Хим. Сдвиг',
            multiplet: 'Мультиплет',
          },
        },
        techWorks: {
          header: 'Ведутся технические работы',
          desc: 'Платформа находится в стадии технического обслуживания и скоро будет доступна',
          timerInfo: 'Открытие через:',
          contactUs: 'Связаться с нами',
        },
        smilesIupac: {
          header: 'Конвертер SMILES в IUPAC',
          enterMolecule: ' Введите здесь молекулу в',
          smiles: 'SMILES,',
          notationHere:
            'например, C=CC(=O)N1CCC[C@H](C1)N2C3=NC=NC(=C3C(=N2)C4=CC=C(C=C4)OC5=CC=CC=C5)N и нажмите enter.',
          youWillSeeResults: `Вы увидите 5 прогнозируемых результатов и указания на то, является ли имя правильным или нет.`,
          enterSmiles: 'Введите SMILES здесь',
          convert: 'Конвертировать',
          modelForecasts: 'Модельные прогнозы',
          inRussian: 'На русском',
          inEnglish: 'На английском',
          error: 'Ошибка',
          serverError: 'Ошибка сервера',
        },
        timer: {
          days: 'дней',
          hours: 'часов',
          minutes: 'минут',
          seconds: 'секунд',
        },
        billing: {
          serviceName: 'Название сервиса',
          shortName: 'Короткое название',
          desc: 'Описание',
          price: 'Цена',
          addNewService: 'Добавить новый сервис',
          close: 'Закрыть',
        },
        admin: {
          billing: 'Платежи',
          recognition: 'Распознавание',
          plugins: 'Плагины',
          'molecule params viewer': 'Параметры молекул',
          'feedback viewer': 'Отзывы',
          userLog: 'Журнал пользователей',
          users: 'Пользователи',
          'ban list': 'Список заблокированных пользователей',
          invite: 'Пригласить пользователя',
          usersCount: 'Количество пользователей:',
          name: 'Имя',
          email: 'Email',
          company: 'Компания',
          position: 'Должность',
          baskets: 'Датасеты',
          molecules: 'Молекулы',
          pdfs: 'PDF',
          regDate: 'Дата регистрации',
          lastEnter: 'Последний вход',
          newUsers: 'Новые пользователи',
          loading: 'Загрузка...',
          addNewPlugin: 'Добавить плагин',
          pluginsList: 'Список плагинов',
          pluginName: 'Название плагина',
          type: 'Тип',
          viewType: 'Вид',
          table: 'Таблица',
          list: 'Список',
          pluginsView: 'Вид плагина',
          address: 'Адрес',
          addNewFunc: 'Добавить новые функции',
          rpcMethodName: 'Название RPC-метода',
          input: 'Ввод',
          inchiKey: 'InChi ключ',
          default: 'По умолчанию',
          defaultValue: '0 - значение по умолчанию',
          serviceTimeout: 'Cервисный тайм-аут',
          outputName: 'Название вывода',
          exit: 'Выйти',
          edit: 'Редактировать',
          create: 'Создать',
          mails: 'Домены',
          mail: 'Почта',
          addMail: 'Добавить email',
          addIP: 'Добавить IP',
          invites: 'Приглашения',
          generate: 'Сгенерировать',
          generated: 'успешно сгенерирован',
          generation_failed: 'Ошибка генерации {{name}}',
          token: 'Токен',
          created: 'Создан',
          regData: 'Дата регистрации',
          notUsed: 'не используется',
          num: 'Кол-во',
          enterIP: 'Введите IP',
          addIPToList: 'Добавить IP в список',
          clickOnIp: 'Нажмите на IP для удаления из списка',
          // new
          admin_panel: 'Панель администратора',
          from: 'c',
          to: 'по',
          not_active: 'Не активный',
          tabs: {
            users_and_companies: 'Пользователи и компании',
            statistics: 'Статистика',
            plugins: 'Плагины',
            molecule_params: 'Параметры молекул',
            recognition: 'Распознавание',
            user_messages: 'Сообщения пользователей',
          },
          placeholder: {
            enter_company_name: 'Введите название компании',
            enter_email: 'Введите email',
            enter_domain: 'Введите домен',
          },
          companies: 'Компании',
          filter: {
            companyType: 'Тип компании',
            date_from: 'Дата активации лицензии',
            date_to: 'Дата окончания лицензии',
            placeholder:
              'Введите название компании, должность, e-mail или имя и фамилию',
            add_user: 'Добавить пользователя',
            add_company: 'Добавить компанию',
            set_filter: 'Применить фильтр',
            options: { all: 'Все' },
          },
          test_period: 'Тестовый период',
          license: 'Лицензия',
          with_license: 'С лицензией',
          admin: 'Администратор',
          user: 'Пользователь',
          domain: 'Домен',
          status: 'Статус',
          numberOfLicenses: 'Количество лицензий',
          registered: 'Зарегистрировано',
          periodOfValidity: 'Срок действия лицензии',
          addUser: 'Добавить пользователя',
          inviteLink: 'Инвайт ссылка',
          limit_access: 'Ограничить доступ',
          allow_access: 'Разрешить доступ',
          name_email: 'Имя, должность, Email',
          activation: 'Активация',
          ending: 'Окончание',
          last_activity: 'Последний визит',
          name_position: 'Имя, должность',
          disable_access: 'Отключить доступ',
          restore_access: 'Восстановить доступ',
          edit_company: 'Редактировать информацию о компании',
          toggle_access_error:
            'Произошла ошибка при изменении статуса лицензии',
          customer_update_error: 'Произошла ошибка при редактировании компании',
          get_companies_error: 'Произошла ошибка при получении списка компаний',
          get_users_error: 'Произошла ошибка при получении пользователей',
          no_name: 'Нет имени',
          no_company: 'Без компании',
          edit_company_info: 'Редактировать информацию о компании',
          undo_changes: 'Отменить изменения',
          license_period: 'Срок действия лицензии',
          company_name: 'Название компании',
          accs_number: 'Кол-во аккаунтов',
          with_domain: 'Есть домен',
          add_company_error: 'Ошибка при добавлении компании',
          add_company_error_name_exists:
            'Компания с таким названием уже существует',
          company_added: 'Компания добавлена',
          company_added_info:
                'Она появится в панели администратора. Скопируйте ниже ключ продукта для компании или скопируйте ссылку для регистрации. Также вы найдете ключ и ссылку на странице компании',
          domain_hint: 'Предоставляет регистрацию без ввода ключа, для пользователей с данным доменом'
        },
        generator: {
          removeGenerator: 'Удалить генератор',
          createGenerator: 'Создать генератор',
          options: 'Опции генератора',
          create: 'Выбрать',
          radius: 'Радиус',
          epoch: 'Эпоха',
          inputName: 'Название',
          noParent: 'Нет родителя (root)',
          selectParent: 'Выберите родителя',
          epochs: 'Эпохи',
          selectAreaOnMMAP: 'Выделите область на MMap',
          addLayerFirst: 'Необходимо добавить слой с молекулами',
          enterBrutto: 'Введите брутто-формулу или SMILES',
          minimize: 'Min',
          maximize: 'Max',
          addNewProperty: 'Добавить новое свойство',
          youWillLose: `Вы потеряете всех потомков выбранного родителя!`,
          sessionStarted: 'Генерация датасета «{{name}}»',
          sessionStartedSmall: 'Генерация датасета «{{name}}» в процессе',
          genStarted: 'Создание генератора',
          session_failed:
            'В процессе генерации датасета «{{name}}» произошла ошибка',
          selectAll: 'Выбрать все',
          molecules: 'молекулы',
          cancel: 'Отменить',
          mmap: {
            x: 'по X',
            y: 'по Y',
          },
          generation: 'Генерация',
          optimization: 'Оптимизация',
          addParameter: 'Добавить параметр',
          parameterWeight: 'Вес параметра',
          filtrationTooltip:
            '% молекул с наибольшим значением функцией награды',
          filtration: 'Фильтрация',
          create_new: 'Создать',
          enterSmiles: 'Введите SMILES',
          generator_failed: 'Не удалось создать генератора',
        },
        r_selected: {
          brutto: 'Брутто-формула',
          score: 'Надежность прогноза',
        },
        error: {
          internalError: 'Внутренняя ошибка сервера',
          not_valid_smiles: 'Невалидный SMILES',
          error_occured: 'Произошла ошибка',
        },
        searchVariants: {
          best_match: 'Лучшее совпадение',
          similar_structures: 'Похожие структуры',
          prev_searched_structures: 'История запросов поиска',
        },
        synthCost: {
          output: 'Выход: ',
          more: 'Больше',
          less: 'Меньше',
          no_info: 'Нет информации',
          synthesis_cost: 'Cтоимость синтеза',
          synthesis_cost_description:
            'Аналитический инструмент, разработанный для оценки стоимости синтеза химических соединений. Вам необходимо ввести параметры желаемого синтеза: продукт, реагент, желаемый вес синтезируемого вещества и количество стадий реакции. Результатом является ТОП-5 схем реакций, упорядоченных по возрастанию стоимости. Это позволяет провести анализ по известным методикам и выбрать наиболее оптимальный путь синтеза с расчетом экономической эффективности. Модуль предоставляет возможности для детального анализа каждой схемы, редактирования таблицы стоимости и экспорта данных в различных форматах',
          product: 'Продукт',
          reagent_capital: 'Реагент',
          main_component_of_the_reaction: 'Основной компонент реакции',
          enter_inchi_or_smiles: 'Введите InChI или SMILES',
          weight_in_grams: 'Требуемое количество (г)',
          formula: 'Формула целевого соединения для синтеза',
          desired_weight: 'Желаемый вес синтезируемого вещества в граммах',
          number_of_stages: 'Число стадий',
          max_number_of_stages:
            'Максимально допустимое число стадий синтеза (от 1 до 6)',
          predict: 'Предсказать',
          reset: 'Сбросить',
          g: 'г',
          from: 'от 1',
          to: 'до 6',
          placeholder_from: 'от',
          placeholder_to: 'до',
          from_pseudo: 'от',
          to_pseudo: 'до',
          final_stage_cannot_be_less:
            'Конечная стадия не может быть меньше начальной',
          draw: 'Нарисовать',
          calculated_results: 'Вычисленные результаты',
          scheme: 'схема',
          stage: 'Cтадия',
          download: 'Скачать',
          download_scheme: 'Скачать схему',
          all_schemes: 'все схемы',
          overall: 'Всего',
          compound_name: 'Название соединения',
          price: 'Стоимость',
          amount: 'Количество',
          source: 'Источник',
          estimated_substance_price:
            'Расчетная стоимость требуемого количества вещества',
          required_amount: 'Требуемое количество вещества',
          add_row: 'Добавить строку',
          delete_stage: 'Удалить стадию',
          edit_table: 'Редактировать таблицу',
          total_cost: 'Общая стоимость всех стадий',
          add_stage: 'Добавить стадию',
          cancel: 'Отменить',
          return: 'Вернуть',
          save_and_close: 'Cохранить и закрыть',
          changes: 'изменения',
          Scheme: 'Схема',
          calculating: 'Вычисление',
          synth_cost: 'стоимости синтеза...',
          calculating_failed: 'Ошибка вычисления стоимости синтеза',
          synth_cost_calculated: 'Стоимость синтеза расчитана',
          searched_nothing: 'Ничего не найдено',
          is_not_smiles: 'не является SMILES',
          server_returned_error: 'Сервер вернул ошибку',
          reaction_scheme_source: 'Источник схемы реакции',
          cancel_calculating: 'Отменить вычисление стоимости синтеза?',
          edit: 'Изменить',
          add_value: 'Необходимо добавить значение',
          reagent: 'Реагент',
          one_reagent: 'реагент',
          reagents_few: 'реагента',
          reagents_many: 'реагентов',
        },
        dataset_table: {
          table_analysis: 'Табличный анализ',
          all_columns: 'Все колонки',
          table_analysis_of_the_dataset: 'Табличный анализ датасета «{{name}}»',
          contains: 'Содержит',
          doesNotContain: 'Не содержит',
          equals: 'Равно',
          doesNotEqual: 'Не равно',
          startsWith: 'Начинается с',
          endsWith: 'Заканчивается на',
          blank: 'Пустое',
          notBlank: 'Не пустое',
          greaterThan: 'Больше чем',
          greaterThanOrEqual: 'Больше или равно',
          lessThan: 'Меньше чем',
          lessThanOrEqual: 'Меньше или равно',
          inRange: 'В диапазоне',
          value: 'Значение',
          select_all: 'Выбрать все',
          not_selected: 'Не выбрано',
          type: 'Тип',
          from: 'От',
          to: 'До',
          and: 'И',
          or: 'Или',
          filter: 'Фильтровать',
          paint: 'Покрасить',
          by_name: 'По названиям',
          search: 'Поиск',
          clear: 'Очистить',
          apply: 'Применить',
          value_not_found: 'Значение не найдено',
          no_values: 'Нет значений',
          nothing_found: 'Ничего не найдено',
          select_value: 'Выберите значение',
          calculating_data: 'Вычисление данных для табличного анализа датасета',
          cancel_calculating_data:
            'Отменить вычисление данных для табличного анализа датасета',
        },
        calendar: {
          select_date: 'Выберите период действия лицензии',
        },
      },
    },
  },
})

export default i18n
