import { useState, useEffect } from 'react'
import { useTheme } from 'styled-components'
import { useTranslation } from 'react-i18next'
import { connect, useDispatch } from 'react-redux'
import { linksList } from 'config/linksList'
import { resetSearch } from 'store/actions/crud'
import { CustomCloseIcon } from 'components/common/customCloseIcon'
import { Subheadline, TextBody } from 'components/common/text/index.style'
import { StyledSnackbar, SnackbarContent } from './index.style'
import { readStorage, setStorage } from 'utils/storage/storage'
import { TIPS_DELAY } from 'config/constants'
import { isMoleculePath, isMoleculesPath } from 'utils/common/common'

const HOT_TIPS_STORAGE_KEY = 'hot_tips'

const CustomSnackbar = ({
  path,
  showTips,
  withChildren = false,
  children,
  label,
}) => {
  const theme = useTheme()
  const [tips, setTips] = useState([])
  const currentPath = isMoleculesPath(path)
    ? '/molecules'
    : isMoleculePath(path)
    ? '/molecule'
    : path

  const isMoleculesPage =
    window.location.pathname.includes('/molecules') ||
    window.location.pathname.includes('/search')

  const isMoleculePage = window.location.pathname.includes('/molecule')
  const isReactionsPage = window.location.pathname.includes('/reactions')

  const { t } = useTranslation()
  const dispatch = useDispatch()

  const handleCloseTip = () => {
    const currentDate = new Date()
    const storedTips = readStorage(HOT_TIPS_STORAGE_KEY)

    let propTips = storedTips ?? []

    if (propTips.find((tip) => tip.link === currentPath)) {
      const index = propTips.findIndex((tip) => tip.link === currentPath)

      if (Number.isInteger(index)) propTips[index].prevDate = currentDate
    } else {
      propTips.push({ link: currentPath, prevDate: currentDate })
    }
    setStorage(HOT_TIPS_STORAGE_KEY, propTips)
    setTips([])
  }
  useEffect(() => {
    const currentDate = new Date()
    const storedTips = readStorage(HOT_TIPS_STORAGE_KEY)

    let propTips = storedTips ?? []

    const res = linksList.find(({ link }) => {
      if (link === path) return true
      if (isMoleculesPath(path) && link === '/molecules') {
        return true
      }
      return false
    })

    if (
      propTips?.length &&
      propTips.find(
        (tip) =>
          tip?.link === currentPath &&
          currentDate - new Date(tip?.prevDate) < TIPS_DELAY
      )
    ) {
      return
    }

    if (res) {
      if (path === '/molecules') {
        setTips([])
      } else {
        setTips(res.tips || [])
      }
    } else {
      if (path === '/' || path.includes('molecules')) {
        return setTips([
          'hot_tips.molecules.left_click',
          'hot_tips.molecules.select',
          'hot_tips.molecules.select_all',
        ])
      }
      if (path.includes('/molecule/')) {
        return setTips(['hot_tips.molecule.applicability_tip'])
      }
      setTips([])
    }

    dispatch(resetSearch())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, path])

  if (tips.length === 0 || !showTips) return null

  return (
    <StyledSnackbar
      isMoleculesPage={isMoleculesPage}
      isReactionsPage={isReactionsPage}
      className="custom-snackbar-outbound-exception"
      width={
        isMoleculePage && withChildren
          ? 'auto'
          : isReactionsPage
          ? '26rem'
          : '18.75rem'
      }
    >
      <CustomCloseIcon onClick={handleCloseTip} size="1rem" />

      <TextBody fontWeight={500} color={theme.colors.text.white}>
        {t(label ? label : 'hot_tips.label')}
      </TextBody>
      <SnackbarContent>
        {tips?.map((tip, i) =>
          withChildren ? (
            children
          ) : (
            <Subheadline
              key={i}
              fontWeight={300}
              color={theme.colors.text.white}
            >
              {t(`${tip}`)}
            </Subheadline>
          )
        )}
      </SnackbarContent>
    </StyledSnackbar>
  )
}

const mapStateToProps = (state) => {
  return {
    path: state.router.location.pathname,
    showTips: state.settings.showTips,
  }
}

export default connect(mapStateToProps)(CustomSnackbar)
