import CustomButton from 'components/common/customButton'
import { CustomDialog } from 'components/common/customDialog'
import CustomInput from 'components/common/customInput'
import { TitleTertiary } from 'components/common/text/index.style'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { DateObject } from 'react-multi-date-picker'
import { useTheme } from 'styled-components'
import {
  DialogBody,
  TopBlock,
  DoubleInputBlock,
  ButtonsBlock,
  InputWrapper,
} from './index.style'
import '../../../styles/datePicker.css'
import RangeCalendar from '../rangeCalendar'
import { connect, useDispatch } from 'react-redux'
import { customerUpdate } from 'store/actions/adminPanel'
import { useLocation } from 'react-router-dom'
import { emailReg, validateEnglishInput } from 'utils/common/common'
import { formatUtcDigitDateByFormat } from 'utils/dateTime/formatUtcDate'

const EditCompanyDialog = ({ onClose, company, setEditOpen }) => {
  const dispatch = useDispatch()
  const {
    customer_name,
    customer_admin,
    license_max_users_count,
    license_started_at,
    license_expired_at,
    customer_id: customerId,
    license_id: licenseId,
  } = company
  const [companyName, setCompanyName] = useState(customer_name ?? '')
  const [accsNumber, setAccsNumber] = useState(license_max_users_count ?? '')
  const [adminEmail, setAdminEmail] = useState(customer_admin ?? '')
  const [emailError, setEmailError] = useState('')
  const [calendarOpen, setCalendarOpen] = useState(false)
  const [calendarValues, setCalendarValues] = useState([
    new DateObject(license_started_at ?? ''),
    license_expired_at ? new DateObject(license_expired_at) : null,
  ])
  const theme = useTheme()
  const { t } = useTranslation()
  const location = useLocation()

  const handleToggleCalendar = () => {
    setCalendarOpen((prev) => !prev)
  }

  const handleUpdate = () => {
    dispatch(
      customerUpdate({
        isCurrentCustomerPage: location.pathname.includes('company'),
        companyName,
        accsNumber,
        adminEmail,
        calendarValues,
        customerId,
        licenseId,
      })
    )
    setEditOpen(false)
  }

  const handleSetAccsNumber = (e) => {
    const regexp = /^[0-9\b]+$/
    if (e[0] === '0' && e.length > 1) return setAccsNumber(e.substring(1))
    if (e === '' || regexp.test(e)) return setAccsNumber(e)
  }

  const handleSetEmail = (e) => {
    if (validateEnglishInput(e) || !e) setAdminEmail(e)
    if (!e || emailReg.test(e)) {
      setEmailError('')
    } else {
      setEmailError('account.emailError')
    }
  }

  return (
    <CustomDialog
      withPortal
      onClose={() => {
        !calendarOpen && onClose()
      }}
      padding="1.5rem"
      minWidth="29rem"
      outboundExceptions={['calendar-dialog']}
      onBodyClick={(e) => e.stopPropagation()}
    >
      <DialogBody>
        <TopBlock>
          <TitleTertiary fontWeight={theme.fontWeight.semibold}>
            {t('admin.edit_company_info')}
          </TitleTertiary>
          <CustomInput
            label={t('admin.company_name')}
            gap="0.375rem"
            withClearButton
            value={companyName}
            onChange={setCompanyName}
            placeholder={t('admin.placeholder.enter_company_name')}
          />
          <DoubleInputBlock>
            <CustomInput
              label={t('admin.admin')}
              value={adminEmail}
              onChange={handleSetEmail}
              gap="0.375rem"
              placeholder={t('admin.placeholder.enter_email')}
              withClearButton
              error={emailError}
              id="admin-email"
              type="email"
              autoComplete="email"
              errorTranslationParams={{ adminEmail }}
            />
            <CustomInput
              label={t('admin.accs_number')}
              gap="0.375rem"
              width="9rem"
              withClearButton
              value={accsNumber}
              onChange={handleSetAccsNumber}
              pseudoAfterText={t('filter.pieces')}
            />
          </DoubleInputBlock>
          <DoubleInputBlock>
            <InputWrapper onClick={handleToggleCalendar}>
              <CustomInput
                label={`${t('admin.license_period')} *`}
                pseudoBeforeText={t('filter.from_rus')}
                gap="0.375rem"
                value={formatUtcDigitDateByFormat(
                  new Date(calendarValues[0]),
                  'dd.MM.yyyy'
                )}
                readOnly
              />
            </InputWrapper>
            <InputWrapper onClick={handleToggleCalendar}>
              <CustomInput
                label={' '}
                pseudoBeforeText={t('filter.to_rus')}
                gap="0.375rem"
                width="100%"
                value={
                  calendarValues?.[1]
                    ? formatUtcDigitDateByFormat(
                        new Date(calendarValues[1]),
                        'dd.MM.yyyy'
                      )
                    : ''
                }
                readOnly
              />
            </InputWrapper>
          </DoubleInputBlock>
        </TopBlock>
        <ButtonsBlock>
          <CustomButton width="100%" type="secondary" onClick={onClose}>
            {t('admin.undo_changes')}
          </CustomButton>
          <CustomButton
            width="100%"
            type="accent"
            onClick={handleUpdate}
            disabled={!calendarValues[1] || !!emailError}
          >
            {t('ketcher.save')}
          </CustomButton>
        </ButtonsBlock>
      </DialogBody>
      {calendarOpen && (
        <RangeCalendar
          values={calendarValues}
          setValues={setCalendarValues}
          onClose={() => {
            setCalendarOpen(false)
          }}
        />
      )}
    </CustomDialog>
  )
}

export default connect()(EditCompanyDialog)
