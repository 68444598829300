import { Tooltip as ReactTooltip } from 'react-tooltip'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import Icon from 'components/Icon'
import CustomInput from 'components/common/customInput'
import CustomButton from 'components/common/customButton'
import {
  CANVAS_ARR,
  getIsPredictBtnDisabled,
} from 'pages/SynthCost/config/config'
import { Caption, TextMedium } from 'components/common/text/index.style'

import {
  Row,
  Form,
  Error,
  Column,
  Inputs,
  Buttons,
  InputLabel,
  InputContainer,
} from './index.style'
import MainSearchBar from 'components/main-search-bar/main-search-bar'
import { useTheme } from 'styled-components'

const ProductForm = ({
  loading,
  settings,
  handleReset,
  isDataLoaded,
  handleChange,
  handlePredict,
  isSidebarCollapsed,
}) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const [error, setError] = useState(false)

  const { stage_start, stage_end } = settings

  useEffect(() => {
    if (stage_start && stage_end) {
      if (Number(stage_start) > Number(stage_end)) setError(true)
      else setError(false)
    } else if (error) setError(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stage_start, stage_end])

  const isPredictBtnDisabled = useMemo(
    () => getIsPredictBtnDisabled(settings, loading, isDataLoaded),
    [isDataLoaded, loading, settings]
  )

  const isResetBtnDisabled = !Object.values(settings).some((el) => el)

  return (
    <Form gap="2rem" className="hidden-for-print">
      <Column gap="1.25rem">
        {CANVAS_ARR.map((type) => (
          <InputContainer gap="0.5rem" key={type}>
            <InputLabel data-tooltip-id={type}>
              <Caption
                color={theme.colors.text.secondary}
                fontWeight={theme.fontWeight.medium}
              >
                {type === 'reagent'
                  ? t(`synthCost.reagent_capital`)
                  : t(`synthCost.${type}`)}
                {type === 'product' && '*'}
              </Caption>
              <Icon iconType="description" />
            </InputLabel>
            <ReactTooltip
              id={type}
              place={'top-start'}
              className="c-tooltip c-tooltip-base"
              classNameArrow="c-tooltip-arrow"
            >
              {type === 'product'
                ? t('synthCost.formula')
                : t('synthCost.main_component_of_the_reaction')}
            </ReactTooltip>
            <MainSearchBar
              withWhiteBackground={false}
              inputValue={settings[type]}
              onResetSearch={() => handleChange(type, '')}
              onInputChange={(text) => handleChange(type, text)}
              onSelectOption={(smiles) => {
                handleChange(type, smiles)
              }}
            />
          </InputContainer>
        ))}

        <Inputs gap="1.25rem" isSidebarCollapsed={isSidebarCollapsed}>
          <InputContainer>
            <InputLabel data-tooltip-id="weight">
              <Caption
                color={theme.colors.text.secondary}
                fontWeight={theme.fontWeight.medium}
              >
                {t('synthCost.weight_in_grams')}
                {'*'}
              </Caption>
              <Icon iconType="description" />
            </InputLabel>
            <ReactTooltip
              id="weight"
              place={'top-start'}
              className="c-tooltip c-tooltip-base"
              classNameArrow="c-tooltip-arrow"
            >
              <TextMedium
                color={theme.colors.text.white}
                fontWeight={theme.fontWeight.light}
              >
                {t('synthCost.desired_weight')}
              </TextMedium>
            </ReactTooltip>
            <CustomInput
              withClearButton={true}
              value={settings.weight}
              placeholder={`100 ${t('synthCost.g')}`}
              pseudoAfterText={` ${t('synthCost.g')}`}
              onChange={(value) =>
                handleChange(
                  'weight',
                  value.replace(/[^\d.]|(\.(?=.*\.))/g, '')
                )
              }
            ></CustomInput>
          </InputContainer>
          <InputContainer>
            <InputLabel data-tooltip-id="stages">
              <Caption
                color={theme.colors.text.secondary}
                fontWeight={theme.fontWeight.medium}
              >
                {t('synthCost.number_of_stages')}
              </Caption>
              <Icon iconType="description" />
            </InputLabel>
            <ReactTooltip
              id="stages"
              place={'top-start'}
              className="c-tooltip c-tooltip-base"
              classNameArrow="c-tooltip-arrow"
            >
              {t('synthCost.max_number_of_stages')}
            </ReactTooltip>
            <Row>
              <CustomInput
                withClearButton={true}
                value={settings.stage_start}
                placeholder={t('synthCost.from')}
                pseudoBeforeText={t('synthCost.from_pseudo')}
                onChange={(value) =>
                  handleChange(
                    'stage_start',
                    value.replace(/[^1-6]/g, '').slice(-1)
                  )
                }
              />
              <CustomInput
                withClearButton={true}
                value={settings.stage_end}
                placeholder={t('synthCost.to')}
                pseudoBeforeText={t('synthCost.to_pseudo')}
                onChange={(value) =>
                  handleChange(
                    'stage_end',
                    value.replace(/[^1-6]/g, '').slice(-1)
                  )
                }
              />
            </Row>
            {error && (
              <Error>
                <Caption color="#FF3B30">
                  {t('synthCost.final_stage_cannot_be_less')}
                </Caption>
              </Error>
            )}
          </InputContainer>{' '}
        </Inputs>
      </Column>

      <Buttons>
        <CustomButton
          gap="0.25rem"
          type="accent"
          disabled={isPredictBtnDisabled}
          onClick={handlePredict}
        >
          <Icon iconType="forecast" size="1rem" />
          {t('synthCost.predict')}
        </CustomButton>

        <CustomButton
          gap="0.25rem"
          type="secondary"
          onClick={handleReset}
          disabled={isResetBtnDisabled}
        >
          <Icon iconType="refresh" size="1rem" />
          {t('synthCost.reset')}
        </CustomButton>
      </Buttons>
    </Form>
  )
}

export default ProductForm
