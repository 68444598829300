import styled from 'styled-components'

import { Label } from 'components/common/text/index.style'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  width: 100%;
  align-items: center;
  white-space: pre-line;
  span {
    color: ${({ theme }) => theme.colors.text.accentPrimary};
  }
`

export const MainSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
  width: 22.5rem;
  ${Label} {
    position: relative;
    width: 100%;
  }
`

export const Buttons = styled.div`
  display: flex;
  width: 100%;
  gap: 0.5rem;
`

export const Body = styled.div`
  gap: 0.5rem;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
`
