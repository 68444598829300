import { t } from 'i18next'
import { formatUtcDigitDateByFormat } from 'utils/dateTime/formatUtcDate'

export const getLicenseDate = (dateFrom, dateTo) => {
  const from = `${t('admin.from')} ${
    dateFrom
      ? formatUtcDigitDateByFormat(new Date(dateFrom), 'dd.MM.yyyy')
      : '—'
  }`

  const to = `${t('admin.to')} ${
    dateTo ? formatUtcDigitDateByFormat(new Date(dateTo), 'dd.MM.yyyy') : '—'
  }`

  return `${from} ${to}`
}
