import styled from 'styled-components'

import plusImg from '../../../../assets/plus_reaction.svg'

export const MolList = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: auto;
  align-items: flex-start;
  justify-content: flex-start;
  row-gap: 1rem;

  & > div {
    position: relative;

    &:after {
      content: '';
      background-image: url(${plusImg});
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
      position: absolute;
      top: calc(50% + 0.625rem);
      transform: translateY(-100%);
      right: -1.75rem;
      height: 1.25rem;
      width: 1.25rem;
    }
  }
`

export const ResultList = styled(MolList)`
  padding: 0;
  & > div {
    margin-right: 2.25rem;

    &:last-child {
      &:after {
        display: none;
      }
    }
  }
`

export const ResultsList = styled.div`
  display: grid;
  width: 100%;
  gap: 1rem;
  padding: 1rem;
  border-radius: 0.75rem;
  background: ${({ theme }) => theme.colors.backgrounds.primary};
`
export const ReactionViewerBlock = styled.div`
  width: 100%;
  display: flex;
  row-gap: 1rem;
  flex-direction: column;
`

export const ButtonBlock = styled.div`
  display: flex;
  column-gap: 0.5rem;
  opacity: ${({ visible }) => (visible ? '1' : '0')};
  transition: opacity 0.3s;
`
export const Divider = styled.div`
  width: 100%;
  height: 0.0625rem;
  border-radius: 0.75rem;
  background: ${({ theme }) => theme.colors.backgrounds.separator};
`
