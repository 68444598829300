import styled, { css } from 'styled-components'

import { Subheadline } from 'components/common/text/index.style'

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 1rem;
  background: ${({ isFullMode, theme }) =>
    isFullMode
      ? theme.colors.backgrounds.primary
      : theme.colors.backgrounds.secondary};
  border-radius: 0.75rem;
`

const expandedHeaderStyles = css`
  & > svg {
    color: #42c1bf;
    transform: rotate(-180deg);
  }
`

const hoverColorStyles = css`
  &:hover {
    color: #42c1bf;
    & > svg,
    div {
      color: #42c1bf;
    }
  }
`

export const CategoryHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;

  ${({ noHoverStyles }) => !noHoverStyles && hoverColorStyles}
  ${({ isExpanded }) => (isExpanded ? expandedHeaderStyles : '')}

  @media print {
    break-after: avoid;

    & > svg {
      display: none;
    }
  }
`

export const CategoryContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding-top: 16px;
  padding-top: ${({ isExpanded }) => (isExpanded ? '16px' : '0')};
  max-height: ${({ isExpanded }) => (isExpanded ? 'none' : '0')};
  overflow: hidden;
  transition: all 0.3s;

  @media print {
    max-height: none;
  }
`

export const KeyValueProperty = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 16px;
  align-items: center;

  & .property-name {
    white-space: wrap;
    width: 13.75rem;
    min-width: 13.75rem;
  }

  & .property-value {
    width: 100%;
    position: relative;
    width: fit-content;
    display: flex;
    // align-items: center;
    gap: 0.5rem;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  @media print {
    break-inside: avoid;
    visibility: visible;
    & .property-name {
      white-space: nowrap !important;
    }
  }
`

export const DescriptedPropertyKey = styled.a`
  display: flex;
  gap: 4px;
  cursor: pointer;

  & > svg {
    color: #616e7c;
    transition: 0.2s;
    min-width: 1rem;

    ${({ status }) =>
      status &&
      css`
        color: ${({ status }) => {
          if (status === 'success') return '#34C759'
          if (status === 'warning') return '#FF3B30'
        }};
      `}
  }

  ${hoverColorStyles}

  @media print {
    & > svg {
      display: none;
    }
  }
`

export const MarkdownParagraph = styled.p`
  margin: 0;
`

export const PropertyValue = styled.div`
  display: ${({ name }) => (name === 'smiles' ? 'flex' : 'inline-block')};
  vertical-align: middle;
  align-items: center;
  text-align: right;
  max-width: 85%;

  & > svg:not(.loader-icon) {
    color: ${({ theme }) => theme.colors.icons.tertiary};
    transition: 0.2s;
    cursor: pointer;
    vertical-align: middle;
    min-width: 1rem;

    &:hover {
      color: #42c1bf;
    }
  }

  .loader-icon {
    width: 1rem;
    height: 1rem;
    vertical-align: middle;
    color: ${({ theme }) => theme.colors.statuses.success};
  }

  & > span {
    vertical-align: middle;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  @media screen and (width < 1100px) {
    max-width: 75%;
  }

  @media print {
    & > svg {
      display: none;
    }
  }
`

export const PropertySource = styled.div`
  margin-left: 4px;
  display: inline-flex;
  padding: 6px 8px;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  background: #12b76a;
  text-transform: uppercase;
  color: #fff;
  font-size: 10px;
  font-weight: 400;
  line-height: 12px;
  letter-spacing: -0.12px;

  ${({ withTooltip }) =>
    withTooltip &&
    css`
      cursor: pointer;
      text-decoration-line: underline;
      &:hover {
        background: #a6f4c5;
      }
    `}
`

export const ExternalLink = styled.a`
  display: flex;
  gap: 4px;
  align-items: center;

  color: #1f2933;
  text-decoration: none;

  &:hover {
    color: #42c1bf;

    & > div {
      color: #42c1bf;
    }
  }
`

export const GroupedProperties = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
`

export const Group = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
`

export const ColoredSubheadline = styled(Subheadline)`
  font-weight: 500;

  ${({ color }) =>
    color &&
    css`
      color: ${({ color, status }) => {
        if (status === 'success') return '#34C759'
        if (status === 'warning') return '#FF3B30'
        return color
      }};
    `}
`
export const Row = styled.div`
  gap: 0.5rem;
  display: flex;
  align-items: center;
  flex-direction: row;
`

export const PropertyRow = styled(Row)``

export const CategorySort = styled.div`
  display: flex;
  column-gap: 0.5rem;
  height: ${({ isExpanded }) => (isExpanded ? 'auto' : '0')};
  overflow: ${({ isExpanded }) => (isExpanded ? 'visible' : 'hidden')};
  opacity: ${({ isExpanded }) => (isExpanded ? '1' : '0')};
  margin-top: ${({ isExpanded }) => (isExpanded ? '1rem' : '0')};
  transition: all 0.3s;
`

export const CategorySortItem = styled.div`
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  padding: 0.375rem 0.625rem;
  border-radius: 0.5rem;
  background: ${({ active, isFullMode, theme }) => {
    if (isFullMode) {
      return active
        ? theme.colors.backgrounds.accent
        : theme.colors.backgrounds.secondary
    }
    return active
      ? theme.colors.backgrounds.accent
      : theme.colors.backgrounds.primary
  }};
  color: ${({ active }) => (active ? '#FFFFFF' : '#6e809d')};
  transition: all 0.3s;
  cursor: pointer;
  white-space: nowrap;

  &:hover {
    background: ${({ active }) => (active ? '#3AA9A7' : '#f3f5f8')};
    color: ${({ active }) => (active ? '#FFFFFF' : '#42C1BF')};
  }
`

export const SynonymsHint = styled.div`
  position: absolute;
  right: 1rem;
  display flex;
  align-items:center;
  gap: 0.25rem;
  height: 2.25rem;
  width: 7.125rem;
  border-radius: 0.75rem;
  background: ${({ isFullMode, theme }) =>
    isFullMode ? theme.colors.buttons.secondary : theme.colors.buttons.primary};
  color: ${({ theme }) => theme.colors.text.primary};
  padding: 0.375rem 0.625rem;

  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  transition: all 0.3s;
  cursor: pointer;

  &:hover {
    color: ${({ theme }) => theme.colors.text.accentPrimary};
  }
  &:hover > svg {
    color: ${({ theme }) => theme.colors.text.accentPrimary};
    transition: color 0.3s ease;
  }
`
