import CustomButton from 'components/common/customButton'
import {
  TextButtonMedium,
  TextMedium,
} from 'components/common/text/index.style'
import styled, { css, keyframes } from 'styled-components'

export const Search = styled.div`
  display: flex;
  flex-direction: column;
`

export const SearchTypes = styled.div`
  display: flex;
  background: #ffffff;
  width: fit-content;
  border-radius: 12px 12px 0 0;
  margin-bottom: -1rem;
  padding-bottom: 1rem;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      180deg,
      rgba(45, 55, 69, 0) 50%,
      rgba(45, 55, 69, 0.12) 100%
    );
  }
`

export const SearchType = styled.div`
  display: flex;
  position: relative;
  padding: 0.25rem;
  border-radius: 12px 12px 0 0;

  &:not(:last-of-type) {
    &:after {
      content: '';
      position: absolute;
      top: 10px;
      right: 0;
      width: 1px;
      height: 1rem;
      background: #f5f7f9;
    }
  }
`

export const SearchTypeTooltip = styled.div`
  max-width: 216px;
`

export const SearchPanel = styled.div`
  display: flex;
  column-gap: 0.5rem;
`

export const SearchButton = styled.div`
  display: flex;
  align-items: center;
  gap: 0.25rem;
  padding: ${({ isStandartSearchBar }) =>
    isStandartSearchBar ? '0.625rem 0.75rem' : '0.375rem 0.5rem'};
  transition: all 0.3s;
`

export const SearchBlock = styled.div`
  gap: 0.5rem;
  display: flex;
  background: ${({ withWhiteBackground }) =>
    withWhiteBackground ? '#FFF' : '#f5f7f9'};
  width: 100%;
  flex: 1;
  border-radius: 0.75rem;
  position: relative;
  padding: 0.25rem;
  padding-left: ${({ hiddenDraw }) => hiddenDraw && '0.75rem'};
  align-items: center;

  height: ${({ isStandartSearchBar }) =>
    isStandartSearchBar ? '44px' : '36px'};

  & > svg {
    transition: all 0.3s;
    color: ${({ focused }) => (focused ? '#2D3745' : '#cbd2d9;')};
    min-width: ${({ isStandartSearchBar }) =>
      isStandartSearchBar ? '1.25rem' : '1rem'};
  }

  &:hover {
    box-shadow: ${({ theme }) => theme.shadow.default};
  }

  &:hover:

  & > input {
    flex: 1;
    margin-right: 0;
  }
`

export const InputBlockWrapper = styled.div`
  width: 100%;
  position: relative;
`

export const TooltipText = styled(TextMedium)`
  position: absolute;
  top: ${({ cursorY }) => `${cursorY + 16}px`};
  left: ${({ cursorX }) => `${cursorX + 16}px`};
  background: ${({ theme }) => theme.colors.backgrounds.hint};
  padding: 1rem;
  border-radius: 0.75rem;
  color: ${({ theme }) => theme.colors.text.white};
  z-index: 10;
  font-weight: ${({ theme }) => theme.fontWeight.light};
  width: 11.25rem;
`

export const InputBlock = styled.input`
  display: flex;
  border: none;
  outline: none;
  width: 100%;
  padding: 0;
  color: ${({ theme }) => theme.colors.text.primary};

  ${({ isStandartSearchBar }) =>
    isStandartSearchBar
      ? css`
          font-size: ${({ theme }) => theme.text[14]};
          font-weight: ${({ theme }) => theme.fontWeight.light};
          line-height: ${({ theme }) => theme.text[18]};
        `
      : css`
          font-size: ${({ theme }) => theme.text[12]};
          font-weight: ${({ theme }) => theme.fontWeight.medium};
          line-height: ${({ theme }) => theme.text[16]};
        `}

  background: ${({ withWhiteBackground }) =>
    withWhiteBackground ? '#FFF' : '#f5f7f9'};

  &::placeholder {
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 1rem;
    color: ${({ theme }) => theme.colors.text.tertiary};
  }

  &:disabled {
    cursor: not-allowed;
    background: ${({ withWhiteBackground, theme }) =>
      withWhiteBackground
        ? theme.colors.backgrounds.white
        : theme.colors.backgrounds.secondary};
  }
`

export const InputBlockBtnCross = styled.button`
  display: flex;
  justify-content: center;
  background: ${({ background }) => background};
  outline: none;
  border: none;
  border-radius: 0.5rem;
  align-items: center;
  cursor: pointer;
  width: ${({ isStandartSearchBar }) =>
    isStandartSearchBar ? '2.25rem' : '1.5rem'};
  padding: ${({ isStandartSearchBar }) =>
    isStandartSearchBar ? '0.625rem' : '0.25rem'};

  transition: all 0.3s;
  color: ${({ theme }) => theme.colors.icons.tertiary};

  &:hover {
    color: ${({ theme }) => theme.colors.icons.accentPrimary};
  }
`

export const VariantBlock = styled.div`
  position: absolute;
  top: calc(100% + 0.75rem);
  left: -3px;
  right: -3px;
  padding: var(--6, 4px);
  background-color: ${({ withSelectStyles, theme }) =>
    withSelectStyles
      ? theme.colors.backgrounds.contextMenu
      : theme.colors.backgrounds.primary};

  ${({ withSelectStyles, theme }) =>
    withSelectStyles &&
    css`
      backdrop-filter: blur(30px);
      box-sizing: border-box;
      border: 1.2px solid ${theme.colors.icons.white};
    `};

  z-index: 10;
  border-radius: 0.75rem;
  box-shadow: ${({ theme }) => theme.shadow.default};
`

export const BestVariantBlock = styled.div`
  border-bottom: 2px solid #f1f1f1;
  display: flex;
  flex-direction: column;
  column-gap: 0.75rem;
  row-gap: 0.75rem;
`

export const VariantBlockTitle = styled.span`
  margin-top: 0.5rem;
  padding: 0 0.5rem;
  font-size: 10px;
  font-weight: 500;
  line-height: 12px;
  letter-spacing: -0.12px;
  color: #616e7c;
`

export const VariantBlockList = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
`

export const VariantBlockListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  ${({ withMaxHeight }) =>
    withMaxHeight &&
    css`
      max-height: 15rem;
    `}
`

export const VariantWrapper = styled.div`
  display: flex;
  width: 100%;
  padding: 0.5rem;
  align-items: center;
  cursor: pointer;
  border-radius: 0.5rem;

  &:hover {
    color: #42c1bf;
    background: ${({ theme, withSelectStyles }) =>
      withSelectStyles
        ? theme.colors.backgrounds.contextMenuHover
        : theme.colors.backgrounds.secondary};
  }

  & > div {
    font-weight: 400;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    color: ${({ theme }) => theme.colors.text.primary};
  }

    ${({ isStandartSearchBar, withSelectStyles }) =>
      !isStandartSearchBar || withSelectStyles
        ? css`
            font-size: 12px;
            line-height: 16px;
            letter-spacing: -0.144px;
          `
        : css`
            font-size: 14px;
            line-height: 18px;
            letter-spacing: -0.168px;
          `}
  }
`

export const DrawnMoleculeContainer = styled.div`
  background: ${({ theme }) => theme.colors.backgrounds.primary};
  border-radius: 0.375rem;
  transition: all 0.3s;

  width: ${({ exists, isStandartSearchBar }) => {
    if (exists) return isStandartSearchBar ? '3.75rem' : '3.25rem'
    return '0'
  }};
  height: ${({ isStandartSearchBar }) =>
    isStandartSearchBar ? '2rem' : '1.5rem'};
  margin-right: ${({ exists }) => (exists ? '0.125rem' : '0')};

  & > svg {
    width: ${({ exists }) => !exists && '0'};
    height: ${({ exists }) => !exists && '0'};
  }
`

export const DrawnMoleculeContainerLarge = styled.div`
  position: absolute;
  width: 0;
  height: 0;
  top: 100%;
  right: 0;
  z-index: 11;

  & > div {
    width: 100%;
    background: #ffffff;
    border-radius: 0.5rem;
    box-shadow: ${({ theme }) => theme.shadow.default};

    & > div {
      overflow: hidden;
      height: 0;
    }
  }
`

export const SearchBtns = styled.div`
  border-radius: 0.5rem;
  background-color: ${({ theme, exists }) =>
    exists ? theme.colors.buttons.secondary : theme.colors.buttons.accent};
  cursor: pointer;

  display: ${({ hidden }) => (hidden ? 'none' : 'flex')};
  align-items: center;
  position: relative;
  transition: all 0.3s;

  & ${SearchButton}, ${TextButtonMedium} {
    color: ${({ theme, exists }) =>
      exists ? theme.colors.text.accentPrimary : theme.colors.text.white};
  }

  & ${DrawnMoleculeContainerLarge} {
    opacity: 0;
  }

  &:hover {
    background-color: ${({ theme, exists }) =>
      exists ? theme.colors.buttons.accent : theme.colors.buttons.accentHover};

    & ${SearchButton}, ${TextButtonMedium} {
      color: ${({ theme }) => theme.colors.text.white};
    }

    & ${DrawnMoleculeContainerLarge} {
      opacity: ${({ exists }) => (exists ? '1' : '0')};
      height: ${({ exists }) => (exists ? '100%' : '0')};
      width: ${({ exists }) => (exists ? '100%' : '0')};
      overflow: unset;
      padding-top: 0.5rem;
      height: unset;
      transition: all 0.3s;

      & svg {
        width: 100%;
        height: unset;
        transition: all 0.3s;
      }

      & > div > div {
        height: unset;
      }
    }
  }
`

export const SmallEditBtn = styled(SearchButton)`
  border-radius: 0.75rem;
  width: 2.75rem;
  height: 2.75rem;
  justify-content: center;
  padding: 0.75rem;
  color: #ffffff;
  background: #42c1bf;
  &:hover {
    background: #3aa9a7;
    color: #ffffff;
  }
`

export const SmallDeleteBtn = styled(SearchButton)`
  border-radius: 0px 0px 0.75rem 0.75rem;
  width: 2.75rem;
  height: 2.75rem;
  justify-content: center;
  padding: 0.75rem;
  color: #ff3b30;
  background: #ffc0bc;
  &:hover {
    background: #ff3b30;
    color: #ffffff;
  }
  opacity: 0;
  visibility: hidden;
`

export const SmallBtns = styled.div`
  display: flex;
  gap: 0.125rem;
  flex-direction: column;
  cursor: pointer;

  &:hover {
    & ${SmallDeleteBtn} {
      opacity: 1;
      visibility: visible;
    }
    & ${SmallEditBtn} {
      border-radius: 0.75rem 0.75rem 0px 0px;
    }
  }
`

const spin = keyframes`
  to{transform: rotate(1turn)}
`

const spinnerAnimation = css`
  animation: ${spin} 1s infinite linear;
`

export const LongTaskBlock = styled.div`
  padding: 10px 8px;
  width: max-content;
  display: flex;
  align-items: center;
  gap: 8px;
  background-color: #f5f7f9;
  border-radius: 6px;
  white-space: nowrap;
  color: #42c1bf;

  .spinner-icon {
    ${spinnerAnimation}
  }
`

export const CancelButton = styled(CustomButton)`
  background-color: #ffffff;
  color: #2d3745;
  padding: 12px 20px;
  display: flex;
  gap: 4px;

  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
`
export const SmallBtnsContainer = styled.div`
  position: fixed;
  top: 10rem;
  right: 1.5rem;
  z-index: 1;

  &:hover {
    & ${DrawnMoleculeContainerLarge} {
      opacity: ${({ exists }) => (exists ? '1' : '0')};
      height: ${({ exists }) => (exists ? '15rem' : '0')};
      width: ${({ exists }) => (exists ? '15rem' : '0')};
      overflow: unset;
      height: unset;
      transition: all 0.3s;
      top: 0px;
      right: 100%;
      margin-right: 1rem;

      & svg {
        width: 100%;
        height: unset;
        transition: all 0.3s;
      }

      & > div > div {
        height: unset;
      }
    }
  }
`
