import { useTranslation } from 'react-i18next'
import { Subheadline } from 'components/common/text/index.style'
import ApplicabilityIndicator from 'components/applicability-indicator'
import { applicabilityTypes } from '../config'
import { Row, ApplicabilityBlock } from '../index.style'
import { useTheme } from 'styled-components'

const ApplicabilityInner = (asHotTip = false) => {
  const { t } = useTranslation()
  const theme = useTheme()

  return (
    <ApplicabilityBlock asHotTip={asHotTip}>
      <Subheadline color={asHotTip && '#FFFFFFCC'} fontWeight={asHotTip && 300}>
        {t('moleculeslist.applicability_indicator_description')}
      </Subheadline>
      {applicabilityTypes.map(({ number, description }, ind) => (
        <Row key={ind}>
          <ApplicabilityIndicator {...{ number }} asHotTip={asHotTip} />
          <Subheadline color={asHotTip && theme.colors.text.white}>
            {t(`${description}`)}
          </Subheadline>
        </Row>
      ))}
    </ApplicabilityBlock>
  )
}

ApplicabilityInner.displayName = 'ApplicabilityInner'
export default ApplicabilityInner
