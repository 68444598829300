import { SHOW_CONFIRM, HIDE_CONFIRM } from '../constants/confirm'

export const confirm = (
  text,
  onConfirm,
  description,
  confirmFirst = false
) => ({
  type: SHOW_CONFIRM,
  text,
  onConfirm,
  description,
  confirmFirst,
})

export const cancel = () => ({
  type: HIDE_CONFIRM,
})
