import { memo, useCallback } from 'react'
import { useDropzone } from 'react-dropzone'
import { useTranslation } from 'react-i18next'
import './index.css'
import Icon from 'components/Icon'
import CustomButton from 'components/common/customButton'
import { Subheadline } from 'components/common/text/index.style'
import { Tooltip as ReactTooltip } from 'react-tooltip'

const MyDropzone = memo(
  ({
    setFiles,
    setRawFiles,
    format = 'image',
    addBtnText = 'feedback.addFileButton',
    files,
  }) => {
    const { t } = useTranslation()

    const isPDFInputDisabled = format === 'pdf' && files.length >= 1
    const onDrop = useCallback(
      (acceptedFiles) => {
        setRawFiles((f) => [...f, ...acceptedFiles])
        acceptedFiles.map(async (file) => {
          const reader = new FileReader()
          reader.readAsDataURL(file)
          reader.onload = (e) => {
            setFiles((f) => [...f, { name: file.name, data: reader.result }])
          }
        })
      },
      [setFiles, setRawFiles]
    )

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
      onDrop,
      multiple: format === 'pdf' ? false : true,
      maxFiles: format === 'pdf' ? 1 : 0, //0 - отсутствие лимита
      accept:
        format === 'image'
          ? {
              'image/jpeg': [],
              'image/png': [],
            }
          : format === 'pdf'
          ? {
              'application/pdf': [],
            }
          : {},
      noClick: true,
      disabled: isPDFInputDisabled,
    })

    return (
      <div {...getRootProps()}>
        <input id="add-file-input" {...getInputProps()} />
        {isDragActive ? (
          <Subheadline>{t('feedback.dragMsg')}</Subheadline>
        ) : (
          <>
            <CustomButton
              data-tooltip-id="feedback-file-input"
              type="text"
              gap="0.25rem"
              as={'label'}
              htmlFor="add-file-input"
              withIcon={true}
              className={isPDFInputDisabled ? 'feedback-disabled-input' : ''}
            >
              {t(addBtnText)}
              <Icon iconType="download" size="1rem" defaultColor="#2d3745" />
            </CustomButton>
            {format === 'pdf' && files.length >= 1 && (
              <ReactTooltip
                id="feedback-file-input"
                className="c-tooltip c-tooltip-base"
                classNameArrow="c-tooltip-arrow"
                place="top"
                offset={14}
              >
                {t('feedback.only_one_pdf')}
              </ReactTooltip>
            )}
          </>
        )}
      </div>
    )
  }
)

MyDropzone.displayName = 'MyDropzone'
export default MyDropzone
