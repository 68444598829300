export const LIMITS_ERROR_CODES = [400, 403]

export const LIMITS_ERRORS_TYPES = [
  'max_file_size',
  'mol_max_number',
  'mol_max_number_by_day',
  'mol_max_number_by_month',
  'period.day',
  'period.month',
]
