import styled from 'styled-components'

import CustomButton from 'components/common/customButton'

export const CopyBtn = styled(CustomButton)`
  display: inline-flex;
  width: 1rem;
  height: 1rem;
  padding: 0;
  position: absolute;
  bottom: 0;
  margin-left: 0.125rem;
`
