import IdBlock from 'components/IdBlock'
import IconButtons from './components/IconButtons'
import { IdWithIconButtons, IdsBlock } from './index.style'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import CustomButton from 'components/common/customButton'
import Icon from 'components/Icon'

const MoleculeIdWithButtons = ({
  num,
  noIdText,
  noPadding,
  noIdDescription,
  showIdWithoutMenu,
  iconButtonsConfig,
  isGoToSectionMenu,
  isBestMatch = false,
  tagType,
  isOnlyThreeDotsShow,
  toggleMenu,
  isDialog,
}) => {
  const { t } = useTranslation()
  const location = useLocation()
  const isMolIndividualPage = location?.pathname?.includes('/molecule/')

  return (
    <IdWithIconButtons
      noPadding={noPadding}
      isBestMatch={isBestMatch}
      isDialog={isDialog}
    >
      {(!isMolIndividualPage || (isMolIndividualPage && !num)) && (
        <IdsBlock isBestMatch={isBestMatch}>
          {isBestMatch && (
            <IdBlock
              noIdText={t('moleculeslist.best_match')}
              withTooltip={false}
              type={tagType}
              isMolIndividualPage={isMolIndividualPage}
              isDialog={isDialog}
            />
          )}
          <IdBlock
            type={tagType}
            {...{ num, noIdText, noIdDescription }}
            withTooltip={!isBestMatch}
            isMolIndividualPage={isMolIndividualPage}
            isDialog={isDialog}
          />
        </IdsBlock>
      )}
      {!showIdWithoutMenu &&
        (isOnlyThreeDotsShow ? (
          <CustomButton
            type="text"
            gap="0.25rem"
            onMouseOver={(e) => {
              toggleMenu(e)
            }}
          >
            {t('molecule_viewer.transfer_to')}
            <Icon iconType="expand" size="1rem" />
          </CustomButton>
        ) : (
          <IconButtons
            {...{
              noPadding,
              showIdWithoutMenu,
              iconButtonsConfig,
              isGoToSectionMenu,
              num,
            }}
          />
        ))}
    </IdWithIconButtons>
  )
}

export default MoleculeIdWithButtons
