import i18n from 'i18n'
import axios from 'axios'
import { useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { useDispatch } from 'react-redux'
import { getHeaders } from 'services/rest'
import { useTheme } from 'styled-components'
import { useTranslation } from 'react-i18next'

import Icon from 'components/Icon'
import { API_V2_URL } from 'config/config'
import CustomInput from 'components/common/customInput'
import CustomButton from 'components/common/customButton'
import { openFeedbackForm } from 'store/actions/feedback'
import { addNotification } from 'store/actions/notifications'
import LicensePurchaseInfo from 'components/LicensePurchaseInfo'
import SocialNetworksFooter from 'components/SocialNetworksFooter/index.js'
import { Label, TitleTertiary } from 'components/common/text/index.style'

import {
  Info,
  Footer,
  Content,
  Buttons,
  SurveySection,
  LicenseKeyBlock,
} from './index.style'
import { isLicenseExpired } from './utils/utils'

const LicenseBlock = ({ licenseError, setLicenseError, onLogin }) => {
  const theme = useTheme()
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const [loading, setLoading] = useState(false)
  const [licenseKey, setLicenseKey] = useState('')
  const [isLicenseBlockShow, setIsLicenseBlockShow] = useState(false)

  const lang = i18n?.language
  const { error_data: data } = licenseError

  const isNoLicense = !data?.license_id
  const isFreeTrial = data?.license_is_free_trial
  const isExpired = data?.license_expired_at
    ? isLicenseExpired(data?.license_expired_at)
    : false

  const getErrorText = () => {
    if (isNoLicense) return t('account.no_license')
    if (isExpired)
      return isFreeTrial
        ? t('account.trial_period_expired')
        : t('account.license_expired')
    else return t('account.license_invalid')
  }

  const handleOpenSurvey = () => {
    const link = document.createElement('a')
    link.setAttribute('target', '_blank')
    link.href =
      lang === 'ru'
        ? 'https://forms.gle/MZsz1hM9gfDXkCUo9'
        : 'https://forms.gle/wdJvMdJoGxw8VXq8A '
    document.body.appendChild(link)
    link.click()
    link.remove()
  }

  const handleActivate = async () => {
    setLoading(true)
    try {
      await axios.post(
        `${API_V2_URL}user/update`,
        {
          params: {
            user_id: data.user_id,
            customer_invite_token: licenseKey,
          },
        },
        {
          headers: getHeaders(data.token),
        }
      )
      onLogin(true, () => setLoading(false))
    } catch (e) {
      setLoading(false)
      const error =
        e?.response?.data?.result?.error_data?.[0]?.msg ===
          'value is not a valid uuid' ||
        e?.response?.data?.result?.error_message?.includes(
          'Invalid invite token'
        )
          ? 'account.invalid_license_key'
          : 'account.license_activation_failed'
      const notify = {
        id: uuidv4(),
        name: 'notification.error',
        text: error,
        notification_type: 'error',
        autoRemove: true,
        timeout: 5000,
      }
      dispatch(addNotification(notify))
    }
  }

  const handleBuyLicense = () => {
    dispatch(openFeedbackForm())
  }

  return (
    <>
      <Content>
        <CustomButton type="text_accent" onClick={() => setLicenseError(null)}>
          <Icon iconType="arrowLeft" size="1rem" />
          {t('registration.back')}
        </CustomButton>
        <Info>
          <TitleTertiary fontWeight={theme.fontWeight.semilbold}>
            {t('account.unfortunately')} {getErrorText()}
          </TitleTertiary>
          <LicensePurchaseInfo isFreeTrial={isFreeTrial || isNoLicense} />
        </Info>
        <Buttons>
          <CustomButton type="accent" onClick={handleBuyLicense}>
            {t('account.buy_a_license')}
          </CustomButton>
          {!isLicenseBlockShow && (
            <CustomButton
              type="secondary"
              onClick={() => setIsLicenseBlockShow(true)}
            >
              {t('account.already_have_key')}
            </CustomButton>
          )}
        </Buttons>
        {isLicenseBlockShow && (
          <LicenseKeyBlock>
            <CustomInput
              withClearButton
              label={t('registration.license_key')}
              placeholder={t('account.enter_license_key')}
              value={licenseKey}
              onChange={(value) => setLicenseKey(value)}
            />
            <CustomButton
              type="accent"
              onClick={handleActivate}
              disabled={loading || !licenseKey}
            >
              {t('account.activate')}
            </CustomButton>
          </LicenseKeyBlock>
        )}
      </Content>
      <Footer>
        <SurveySection>
          <Label>{t('account.we_would_be_grateful_2')}</Label>
          <CustomButton type="accent" onClick={handleOpenSurvey}>
            {t('account.take_a_survey')}
          </CustomButton>
        </SurveySection>
        <SocialNetworksFooter direction="row" width="100%" />
      </Footer>
    </>
  )
}

export default LicenseBlock
