import { store } from '../../../services/rest'
import {
  SYNTH_COST,
  BINGO_SEARCH,
  SYNMAP_3D_TASK_NAME,
  PDF2SMILES_UPLOAD_TASK_PREFIX,
  RETRO_TREE,
  GENERATOR,
  TABULAR_ANALYZE,
} from '../config/config'

const handleCancel = (taskStatus) => {
  const { uuid } = taskStatus
  const data = {
    task_uuid: uuid,
  }
  store('/cancel-task', data)
}

export default handleCancel

export const getName = ({ name, t, el }) => {
  const { progress, status } = el || {}
  const isLongTaskReady = progress === 100 && status === 'ok'
  const isLongTaskError = status === 'error'

  if (typeof name !== 'string') return ''

  if (name?.toLowerCase()?.includes(TABULAR_ANALYZE))
    return `${t('dataset_table.calculating_data')}${name.replace(
      'Calculating data for tabular analyze',
      ''
    )}`

  if (name?.toLowerCase()?.includes('calculating'))
    return isLongTaskReady
      ? `${t('baskets.calculated', {
          name: name.replace('Calculating of ', ''),
        })}`
      : isLongTaskError
      ? t('baskets.calculating_failed', {
          name: name.replace('Calculating of', ''),
        })
      : `${t('baskets.calculating')}`

  if (name?.toLowerCase()?.includes('molecules copy')) {
    const basketName = `«${name
      .replace('Molecules copy to basket', '')
      .trim()
      .slice(1, -1)}»`

    return isLongTaskReady
      ? t('synmap.molecules_copied') + basketName
      : isLongTaskError
      ? t('synmap.copy_molecules_failed', {
          name: basketName,
        })
      : t('synmap.copy_molecules') + basketName
  }

  if (name === SYNMAP_3D_TASK_NAME)
    return isLongTaskReady
      ? t('notifications.calculation_of_3d_coordinates_success')
      : isLongTaskError
      ? t('notifications.failed_to_load_3d_coordinates')
      : t('notifications.calculation_of_3d_coordinates_in_progress')

  if (name === SYNTH_COST)
    return isLongTaskReady
      ? `${t('synthCost.synth_cost_calculated')}`
      : isLongTaskError
      ? `${t('synthCost.calculating_failed')}`
      : `${t('synthCost.calculating') + ' ' + t('synthCost.synth_cost')}`

  if (name === RETRO_TREE)
    return isLongTaskReady
      ? `${t('notification.retrosynthesis_success')}`
      : isLongTaskError
      ? `${t('notification.retrosynthesis_failed')}`
      : `${t('notification.retrosynthesis_start')}`

  if (name === GENERATOR) return `${t('generator.genStarted')}`
  if (name?.includes(PDF2SMILES_UPLOAD_TASK_PREFIX))
    return isLongTaskReady
      ? `${
          t('notification.file_recognition') +
          name?.replace(PDF2SMILES_UPLOAD_TASK_PREFIX, '') +
          ' ' +
          t('notification.finished')
        }`
      : isLongTaskError
      ? t('pdf2smiles.dashboard.recognition_failed', {
          name: name?.replace(PDF2SMILES_UPLOAD_TASK_PREFIX, ''),
        })
      : `${
          t('pdf2smiles.dashboard.recognition_of') +
          name?.replace(PDF2SMILES_UPLOAD_TASK_PREFIX, '')
        }`

  if (name?.includes(BINGO_SEARCH))
    return t('search.search_by_structures_in_progress')

  if (name?.toLowerCase()?.includes('generation'))
    return isLongTaskReady
      ? `${name?.replace('Generation of', '') + ' ' + t('admin.generated')}`
      : isLongTaskError
      ? t('admin.generation_failed', {
          name: name?.replace('Generation of', ''),
        })
      : `${t('admin.generate') + name?.replace('Generation of', '')}`

  if (name?.toLowerCase()?.includes('uploading of'))
    return isLongTaskReady
      ? `${
          name?.replace('Uploading of', '') +
          ' ' +
          t('notification.successfully_uploaded')
        }`
      : isLongTaskError
      ? t('notification.uploading_failed', {
          name: name?.replace('Uploading of', ''),
        })
      : name?.replace('Uploading of', `${t('notification.uploading_of')}`)
  return name
}

export const getText = ({ name, el }) => {
  const { progress, status } = el || {}
  const isLongTaskReady = progress === 100 && status === 'ok'
  const isLongTaskError = status === 'error'

  if (typeof name !== 'string' || name?.includes('tabular')) return ''
  if (name?.toLowerCase()?.includes('calculating'))
    return isLongTaskReady || isLongTaskError
      ? ''
      : name.replace('Calculating of ', '')
}
