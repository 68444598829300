import styled from 'styled-components'

import { Caption, Headline } from 'components/common/text/index.style'

export const DocumentWrapper = styled.div`
  padding: 1.25rem;
  width: 15.875rem;
  height: 19rem;
  border-radius: 0.75rem;
  background-color: ${({ theme }) => theme.colors.backgrounds.tertiary};
`

export const DocumentImage = styled.img`
  width: 13.375rem;
  height: 100%;
  border-radius: 0.25rem 0.25rem 0 0;
  box-shadow: ${({ theme }) => theme.shadow.default};
`

export const DocumentCard = styled.div`
  padding: 1rem;
  width: 15.875rem;
  height: 10.25rem;
  border-radius: 0.75rem;
  background-color: ${({ theme }) => theme.colors.backgrounds.primary};
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: 0;

  &::before {
    content: '';
    width: 100%;
    height: 1.25rem;
    background: linear-gradient(
      180deg,
      rgba(230, 235, 241, 0) 0%,
      #e6ebf1 100%
    );
    position: absolute;
    top: -1.25rem;
    left: 0;
  }
`

export const DocumentCardArea = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const DocumentCardTopArea = styled(DocumentCardArea)`
  margin-bottom: 0.75rem;
`

export const DocumentName = styled(Headline)`
  transition: all 0.2s ease;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
`

export const DocumentTooltipName = styled.div`
  max-width: 500px;
`

export const DocumentStructureCount = styled(Caption)`
  display: flex;
  padding: 6px 12px;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  background: #f5f7f7;
`

export const DocumentCardBottomArea = styled(DocumentCardArea)`
  margin-top: auto;
`

export const DocumentArrow = styled.div`
  width: 24px;
  height: 24px;
  justify-content: center;
  align-items: center;
  display: flex;
  visibility: hidden;
  color: ${({ theme }) => theme.colors.text.accentPrimary};
  transition: all 0.2s ease;
`

export const Container = styled.div`
  position: relative;
  cursor: pointer;
  border-radius: 0.75rem;
  height: fit-content;

  :hover {
    box-shadow: ${({ theme }) => theme.shadow.default};

    ${DocumentName} {
      color: ${({ theme }) => theme.colors.text.accentPrimary};
    }

    ${DocumentArrow} {
      visibility: visible;
    }
  }
`
