import styled from 'styled-components'

export const Indicator = styled.div`
  width: 2.375rem;
  min-width: 2.375rem;
  height: 1.5rem;
  display: flex;
  font-size: 0.625rem;
  padding: 0.375rem 0.625rem;
  line-height: 0.75rem;
  border-radius: 0.5rem;
  align-items: center;
  justify-content: center;
  background-color: ${({ backgroundColor }) =>
    backgroundColor ? backgroundColor : 'rgba(97, 110, 124, 0.08)'};
  color: ${({ color }) => (color ? color : '#2E90FA')};

  svg {
    width: 1rem;
    height: 1rem;
  }
`
