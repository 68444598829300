export const BINGO_PAGINATION_LIMIT = 50
export const LIT_PAGINATION_LIMIT = 20

export const KEY_RU = 'key_ru'
export const KEY_EN = 'key_en'

export const INNER_LANG_OPERATOR = 'or_operator'

export const KEYS_WITH_LANG = [
  'abstract',
  'applicant',
  'authors',
  'claims',
  'company',
  'text',
  'title',
]
