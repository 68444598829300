import { ru } from 'date-fns/locale'

import { addMinutes, format, parseISO } from 'date-fns'

import { INNER_LANG_OPERATOR } from 'store/sagas/utils/config'

export const getGroupedHistory = (history, userLang) => {
  const data = history?.map((el) => {
    const utcDate = parseISO(el.created_at)
    const timeZoneOffset = -new Date().getTimezoneOffset()
    const modifiedDate = addMinutes(utcDate, timeZoneOffset)

    const date =
      userLang === 'ru'
        ? format(modifiedDate, 'dd MMMM yyyy года', {
            locale: ru,
          })
        : format(modifiedDate, 'MMMM d, yyyy', {})

    const time =
      userLang === 'ru'
        ? format(modifiedDate, 'HH:mm')
        : format(modifiedDate, 'h:mm a')
    return { ...el, date, time }
  })

  const groupedData = data.reduce((acc, curr) => {
    const { date, ...rest } = curr
    if (!acc[date]) {
      acc[date] = { date, values: [rest] }
    } else {
      acc[date].values.push(rest)
    }
    return acc
  }, {})
  return Object.values(groupedData)
}

export const getSearchText = (query) => {
  if (query.type === 'full_text_search') {
    if (query?.params?.title?.values) return query?.params?.title?.values[0]
    if (query?.params?.name) return query.params.name
  }

  if (query.type === 'advanced_text_search') {
    const conditions = query.params.query.and_operator.filter(
      (el) => el?.['or_operator'] || el?.['and_operator']
    )
    const objWithTitle = conditions.find((el) =>
      (el?.['or_operator'] || el?.['and_operator'])?.some((item) =>
        item?.[INNER_LANG_OPERATOR]?.some(
          (el) => el?.field === 'title.ru' || el?.field === 'title.en'
        )
      )
    )
    const objWithName = conditions.find((el) =>
      (el?.['or_operator'] || el?.['and_operator'])?.some(
        (item) => item?.field === 'name'
      )
    )

    if (objWithTitle) {
      const inner =
        objWithTitle?.['or_operator'] || objWithTitle?.['and_operator']
      return inner[0][INNER_LANG_OPERATOR][0].value || ''
    }

    if (objWithName) return objWithName?.['or_operator']?.[0].value || ''

    return ''
  }

  if (query.type === 'molecules_search') {
    if (query?.params?.searchV2?.text) return query.params.searchV2.text
  }
  return ''
}

export const getSearchSmiles = (query) => {
  if (query.type === 'advanced_text_search')
    return query?.params?.structures_ids?.smiles_list?.[0] || ''

  return (
    query?.params?.smiles ||
    query?.params?.structures_ids?.smiles_list?.[0] ||
    query?.params?.searchV2?.smiles
  )
}
