import i18next from 'i18next'
import { matchPath } from 'react-router-dom'
import store from 'store'

import { confirm } from 'store/actions/confirm'
import { deleteHighlights, editSmiles } from 'store/actions/pdf2smiles'

export const PDF_JS_WORKER_SRC =
  'https://unpkg.com/browse/pdfjs-dist@4.0.269/build/pdf.worker.min.mjs'

const getDocument = (elm) => (elm || {}).ownerDocument || document

export const findOrCreateContainerLayer = (container, className) => {
  const doc = getDocument(container)
  let layer = container?.querySelector(`.${className}`)

  if (!layer) {
    layer = doc.createElement('div')
    layer.className = className
    container.appendChild(layer)
  }

  return layer
}

export const scaledToViewport = (scaled, viewport) => {
  const { width, height } = viewport

  const isFloat = scaled.left < 1

  const x1 = (width * scaled.left) / (isFloat ? 1 : scaled.width)
  const y1 = (height * scaled.top) / (isFloat ? 1 : scaled.height)

  const x2 = (width * scaled.right) / (isFloat ? 1 : scaled.width)
  const y2 = (height * scaled.bottom) / (isFloat ? 1 : scaled.height)

  return {
    left: x1,
    top: y1,
    width: x2 - x1,
    height: y2 - y1,
  }
}

export const getSmilesDrawerSize = (variant) => {
  if (variant === 'modal') return { width: 144, height: 120 }
  if (variant === 'medium') return { width: 180, height: 150 }
  return { width: 156, height: 130 }
}

export const HIGHLIGHTS_SIDEBAR_WIDTH_M = '440px'
export const HIGHLIGHTS_SIDEBAR_WIDTH_S = '384px'

export const updateHashById = (id) => {
  document.location.hash = `highlight-${id}`
}

export const resetHash = () => {
  document.location.hash = ''
}

export const parseHighlightIdFromHash = () =>
  document.location.hash.slice('#highlight-'.length)

export const PDF_SCROLL_MARGIN = 100
export const HIGHLIGHTS_SIDEBAR_ID = 'h-sidebar'
export const HIGHLIGHT_IN_SIDEBAR_PREFIX = 'h-sidebar-highlight-'

export const isElementInSidebarViewport = (element, sidebar) => {
  if (element && sidebar) {
    const rect = element.getBoundingClientRect()
    const sidebarRect = sidebar.getBoundingClientRect()
    return (
      rect.top >= sidebarRect.top &&
      rect.left >= sidebarRect.left &&
      rect.bottom <= sidebarRect.bottom &&
      rect.right <= sidebarRect.right
    )
  }
  return false
}

export const scrollToSidebarHighlight = (id) => {
  document.location.hash = ''
  const element = document.getElementById(`${HIGHLIGHT_IN_SIDEBAR_PREFIX}${id}`)
  const sidebar = document.getElementById(HIGHLIGHTS_SIDEBAR_ID)

  if (element && sidebar && !isElementInSidebarViewport(element, sidebar)) {
    const elementMargin = parseInt(getComputedStyle(element).margin)
    sidebar.scrollTop = element.offsetTop - sidebar.offsetTop - elementMargin
  }
}

const getPdf2SmilesDocumentId = () => {
  const match = matchPath(window.location.pathname, {
    path: '/pdf2smiles/:id',
    exact: true,
    strict: false,
  })

  return match.params.id
}

export const confirmDeleteHighlights = (ids) => {
  store.dispatch(
    confirm(
      i18next.t(
        ids.length === 1
          ? 'pdf2smiles.viewer.molecule_delete_confirmation'
          : 'pdf2smiles.viewer.molecules_delete_confirmation'
      ),
      () => store.dispatch(deleteHighlights(ids, getPdf2SmilesDocumentId()))
    )
  )
}

export const editHighlightSmiles = (id, sm) => {
  store.dispatch(editSmiles(id, sm, getPdf2SmilesDocumentId()))
}

export const RELIABLE_SCORE = 0.995
