import i18n from 'i18n'
import { useTheme } from 'styled-components'
import { useTranslation } from 'react-i18next'

import { Caption } from 'components/common/text/index.style'

import { NETWORKS_CONFIG } from './config/config'
import { Container, NetworkIcon, NetworksIcons } from './index.style'

const SocialNetworksFooter = ({
  direction = 'column',
  width = '23.5625rem',
}) => {
  const theme = useTheme()
  const { t } = useTranslation()
  const lang = i18n?.language

  const handleOpenNetwork = (lnk) => {
    const link = document.createElement('a')
    link.setAttribute('target', '_blank')
    link.href = lnk
    document.body.appendChild(link)
    link.click()
    link.remove()
  }

  return (
    <Container {...{ width, direction }}>
      <NetworksIcons>
        {NETWORKS_CONFIG.map(({ icon, link }) => (
          <NetworkIcon
            key={icon}
            iconType={`${icon}Logo`}
            hidden={lang !== 'ru' && icon === 'vk'}
            onClick={() => handleOpenNetwork(link)}
          />
        ))}
      </NetworksIcons>
      <Caption color={theme.colors.text.tertiary} textAlign="center">
        {t('account.follow_the_platform')}
      </Caption>
    </Container>
  )
}

export default SocialNetworksFooter
