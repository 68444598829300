export const INDICATORS_CONFIG = [
  {
    value: 79,
    description: 'low_reliability',
  },
  {
    value: 94,
    description: 'medium_reliability',
  },
  {
    value: 100,
    description: 'high_reliability',
  },
]
