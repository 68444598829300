import { useDispatch } from 'react-redux'
import { useTheme } from 'styled-components'
import { useTranslation } from 'react-i18next'

import Icon from 'components/Icon'
import { copyToClipboard } from 'utils/common/common'
import { Label } from 'components/common/text/index.style'
import { addSimpleNotification } from 'store/actions/notifications'

import { CopyBtn } from './index.style'

const LicensePurchaseInfo = ({ isFreeTrial, textAlign = 'start' }) => {
  const theme = useTheme()
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const handleCopyEmail = () => {
    copyToClipboard('admin@syntelly.ru')
    dispatch(addSimpleNotification('account.email_copied'))
  }

  return (
    <Label
      textAlign={textAlign}
      fontWeight={theme.fontWeight.light}
      color={theme.colors.text.secondary}
    >
      {t('account.you_can_purchase_a_license', {
        value: isFreeTrial ? t('account.purchase') : t('account.extend'),
      })}{' '}
      <span>admin@syntelly.ru</span>{' '}
      <CopyBtn type="text_accent" onClick={handleCopyEmail}>
        <Icon iconType="copy" size="1rem" />
      </CopyBtn>
    </Label>
  )
}

export default LicensePurchaseInfo
