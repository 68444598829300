import { Tooltip as ReactTooltip } from 'react-tooltip'
import { useTranslation } from 'react-i18next'

import Icon from 'components/Icon'

import {
  CustomInputLabel,
  CustomInputField,
  CustomInputWrapper,
  InputLabelContainer,
  CustomInputClearButton,
  CustomInputFieldWrapper,
  CustomInputValue,
  PseudoLabel,
} from './index.style'
import { PasswordTooltip } from './passwordTooltip'
import { useRef } from 'react'

const CustomInput = ({
  onChange,
  value = '',
  label = '',
  placeholder = '',
  withClearButton = false,
  onKeyUp,
  autoFocus,
  className,
  onBlur,
  onFocus = () => '',
  additionalValue,
  type = 'text',
  showSearchIcon = false,
  focused = false,
  disabled = false,
  withWhiteBackground = false,
  autoComplete = 'off',
  error = '',
  isValidating = false,
  isValidated = false,
  id = 'inputField',
  withPasswordTooltip = false,
  email = '',
  dataTest = 'inputField',
  step = 1,
  min = 1,
  max = 10,
  width = '100%',
  padding = '',
  gap = '0.5rem',
  afterLabelPadding = '0.75rem',
  pseudoBeforeText = '',
  pseudoAfterText = '',
  tooltipPlace = 'top-start',
  tooltipOffset = 14,
  clearBtnProps,
  errorTranslationParams,
  readOnly = false,
}) => {
  const showClearButton = withClearButton && value.toString().length > 0
  const inputRef = useRef(null)

  const { t } = useTranslation()

  const handleChange = (e) => onChange(e.target.value)
  const clear = () => {
    onChange('')
  }

  return (
    <CustomInputWrapper className={className} width={width} gap={gap}>
      {label && (
        <InputLabelContainer
          data-tooltip-id={
            withPasswordTooltip ? 'password-req-tooltip' : `${type}-${id}-error`
          }
        >
          <>
            <CustomInputLabel htmlFor={id} onFocus={onFocus}>
              {label}
            </CustomInputLabel>
            {isValidating && (
              <Icon
                size="1rem"
                iconType="loader"
                className="icon-login-validating"
              />
            )}
            {!!error && (
              <>
                <Icon
                  size="1rem"
                  iconType="description"
                  className="icon-login-error"
                />

                {withPasswordTooltip ? (
                  <ReactTooltip
                    id="password-req-tooltip"
                    className="c-tooltip c-tooltip-base c-tooltip-password-check"
                    place={tooltipPlace}
                    noArrow
                    offset={tooltipOffset}
                  >
                    <PasswordTooltip password={value} email={email} />
                  </ReactTooltip>
                ) : (
                  <ReactTooltip
                    id={`${type}-${id}-error`}
                    className="c-tooltip c-tooltip-base"
                    classNameArrow="c-tooltip-arrow"
                    place={tooltipPlace}
                    offset={tooltipOffset}
                  >
                    {t(`${error}`, errorTranslationParams)}
                  </ReactTooltip>
                )}
              </>
            )}
            {isValidated && (
              <Icon
                size="1rem"
                iconType="tick"
                className="icon-login-validated"
              />
            )}
          </>
        </InputLabelContainer>
      )}
      <CustomInputFieldWrapper
        focused={focused}
        withWhiteBackground={withWhiteBackground}
      >
        {showSearchIcon && <Icon iconType="search" size="1rem" />}
        {!!pseudoBeforeText.length && value.length !== 0 && (
          <PseudoLabel
            onClick={() => {
              inputRef.current.focus()
            }}
          >
            {pseudoBeforeText}
          </PseudoLabel>
        )}
        <CustomInputField
          ref={inputRef}
          step={step}
          min={min}
          max={max}
          id={id}
          name={id}
          placeholder={placeholder}
          value={additionalValue ? `${t(additionalValue)} ${value}` : value}
          onChange={handleChange}
          onKeyUp={onKeyUp}
          onBlur={onBlur}
          autoFocus={autoFocus}
          spellCheck={false}
          showClearButton={showClearButton && !disabled}
          type={type}
          onFocus={onFocus}
          autoComplete={autoComplete}
          showSearchIcon={showSearchIcon}
          disabled={disabled}
          withWhiteBackground={withWhiteBackground}
          data-test={dataTest}
          withBeforeLabel={!!pseudoBeforeText.length && value.length !== 0}
          padding={padding}
          readOnly={readOnly}
        />
        {pseudoAfterText &&
          pseudoBeforeText.length === 0 &&
          value.length !== 0 && (
            <CustomInputValue afterLabelPadding={afterLabelPadding}>
              <span style={{ opacity: 0, marginRight: '2px' }}>{value}</span>

              <span style={{ position: 'relative' }}>{pseudoAfterText}</span>
            </CustomInputValue>
          )}
        {showClearButton && !disabled && (
          <CustomInputClearButton
            onClick={clear}
            id="clear"
            clearBtnProps={clearBtnProps}
            withWhiteBackground={withWhiteBackground}
          >
            <Icon iconType="close" />
          </CustomInputClearButton>
        )}
      </CustomInputFieldWrapper>
    </CustomInputWrapper>
  )
}

export default CustomInput
