import styled from 'styled-components'

export const CompanyCardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  padding: 1rem;
  border-radius: 0.75rem;
  background: ${({ theme }) => theme.colors.backgrounds.primary};
  cursor: pointer;
  transition: all 0.3s;
  position: relative;

  &:hover h4[id^='company-label'] {
    color: ${({ theme }) => theme.colors.text.accentPrimary};
    transition: all 0.3s;
  }

  & div[id^='card-button'] {
    opacity: 0;
  }
  &: hover div[id^= 'card-button'] {
    opacity: 1;
    transition: all 0.3s;
  }
  &:hover {
    box-shadow: 0px 0px 4px 0px #2d374505, 0px -4px 12px 0px #2d37450a,
      0px 4px 12px 0px #2d37450a;
  }
`

export const LicenseTag = styled.div`
  display: flex;
  align-items: center;
  padding: 0.375rem 0.5rem;
  border-radius: 0.375rem;
  width: fit-content;
  color: ${({ theme, isLicense }) =>
    isLicense ? theme.colors.text.white : theme.colors.text.primary};
  background: ${({ isLicense, theme }) =>
    isLicense
      ? theme.colors.backgrounds.accent
      : theme.colors.backgrounds.secondary};
`

export const SegmentsWrapper = styled.div`
  display: flex;
  gap: 0.75rem;
`

export const Segment = styled.div`
  width: 25%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`

export const DotsButtonWrapper = styled.div`
  position: absolute;
  top: 1rem;
  right: 1rem;
`
