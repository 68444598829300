import styled from 'styled-components'

export const ReagentNumber = styled.div`
  bottom: 5px;
  width: 28px;
  padding: 8px;
  display: flex;
  border-radius: 8px;
  position: absolute;
  background: #ffffff;
  align-items: center;
  justify-content: center;
  box-shadow: ${({ theme }) => theme.shadow.default};
`
