import { useState } from 'react'
import { connect } from 'react-redux'
import { useTheme } from 'styled-components'
import { useTranslation } from 'react-i18next'

import {
  calcSynth,
  addMoleculeToReaction,
  editMoleculeInReaction,
  removeMoleculeFromReaction,
} from 'store/actions/reactions'
import {
  resetCalculations,
  removeAllMoleculesFromReaction,
} from 'store/actions/reactions'
import Icon from 'components/Icon'
import Ketcher from 'components/Ketcher'
import CustomSnackbar from 'components/HotTip'
import ReactionViewer from 'components/ReactionViewer'
import MoleculeViewer from 'components/MoleculeViewer'
import LoadingOverlay from 'components/LoadingOverlay'
import CustomButton from 'components/common/customButton'
import { addNotification } from 'store/actions/notifications'
import { TitleTertiary } from 'components/common/text/index.style'
import MoleculeAddViewRestyled from 'components/MoleculeAddViewRestyled'

import {
  Divider,
  ResultList,
  ButtonBlock,
  ResultsList,
  ReactionViewerBlock,
} from './index.style'
import ScoreHotTip from './ScoreHotTip'

const Synthesis = ({
  viewSize,
  reaction,
  onCalcReaction,
  resetCalculations,
  addMoleculeToReaction,
  editMoleculeInReaction,
  removeMoleculeFromReaction,
  removeAllMoleculesFromReaction,
}) => {
  const theme = useTheme()
  const { t } = useTranslation()

  const { list, calculation, loading } = reaction

  const [selected, setSelected] = useState([])
  const [editId, setEditId] = useState(null)
  const [isAddDialogOpen, setIsAddDialogOpen] = useState(false)

  // legacy, does not work
  const handleSelect = (id) =>
    setSelected((prev) =>
      prev.includes(id) ? prev.filter((el) => el !== id) : [...prev, id]
    )

  const handleMultiSmiles = (list) => {
    list.forEach(({ smiles }) => {
      addMoleculeToReaction(smiles)
    })
    setIsAddDialogOpen(false)
    resetCalculations()
  }

  const handleData = (smiles) => {
    /* сохранение / редактирование через кетчер */
    if (editId !== null) {
      editMoleculeInReaction(editId, smiles)
      setEditId(null)
    } else {
      addMoleculeToReaction(smiles)
    }
    resetCalculations()
  }

  const handleResetReaction = () => {
    if (calculation) resetCalculations()
    removeAllMoleculesFromReaction()
  }

  return (
    <>
      <ResultList>
        {list.map((el, id) => {
          return (
            <MoleculeViewer
              key={id}
              num={id}
              size={viewSize}
              smiles={el}
              smiles_kekule={reaction?.kekule?.[el]}
              handleClick={() => {}}
              onEdit={setEditId}
              onDelete={() => {
                resetCalculations()
                removeMoleculeFromReaction(id)
              }}
              noMenu
              type="no-border"
              onShowProps={() => {}}
            />
          )
        })}
        <MoleculeViewer
          size={viewSize}
          clicknaddMode
          noContextMenu
          handleClick={() => setIsAddDialogOpen(true)}
          onShowProps={() => setIsAddDialogOpen(true)}
          isAddDialogOpen={isAddDialogOpen}
        />
      </ResultList>

      <ButtonBlock visible={list.length}>
        <CustomButton
          type="accent"
          gap="0.25rem"
          onClick={onCalcReaction}
          disabled={list.length < 2 || calculation?.length > 0}
        >
          <Icon iconType={'reactions'} size="1rem" />
          <span>{t('reactions_prediction.predict_btn')}</span>
        </CustomButton>

        <CustomButton
          type="primary"
          gap="0.25rem"
          onClick={handleResetReaction}
        >
          <Icon iconType="refresh" size="1rem" />
          <span>{t('reactions_prediction.reset_btn')}</span>
        </CustomButton>
      </ButtonBlock>

      {loading && <LoadingOverlay />}
      {calculation?.length > 0 && !loading && (
        <ResultsList>
          <TitleTertiary fontWeight={theme.fontWeight.semibold}>
            {t('reactions_prediction.results')}
          </TitleTertiary>
          <ReactionViewerBlock>
            {calculation.map((el, idx, arr) => {
              return (
                <>
                  <ReactionViewer
                    data={el}
                    key={idx}
                    id={idx}
                    size={viewSize}
                    handleOpen={handleSelect}
                    handleSelect={handleSelect}
                    selected={selected.includes(idx)}
                    editable={editId === idx}
                    woAdditionalBorder
                  />
                  {idx !== arr.length - 1 && <Divider />}
                </>
              )
            })}
          </ReactionViewerBlock>
        </ResultsList>
      )}
      {isAddDialogOpen && (
        <MoleculeAddViewRestyled
          handleMultiSmiles={handleMultiSmiles}
          handleClose={() => setIsAddDialogOpen(false)}
          noFiles
          withPortal={true}
        />
      )}
      {editId !== null && (
        <Ketcher
          handleData={handleData}
          smiles={list[editId]}
          closeKetcher={() => setEditId(null)}
        />
      )}
      <CustomSnackbar
        withChildren={true}
        label="reactions_prediction.model_confidence_indicator"
      >
        <ScoreHotTip data-test="model-confidence-indicator" asHotTip={true} />
      </CustomSnackbar>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    reaction: state.reactions,
    viewSize: state.settings.viewSize,
  }
}

const mapDispatchToProps = {
  calcSynth,
  editMoleculeInReaction,
  addMoleculeToReaction,
  addNotification,
  resetCalculations,
  removeMoleculeFromReaction,
  removeAllMoleculesFromReaction,
}

export default connect(mapStateToProps, mapDispatchToProps)(Synthesis)
