import styled from 'styled-components'
import CustomButton from 'components/common/customButton'

export const StyledButton = styled(CustomButton)`
  padding: 0;
  gap: 0.25rem;
  color: #1f2933;
  & > div {
    visibility: hidden;
  }
  &:hover {
    div {
      color: #42c1bf;
      transition: color 0.3s;
    }
  }
`
export const SearchText = styled.div`
  width: 214px;
  color: #1f2933;
  font-size: 14px;
  overflow: hidden;
  font-weight: 500;
  line-height: 18px;
  white-space: normal;
  word-break: break-all;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  transition: color 0.3s;
  text-overflow: ellipsis;
  letter-spacing: -0.144px;
  -webkit-box-orient: vertical;
`

export const Card = styled.div`
  width: 100%;
  position: relative;
  cursor: pointer;
  display: flex;
  height: 232px;
  padding: 1rem;
  background: #fff;
  border-radius: 0.75rem;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
  &:hover {
    ${SearchText} {
      color: #42c1bf;
    }
    box-shadow: ${({ theme }) => theme.shadow.default};

    & div[data-test='history-repeat-search-text'] {
      visibility: visible;
      transition: color 0.3s;
    }
  }
`

export const CardFooter = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
`

export const Row = styled.div`
  gap: ${({ gap }) => (gap ? gap : '0.5rem')};
  display: flex;
  flex-direction: row;
  align-items: center;
`

export const CardImageWrapper = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
  display: flex;
  width: 45%;
`
