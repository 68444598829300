import styled from 'styled-components'
import { SelectContainer } from 'components/CustomSelect/index.style'

export const SelectWithLabel = styled.div`
  gap: 0.5rem;
  display: flex;
  font-size: 0.6875rem;
  flex-direction: column;
`

export const KetcherBlock = styled.div`
  gap: 0.75rem;
  display: flex;
`

export const Wrapper = styled.div`
  gap: 0px;
  width: 100%;
  display: flex;
  flex-direction: column;
`
export const Content = styled.div`
  gap: 1rem;
  display: flex;
  flex-direction: column;
  ${SelectContainer} {
    width: 100%;
  }
`

export const SmilesView = styled.svg`
  border-radius: 0.75rem;
  box-shadow: ${({ theme }) => theme.shadow.default};

  width: ${({ exist }) => (exist ? '20.25rem' : '0rem')};
  height: ${({ exist }) => (exist ? '11.25rem' : '0rem')};
  margin-top: ${({ exist }) => (exist ? '1.5rem' : '0rem')};
`

export const FileInfo = styled.div`
  gap: 1rem;
  display: flex;
  padding: 1rem;
  width: 20.25rem;
  cursor: pointer;
  position: relative;
  border-radius: 0.75rem;
  flex-direction: column;
  justify-content: center;
  background: ${({ theme }) => theme.colors.backgrounds.secondary};

  svg:last-child {
    display: none;
  }

  div {
    width: 100%;
    overflow: hidden;
    max-height: 2rem;
    white-space: wrap;
    display: -webkit-box;
    word-break: break-word;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  &:hover {
    svg:last-child {
      top: 0.5rem;
      right: 0.5rem;
      display: block;
      position: absolute;
      transition: all 0.3s ease;
      color: ${({ theme }) => theme.colors.statuses.error};
    }
  }
`
export const FileInput = styled.input`
  display: none;
`
