import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'

import Icon from 'components/Icon'
import { Label } from 'components/common/text/index.style'
import { settingsLangChange } from 'store/actions/settings'

import { getParams } from './utils'
import { AnimLabelWrapper, IconWrapper, LangBlock } from './index.style'

const SidebarButton = ({
  showLabel = true,
  blockID = 'lang-block',
  labelID = 'lang-label',
  type = 'language',
}) => {
  const { t, i18n } = useTranslation()
  const dispatch = useDispatch()
  const currentLang = i18n?.language

  const { icon, label } = getParams(type, currentLang)

  const handleChangeLang = () => {
    const newLang = currentLang === 'en' ? 'ru' : 'en'
    i18n.changeLanguage(newLang)
    dispatch(settingsLangChange(newLang))
  }

  const buttonActionConfig = {
    language: handleChangeLang,
    sidebarLanguage: handleChangeLang,
    telegram: () => {
      const link = document.createElement('a')
      link.setAttribute('target', '_blank')
      link.href = 'https://t.me/syntellyplatform'
      document.body.appendChild(link)
      link.click()
      link.remove()
    },
  }

  return (
    <LangBlock
      onClick={(e) => buttonActionConfig[type](e)}
      id={blockID}
      type={type}
      showLabel={showLabel}
    >
      <IconWrapper withBackground={type === 'sidebarLanguage'}>
        <Icon iconType={icon} size="1.25rem" />
      </IconWrapper>
      <AnimLabelWrapper opened={showLabel}>
        <Label color="inherit" id={labelID}>
          {t(`sidebar.${label}`)}
        </Label>
      </AnimLabelWrapper>
    </LangBlock>
  )
}

export default SidebarButton
