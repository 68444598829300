import styled from 'styled-components'

export const CompanyCardsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: ${({ marginBottom }) => marginBottom};
`

export const CompanyCards = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`
