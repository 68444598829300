import { v4 as uuidv4 } from 'uuid'
import { useEffect, useState } from 'react'
import { useTheme } from 'styled-components'
import { push } from 'connected-react-router'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { connect, useDispatch, useSelector } from 'react-redux'

import {
  emailReg,
  validatePasswordSymb,
  validatePasswordLength,
  validatePasswordLetters,
} from 'utils/common/common'
import Icon from 'components/Icon'
import Promo from 'components/Promo'
import { store } from 'services/rest'
import SidebarButton from 'components/SidebarButton'
import { useGetLogoImage } from 'hooks/useGetLogoImage'
import { addNotification } from 'store/actions/notifications'
import { Caption, Label } from 'components/common/text/index.style'
import { clearUserTempCreds, setUserTempCreds } from 'store/actions/auth'

import {
  Wrapper,
  LogInText,
  LogInButton,
  SignUpBlock,
  SignUpWithPromo,
  LogInWithCopyRight,
} from './index.style'

import { FULL_FIRST_STEP_PROGRESS, PROGRESS_VALUE } from './config'
import { getProgressValue } from './utils'
import Activation from './components/Activation'
import PersonalData from './components/PersonalData'
import EmailAndPassword from './components/EmailAndPassword'
import RegistrationInfo from './components/RegistrationInfo'
import ReCaptchaStep from './components/ReCaptchaStep'

const RegistrationPageNew = ({ push }) => {
  const theme = useTheme()
  const logoImage = useGetLogoImage()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const tempUserCreds = useSelector((store) => store.auth?.userCreds)

  const [progress, setProgress] = useState(0)
  const [loading, setLoading] = useState(false)
  const [isPasswordsMatch, setIsPasswordsMatch] = useState(false)
  const [isAgreeWithPrivPolicy, setIsAgreeWithPrivPolicy] = useState(false)

  const [emailError, setEmailError] = useState('')
  const [licenseError, setLicenseError] = useState('')
  const [password2Error, setPassword2Error] = useState('')
  const [step, setStep] = useState(0)
  const location = useLocation()

  const [data, setData] = useState({
    password: tempUserCreds?.password ?? '',
    password2: '',
    email: tempUserCreds?.email ?? '',
    username: '',
    surname: '',
    company: '',
    role: '',
    is_license: false,
    license_key: '',
  })

  const {
    email,
    password,
    password2,
    username,
    surname,
    company,
    role,
    is_license: isLicense,
    license_key: licenseKey,
  } = data

  const isPasswordValidationError =
    !!password &&
    (!validatePasswordLength(password) ||
      !validatePasswordLetters(password) ||
      !validatePasswordSymb(password) ||
      (!!email && password.includes(email)))

  // если включен ключ продукта, то шаг прогресса меньше
  const firstStepProgressValue =
    step === 1 ? getProgressValue(isLicense) : getProgressValue()

  // на втором этапе (имя, фамилия и тд) шаг прогресса стандартный, ни от чего не зависит
  const secondStepProgressValue = PROGRESS_VALUE

  // если пользователь прошел по ссылке для регистрации
  useEffect(() => {
    const customerInviteToken = location?.search?.toString()?.split('=')?.[1]
    if (customerInviteToken) {
      setData({ ...data, is_license: true, license_key: customerInviteToken })
      setStep(1)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])

  // отслеживаем изменения в пароле и меняем прогресс на первом шаге
  useEffect(() => {
    if (step === 1) {
      if (password2Error === 'account.password2Error') setPassword2Error('')
      let newProgress = updateProgress(firstStepProgressValue)
      setProgress(newProgress)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [password, password2, t])

  // отслеживаем остальные изменения и меняем прогресс на первом шаге
  useEffect(() => {
    if (step === 1) {
      let newProgress = updateProgress(firstStepProgressValue)
      if (
        password &&
        !isPasswordValidationError &&
        password2 &&
        !password2Error
      ) {
        newProgress += firstStepProgressValue
      }
      setProgress(newProgress)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email, t, isAgreeWithPrivPolicy, isLicense, licenseKey])

  // отслеживаем изменения и меняем прогресс на втором шаге
  useEffect(() => {
    if (step === 2) {
      let newProgress = FULL_FIRST_STEP_PROGRESS
      if (username.trim()) newProgress += secondStepProgressValue
      if (surname.trim()) newProgress += secondStepProgressValue
      if (company.trim()) newProgress += secondStepProgressValue
      if (role.trim()) newProgress += secondStepProgressValue
      if (progress !== newProgress) setProgress(newProgress)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [username, surname, company, role])

  const updateProgress = (value) => {
    setIsPasswordsMatch(false)
    let newProgress = 0
    if (email && emailReg.test(email)) newProgress += value

    if (email && !emailReg.test(data.email)) setEmailError('account.emailError')
    else if (emailError) setEmailError('')

    if (password && !isPasswordValidationError) newProgress += value
    if (!password2 && password2Error) setPassword2Error('')
    if (isAgreeWithPrivPolicy) newProgress += value
    if (isLicense && licenseKey) newProgress += value

    return newProgress
  }

  const handleChange = (value, id) => {
    if (id === 'is_license') {
      const newProgress = updateProgress(firstStepProgressValue)
      setProgress(newProgress)
    }
    if (id === 'license_key' && licenseError) setLicenseError('')
    setData({ ...data, [id]: value })
  }

  const handleSubmit = async () => {
    if (progress < 100) return

    try {
      setLoading(true)
      const { data: response } = await store('/profile/register', {
        agreed: true,
        company,
        email,
        password,
        password2,
        name: username,
        surname,
        position: role,
        customer_invite_token: licenseKey,
      })

      setLoading(false)
      if (response.status === 'error') {
        let errorText = response.error
        let needTranslateText = false
        let translationTextParams
        if (response.errorType === 'email') {
          if (errorText.startsWith('User with email')) {
            translationTextParams = { email: data.email }
            errorText = 'registration.email_error'
            needTranslateText = true
            setEmailError(errorText)
          }
        }

        const id = uuidv4()
        const notify = {
          id,
          name: 'notification.error',
          text: errorText,
          notification_type: 'error',
          timeout: 5000,
          autoRemove: true,
          needTranslateText,
          translationTextParams,
        }
        dispatch(addNotification(notify))
        setStep(1)
      } else if (response.status === 'ok') {
        handleGoToNextStep()
      }
    } catch (e) {
      let error = 'registration.registration_failed'
      if (
        e?.response?.status === 500 &&
        e?.response?.data?.result?.error_message?.includes('invalid UUID')
      ) {
        error = 'registration.invalid_license_key'
        setLicenseError(error)
        setStep(1)
      }

      const id = uuidv4()
      const notify = {
        id,
        name: 'notification.error',
        text: error,
        notification_type: 'error',
        autoRemove: true,
        timeout: 5000,
        needTranslateText: true,
      }
      dispatch(addNotification(notify))
      setLoading(false)
    } finally {
      dispatch(clearUserTempCreds())
    }
  }

  const handleBlur = () => {
    if (password && password2) {
      if (password !== password2) {
        setPassword2Error('account.password2Error')
        setIsPasswordsMatch(false)
      } else {
        setPassword2Error('')
        if (!isPasswordsMatch) {
          setProgress((prev) => prev + secondStepProgressValue)
          setIsPasswordsMatch(true)
        }
      }
    }
  }

  const handleKeyUp = (e) => {
    if (e.key === 'Enter') {
      handleBlur()
    }
  }

  const handleClickLogin = () => {
    if (!!data.email || !!data.password) {
      dispatch(setUserTempCreds({ email: data.email, password: data.password }))
    }
    push('/login')
  }

  const handleCompleteRecaptcha = (token) => {
    if (token) {
      setTimeout(() => handleSubmit(), 1000)
    }
  }

  const handleGoToPrevStep = () => setStep((prev) => prev - 1)
  const handleGoToNextStep = () => setStep((prev) => prev + 1)

  return (
    <Wrapper>
      <SignUpWithPromo>
        <SignUpBlock>
          <img
            src={logoImage}
            alt="syntelly"
            height="4.375rem"
            style={{ margin: 0 }}
          />
          {step === 0 && (
            <RegistrationInfo onGoToNextStep={handleGoToNextStep} />
          )}
          {step === 1 && (
            <EmailAndPassword
              {...{
                email,
                password,
                progress,
                password2,
                emailError,
                isLicense,
                licenseKey,
                licenseError,
                password2Error,
                isPasswordsMatch,
                isAgreeWithPrivPolicy,
                isPasswordValidationError,
              }}
              onBlur={handleBlur}
              onKeyUp={handleKeyUp}
              onChange={handleChange}
              onChangeAgreeWithPrivPolicy={() =>
                setIsAgreeWithPrivPolicy((prev) => !prev)
              }
              onGoBack={handleGoToPrevStep}
              onGoToNextStep={handleGoToNextStep}
            />
          )}
          {step === 2 && (
            <PersonalData
              onChange={handleChange}
              {...{
                username,
                surname,
                company,
                role,
                progress,
                setProgress,
              }}
              onGoBack={handleGoToPrevStep}
              onGoToNextStep={handleGoToNextStep}
            />
          )}
          {step === 3 && (
            <ReCaptchaStep
              onChangeRecaptcha={handleCompleteRecaptcha}
              isRequestLoading={loading}
            />
          )}
          {step === 4 && <Activation />}
          <LogInWithCopyRight>
            {step === 0 && (
              <>
                <LogInText>
                  <Label
                    style={{ margin: 0 }}
                    color={theme.colors.text.secondary}
                  >
                    {' '}
                    {t('registration.alreadyRegistered')}
                  </Label>
                  <LogInButton
                    type="text"
                    onClick={handleClickLogin}
                    gap="0.25rem"
                    data-test="registration-login-btn"
                  >
                    {t('account.signIn')}
                    <Icon iconType="arrowRight" size="1rem" />
                  </LogInButton>
                </LogInText>
                <SidebarButton />
              </>
            )}

            <Caption
              fontWeight={theme.fontWeight.medium}
              color={theme.colors.text.tertiary}
            >
              {t('account.copyRight').toUpperCase()}
              {new Date().getFullYear()}
            </Caption>
          </LogInWithCopyRight>
        </SignUpBlock>
        <Promo />
      </SignUpWithPromo>
    </Wrapper>
  )
}

const mapStateToProps = (state) => ({
  path: state.router.location.pathname,
})

const mapDispatchToProps = {
  push,
}

export default connect(mapStateToProps, mapDispatchToProps)(RegistrationPageNew)
