import { Caption } from 'components/common/text/index.style'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { connect, useDispatch } from 'react-redux'
import { useTheme } from 'styled-components'
import {
  CardsHeader,
  HeaderItem,
  UsersSkeletonWrapper,
} from '../customers/index.style'
import UserCard from '../userCard'
import { UsersWrapper, UsersBlock } from './index.style'
import { v4 as uuidv4 } from 'uuid'
import SkeletonComponent from 'components/common/skeleton'
import { getUsers } from 'store/actions/adminPanel'
import { SYN_ADMIN_HEADER_BLOCKS_TEXT, USERS_BLOCKS_WIDTH } from '../config'

const Users = ({ users, getUsersError, getUsersLoading, firstRequest }) => {
  const theme = useTheme()
  const { t } = useTranslation()
  const dispatch = useDispatch()

  useEffect(() => {
    if (firstRequest) dispatch(getUsers({ limit: 24, offset: 0 }))
  }, [firstRequest])

  return (
    <UsersWrapper id="usersContainer">
      {getUsersLoading && (
        <UsersSkeletonWrapper>
          {Array.from({ length: 12 }).map((_, i) => (
            <SkeletonComponent
              height="5.5rem"
              width="100%"
              variant="dark"
              key={`skeleton-index-${i}`}
            />
          ))}
        </UsersSkeletonWrapper>
      )}
      {!!users.length && !getUsersLoading && !getUsersError && (
        <>
          <CardsHeader>
            {USERS_BLOCKS_WIDTH.map((el, i) => (
              <HeaderItem
                width={el.value}
                key={uuidv4()}
                justifyContent={el.justify}
              >
                <Caption
                  color={theme.colors.text.secondary}
                  style={{ whiteSpace: 'nowrap' }}
                >
                  {t(SYN_ADMIN_HEADER_BLOCKS_TEXT[i])}
                </Caption>
              </HeaderItem>
            ))}
          </CardsHeader>
          <UsersBlock>
            {users.map((user) => (
              <UserCard card={user} key={user.user_id} role="syn_admin" />
            ))}
          </UsersBlock>
        </>
      )}
    </UsersWrapper>
  )
}

const mapStateToProps = (state) => {
  return {
    users: state.adminPanel.users.items,
    getUsersLoading: state.adminPanel.users.loading,
    getUsersError: state.adminPanel.users.error,
    firstRequest: state.adminPanel.users.firstRequest,
  }
}

export default connect(mapStateToProps)(Users)
