import XLSX from 'xlsx'
import json2csv from 'json2csv'
import { v4 as uuidv4 } from 'uuid'
import { connect, useDispatch } from 'react-redux'
import * as FileSaver from 'file-saver'
import SmilesDrawer from 'smiles-drawer'
import { useTranslation } from 'react-i18next'
import { useEffect, useRef, useState } from 'react'
import './index.css'
import {
  predict,
  saveSettings,
  resetSettings,
  resetPrediction,
  resetSynthCostError,
} from 'store/actions/synthcost'
import Icon from 'components/Icon'
import Ketcher from 'components/Ketcher'
import {
  Caption,
  Subheadline,
  TitleSecondary,
  TextLarge,
  Headline,
  Label,
} from 'components/common/text/index.style'
import { removeTask } from 'store/actions/tasks'
import { copyToClipboard, generatePdf } from 'utils/common/common'
import {
  addNotification,
  addSimpleNotification,
} from 'store/actions/notifications'
import handleCancel from 'components/Notifications/utils/utils'
import {
  Tag,
  Row,
  Box,
  Column,
  Scheme,
  Schemes,
  Content,
  StageTag,
  Container,
  SvgWrapper,
  EditButton,
  SwitchBlock,
  StyledButton,
  ResultsWrapper,
  MoleculeWrapper,
  DrawContainer,
  SmilesSvg,
  ArrowDownSvg,
  HeaderWrapper,
  ButtonsBlock,
  StagesBlock,
  ArrowBtn,
} from './index.style'
import {
  getErrorText,
  getDataForRender,
  getSmirksForRender,
  getTableForDownload,
  getAllSchemesCSVData,
  getReagentsTitle,
} from './utils'
import Reaction from './components/Reaction'
import AllSchemes from './components/AllSchemes'
import ProductForm from './components/ProductForm'
import SourceDialog from './components/SourceDialog'
import SynthCostTable from './components/SynthCostTable'
import { CANVAS_ARR, INIT_SETTINGS, SMILES_SIZE } from './config/config'
import { readStorage, removeFromStorage } from 'utils/storage/storage'
import { REDIRECT_SMILES } from 'components/MoleculeViewer/config/config'
import { handleShowContextMenu } from 'store/actions/contextMenu'
import { SMILES_DRAWER_OPTIONS } from 'config/constants'
import CustomScrollbar from 'components/CustomScrollbar'
import { useTheme } from 'styled-components'
import CustomButton from 'components/common/customButton'
import { useLocation } from 'react-router-dom'
import history from 'services/history'

export const INITIAL_OUTPUT = 100

const SynthCost = ({
  predict,
  synthcost,
  removeTask,
  saveSettings,
  resetSettings,
  resetPrediction,
  addNotification,
  isSidebarCollapsed,
  addSimpleNotification,
  resetSynthCostError,
}) => {
  /** INIT - START */
  const dispatch = useDispatch()
  const theme = useTheme()
  const { t } = useTranslation()
  const location = useLocation()

  const path = location?.pathname
  const isSynthCostPage = path.includes('/synthcost')

  const { task, data, error, loading, settings: savedSettings } = synthcost
  const { full_history, all_smirks } = data

  const [settings, setSettings] = useState(savedSettings)
  const [headerOpen, setHeaderOpen] = useState(false)
  const { product, reagent } = settings

  const [showKetcher, setShowKetcher] = useState('')
  const [downloadAllInPdfClicked, setDownloadAllInPdfClicked] = useState(false)
  const [allowAnimate, setAllowAnimate] = useState(true)
  const [selectedStage, setSelectedStage] = useState(0)
  const [selectedScheme, setSelectedScheme] = useState(0)
  const [isReactionSourceShow, setIsReactionSourceShow] = useState(false)
  const [schemes, setSchemes] = useState([])
  const [defaultValues, setDefault] = useState([])

  const productView = useRef()
  const reagentView = useRef()
  const errorRef = useRef(error)

  const smirks = getSmirksForRender(all_smirks)
  const processedHistoryTable = getDataForRender(full_history)
  const isDataLoaded = !!Object.keys(data).length
  const isNothingToShow =
    isDataLoaded && Object.values(data).every((el) => el.length === 0)

  const reactionSourceArr = smirks?.length
    ? smirks[selectedScheme][selectedStage].source
        .split(' ')
        .filter((el) => el.trim())
    : []
  /** INIT - END */

  /** EFFECTS - START */
  useEffect(() => {
    const redirectSmiles = readStorage(REDIRECT_SMILES)
    if (!redirectSmiles) {
      product && drawSmiles('product', product)
    } else {
      redirectSmiles && drawSmiles('product', redirectSmiles)
      setSettings((prev) => ({ ...prev, product: redirectSmiles }))
      removeFromStorage(REDIRECT_SMILES)
    }
    reagent && drawSmiles('reagent', reagent)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (downloadAllInPdfClicked) {
      generatePdf({
        id: 'all_schemes',
        classForPrint: 'all-schemes-print',
        shouldScale: true,
      })
      setAllowAnimate(false)
      setDownloadAllInPdfClicked(false)
    }
  }, [downloadAllInPdfClicked])

  useEffect(() => {
    errorRef.current = error
  }, [error])

  useEffect(
    () => {
      return () => {
        if (errorRef.current) {
          handleReset()
          !task && resetSynthCostError()
          errorRef.current = null
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  useEffect(() => {
    if (error) {
      const id = uuidv4()
      const notify = {
        id,
        name: 'notification.error',
        text: getErrorText(error),
        notification_type: 'error',
        autoRemove: true,
        timeout: 5000,
      }
      addNotification(notify)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error])

  useEffect(() => {
    const data = processedHistoryTable.map((scheme) =>
      scheme.map((stage) => {
        return {
          output: INITIAL_OUTPUT.toString(),
          stages: stage.map((reagent) => ({
            ...reagent,
            price: reagent?.price || 0,
            weight: reagent?.weight || 0,
          })),
        }
      })
    )

    setSchemes(data)
    setDefault(data)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [processedHistoryTable.length])
  /** EFFECTS - END */

  /** METHODS - START */
  const drawSmiles = (type, text) => {
    let element = type === 'product' ? productView.current : reagentView.current
    if (element) {
      element.draw = false
      if (text) {
        const smilesDrawer = new SmilesDrawer.SvgDrawer({
          ...SMILES_SIZE,
          ...SMILES_DRAWER_OPTIONS,
        })
        SmilesDrawer.parse(text, (tree) => {
          smilesDrawer.draw(tree, element)
          element.draw = true
        })
      }
    }
  }

  const deleteTask = () => {
    handleCancel(task)
    removeTask(task.id)
  }

  const clear = () => {
    if (task || isDataLoaded) {
      task && deleteTask()
      resetPrediction()
      selectedStage !== 0 && setSelectedStage(0)
      selectedScheme !== 0 && setSelectedScheme(0)
    }
  }

  const handleChange = (key, value) => {
    if (isSynthCostPage && history?.location?.pathname === '/synthcost') {
      clear()
      setSettings((prev) => ({ ...prev, [key]: value }))
      if (key === 'product' || key === 'reagent') {
        drawSmiles(key, value)
      }
    }
  }

  const handleReset = () => {
    clear()
    setSettings(INIT_SETTINGS)
    resetSettings()
    if (product) drawSmiles('product', '')
    if (reagent) drawSmiles('reagent', '')
  }

  const handlePredict = () => {
    const newSettings = {
      ...settings,
      weight: Number(settings.weight),
    }
    setSettings(newSettings)
    saveSettings(newSettings)
    predict()
  }

  const handleDownload = (downloadType, scheme) => {
    const fileName = scheme === undefined ? 'schemes' : `scheme_${scheme + 1}`
    const tableForDownload = getTableForDownload(schemes)

    if (scheme === undefined) {
      if (downloadType === 'csv') {
        const data = getAllSchemesCSVData(tableForDownload, t, json2csv)

        const blob = new Blob([data], { type: 'application/csv' })
        FileSaver.saveAs(blob, `${fileName}.csv`)
      }
      if (downloadType === 'excel') {
        const wb = XLSX.utils.book_new()
        tableForDownload.forEach((scheme, index) => {
          const wsData = scheme.reduce((acc, curr) => acc.concat(curr, []))
          const ws = XLSX.utils.json_to_sheet(wsData)
          XLSX.utils.book_append_sheet(
            wb,
            ws,
            `${t('synthCost.Scheme')} ${index + 1}`
          )
        })
        const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'array' })
        const blob = new Blob([wbout], { type: 'application/octet-stream' })
        FileSaver.saveAs(blob, `${fileName}.xlsx`)
      }
      if (downloadType === 'pdf') {
        setDownloadAllInPdfClicked(true)
      }
    } else {
      if (downloadAllInPdfClicked) setDownloadAllInPdfClicked(false)
      const schemeForDownload = tableForDownload[selectedScheme]

      if (downloadType === 'csv') {
        let stageNumber = 0
        const table = schemeForDownload.reduce((a, b) => {
          stageNumber++
          return a.concat({ '№': `${t('synthCost.stage')} ${stageNumber}` }, b)
        }, [])
        const csv = json2csv.parse(table, { header: true })

        const blob = new Blob([csv], { type: 'application/csv' })
        FileSaver.saveAs(blob, `${fileName}.csv`)
      } else if (downloadType === 'excel') {
        const wb = XLSX.utils.book_new()
        const wsData = tableForDownload[scheme].reduce(
          (acc, curr) => acc.concat(curr, []),
          []
        )
        const ws = XLSX.utils.json_to_sheet(wsData)
        XLSX.utils.book_append_sheet(wb, ws, `Scheme`)
        const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'array' })
        const blob = new Blob([wbout], { type: 'application/octet-stream' })
        FileSaver.saveAs(blob, `${fileName}.xlsx`)
      } else {
        generatePdf({
          id: `synthcost-scheme-${selectedScheme}`,
          fileName: `Scheme-${selectedScheme + 1}`,
          shouldScale: true,
        })
      }
    }
  }

  const handleCopySource = (source) => {
    copyToClipboard(source)

    addSimpleNotification('notification.source_copied')
  }

  const handleContextMenu = (e) => {
    dispatch(
      handleShowContextMenu({
        e,
        menu: 'synthCostMenu',
        item: { handler: handleDownload, mouseLeaveHide: true },
      })
    )
  }
  /** METHODS - END */

  const reagentsCount = smirks[selectedScheme]?.reduce(
    (acc, curr) => curr.reagents.length + acc,
    0
  )

  return (
    <>
      {isReactionSourceShow && (
        <SourceDialog
          onCopy={handleCopySource}
          sources={reactionSourceArr}
          onClose={() => setIsReactionSourceShow(false)}
        />
      )}
      <CustomScrollbar className="margin-4-right">
        <Container>
          <Column gap="0.5rem" className="hidden-for-print" maxWidth="80%">
            <TitleSecondary color={theme.colors.text.primary}>
              {t('synthCost.synthesis_cost')}
            </TitleSecondary>
            <HeaderWrapper opened={headerOpen}>
              <TextLarge
                color={theme.colors.text.secondary}
                fontWeight={theme.fontWeight.medium}
                textAlign="start"
              >
                {t('synthCost.synthesis_cost_description')}
              </TextLarge>
            </HeaderWrapper>
            <CustomButton
              gap="0.25rem"
              type="text"
              onClick={() => setHeaderOpen((prev) => !prev)}
            >
              {headerOpen ? t('synthCost.less') : t('synthCost.more')}
              <ArrowDownSvg
                iconType="arrowDown"
                size="1rem"
                opened={headerOpen}
              />
            </CustomButton>
          </Column>
          <Content isSidebarCollapsed={isSidebarCollapsed}>
            <ProductForm
              {...{
                loading,
                settings,
                handleReset,
                isDataLoaded,
                handleChange,
                handlePredict,
                isSidebarCollapsed,
              }}
            />
            <Row gap="1rem" className="hidden-for-print">
              {CANVAS_ARR.map((type) => {
                const isSmilesExists =
                  type === 'product'
                    ? !!product && productView?.current?.draw
                    : !!reagent && reagentView?.current?.draw
                return (
                  <MoleculeWrapper
                    key={type}
                    onClick={() => setShowKetcher(type)}
                    width={`${SMILES_SIZE.width}px`}
                    height={`${SMILES_SIZE.height}px`}
                    isSmilesExists={isSmilesExists}
                  >
                    <Tag type={isSmilesExists ? 'grey' : 'white'}>
                      <Caption color="inherit">
                        {type === 'reagent'
                          ? t(`synthCost.reagent_capital`)
                          : t(`synthCost.${type}`)}
                      </Caption>
                    </Tag>
                    <SmilesSvg
                      ref={type === 'product' ? productView : reagentView}
                      isSmilesExists={isSmilesExists}
                    />

                    <EditButton type="text">
                      <Icon iconType="molecularEditor" />
                      <div>{t('synthCost.edit')}</div>
                    </EditButton>

                    <DrawContainer>
                      <SvgWrapper>
                        <Icon iconType={'molecularEditor'} size="1.25rem" />
                      </SvgWrapper>
                      <Subheadline>{t('synthCost.draw')}</Subheadline>
                    </DrawContainer>
                  </MoleculeWrapper>
                )
              })}
            </Row>
          </Content>
          {!!showKetcher && (
            <Ketcher
              closeKetcher={() => setShowKetcher('')}
              smiles={showKetcher === 'product' ? product : reagent}
              handleData={(smiles) => handleChange(showKetcher, smiles)}
            />
          )}
          {isDataLoaded && (
            <>
              <Headline
                fontWeight={theme.fontWeight.semibold}
                color={theme.colors.text.primary}
                className="hidden-for-print"
                style={{ marginTop: '-0.25rem' }}
              >
                {t('synthCost.calculated_results')}
              </Headline>
              {isNothingToShow ? (
                <Subheadline>{t('synthCost.searched_nothing')}</Subheadline>
              ) : (
                <>
                  <SwitchBlock className="hidden-for-print">
                    <Schemes>
                      {processedHistoryTable?.map((_, ind) => {
                        return (
                          <Scheme
                            selected={selectedScheme === ind}
                            key={uuidv4()}
                            onClick={() => {
                              setSelectedScheme(ind)
                              setSelectedStage(0)
                              if (allowAnimate === false) {
                                setAllowAnimate && setAllowAnimate(true)
                              }
                            }}
                          >
                            <Label
                              className={!isSidebarCollapsed ? 'hidden' : ''}
                              color={
                                selectedScheme === ind
                                  ? theme.colors.text.accentPrimary
                                  : theme.colors.text.secondary
                              }
                            >
                              {t('synthCost.Scheme')}
                            </Label>
                            <Label
                              color={
                                selectedScheme === ind
                                  ? theme.colors.text.accentPrimary
                                  : theme.colors.text.secondary
                              }
                            >
                              {ind + 1}
                            </Label>
                          </Scheme>
                        )
                      })}
                    </Schemes>
                    <StyledButton
                      onClick={handleContextMenu}
                      noPadding
                      onMouseEnter={handleContextMenu}
                      className="hidden-for-print"
                    >
                      {t('synthCost.download')} {t('synthCost.all_schemes')}
                      <Icon iconType="upload" size="1rem" />
                    </StyledButton>
                  </SwitchBlock>
                  <ResultsWrapper
                    id={`synthcost-scheme-${selectedScheme}`}
                    downloadAllInPdfClicked={downloadAllInPdfClicked}
                  >
                    <Column
                      gap="12px"
                      margin="1.25rem"
                      className={
                        downloadAllInPdfClicked ? 'hidden-for-print' : ''
                      }
                    >
                      <Row justify="space-between" height="1.5rem">
                        {!!reactionSourceArr.length && (
                          <Subheadline className="source">
                            <StyledButton
                              noPadding
                              onClick={() => {
                                setIsReactionSourceShow((prev) => !prev)
                              }}
                            >
                              {t('synthCost.reaction_scheme_source')}
                              <Icon iconType="arrowRight" size="1rem" />
                            </StyledButton>
                          </Subheadline>
                        )}
                        <StageTag noCursor>
                          <Caption
                            fontWeight={theme.fontWeight.medium}
                            color={theme.colors.text.primary}
                          >
                            {t('synthCost.overall')} {reagentsCount}{' '}
                            {getReagentsTitle(reagentsCount)}
                          </Caption>
                        </StageTag>
                      </Row>
                      <Box>
                        <Row justify="space-between" width="100%">
                          <StagesBlock>
                            {processedHistoryTable[selectedScheme]?.map(
                              (_, ind) => (
                                <StageTag
                                  key={uuidv4()}
                                  selected={selectedStage === ind}
                                  onClick={() => setSelectedStage(ind)}
                                >
                                  <Label
                                    color={
                                      selectedStage === ind
                                        ? theme.colors.text.white
                                        : theme.colors.text.secondary
                                    }
                                  >
                                    {ind + 1}
                                  </Label>
                                </StageTag>
                              )
                            )}
                          </StagesBlock>
                          <ButtonsBlock>
                            {' '}
                            <CustomButton
                              type="secondary"
                              disabled={selectedStage === 0}
                              defaultPadding="0.375rem"
                              height="1.75rem"
                              borderRadius="0.5rem"
                              onClick={() =>
                                setSelectedStage((prev) => prev - 1)
                              }
                            >
                              <Icon iconType="arrowLeft" size="1rem" />
                            </CustomButton>
                            <CustomButton
                              type="secondary"
                              defaultPadding="0.375rem"
                              height="1.75rem"
                              borderRadius="0.5rem"
                              onClick={() =>
                                setSelectedStage((prev) => prev + 1)
                              }
                              disabled={
                                selectedStage ===
                                smirks[selectedScheme].length - 1
                              }
                            >
                              <Icon iconType="arrowRight" size="1rem" />
                            </CustomButton>
                          </ButtonsBlock>
                        </Row>
                      </Box>
                    </Column>
                    <Reaction
                      {...{
                        smirks,
                        selectedStage,
                        selectedScheme,
                        setSelectedStage,
                      }}
                      initialId={smirks[selectedScheme]
                        .slice(0, selectedStage)
                        .reduce((acc, curr) => curr.reagents.length + acc, 0)}
                      reagents={
                        smirks[selectedScheme][selectedStage]?.reagents || []
                      }
                      product={
                        smirks[selectedScheme][selectedStage]?.products[0] || ''
                      }
                      downloadAllInPdfClicked={downloadAllInPdfClicked}
                    />
                    {!downloadAllInPdfClicked && (
                      <SynthCostTable
                        {...{
                          selectedScheme,
                          downloadAllInPdfClicked,
                        }}
                        savedSchemes={schemes}
                        onDownload={handleDownload}
                        setSavedSchemes={setSchemes}
                        isSidebarCollapsed={isSidebarCollapsed}
                        allowAnimate={allowAnimate}
                        setAllowAnimate={setAllowAnimate}
                        defaultValues={defaultValues}
                        setDefault={setDefault}
                      />
                    )}
                  </ResultsWrapper>
                  {downloadAllInPdfClicked && (
                    <AllSchemes
                      data={schemes}
                      allowAnimate={allowAnimate}
                      setAllowAnimate={setAllowAnimate}
                    />
                  )}
                </>
              )}
            </>
          )}
        </Container>
      </CustomScrollbar>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    synthcost: state.synthcost,
    isSidebarCollapsed: state.settings.isSidebarCollapsed,
  }
}

const mapDispatchToProps = {
  predict,
  removeTask,
  saveSettings,
  resetSettings,
  resetPrediction,
  addNotification,
  addSimpleNotification,
  resetSynthCostError,
}

export default connect(mapStateToProps, mapDispatchToProps)(SynthCost)
