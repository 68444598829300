import { t } from 'i18next'

export const getProgressNotifIcon = (isLongTaskReady, isLongTaskError) => {
  if (isLongTaskReady) return 'success'
  if (isLongTaskError) return 'error'
  return 'deleted'
}

export const getNeedToDeleteTitle = (name, text) => {
  if (name.includes(t('dataset_table.calculating_data')))
    return `${t('dataset_table.cancel_calculating_data')}${name.replace(
      t('dataset_table.calculating_data'),
      ''
    )}?`
  if (name.includes(t('synthCost.synth_cost')))
    return t('synthCost.cancel_calculating')
  if (name.includes(t('baskets.calculating')))
    return t('baskets.tasks_status.cancel_set_calculation', {
      name: text,
    })
  if (name.includes(t('synmap.copy_molecules')))
    return t('synmap.cancel_copy_molecules')
  if (name.includes(t('search.search_by_structures_in_progress')))
    return t('search.cancel_search_by_structures')
  return `${t('notifications.cancel')}?`
}

export const getContinueBtnName = (name) => {
  if (name.includes(t('synthCost.synth_cost')))
    return t('notifications.continue_task.calculating')
  if (name.includes(t('baskets.calculating')))
    return t('notifications.continue_task.calculation')
  if (name.includes(t('synmap.copy_molecules')))
    return t('notifications.continue_task.coping')
  if (name.includes(t('search.search_by_structures_in_progress')))
    return t('notifications.continue_task.search')
  return `${t('notifications.continue_task.continue')}`
}
