import styled, { css } from 'styled-components'

export const IdWithIconButtons = styled.div`
  ${({ isBestMatch }) =>
    !isBestMatch &&
    css`
      width: 100%;
      padding: ${({ noPadding }) => (noPadding ? '16px' : '20px 20px 0px')};
      position: ${({ noPadding }) => (noPadding ? 'absolute' : 'relative')};
      flex-direction: row;
    `}

  display: flex;
  padding: 1rem;
  position: relative;
  flex-direction: row;
  align-items: center;
  align-items: ${({ isBestMatch }) => (isBestMatch ? 'start' : 'center')};
  justify-content: ${({ isDialog }) => (isDialog ? 'start' : 'space-between')};
`

export const IdsBlock = styled.div`
  display: flex;
  gap: 0.5rem;
  padding-top: ${({ isBestMatch }) => isBestMatch && '11px'};
`
