import styled from 'styled-components'

export const FilterRadioListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
`

export const FilterRadioListTitle = styled.span`
  font-size: ${({ fontSize }) => (fontSize ? fontSize : '11px')};
  font-weight: ${({ fontWeight }) => (fontWeight ? fontWeight : '400')};
  line-height: ${({ lineHeight }) => (lineHeight ? lineHeight : '0.75rem')};
  color: ${({ theme }) => theme.colors.text.secondary};
`

export const FilterRadioItems = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
`

export const FilterRadioItem = styled.div`
  display: flex;
  align-items: center;
  column-gap: 0.5rem;
  cursor: ${({ disabled }) => (disabled ? 'wait' : 'pointer')};
`

export const FilterRadioItemName = styled.span`
  font-size: ${({ fontSize }) => (fontSize ? fontSize : '0.75rem')};
  font-weight: ${({ fontWeight }) => (fontWeight ? fontWeight : '400')};
  line-height: ${({ lineHeight }) => (lineHeight ? lineHeight : '1rem')};
  color: ${({ selected }) => (selected ? '#2D3745' : '#6E809D')};
`

export const FilterRadioBtns = styled.div`
  width: 100%;
  padding: 0.25rem;
  border-radius: 0.75rem;
  background: #f5f7f7;

  display: flex;
`
export const FilterRadioBtn = styled.button`
  flex: 1;
  border: none;
  outline: none;
  background: ${({ selected }) => (selected ? '#42C1BF' : 'transparent')};
  color: ${({ selected }) => (selected ? '#ffffff' : '#616E7C')};
  padding: 0.375rem 0.5rem;
  border-radius: 0.5rem;
  cursor: pointer;
  transition: all 0.3s;
  box-shadow: ${({ selected, theme }) =>
    selected ? theme.shadow.checkbox : ''};

  &:hover {
    background: ${({ selected }) => (selected ? '#3AA9A7' : 'transparent')};
    color: ${({ selected }) => (selected ? '#ffffff' : '#42C1BF')};
  }
`
