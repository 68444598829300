import { useTranslation } from 'react-i18next'

import CustomInput from 'components/common/customInput'
import CustomToggle from 'components/common/customToggle'
import { TEST_PERIOD } from 'components/RegistrationPageNew/config'

import { ToggleContainer } from './index.style'

const LicenseKeyBlock = ({ isLicense, licenseKey, onChange, licenseError }) => {
  const { t } = useTranslation()
  return (
    <>
      <ToggleContainer>
        <CustomToggle
          label={t('registration.product_key')}
          withTooltip
          type="grey"
          tooltipText={t('registration.if_no_key', {
            count: TEST_PERIOD,
          })}
          value={isLicense}
          onClick={(e) => {
            e.preventDefault()
            onChange(!isLicense, 'is_license')
          }}
        />
      </ToggleContainer>
      {isLicense && (
        <CustomInput
          error={licenseError}
          label={t('registration.license_key')}
          placeholder={t('registration.license_key_placeholder')}
          value={licenseKey}
          withClearButton
          onChange={(value) => onChange(value, 'license_key')}
        />
      )}
    </>
  )
}
export default LicenseKeyBlock
