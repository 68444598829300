export const NETWORKS_CONFIG = [
  {
    icon: 'telegram',
    link: 'https://t.me/syntellyplatform',
  },
  { icon: 'vk', link: 'https://vk.com/syntelly' },

  {
    icon: 'youtube',
    link: 'https://www.youtube.com/channel/UCiRKqhliOJ0OZBGUmP5ee8w',
  },
]
