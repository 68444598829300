import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const SearchBlock = styled.div`
  display: flex;
  border: 1.6px solid #42c1bf;
  background: #ffffff;
  width: 100%;
  flex: 1;
  border-radius: 0.75rem;
  position: relative;
  padding: 0.5rem;

  & > svg {
    color: #cbd2d9;
    opacity: ${({ focused }) => (focused ? '0' : '1')};
    width: ${({ focused }) => (focused ? '0' : 'unset')};
    margin-right: ${({ focused }) => (focused ? '0' : '1rem')};
    transition: all 0.3s;
    align-self: center;
  }

  & > input {
    margin-right: ${({ focused }) => (focused ? '1rem' : '0')};
  }
`

export const InputBlockBtns = styled.div`
  display: flex;
  align-items: center;
`
export const InputBlockBtnCross = styled.button`
  display: flex;
  background: #f5f7f7;
  outline: none;
  border: none;
  border-radius: 0.25rem;
  align-items: center;
  cursor: pointer;
  opacity: ${({ visible }) => (visible ? '1' : '0')};
  width: ${({ visible }) => (visible ? 'unset' : '0')};
  margin-right: ${({ visible }) => (visible ? '0.5rem' : '0')};
  padding: ${({ visible }) => (visible ? '0.375rem' : '0.375rem 0')};
  transition: all 0.3s;

  & > svg {
    width: 1.25rem;
    height: 1.25rem;
    color: #cbd2d9;
  }

  &:hover {
    & > svg {
      color: #42c1bf;
      transition: color 0.3s;
    }
  }
`
export const InputBlockBtnSearch = styled.button`
  display: flex;
  background: #f5f7f7;
  outline: none;
  border: none;
  border-radius: 0.25rem;
  align-items: center;
  cursor: pointer;
  opacity: ${({ focused }) => (focused ? '1' : '0')};
  width: ${({ focused }) => (focused ? 'unset' : '0')};
  padding: ${({ focused }) => (focused ? '0.375rem' : '0.375rem 0')};
  transition: all 0.3s;

  & > svg {
    width: 1.25rem;
    height: 1.25rem;
    color: #cbd2d9;
  }

  &:hover {
    & > svg {
      color: #42c1bf;
      transition: color 0.3s;
    }
  }
`

export const InputBlock = styled.input`
  display: flex;
  border: none;
  outline: none;
  width: 100%;
  padding: 0;
  line-height: 1rem;

  &::placeholder {
    color: #cbd2d9;
  }
`

export const VariantBlock = styled.div`
  position: absolute;
  top: calc(100% + 5px);
  left: 0;
  width: 100%;
  background-color: #fff;
  z-index: 10;
  border-radius: 0.75rem;
  box-shadow: ${({ theme }) => theme.shadow.default};
`

export const VariantWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  cursor: pointer;
  padding: 8px 20px;

  &:first-child {
    padding-top: 20px;
  }

  &:last-child {
    padding-bottom: 20px;
  }

  &:hover {
    background: rgba(196, 196, 196, 0.2);
  }
`
export const VariantElement = styled.div``

export const FlexBlock = styled.div`
  display: flex;
  width: 100%;
  height: 2.75rem;
`
