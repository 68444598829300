import { useEffect, useRef, useState } from 'react'
import { CanvasPreviewItem, PageIdentificator } from '../index.style'
import './index.css'

const CanvasPreviewElement = ({
  pdfDocument,
  page,
  changePageHandler,
  selectedPage,
  setSelectedPage,
  id,
}) => {
  const canvasRef = useRef(null)
  const renderPageSnapshot = (pageNumber, canvas) => {
    pdfDocument.getPage(pageNumber).then(function (page) {
      const viewport = page.getViewport({ scale: 1 })
      canvas.height = viewport.height
      canvas.width = viewport.width
      page.render({
        canvasContext: canvas.getContext('2d'),
        viewport: viewport,
      })
    })
  }

  useEffect(() => {
    if (canvasRef.current) {
      renderPageSnapshot(page, canvasRef.current)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page])

  return (
    <CanvasPreviewItem
      selected={page === selectedPage}
      id={id}
      onClick={() => {
        changePageHandler(page)
        setSelectedPage(page)
      }}
    >
      <canvas ref={canvasRef} className="pdf-preview-canvas" />
      <PageIdentificator selected={page === selectedPage}>
        {page}
      </PageIdentificator>
    </CanvasPreviewItem>
  )
}

CanvasPreviewElement.displayName = 'CanvasPreviewElement'
export default CanvasPreviewElement
