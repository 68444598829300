export const getApplicabilityColors = (value, asHotTip) => {
  if (value === null)
    return {
      color: asHotTip ? '#FFFFFF' : '#2E90FA',
      backgroundColor: asHotTip ? '#2E90FA' : '#2E90FA29',
    }
  if (value <= 0.2)
    return {
      color: asHotTip ? '#FFFFFF' : '#F04438',
      backgroundColor: asHotTip ? '#F04438' : '#F0443829',
    }
  if (value <= 0.5)
    return {
      color: asHotTip ? '#FFFFFF' : '#F79009',
      backgroundColor: asHotTip ? '#F79009' : '#F7900929',
    }
  return {
    color: asHotTip ? '#FFFFFF' : '#12B76A',
    backgroundColor: asHotTip ? '#12B76A' : '#12B76A29',
  }
}
