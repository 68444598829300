import { animated } from 'react-spring'
import styled, { css, keyframes } from 'styled-components'

import CustomButton from 'components/common/customButton'

const compressedItemStyles = css`
  overflow: hidden;
  padding: 0rem;
  opacity: 1;
  height: 2rem;
  scale: 1;

  & div {
    opacity: 0;
    transition: opacity 0.5s ease in-out;
  }
`

export const CloseBtn = styled.button`
  display: flex;
  align-items: center;
  position: absolute;
  right: -0.5rem;
  top: -0.5rem;
  border: none;
  outline: none;
  z-index: 10001;
  border-radius: 50%;
  background: rgba(243, 245, 248, 0.84);
  padding: 0.25rem;
  box-shadow: ${({ theme }) => theme.shadow.blur};

  opacity: 0;
  transition: opacity 0.3s;
  cursor: pointer;

  & > svg {
    color: ${({ theme }) => theme.colors.icons.tertiary};
    width: 1rem;
    height: 1rem;
  }
  &:hover {
    svg {
      color: ${({ theme }) => theme.colors.icons.accentPrimary};
    }
  }
`

export const NotificationElem = styled(animated.div)`
  position: sticky;
  width: 100%;
  padding: 0rem;
  gap: 1rem;
  cursor: pointer;
  background: rgba(243, 245, 248, 0.84);
  border: ${({ theme }) => `1.2px solid ${theme.colors.icons.white}`};
  box-shadow: ${({ theme }) => theme.shadow.blur};
  transition: all 0.5s ease-in-out;
  border-radius: 0.75rem;
  justify-content: center;
  user-select: none;
  * {
    font-family: 'Geologica Cursive' !important;
  }
  &.hidden {
    display: none;
  }
  ${({ isRemoved }) =>
    isRemoved &&
    css`
      margin-bottom: -2rem;
      height: 1rem !important;
      overflow: hidden !important;
      opacity: 0 !important;
      transition: all 0.5s ease !important;
      pointer-events: none !important;
      ${CloseBtn} {
        display: none;
      }
    `}
`

export const NotificationInner = styled.div`
  display: flex;
  row-gap: 0.5rem;
  border-radius: 0.75rem;
  flex-direction: column;
  backdrop-filter: blur(30px) !important;
`

const hideAnimation = keyframes`
from {
	opacity: 1;
	bottom: 1.5rem;
	
}
to {
	bottom: -3rem;
	opacity: 0;
}
`

const openAnimation = keyframes`
from {
	bottom: -3rem;
	opacity: 0;
};
to {
	opacity: 1;
	bottom: 1.5rem;
}
`

export const NotificationsWrapper = styled.div`
  position: fixed;
  z-index: 10000;
  bottom: 0rem;
  right: 0;
  display: flex;
  pointer-events: none;
  justify-content: center;
  width: 100%;
  animation: ${({ isHidden }) => (isHidden ? hideAnimation : openAnimation)} 1s
    forwards;
`

export const NotificationsList = styled.div`
  display: flex;
  flex-direction: column-reverse;
  width: 100%;
  pointer-events: auto;
  align-items: center;
  & ${NotificationElem} {
    & div {
      opacity: 0;
    }
  }
  max-height: calc(100vh - 7.25rem);
  overflow-y: ${({ isCompressed, isHidden, count }) =>
    isHidden ? 'hidden' : isCompressed || count === 1 ? 'visible' : 'scroll'};

  padding: 1rem;
  padding-bottom: 0.25rem;

  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  ${({ itemsCompressed, count }) =>
    !itemsCompressed && count !== 1
      ? css`
          & ${NotificationElem} {
            height: unset;
            scale: 1;
            margin-bottom: 0.75rem;
            bottom: 0;
            & div {
              opacity: 1;
              transition: opacity 0.5s ease-in-out;
            }

            &:hover {
              & ${CloseBtn} {
                opacity: 1;
              }
            }
            &:first-child {
              margin-bottom: 0;
              bottom: 0;
            }
          }
        `
      : css`
          & ${NotificationElem} {
            padding: 0;
            opacity: 0;
            height: 0;
            scale: 0.3;
            & div {
              opacity: 0;
            }

            &:nth-last-child(5) {
              ${compressedItemStyles}
              margin-top: -1.5rem;
              width: 60%;
            }

            &:nth-last-child(4) {
              ${compressedItemStyles}
              margin-top: -1.5rem;
              width: 70%;
            }

            &:nth-last-child(3) {
              ${compressedItemStyles}
              margin-top: -1.5rem;
              width: 80%;
            }

            &:nth-last-child(2) {
              ${compressedItemStyles}
              margin-top: -1.5rem;
              width: 90%;
            }

            &:last-child {
              opacity: 1;
              transition: none;
              div {
                opacity: 1;
                transition: none;
              }
              scale: 1;

              height: unset;
              width: 100%;
              padding: 0rem;

              &:hover {
                & ${CloseBtn} {
                  opacity: 1;
                  transition: opacity 0.7s;
                }
              }
            }
          }
        `}
`

export const NotificationsContainer = styled.div`
  width: 25.75rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: relative;
  top: 0.25rem;
`

export const NotificationBtns = styled.div`
  padding: 1rem;
  display: flex;
  column-gap: 0.5rem;
  border-radius: 0 0 0.75rem 0.75rem;
  border-top: ${({ theme }) => `1.2px solid ${theme.colors.icons.white}`};
  background-color: ${({ theme }) => theme.colors.backgrounds.contextMenuHover};
`

export const GoToBasketBtn = styled(CustomButton)`
  color: ${({ theme }) => theme.colors.text.primary};
`
