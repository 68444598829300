import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  height: 100%;
`

export const Header = styled.div`
  width: 100%;
  padding: 1.125rem 1.5rem 1.5rem 0.75rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const Toolbar = styled.div`
  width: 100%;
  display: flex;
  justify-content: end;
  align-items: center;
`

export const Tools = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`

export const Documents = styled.div`
  width: 100%;
  min-height: 100%;
  max-height: calc(100% - 128px);
  display: flex;
  column-gap: 12px;
  row-gap: 16px;
  flex-wrap: wrap;
  padding: 0 12px 12px 12px;
`

export const FileUploadInput = styled.input`
  display: none;
`

export const P2SDropzoneWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  border-radius: 12px;
  background: rgba(236, 249, 249, 1);
  box-shadow: ${({ theme }) => theme.shadow.default};
`
