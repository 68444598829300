import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
`
export const Indicators = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`

export const IndicatorRow = styled.div`
  display: flex;
  gap: 0.75rem;
  align-items: center;
`

export const Indicator = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.375rem;
  padding: 0.3125rem 0.25rem;
  width: 2.375rem;
  background: ${({ background }) => background};
`
