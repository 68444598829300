import styled from 'styled-components'

import CustomButton from 'components/common/customButton'
import { Label, Headline } from 'components/common/text/index.style'

export const CardWrapper = styled.div`
  padding: ${({ padding }) => (padding ? padding : '1.25rem')};
  max-width: 100%;
  min-width: 100%;
  display: flex;
  min-height: ${({ minHeight }) => minHeight && minHeight};
  flex-direction: column;
  gap: ${({ gap }) => (gap ? gap : '0.75rem')};
  position: relative;
  border-radius: 0.75rem;
  background: ${({ theme }) => theme.colors.backgrounds.primary};

  cursor: ${({ isFullMode }) => (isFullMode ? 'default' : 'pointer')};

  &:hover {
    .header-title {
      color: ${({ theme }) => theme.colors.text.accentPrimary};
    }
  }

  em {
    font-style: normal;
    color: ${({ theme }) => theme.colors.text.accentPrimary};
    background: ${({ theme, isDialog }) =>
      isDialog
        ? `${theme.colors.backgrounds.accentSecondary}40`
        : theme.colors.backgrounds.white};
    border-radius: 0.375rem;
  }
`

export const CardHeader = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  max-width: 95%;
`

export const Header = styled(Headline)`
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  max-width: calc(100% - 4.5rem);
  font-weight: ${({ theme }) => theme.fontWeight.semibold};
`

export const Row = styled.div`
  display: flex;
  align-items: center;
  gap: 0.25rem;
`

export const TypeRow = styled(Row)`
  & > svg:not(.copy-name-icon) {
    color: ${({ theme }) => theme.colors.statuses.link};
    cursor: pointer;
  }
  .copy-name-icon {
    color: ${({ theme }) => theme.colors.icons.tertiary};

    &:hover {
      cursor: pointer;
      color: ${({ theme }) => theme.colors.icons.accentPrimary};
    }
  }
`

export const TypeNumber = styled(Label)`
  color: ${({ theme, isLink }) =>
    isLink ? theme.colors.statuses.link : theme.colors.text.primary};
  text-decoration: none;
  & a {
    color: inherit;
    text-decoration: inherit;
  }
`

export const CardInfo = styled.div`
  margin-top: ${({ marginTop }) => (marginTop ? marginTop : '0rem')};
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`

export const FooterButtons = styled.div`
  gap: 0.75rem;
  display: flex;
  align-items: center;
`

export const InfoRow = styled(Row)`
  display: ${({ display }) => (display ? display : 'flex')};
  justify-content: start;
  font-size: 0.6875rem;
  font-weight: ${({ theme, fontWeight }) =>
    fontWeight ? fontWeight : theme.fontWeight.medium};
  line-height: 0.75rem;
  flex-wrap: wrap;

  & > div {
    color: ${({ theme }) => theme.colors.text.secondary};
  }
`

export const CardFooter = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: auto;
`

export const CardButton = styled(CustomButton)`
  width: 1.5rem;
  height: 1.5rem;
  color: ${({ theme }) => theme.colors.icons.tertiary};
`

export const CardRightButtons = styled.div`
  display: flex;
  gap: 1rem;
  top: 1rem;
  right: 1rem;
  position: absolute;
`

export const DialogCardAbstract = styled.div`
  font-size: 12px;
  font-weight: 300;
  line-height: 16px;
  color: #6e809d;
  cursor: inherit;
`

export const CardAbstract = styled(DialogCardAbstract)`
  max-height: 4rem;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
`

export const CaptionAuthors = styled.div`
  color: ${({ theme, color }) => (color ? color : theme.colors.text.secondary)};
  font-size: ${({ theme }) => theme.text[11]};
  font-weight: ${({ theme, fontWeight }) =>
    fontWeight ? fontWeight : theme.fontWeight.medium};
  line-height: ${({ theme }) => theme.text[12]};
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
`

export const DialogCardFullText = styled.div`
  font-size: ${({ theme, fontSize }) => (fontSize ? fontSize : theme.text[12])};
  font-weight: ${({ theme }) => theme.fontWeight.light};
  color: ${({ theme }) => theme.colors.text.secondary};
  line-height: ${({ lineHeight }) => (lineHeight ? lineHeight : '16px')};
  cursor: inherit;
`

export const DialogCardFullTextBlock = styled.div`
  display: flex;
  align-items: baseline;
  column-gap: 0.5rem;
  margin-top: 1rem;
  user-select: none;

  & span {
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 1.125rem;
    text-align: left;
    color: ${({ theme }) => theme.colors.text.primary};
  }
  & p {
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 1rem;
    margin: 0;
  }
`
export const FullTextSelectorBlock = styled.div`
  display: flex;
  align-items: center;
  column-gap: 0.25rem;
  color: ${({ theme, showFullText }) =>
    showFullText ? theme.colors.text.accentPrimary : theme.colors.text.primary};
  cursor: pointer;
  transition: color 0.3s ease;
  &:hover {
    color: ${({ theme, showFullText }) => theme.colors.text.accentPrimary};
  }
`
