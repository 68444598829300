import CustomButton from 'components/common/customButton'
import { CustomDialog } from 'components/common/customDialog'
import { ButtonsWrapper } from './index.style'
import Icon from 'components/Icon'
import { useTranslation } from 'react-i18next'
import { Calendar } from 'react-multi-date-picker'
import '../../../styles/datePicker.css'
import {
  TextButtonMedium,
  TitleTertiary,
} from 'components/common/text/index.style'
import { useTheme } from 'styled-components'
import { gregorian_ru } from '../config'
import { useState } from 'react'

const RangeCalendar = ({ values, setValues, onClose }) => {
  const [dateValues, setDateValues] = useState(() => values ?? [])
  const { t, i18n } = useTranslation()
  const theme = useTheme()

  const handleConfirm = () => {
    setValues(dateValues)
    onClose()
  }

  return (
    <CustomDialog
      withPortal
      className="calendar-dialog"
      onClose={onClose}
      showCloseIcon={false}
      gap="1.25rem"
    >
      <TitleTertiary color={theme.colors.text.primary}>
        {t('calendar.select_date')}
      </TitleTertiary>
      <Calendar
        value={dateValues}
        onChange={setDateValues}
        monthYearSeparator={' '}
        range
        numberOfMonths={2}
        showOtherDays
        format="DD.MM.YYYY"
        locale={i18n.language === 'en' ? 'gregorian_en' : gregorian_ru}
        renderButton={(direction, handleClick) => (
          <CustomButton
            onClick={handleClick}
            type="icon_tertiary"
            padding="0.375rem"
            width="1.75rem"
            height="1.75rem"
            borderRadius="0.5rem"
          >
            <Icon
              iconType={direction === 'right' ? 'arrowRight' : 'arrowLeft'}
              size="1rem"
            />
          </CustomButton>
        )}
      >
        <ButtonsWrapper>
          <CustomButton type="secondary" width="100%" onClick={onClose}>
            <TextButtonMedium color="inherit">
              {t('notifications.admit.cancel')}
            </TextButtonMedium>
          </CustomButton>
          <CustomButton
            type="accent"
            width="100%"
            onClick={handleConfirm}
            disabled={!dateValues[0] || !dateValues[1]}
          >
            <TextButtonMedium color="inherit">
              {t('notifications.admit.confirm')}
            </TextButtonMedium>
          </CustomButton>
        </ButtonsWrapper>
      </Calendar>
    </CustomDialog>
  )
}

export default RangeCalendar
