import Icon from 'components/Icon'
import CustomButton from 'components/common/customButton'
import { Container } from './index.style'

const ActionButtons = ({ buttonsConfig, isDownloadMenuShow, margin }) => {
  return (
    <Container margin={margin}>
      {buttonsConfig?.map(({ icon, text, disabled, onClick, id }, i) => {
        return (
          <CustomButton
            type="text"
            key={i}
            onClick={(e) => {
              if (id !== 'download-structure') {
                e.stopPropagation()
                onClick(e)
              }
            }}
            onMouseOver={(e) => {
              if (id === 'download-structure') {
                e.stopPropagation()
                onClick(e)
              }
            }}
            gap="4px"
            disabled={disabled}
            active={icon === 'upload' && isDownloadMenuShow}
          >
            <Icon iconType={icon} size="1rem" />
            {text}
          </CustomButton>
        )
      })}
    </Container>
  )
}

export default ActionButtons
