import { useTranslation } from 'react-i18next'
import { Tooltip as ReactTooltip } from 'react-tooltip'
import { memo } from 'react'
import { connect, useDispatch } from 'react-redux'
import { push } from 'connected-react-router'

import {
  DocumentWrapper,
  DocumentImage,
  Container,
  DocumentCard,
  DocumentCardTopArea,
  DocumentName,
  DocumentArrow,
  DocumentCardBottomArea,
  DocumentTooltipName,
} from './index.style'
import { getCountNoun } from '../config/config'
import Icon from 'components/Icon'
import { Caption } from 'components/common/text/index.style'
import '../../../components/common/tooltip/style.css'
import { confirm } from 'store/actions/confirm'
import { addNotification } from 'store/actions/notifications'
import { setNeedUpdateDocuments } from 'store/actions/pdf2smiles'
import { handleShowContextMenu } from 'store/actions/contextMenu'
import CustomTag from 'components/common/customTag'
import CustomButton from 'components/common/customButton'
import { useTheme } from 'styled-components'

const Document = ({
  id,
  image,
  name,
  structuresCount = 0,
  pagesCount = 0,
  push,
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const theme = useTheme()

  return (
    <>
      <Container
        onClick={(e) => {
          e.stopPropagation()
          push(`/pdf2smiles/${id}`)
        }}
      >
        <DocumentWrapper>
          <DocumentImage
            src={image}
            alt={t('pdf2smiles.dashboard.document_preview')}
          />
        </DocumentWrapper>
        <DocumentCard>
          <DocumentCardTopArea>
            <CustomTag type={'grey'} height={'1.5rem'}>
              {t(
                `pdf2smiles.dashboard.structure.${getCountNoun(
                  structuresCount
                )}`,
                {
                  count: structuresCount,
                }
              )}
            </CustomTag>
            <CustomButton
              onClick={(e) => {
                e.stopPropagation()
                dispatch(
                  handleShowContextMenu({
                    e,
                    menu: 'pdf2smilesMenu',
                    item: { id },
                  })
                )
              }}
              type={'icon_primary'}
              width={'1.5rem'}
              height={'1.5rem'}
              defaultPadding="0"
            >
              <Icon iconType="threeDots" size={'1.25rem'} />
            </CustomButton>
          </DocumentCardTopArea>
          <div>
            <DocumentName fontWeight={500} data-tooltip-id={String(id)}>
              {name}
            </DocumentName>
            <ReactTooltip
              id={String(id)}
              className="c-tooltip c-tooltip-base"
              classNameArrow="c-tooltip-arrow"
              place={'bottom-start'}
            >
              <DocumentTooltipName>{name}</DocumentTooltipName>
            </ReactTooltip>
          </div>
          <DocumentCardBottomArea>
            <Caption
              color={theme.colors.text.secondary}
              lineHeight={theme.text[14]}
              fontWeight={theme.fontWeight.medium}
            >
              {t(`pdf2smiles.dashboard.page.${getCountNoun(pagesCount)}`, {
                count: pagesCount,
              })}
            </Caption>
            <DocumentArrow>
              <Icon iconType="arrowRight" size="1.25rem" />
            </DocumentArrow>
          </DocumentCardBottomArea>
        </DocumentCard>
      </Container>
    </>
  )
}

const mapDispatchToProps = {
  push,
  confirm,
  addNotification,
  setNeedUpdateDocuments,
}

export default connect(undefined, mapDispatchToProps)(memo(Document))
