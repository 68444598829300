import CustomButton from 'components/common/customButton'
import styled from 'styled-components'

const getSmilesViewSize = ({ variant }) => {
  if (variant === 'modal') return '160px'
  if (variant === 'medium') return '180px'
  return '156px'
}

export const SmilesViewWrapper = styled.div`
  display: flex;
  gap: 8px;
`

export const StyledSmilesView = styled.div`
  padding: ${({ variant }) => (variant === 'modal' ? '8px' : '0')};
  width: 50%;
  min-width: ${getSmilesViewSize};
  height: ${getSmilesViewSize};
  border-radius: 0.375rem;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  box-shadow: ${({ theme }) => theme.shadow.default};
  > .smiles-type-icon {
    position: absolute;
    width: 1.25rem;
    height: 1.25rem;
    color: #42c1bf;
    margin-right: auto;
    left: 0.5rem;
    bottom: 0.5rem;
  }
`

export const Image = styled.img`
  max-height: 100%;
  max-width: 100%;
  padding: ${({ variant }) =>
    variant === 'modal' ? '1.5rem 0.5rem' : '2rem 0.5rem'};
`

export const SmilesViewBar = styled.div`
  display: flex;
  width: calc(100% - 16px);
  align-items: center;
  justify-content: space-between;
  position: absolute;
  top: 8px;

  > svg {
    width: 20px;
    height: 20px;
    color: #42c1bf;
    margin-left: auto;
  }
`

export const Smiles = styled.div`
  display: flex;
  gap: 0.25rem;
  align-items: center;
`

export const Container = styled.div`
  padding: 0.5rem 0.5rem 1rem 0.5rem;
  row-gap: 1rem;
  border-radius: 0.75rem;
  display: flex;
  flex-direction: column;
  width: ${({ variant }) => (variant === 'modal' ? '352px' : 'auto')};
  background-color: ${({ variant, theme }) =>
    variant === 'modal'
      ? theme?.colors?.backgrounds?.contextMenu
      : theme?.colors?.backgrounds?.primary};
  ${SmilesViewWrapper} {
    gap: ${({ variant }) => (variant === 'modal' ? '8px' : '12px')};
  }
`

export const EditSmilesButton = styled(CustomButton)`
  background-color: #f3f5f8;
  color: #2d3745;
  padding: 10px;
  position: absolute;
  right: 0;
  bottom: 0;

  > svg {
    width: 16px;
    height: 16px;
  }

  &:active {
    background-color: #f3f5f8;
    color: #42c1bf;
  }
`

export const Buttons = styled.div`
  display: flex;
  gap: 0.5rem;
`

export const SmilesSvg = styled.svg`
  width: 9rem;
  height: 9rem;
`
