import { memo, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useTheme } from 'styled-components'
import { push } from 'connected-react-router'
import { useTranslation } from 'react-i18next'

import Icon from 'components/Icon'
import { removeTask } from 'store/actions/tasks'
import { selectBasket } from 'store/actions/basket'
import { cancelBingoSearch } from 'store/actions/crud'
import CustomButton from 'components/common/customButton'
import { resetSynthCostTask } from 'store/actions/synthcost'
import handleCancel from 'components/Notifications/utils/utils'
import { Label, TextMedium } from 'components/common/text/index.style'
import {
  GoToBasketBtn,
  NotificationBtns,
} from 'components/Notifications/index.style'

import {
  Buttons,
  IconContainer,
  TextContainer,
  HeaderWithIcon,
  ProgressBarBlock,
  TaskProgressBlock,
  RemoveTaskContainer,
  NotificationProgressBody,
  NotificationProgressContainer,
} from './index.style'
import {
  getContinueBtnName,
  getNeedToDeleteTitle,
  getProgressNotifIcon,
} from './utils/utils'
import { resetCalculations } from 'store/actions/reactions'

const ProgressBar = memo(
  ({ item, handleRemove, setIsClicked, isNeedToDelete, setIsNeedToDelete }) => {
    const withIcon = item?.withIcon ?? true
    const { t } = useTranslation()
    const theme = useTheme()
    const dispatch = useDispatch()

    const { notification_type, progress, status } = item || {}
    const isLongTaskReady =
      progress === 100 && status === 'ok' && notification_type === 'progress'

    const isLongTaskError =
      status === 'error' && notification_type === 'progress'

    const buttonsConfig = [
      {
        text: t('notifications.cancel'),
        type: 'secondary',
        onClick: (taskStatus) => {
          handleCancel(taskStatus)
          dispatch(removeTask(taskStatus.id))
          setIsNeedToDelete(false)
          if (taskStatus.name.includes(t('synthCost.synth_cost')))
            dispatch(resetSynthCostTask())
          if (
            taskStatus.name.includes(
              t('search.search_by_structures_in_progress')
            )
          )
            dispatch(cancelBingoSearch())
          if (taskStatus.name.includes(t('notification.retrosynthesis_start')))
            dispatch(resetCalculations())
        },
      },
      {
        text: getContinueBtnName(item.name),
        type: 'accent',
        onClick: () => {
          setIsClicked(false)
          setIsNeedToDelete(false)
        },
      },
    ]

    const title = getNeedToDeleteTitle(item.name, item?.text)
    const iconType = getProgressNotifIcon(isLongTaskReady, isLongTaskError)

    const handleGoToBasket = () => {
      dispatch(selectBasket(null))
      dispatch(push(`/molecules/${item.basket_id}`))
      handleRemove({ withConfirm: false })
    }

    useEffect(() => {
      if ((isLongTaskReady || isLongTaskError) && isNeedToDelete) {
        setIsNeedToDelete(false)
        setIsClicked(false)
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLongTaskError, isLongTaskReady, isNeedToDelete])

    return (
      <NotificationProgressContainer>
        <NotificationProgressBody
          withoutProgressBar={
            isNeedToDelete || isLongTaskError || isLongTaskReady
          }
        >
          {isNeedToDelete ? (
            <Label fontWeight={theme.fontWeight.semibold}> {title}</Label>
          ) : (
            <HeaderWithIcon>
              {(isLongTaskReady || isLongTaskError) && withIcon && (
                <IconContainer
                  isLongTaskReady={isLongTaskReady}
                  isLongTaskError={isLongTaskError}
                >
                  <Icon
                    iconType={iconType}
                    size="1.5rem"
                    data-test={`progress-bar-${iconType}-icon}`}
                  />
                </IconContainer>
              )}
              <TextContainer>
                <Label fontWeight={theme.fontWeight.semibold}>
                  {t(item.name)}
                </Label>
                {!!item.text && (
                  <TextMedium color={theme.colors.text.secondary}>
                    {t(item.text)}
                  </TextMedium>
                )}
              </TextContainer>

              {!(isLongTaskReady || isLongTaskError) &&
                handleRemove &&
                withIcon && (
                  <RemoveTaskContainer
                    onClick={() => handleRemove({ withConfirm: true })}
                  >
                    <Icon
                      size="1rem"
                      iconType={iconType}
                      data-test={`progress-bar-${iconType}-icon}`}
                    />
                  </RemoveTaskContainer>
                )}
            </HeaderWithIcon>
          )}
        </NotificationProgressBody>

        {!(isLongTaskReady || isLongTaskError) && (
          <TaskProgressBlock isNeedToDelete={isNeedToDelete}>
            {isNeedToDelete ? (
              <>
                <Buttons>
                  {buttonsConfig.map(({ text, type, onClick }, i) => (
                    <CustomButton
                      type={type}
                      onClick={() => onClick(item)}
                      width={'50%'}
                      key={i}
                    >
                      {text}
                    </CustomButton>
                  ))}
                </Buttons>
              </>
            ) : (
              <ProgressBarBlock>
                <span
                  style={{ width: `${Math.round(item.progress || 0)}%` }}
                ></span>
              </ProgressBarBlock>
            )}
          </TaskProgressBlock>
        )}
        {isLongTaskReady &&
          item?.basket_id &&
          item.name.includes(t('synmap.molecules_copied')) && (
            <NotificationBtns>
              <GoToBasketBtn
                type="secondary"
                width="100%"
                onClick={handleGoToBasket}
              >
                {t('notifications.go_to_set')}
              </GoToBasketBtn>
            </NotificationBtns>
          )}
      </NotificationProgressContainer>
    )
  }
)

ProgressBar.displayName = 'ProgressBar'
export default ProgressBar
