import { connect, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import * as FileSaver from 'file-saver'

import {
  Buttons,
  Container,
  EmptyHighlight,
  Header,
  Highlight,
  Highlights,
  TitleBlock,
} from './index.style'
import { TitleTertiary } from 'components/common/text/index.style'
import CustomButton from 'components/common/customButton'
import Icon from 'components/Icon'
import SmilesViewer from '../SmilesViewer'
import { addMolecule } from 'store/actions/crud'
import BasketsListModal from 'components/BasketsListModal'
import { InView } from 'react-intersection-observer'
import {
  deleteHighlights,
  setCurrentHighlightId,
  setSelectedHighlights,
  setSelectedSmiles,
} from 'store/actions/pdf2smiles'
import {
  HIGHLIGHTS_SIDEBAR_ID,
  HIGHLIGHT_IN_SIDEBAR_PREFIX,
  confirmDeleteHighlights,
  updateHashById,
} from '../config/config'
import { addNotification } from 'store/actions/notifications'
import {
  PDF2SMILES_DOWNLOAD_MARKUP_EXT,
  PDF2SMILES_DOWNLOAD_MARKUP_OPTIONS,
  PDF2SMILES_DOWNLOAD_MARKUP_URL,
} from 'pages/Pdf2Smiles/config/config'
import { store } from 'services/rest'
import { useParams } from 'react-router-dom'
import { confirm } from 'store/actions/confirm'
import { handleShowContextMenu } from 'store/actions/contextMenu'
import CustomScrollbar from 'components/CustomScrollbar'
import { useTheme } from 'styled-components'

const HighlightsSidebar = ({
  isSidebarCollapsed,
  addMolecule,
  setCurrentHighlightId,
  pdf2Smiles,
  addNotification,
  setSelectedHighlights,
  setSelectedSmiles,
}) => {
  const {
    currentHighlightId,
    markup: highlights,
    filename,
    selectedHighlights,
    selectedSmiles,
  } = pdf2Smiles
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { id: document_id } = useParams()
  const [showSaveDialog, setShowSaveDialog] = useState(false)
  const theme = useTheme()

  const handleSelect = (e, toIdx, id) => {
    const copySelected = [...selectedHighlights]
    if (e.ctrlKey || e.metaKey) {
      setSelectedHighlights(
        copySelected.includes(id)
          ? [...copySelected.filter((s) => s !== id)]
          : [...copySelected, id]
      )
      return
    }

    if (e.shiftKey) {
      let res
      if (copySelected.length === 0) {
        res = highlights.slice(0, toIdx + 1).map(({ id }) => id)
      } else {
        const fromId = copySelected.pop()
        const fromIdx = highlights.findIndex((h) => h.id === fromId)
        const maxIdx = Math.max(toIdx, fromIdx)
        const minIdx = Math.min(toIdx, fromIdx)

        let list = highlights.slice(minIdx, maxIdx + 1).map(({ id }) => id)

        res = [...new Set([...copySelected, ...list])]
      }

      setSelectedHighlights([...res])
      return
    }

    setCurrentHighlightId(id)
    updateHashById(id)
  }

  const saveMolecules = (baskets) => {
    if (!baskets.length) return

    let smilesList
    if (selectedSmiles) {
      smilesList = [{ smiles: selectedSmiles }]
    } else {
      smilesList = Array.from(
        new Set(
          (selectedHighlights.length
            ? highlights.filter(({ id }) => selectedHighlights.includes(id))
            : highlights
          ).map(({ structure }) => structure.smiles[0])
        )
      ).map((smiles) => ({
        smiles,
      }))
    }

    baskets.forEach((basket) => {
      addMolecule(
        basket.id,
        smilesList,
        false,
        basket.name,
        t,
        false,
        selectedHighlights.length > 0
      )
    })
    setSelectedHighlights([])
  }

  const download = async (type) => {
    const id = uuidv4()
    const startNotify = {
      id,
      name: 'pdf2smiles.viewer.export_in_progress',
      text: 'pdf2smiles.viewer.export_in_progress_small',
      notification_type: 'success',
      autoRemove: true,
      timeout: 5000,
    }
    addNotification(startNotify)

    try {
      const { data } = await store(
        PDF2SMILES_DOWNLOAD_MARKUP_URL,
        {
          params: {
            document_id,
            type,
          },
        },
        {},
        PDF2SMILES_DOWNLOAD_MARKUP_OPTIONS
      )
      if (!data) {
        throw Error('No file in responce')
      }

      FileSaver.saveAs(
        data,
        `${filename || 'pdf2smiles'}.${PDF2SMILES_DOWNLOAD_MARKUP_EXT[type]}`
      )
    } catch (e) {
      const id = uuidv4()
      const notify = {
        id,
        name: 'notification.error',
        notification_type: 'error',
        text: 'pdf2smiles.viewer.failed_to_download_structures',
        autoRemove: true,
        timeout: 5000,
      }
      addNotification(notify)
    }
  }

  const selectedHighlightsBtns = [
    {
      text: 'pdf2smiles.viewer.reset_selection',
      icon: 'unselect',
      onClick: () => setSelectedHighlights([]),
    },
    {
      text: 'pdf2smiles.viewer.delete_selected',
      icon: 'deleted',
      onClick: () => confirmDeleteHighlights(selectedHighlights),
    },
  ]
  const handleOpenContextMenu = (e) => {
    e.stopPropagation()
    dispatch(
      handleShowContextMenu({
        e,
        menu: 'highlightsSidebarMenu',
        item: { handler: download },
      })
    )
  }

  return (
    <>
      <Container isSidebarCollapsed={isSidebarCollapsed}>
        <Header>
          <TitleBlock>
            <TitleTertiary fontWeight={theme.fontWeight.semibold}>
              <span>{`${t('pdf2smiles.viewer.structures')} `}</span>
              <sup>{highlights.length}</sup>
            </TitleTertiary>
            <Buttons>
              <CustomButton
                type={'text'}
                onClick={handleOpenContextMenu}
                gap={'0.25rem'}
              >
                <Icon iconType={'upload'} size={'1rem'} />
                <span>{t('pdf2smiles.viewer.download')}</span>
              </CustomButton>
              <CustomButton
                type={'text'}
                onClick={() => setShowSaveDialog(true)}
                gap={'0.25rem'}
              >
                <Icon iconType={'add'} size={'1rem'} />
                <span>
                  {t(
                    selectedHighlights.length === 0
                      ? 'pdf2smiles.viewer.save_all'
                      : 'pdf2smiles.viewer.save_selected'
                  )}
                </span>
              </CustomButton>
            </Buttons>
          </TitleBlock>
          {!!selectedHighlights.length && (
            <Buttons>
              {selectedHighlightsBtns.map(({ text, icon, onClick }, i) => (
                <CustomButton
                  key={i}
                  type={'text'}
                  onClick={onClick}
                  gap={'0.375rem'}
                >
                  <Icon iconType={icon} size={'1rem'} />
                  <span>{t(text)}</span>
                </CustomButton>
              ))}
            </Buttons>
          )}
        </Header>
        <CustomScrollbar className="margin-4-right">
          <Highlights id={HIGHLIGHTS_SIDEBAR_ID}>
            {highlights.map(({ image_url, structure, id }, idx) => (
              <InView key={id}>
                {({ inView, ref }) => (
                  <div ref={ref}>
                    {inView ? (
                      <Highlight
                        onClick={(e) => handleSelect(e, idx, id)}
                        isSelected={selectedHighlights.includes(id)}
                        id={`${HIGHLIGHT_IN_SIDEBAR_PREFIX}${id}`}
                        isCurrent={id === currentHighlightId}
                        key={id}
                      >
                        <SmilesViewer
                          image={image_url}
                          smiles={structure.smiles[0]}
                          score={structure.score}
                          variant={isSidebarCollapsed ? 'medium' : 'small'}
                          id={id}
                        />
                      </Highlight>
                    ) : (
                      <EmptyHighlight
                        id={`${HIGHLIGHT_IN_SIDEBAR_PREFIX}${id}`}
                      />
                    )}
                  </div>
                )}
              </InView>
            ))}
          </Highlights>
        </CustomScrollbar>
      </Container>
      {(showSaveDialog || selectedSmiles) && (
        <BasketsListModal
          onAgree={saveMolecules}
          onClose={() => {
            setShowSaveDialog(false)
            setSelectedSmiles('')
          }}
          withPublic={false}
          withNew={true}
          actionText={t('pdf2smiles.viewer.add_to_dataset')}
        />
      )}
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    isSidebarCollapsed: state.settings.isSidebarCollapsed,
    pdf2Smiles: state.pdf2Smiles,
  }
}

const mapDispatchToProps = {
  addMolecule,
  setCurrentHighlightId,
  addNotification,
  confirm,
  setSelectedHighlights,
  deleteHighlights,
  setSelectedSmiles,
}

export default connect(mapStateToProps, mapDispatchToProps)(HighlightsSidebar)
