import styled from 'styled-components'

export const NotificationProgressContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const TaskProgressBlock = styled.div`
  width: 100%;
  gap: 0.5rem;
  display: flex;
  flex-direction: column;
  padding: ${({ isNeedToDelete }) => (isNeedToDelete ? 0 : '0 1rem 1rem 1rem')};
`

export const ProgressBarBlock = styled.div`
  width: 100%;
  height: 0.75rem;
  overflow: hidden;
  position: relative;
  border-radius: 0.75rem;
  box-sizing: border-box;
  background: ${({ theme }) => theme.colors.backgrounds.tertiary};

  & > span {
    height: 100%;
    display: block;
    position: relative;
    border-radius: 0.75rem;
    transition: all 0.5s ease;
    background-size: 200% 200%;
    background: ${({ theme }) => theme.colors.backgrounds.accent};
  }
`

export const NotificationProgressBody = styled.div`
  gap: 0.75rem;
  padding: 1rem;
  display: flex;
  flex-direction: column;

  & p:first-child {
    width: 100%;
    overflow: hidden;
    white-space: wrap;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    word-break: break-word;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
  }

  & p:nth-child(2) {
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    display: inline-block;
    text-overflow: ellipsis;
  }
`

export const TextContainer = styled.div`
  width: 100%;
  gap: 0.25rem;
  display: flex;
  overflow: hidden;
  flex-direction: column;
`

export const HeaderWithIcon = styled.div`
  width: 100%;
  gap: 0.75rem;
  display: flex;
  align-items: center;
`

export const Buttons = styled.div`
  gap: 0.75rem;
  display: flex;
  padding: 1rem;
  background-color: ${({ theme }) => theme.colors.backgrounds.contextMenuHover};
  border-top: ${({ theme }) => `1.2px solid ${theme.colors.icons.white}`};
  & > div:first-child {
    color: ${({ theme }) => theme.colors.text.primary};
    &:hover {
      color: ${({ theme }) => theme.colors.text.accentPrimary};
    }
    &:active {
      color: ${({ theme }) => theme.colors.text.white};
    }
  }
`
export const IconContainer = styled.div`
  padding: 0;
  display: flex;
  border-radius: 50%;
  width: fit-content;
  align-items: center;
  justify-content: center;
  background: ${({ isLongTaskReady, theme }) =>
    isLongTaskReady
      ? theme.colors.statuses.success
      : theme.colors.statuses.error};

  & > svg {
    color: ${({ theme }) => theme.colors.icons.white};
  }
`

export const RemoveTaskContainer = styled.div`
  display: flex;
  padding: 0.375rem;
  align-items: center;
  border-radius: 0.5rem;
  justify-content: center;
  color: ${({ theme }) => theme.colors.statuses.error};
  background: ${({ theme }) => theme.colors.statuses.errorBg};

  &:hover {
    color: ${({ theme }) => theme.colors.icons.white};
    background: ${({ theme }) => theme.colors.statuses.error};
  }
`
