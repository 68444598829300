export const SELECT_BASKET = 'SELECT_BASKET'
export const SET_SHOW_ADD_DIALOG = 'SET_SHOW_ADD_DIALOG'
export const SET_BASKET_NAME = 'SET_BASKET_NAME'
export const SET_BASKET_DESC = 'SET_BASKET_DESC'
export const SET_EDIT_MODE = 'SET_EDIT_MODE'
export const SET_EDIT_ID = 'SET_EDIT_ID'
export const SET_SHOW_LOG_DIALOG = 'SET_SHOW_LOG_DIALOG'
export const SET_SELECTED_BASKET = 'SET_SELECTED_BASKET'
export const SET_SHOW_JOIN_COPY_DIALOG = 'SET_SHOW_JOIN_COPY_DIALOG'
export const REMOVE_BASKET_IDS_FROM_DB_INIT = 'REMOVE_BASKET_IDS_FROM_DB_INIT'
export const REMOVE_BASKET_IDS_FROM_DB_SUCCESS =
  'REMOVE_BASKET_IDS_FROM_DB_SUCCESS'
export const REMOVE_BASKET_IDS_FROM_DB_ERROR = 'REMOVE_BASKET_IDS_FROM_DB_ERROR'
export const SET_BASKETS_SORTING_PARAM = 'SET_BASKETS_SORTING_PARAM'
export const SET_BASKETS_SEARCH_NAME = 'SET_BASKETS_SEARCH_NAME'
