import { put, select, takeEvery } from 'redux-saga/effects'
import { storeV2 } from 'services/rest'
import {
  ADD_NEW_COMPANY_FAIL,
  ADD_NEW_COMPANY_INIT,
  ADD_NEW_COMPANY_SUCCESS,
  CURRENT_CUSTOMER_EDIT_SUCCESS,
  CURRENT_CUSTOMER_LICENSE_TOGGLE_SUCCESS,
  CURRENT_CUSTOMER_LICENSE_USER_TOGGLE_SUCCESS,
  CUSTOMER_EDIT_INIT,
  CUSTOMER_EDIT_SUCCESS,
  GET_CURRENT_CUSTOMER_INIT,
  GET_CURRENT_CUSTOMER_SUCCESS,
  GET_CURRENT_CUSTOMER_USERS_FAIL,
  GET_CURRENT_CUSTOMER_USERS_INIT,
  GET_CURRENT_CUSTOMER_USERS_SUCCESS,
  GET_CUSTOMERS_FAIL,
  GET_CUSTOMERS_INIT,
  GET_CUSTOMERS_SUCCESS,
  GET_USERS_FAIL,
  GET_USERS_INIT,
  GET_USERS_SUCCESS,
  LICENSE_TOGGLE_ACCESS_INIT,
  LICENSE_TOGGLE_ACCESS_SUCCESS,
  LICENSE_USER_TOGGLE_ACCESS_INIT,
  LICENSE_USER_TOGGLE_ACCESS_SUCCESS,
  SET_ADD_COMPANY_STAGE,
  SET_CURRENT_CUSTOMER_PAGINATION_CONFIG,
  SET_CUSTOMERS_PAGINATION_CONFIG,
  SET_USERS_PAGINATION_CONFIG,
} from 'store/constants/adminPanel'
import { ADD_NOTIFICATION } from 'store/constants/notifications'
import { readStorage } from 'utils/storage/storage'
import { v4 as uuidv4 } from 'uuid'
import { getISOTimeZoneDate } from './utils/utils'

function* getCustomers({ limit, offset }) {
  try {
    const { data } = yield storeV2('customer/get', {
      params: { limit, offset },
    })
    yield put({
      type: GET_CUSTOMERS_SUCCESS,
      items: data?.items,
    })

    yield put({
      type: SET_CUSTOMERS_PAGINATION_CONFIG,
      config: {
        pagesAmount: Math.floor(data?.total / limit) + 1,
        total: data?.total,
        perPage: limit,
        activePage: offset / limit + 1,
      },
    })
  } catch (e) {
    console.log(e)
    yield put({
      type: GET_CUSTOMERS_FAIL,
    })

    const id = uuidv4()
    const notify = {
      id,
      name: 'admin.get_companies_error',
      notification_type: 'error',
      autoRemove: true,
      timeout: 5000,
    }
    yield put({
      type: ADD_NOTIFICATION,
      task: notify,
    })
  }
}

function* getCurrentCustomer({ isSynAdmin }) {
  try {
    const currentCustomerId = yield select(
      (state) => state.adminPanel.currentCustomerId
    )
    const storageCustomerId = readStorage('customer_id')
    const { data } = yield storeV2('customer/get', {
      params: {
        filter: { ids: [isSynAdmin ? currentCustomerId : storageCustomerId] },
      },
    })

    yield put({ type: GET_CURRENT_CUSTOMER_SUCCESS, items: data?.items })
  } catch (e) {
    console.log(e)
  }
}

function* getUsers({ limit, offset }) {
  try {
    const { data } = yield storeV2('user/get', {
      params: { limit, offset },
    })
    yield put({
      type: GET_USERS_SUCCESS,
      items: data?.result?.items,
    })

    yield put({
      type: SET_USERS_PAGINATION_CONFIG,
      config: {
        pagesAmount: Math.floor(data?.result?.total / limit) + 1,
        total: data?.result?.total,
        perPage: limit,
        activePage: offset / limit + 1,
      },
    })
  } catch (e) {
    console.log(e)
    yield put({ type: GET_USERS_FAIL })

    const id = uuidv4()
    const notify = {
      id,
      name: 'admin.get_users_error',
      notification_type: 'error',
      autoRemove: true,
      timeout: 5000,
    }
    yield put({
      type: ADD_NOTIFICATION,
      task: notify,
    })
  }
}

function* getCurrentCustomerUsers({ isSynAdmin, limit, offset }) {
  try {
    const currentCustomerId = yield select(
      (state) => state.adminPanel.currentCustomerId
    )
    const storageCustomerId = readStorage('customer_id')

    const { data } = yield storeV2('user/get', {
      params: {
        limit,
        offset,
        filter: {
          customer_id: isSynAdmin ? currentCustomerId : storageCustomerId,
        },
      },
    })
    yield put({
      type: GET_CURRENT_CUSTOMER_USERS_SUCCESS,
      items: data?.result?.items,
    })

    yield put({
      type: SET_CURRENT_CUSTOMER_PAGINATION_CONFIG,
      config: {
        pagesAmount: Math.floor(data?.result?.total / limit) + 1,
        total: data?.result?.total,
        perPage: limit,
        activePage: offset / limit + 1,
      },
    })
  } catch (e) {
    console.log(e)
    yield put({ type: GET_CURRENT_CUSTOMER_USERS_FAIL })

    const id = uuidv4()
    const notify = {
      id,
      name: 'admin.get_users_error',
      notification_type: 'error',
      autoRemove: true,
      timeout: 5000,
    }
    yield put({
      type: ADD_NOTIFICATION,
      task: notify,
    })
  }
}

function* customerUpdate({ params }) {
  try {
    const { isCurrentCustomerPage, ...restParams } = params
    const {
      companyName,
      adminEmail,
      calendarValues,
      accsNumber,
      licenseId,
      customerId,
    } = restParams

    const { data: licenseData } = yield storeV2('license/update', {
      params: {
        id: licenseId,
        started_at: getISOTimeZoneDate(calendarValues[0]),

        expired_at: getISOTimeZoneDate(calendarValues[1]),

        max_users_count: accsNumber === '' ? null : accsNumber,
      },
    })
    const { data: customerData } = yield storeV2('customer/update', {
      params: {
        id: customerId,
        admin: adminEmail,
        name: companyName,
      },
    })

    yield put({
      type: CUSTOMER_EDIT_SUCCESS,
      items: {
        licenseResult: licenseData.result,
        customerResult: customerData.result,
      },
    })
    if (isCurrentCustomerPage) {
      yield put({
        type: CURRENT_CUSTOMER_EDIT_SUCCESS,
        items: {
          licenseResult: licenseData.result,
          customerResult: customerData.result,
        },
      })
    }
  } catch (e) {
    console.log(e)
    const id = uuidv4()
    const notify = {
      id,
      name: 'admin.customer_update_error',
      notification_type: 'error',
      autoRemove: true,
      timeout: 5000,
    }
    yield put({
      type: ADD_NOTIFICATION,
      task: notify,
    })
  }
}

function* licenseToggleAccess({ params }) {
  try {
    const { isCurrentCustomer, ...restParams } = params
    const { data } = yield storeV2('license/update', { params: restParams })

    yield put({
      type: LICENSE_TOGGLE_ACCESS_SUCCESS,
      items: { id: data?.result?.id, is_active: data?.result?.is_active },
    })
    if (isCurrentCustomer) {
      yield put({
        type: CURRENT_CUSTOMER_LICENSE_TOGGLE_SUCCESS,
        items: { id: data?.result?.id, is_active: data?.result?.is_active },
      })
    }
  } catch (e) {
    const id = uuidv4()
    const notify = {
      id,
      name: 'admin.toggle_access_error',
      notification_type: 'error',
      autoRemove: true,
      timeout: 5000,
    }
    yield put({
      type: ADD_NOTIFICATION,
      task: notify,
    })
  }
}

function* licenseUserToggleAccess({ params }) {
  try {
    const { isCurrentCustomer, license_id, customer_id, user_id } = params
    let customerData = null
    if (license_id === null) {
      customerData = yield storeV2('customer/get', {
        params: {
          filter: { ids: [customer_id] },
        },
      })
    }

    const { data } = yield storeV2('user/update', {
      params: {
        user_id,
        license_id: license_id
          ? null
          : customerData?.data?.items?.[0]?.license_id,
      },
    })

    yield put({
      type: LICENSE_USER_TOGGLE_ACCESS_SUCCESS,
      items: {
        user_id: data?.result?.user_id,
        license_id: data?.result?.license_id,
      },
    })
    if (isCurrentCustomer) {
      yield put({
        type: CURRENT_CUSTOMER_LICENSE_USER_TOGGLE_SUCCESS,
        items: {
          user_id: data?.result?.user_id,
          license_id: data?.result?.license_id,
        },
      })
    }
  } catch (e) {
    const id = uuidv4()
    const notify = {
      id,
      name: 'admin.toggle_access_error',
      notification_type: 'error',
      autoRemove: true,
      timeout: 5000,
    }
    yield put({
      type: ADD_NOTIFICATION,
      task: notify,
    })
  }
}

function* addNewCompany({ params }) {
  try {
    const { data } = yield storeV2('customer/create', { params })
    yield put({ type: ADD_NEW_COMPANY_SUCCESS, item: data?.result })
    yield put({ type: SET_ADD_COMPANY_STAGE, stage: 'success' })
  } catch (e) {
    console.log(e)

    yield put({
      type: ADD_NEW_COMPANY_FAIL,
    })

    const errMsg = e?.response?.data?.result?.error_message
    const getErrorName = () => {
      if (
        errMsg &&
        errMsg.includes('Customer with name') &&
        errMsg.includes('already exists')
      ) {
        return 'admin.add_company_error_name_exists'
      }
      return 'admin.add_company_error'
    }

    const id = uuidv4()
    const notify = {
      id,
      name: getErrorName(),
      notification_type: 'error',
      autoRemove: true,
      timeout: 5000,
    }
    yield put({
      type: ADD_NOTIFICATION,
      task: notify,
    })
  }
}

export function* getCustomersWatcher() {
  yield takeEvery(GET_CUSTOMERS_INIT, getCustomers)
}

export function* licenseToggleAccessWatcher() {
  yield takeEvery(LICENSE_TOGGLE_ACCESS_INIT, licenseToggleAccess)
}

export function* licenseUserToggleAccessWatcher() {
  yield takeEvery(LICENSE_USER_TOGGLE_ACCESS_INIT, licenseUserToggleAccess)
}

export function* getUsersWatcher() {
  yield takeEvery(GET_USERS_INIT, getUsers)
}

export function* getCurrentCustomerUsersWatcher() {
  yield takeEvery(GET_CURRENT_CUSTOMER_USERS_INIT, getCurrentCustomerUsers)
}

export function* customerUpdateWatcher() {
  yield takeEvery(CUSTOMER_EDIT_INIT, customerUpdate)
}
export function* getCurrentCustomerWatcher() {
  yield takeEvery(GET_CURRENT_CUSTOMER_INIT, getCurrentCustomer)
}

export function* addNewCompanyWatcher() {
  yield takeEvery(ADD_NEW_COMPANY_INIT, addNewCompany)
}

const watchers = [
  getCustomersWatcher(),
  licenseToggleAccessWatcher(),
  licenseUserToggleAccessWatcher(),
  getUsersWatcher(),
  getCurrentCustomerUsersWatcher(),
  customerUpdateWatcher(),
  getCurrentCustomerWatcher(),
  addNewCompanyWatcher(),
]

export default watchers
