import styled from 'styled-components'

export const TextBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  align-items: center;
  text-align: center;
`

export const LinksBlock = styled.div`
  display: flex;
  gap: 0.5rem;
  max-width: 100%;
  justify-content: center;
`

export const PseudoInput = styled.div`
  height: 2.25rem;
  padding: 0.25rem 0.25rem 0.25rem 0.75rem;
  border-radius: 0.75rem;
  background-color: ${({ theme }) => theme.colors.backgrounds.secondary};
  width: 50%;

  display: flex;
  align-items: center;
  gap: 0.75rem;
  overflow: hidden;
`

export const ToggleBlock = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
`

export const ToggleText = styled.div`
  display: flex;
  gap: 0.25rem;
  align-items: center;

  & > svg {
    color: ${({ theme }) => theme.colors.icons.tertiary};
    cursor: pointer;
  }
`
