import { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import * as FileSaver from 'file-saver'
import { Handle, Position } from 'reactflow'
import { useTranslation } from 'react-i18next'

import { store } from 'services/rest'
import MoleculeViewer from 'components/MoleculeViewer'
import { copyToClipboard } from 'utils/common/common'
import { setEditorIndividualId } from 'store/actions/molecule'
import { addSimpleNotification } from 'store/actions/notifications'
import { toggleMoleculeDialog } from 'store/actions/retrosynthesis'

import {
  openTab,
  REDIRECT_REACTIONS_TYPE,
  REDIRECT_SPECTRA_TYPE,
} from 'components/MoleculeViewer/config/config'

import { Container } from './index.style'
import CustomNodeMenu from '../CustomNodeMenu'

const CustomNode = ({
  id,
  data,
  isConnectable,
  toggleMoleculeDialog,
  addSimpleNotification,
  setEditorIndividualId,
}) => {
  const { t } = useTranslation()
  const { moleculeId, smiles, width, height } = data

  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const [isSubMenuOpen, setIsSubMenuOpen] = useState(false)

  useEffect(() => {
    if (!isMenuOpen && isSubMenuOpen) setIsSubMenuOpen(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMenuOpen])

  const handleCopySmiles = () => {
    copyToClipboard(smiles)
    addSimpleNotification('notification.smiles_copied')
  }

  const handleCopyBaseId = () => {
    copyToClipboard(moleculeId)
    addSimpleNotification('notification.baseid_copy')
  }

  const handleGoToMoleculePage = () => {
    window.open(`/molecule/${moleculeId}`)
  }

  const handleDownloadPng = async () => {
    try {
      const { data } = await store(`/drawMolecule`, {
        molecule: smiles,
        atoms: [],
      })
      if (data?.result?.status === 'ok') {
        const url = `data:image/png;base64,${data.result.image}`

        fetch(url)
          .then((b) => b.blob())
          .then((blob) => FileSaver.saveAs(blob, 'syntelly.png'))
      }
    } catch (e) {
      console.log(e)
    }
  }

  const handleGoTo = (url) => {
    const redirect_smiles = smiles
    if (!smiles) return
    setIsMenuOpen(false)
    switch (url) {
      case 'molecular_editor':
        setEditorIndividualId(moleculeId)
        openTab(`/moleditor/${encodeURIComponent(smiles)}`)
        return
      case 'synthesis':
        openTab('/reactions', {
          redirect_smiles,
          [REDIRECT_REACTIONS_TYPE]: 'forward',
        })
        return
      case 'retrosynthesis':
        openTab('/reactions', {
          redirect_smiles,
          [REDIRECT_REACTIONS_TYPE]: 'reverse',
        })
        return
      case 'nmr_spectra':
        openTab('/spectra', {
          redirect_smiles,
          [REDIRECT_SPECTRA_TYPE]: 'nmr',
        })
        return
      case 'mass_spectra':
        openTab('/spectra', {
          redirect_smiles,
          [REDIRECT_SPECTRA_TYPE]: 'ms',
        })
        return
      case 'ir_spectra':
        openTab('/spectra', {
          redirect_smiles,
          [REDIRECT_SPECTRA_TYPE]: 'infraredSpectrometry',
        })
        return
      case 'synthesis_cost':
        openTab('/synthcost', {
          redirect_smiles,
        })
        return
      default:
        return
    }
  }

  const menuButtonsConfig = [
    {
      text: t('molecule_viewer.copy_baseid'),
      onClick: handleCopyBaseId,
      disabled: !moleculeId,
      id: 'copy_baseid',
    },
    {
      text: t('molecule_viewer.copy_smiles'),
      onClick: handleCopySmiles,
      disabled: false,
      id: 'copy_smiles',
    },
    {
      text: t('reactions_prediction.retrosynth.to_molecule_page'),
      onClick: handleGoToMoleculePage,
      disabled: !moleculeId,
      id: 'to_molecule_page',
    },
    {
      text: t('molecule_viewer.transfer_to'),
      onClick: () => setIsSubMenuOpen(true),
      withSubActions: true,
      id: 'transfer_to',
    },

    {
      text: t('molecule_viewer.download_png_text'),
      onClick: handleDownloadPng,
      disabled: !moleculeId,
      id: 'download_png',
    },
  ]

  const subMenuButtonsConfig = [
    {
      text: t('molecule_viewer.go_to_section.molecular_editor'),
      onClick: () => handleGoTo('molecular_editor'),
      id: 'go_molecular_editor',
    },
    {
      text: t('molecule_viewer.go_to_section.synthesis'),
      onClick: () => handleGoTo('synthesis'),
      id: 'go_synthesis',
    },
    {
      text: t('molecule_viewer.go_to_section.retrosynthesis'),
      onClick: () => handleGoTo('retrosynthesis'),
      id: 'go_retrosynthesis',
    },

    {
      text: t('molecule_viewer.go_to_section.nmr_spectra'),
      onClick: () => handleGoTo('nmr_spectra'),
      id: 'go_nmr_spectra',
    },
    {
      text: t('molecule_viewer.go_to_section.mass_spectra'),
      onClick: () => handleGoTo('mass_spectra'),
      id: 'go_mass_spectra',
    },
    {
      text: t('molecule_viewer.go_to_section.ir_spectra'),
      onClick: () => handleGoTo('ir_spectra'),
      id: 'go_ir_spectra',
    },
    {
      text: t('molecule_viewer.go_to_section.synthesis_cost'),
      onClick: () => handleGoTo('synthesis_cost'),
      id: 'go_synthesis_cost',
    },
  ]

  return (
    <>
      <Container>
        <Handle
          id={id}
          type="source"
          position={Position.Left}
          isConnectable={isConnectable}
        />
        <MoleculeViewer
          fixedSize
          {...{ smiles, width, height }}
          noPadding
          noDelete
          noEdit
          noMenu
          noHover
          isRetrosynthesisMolecule
          num={moleculeId}
          onShowProps={() => {
            toggleMoleculeDialog(moleculeId)
          }}
          openRetrosynthMolMenu={(e) => {
            setIsMenuOpen(true)
          }}
          isMolMenuOpen={isMenuOpen}
          noContextMenu
          tagType="white"
        />

        <Handle
          id={id}
          type="target"
          position={Position.Right}
          isConnectable={isConnectable}
        />
        {isMenuOpen && (
          <>
            <CustomNodeMenu
              onCloseMenu={() => setIsMenuOpen(false)}
              {...{ menuButtonsConfig, setIsSubMenuOpen }}
            />
            {isSubMenuOpen && (
              <CustomNodeMenu
                type="submenu"
                onCloseMenu={() => setIsMenuOpen(false)}
                menuButtonsConfig={subMenuButtonsConfig}
                {...{ setIsSubMenuOpen }}
              />
            )}
          </>
        )}
      </Container>
    </>
  )
}

const mapDispatchToProps = {
  toggleMoleculeDialog,
  addSimpleNotification,
  setEditorIndividualId,
}

export default connect(null, mapDispatchToProps)(CustomNode)
