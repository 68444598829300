import styled from 'styled-components'

import CustomButton from 'components/common/customButton'

export const WelcomeBlock = styled.div`
  gap: 0.5rem;
  display: flex;
  flex-direction: column;
`

export const PasswordActionsBlock = styled.div`
  gap: 2rem;
  display: flex;
  align-items: center;
  height: fit-content;
  justify-content: space-between;
`

export const SignUpWithCopyRight = styled.div`
  gap: 1.5rem;
  display: flex;
  margin-top: auto;
  flex-direction: column;
`

export const SignUpText = styled.div`
  gap: 0.25rem;
  display: flex;
`
export const RegisterButton = styled(CustomButton)`
  color: ${({ theme }) => theme.colors.buttons.accent};
`
export const TitleWithDescription = styled.div`
  gap: 0.5rem;
  display: flex;
  flex-direction: column;
  & > p {
    white-space: pre-line;
  }
`
export const Descriptions = styled.div`
  gap: 0.25rem;
  display: flex;
  flex-direction: column;
`
export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`
