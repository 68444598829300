import { memo } from 'react'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'

import Icon from 'components/Icon'
import { removeCompletedTasks } from 'store/actions/tasks'
import { hideNotifications } from 'store/actions/notifications'

import NotificationsBtnWithCount from './NotificationsBtnWithCount'
import { NotificationsActions, NotificationsActionsBtn } from './index.style'
import { useAuth } from 'utils/auth/auth'

const NotificationActions = memo(
  ({
    tasks,
    isHidden,
    toggleCompress,
    itemsCompressed,
    isActionsVisible,
    allNotifications,
    hideNotifications,
    setIsActionsVisible,
    removeCompletedTasks,
  }) => {
    const { t } = useTranslation()
    const { isAuthenticated } = useAuth()

    const handleHideNotifications = () => {
      const actions = document.getElementById('notifications-actions')
      actions.classList.add('hidden')
      setTimeout(() => {
        hideNotifications()
      }, 200)

      setTimeout(() => {
        actions.classList.remove('hidden')
      }, 1000)
    }

    const onToggleCompress = () => {
      const actions = document.getElementById('notifications-actions')
      actions.classList.add('hidden')
      setTimeout(() => {
        toggleCompress()
      }, 200)

      setTimeout(() => {
        actions.classList.remove('hidden')
      }, 1000)
    }

    const isOneProgress =
      allNotifications.length === 1 &&
      allNotifications[0].notification_type === 'progress'

    const isTwoProgressCompleted =
      tasks.filter(
        (el) => el.notification_type === 'progress' && el.status === 'ok'
      ).length >= 2

    return (
      <NotificationsActions
        onMouseEnter={() => setIsActionsVisible(true)}
        onMouseLeave={(e) => {
          const currentTarget = e?.currentTarget
          const relatedTarget = e.relatedTarget
          if (
            currentTarget &&
            relatedTarget instanceof Node &&
            !currentTarget?.contains(e.relatedTarget)
          )
            setIsActionsVisible(false)
        }}
        id="notifications-actions"
        className="notifications-actions"
        isActionsVisible={isActionsVisible}
      >
        {isTwoProgressCompleted && isAuthenticated && (
          <NotificationsActionsBtn
            id="remove-completed-tasks"
            onClick={removeCompletedTasks}
          >
            <span>{t('notifications.remove_completed_tasks')}</span>
            <Icon iconType="deleted" size="1rem" />
          </NotificationsActionsBtn>
        )}
        {allNotifications.length > 1 && (
          <NotificationsBtnWithCount
            {...{ itemsCompressed }}
            notificationsCount={allNotifications.length}
            onClick={onToggleCompress}
          />
        )}

        {(allNotifications.length > 1 || isOneProgress) && (
          <NotificationsActionsBtn id="hide" onClick={handleHideNotifications}>
            <Icon iconType="eyeOpen" size="1rem" />
            <span>{t('notifications.hide')}</span>
          </NotificationsActionsBtn>
        )}
      </NotificationsActions>
    )
  }
)

const mapDispatchToProps = {
  hideNotifications,
  removeCompletedTasks,
}

NotificationActions.displayName = 'NotificationActions'

export default connect(null, mapDispatchToProps)(NotificationActions)
