import styled from 'styled-components'

export const CustomTagElement = styled.div`
  display: flex;
  width: ${({ width }) => {
    if (width) return width
    return 'auto'
  }};
  height: ${({ height }) => {
    if (height) return height
    return '1.625rem'
  }};
  gap: 0.25rem;
  padding: ${({ padding }) => (padding ? padding : '0.375rem 0.5rem')};
  justify-content: center;
  align-items: center;
  border-radius: ${({ radius }) => (radius ? radius : '0.375rem')};
  cursor: pointer;
  transition: all 0.2s ease;

  font-size: ${({ theme, fontSize }) => (fontSize ? fontSize : theme.text[11])};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  line-height: 0.875rem;

  border: ${({ type }) => {
    if (type === 'accent_secondary') return `1px solid #42c1bf`
  }};

  color: ${({ type, theme, defaultColor }) => {
    if (defaultColor) return defaultColor
    if (type === 'white' || type === 'grey') return theme.colors.text.primary
    if (type === 'accent_secondary') return theme.colors.text.accentPrimary
    if (type === 'secondary_grey') return theme.colors.text.secondary
    if (type === 'score_plus') return theme.colors.statuses.success
    if (type === 'score_minus') return theme.colors.statuses.error
    return theme.colors.text.white
  }};

  & > svg {
    color: ${({ type, theme }) => {
      if (type === 'white' || type === 'grey' || type === 'secondary_grey')
        return theme.colors.icons.tertiary
      return 'inherit'
    }};
  }

  background-color: ${({ type, theme }) => {
    if (type === 'white') return theme.colors.buttons.primary
    if (type === 'grey' || type === 'secondary_grey')
      return theme.colors.buttons.secondary
    if (type === 'accent' || type === 'generated')
      return theme.colors.buttons.accent
    if (type === 'accent_secondary')
      return theme.colors.backgrounds.accentTertiary
    if (type === 'duplicate') return theme.colors.statuses.link
    if (type === 'decoy') return theme.colors.statuses.attention
    if (type === 'score_plus') return theme.colors.statuses.successBg
    if (type === 'score_minus') return theme.colors.statuses.errorBg

    return theme.colors.buttons.secondary
  }};

  &:hover {
    & > svg {
      color: ${({ withInteraction, theme }) =>
        withInteraction ? theme.colors.icons.accentPrimary : 'inherit'};
    }
  }
`
