import styled from 'styled-components'

export const StyledSnackbar = styled.div`
  gap: 0.75rem;
  display: flex;
  padding: 1rem;
  right: 1.5rem;
  bottom: 1.5rem;
  width: ${({ width }) => (width ? width : '18.75rem')};
  position: ${({ isMoleculesPage, isReactionsPage }) =>
    isMoleculesPage || isReactionsPage ? 'fixed' : 'absolute'};
  z-index: 15 !important;
  flex-direction: column;
  border-radius: 0.75rem;
  backdrop-filter: blur(30px);
  box-shadow: ${({ theme }) => theme.shadow.default};
  background: ${({ theme }) => theme.colors.backgrounds.hint};
  @media print {
    display: none;
  }
`

export const SnackbarContent = styled.div`
  gap: 0.25rem;
  display: flex;
  flex-direction: column;
  background: transparent;
`
