import CustomButton from 'components/common/customButton'
import { CustomDialog } from 'components/common/customDialog'
import CustomInput from 'components/common/customInput'
import CustomSwitch from 'components/common/customSwitch'
import {
  Label,
  TextButtonLarge,
  TextButtonMedium,
  TextMedium,
  TitleTertiary,
} from 'components/common/text/index.style'
import {
  TextBlock,
  LinksBlock,
  PseudoInput,
  ToggleBlock,
  ToggleText,
} from './index.style'
import Icon from 'components/Icon'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { DateObject } from 'react-multi-date-picker'
import { connect, useDispatch } from 'react-redux'
import {
  addNewCompany,
  closeAddCompanyDialog,
  getCustomers,
  setAddCompanyStage,
} from 'store/actions/adminPanel'
import { useTheme } from 'styled-components'
import { ADD_COMPANY_SWITCH_TYPES } from '../config'
import {
  DialogBody,
  DoubleInputBlock,
  InputWrapper,
  TopBlock,
} from '../editCompanyDialog/index.style'
import RangeCalendar from '../rangeCalendar'
import {
  copyToClipboard,
  emailReg,
  validateEnglishInput,
} from 'utils/common/common'
import { addSimpleNotification } from 'store/actions/notifications'
import CustomToggle from 'components/common/customToggle'
import { Tooltip as ReactTooltip } from 'react-tooltip'
import { formatUtcDigitDateByFormat } from 'utils/dateTime/formatUtcDate'

const AddCompanyDialog = ({ addCompany }) => {
  const [switchType, setSwitchType] = useState(ADD_COMPANY_SWITCH_TYPES[0].type)
  const [adminEmail, setAdminEmail] = useState('')
  const [emailError, setEmailError] = useState('')
  const [accsNumber, setAccsNumber] = useState(0)
  const [isDomainActive, setIsDomainActive] = useState(false)
  const [domain, setDomain] = useState('')
  const [companyName, setCompanyName] = useState('')
  const [calendarOpen, setCalendarOpen] = useState(false)
  const [calendarValues, setCalendarValues] = useState([new DateObject(), null])

  const { item, stage, loading } = addCompany

  const { t } = useTranslation()
  const theme = useTheme()
  const dispatch = useDispatch()

  const handleToggleCalendar = () => {
    setCalendarOpen((prev) => !prev)
  }

  const handleSwitch = (type) => {
    setSwitchType(type)
  }

  const handleAddCompany = () => {
    const getAdmin = () => {
      return adminEmail ? { admin: adminEmail } : {}
    }

    const getAccsNumber = () => {
      return { license_max_users_count: accsNumber === '' ? null : accsNumber }
    }
    const getDates = () => {
      return {
        license_started_at: new Date(calendarValues[0])
          .toISOString()
          .slice(0, -1),
        license_expired_at: new Date(calendarValues[1])
          .toISOString()
          .slice(0, -1),
      }
    }

    const getDomain = () => {
      return domain ? { domain } : {}
    }

    dispatch(
      addNewCompany({
        license_is_free_trial: switchType === 'free_trial',
        name: companyName,
        ...getAdmin(),
        ...getAccsNumber(),
        ...getDates(),
        ...getDomain(),
      })
    )
  }

  const handleCopy = (copyType, value) => {
    copyToClipboard(value)
    dispatch(
      addSimpleNotification(
        `notification.${
          copyType === 'link' ? 'invite_link_copied' : 'invite_key_copied'
        }`
      )
    )
  }

  const handleOk = (withUpdate = false) => {
    dispatch(closeAddCompanyDialog())
    dispatch(setAddCompanyStage('create'))
    withUpdate && dispatch(getCustomers({ limit: 24, offset: 0 }))
  }

  const handleSetAccsNumber = (e) => {
    const regexp = /^[0-9\b]+$/
    if (e[0] === '0' && e.length > 1) return setAccsNumber(e.substring(1))
    if (e === '' || regexp.test(e)) return setAccsNumber(e)
  }

  const handleSetEmail = (e) => {
    if (validateEnglishInput(e) || !e) setAdminEmail(e)
    if (!e || emailReg.test(e)) {
      setEmailError('')
    } else {
      setEmailError('account.emailError')
    }
  }
  return (
    <CustomDialog
      withPortal
      onClose={() => {
        !calendarOpen && handleOk()
      }}
      showCloseIcon={stage === 'create'}
      padding="1.5rem"
      minWidth="29rem"
      width="29rem"
    >
      <DialogBody>
        {stage === 'create' && (
          <>
            <TopBlock>
              <TitleTertiary fontWeight={theme.fontWeight.semibold}>
                {t('admin.filter.add_company')}
              </TitleTertiary>
              <CustomSwitch
                items={ADD_COMPANY_SWITCH_TYPES}
                keyTemplate="add-company-switch"
                active={switchType}
                btnWidth="50%"
                wrapperWidth="100%"
                onClick={handleSwitch}
              />
              <CustomInput
                label={`${t('admin.company_name')} *`}
                gap="0.375rem"
                withClearButton
                placeholder={t('admin.placeholder.enter_company_name')}
                value={companyName}
                onChange={(value) => setCompanyName(value)}
              />
              <DoubleInputBlock>
                <CustomInput
                  label={t('admin.admin')}
                  value={adminEmail}
                  onChange={handleSetEmail}
                  gap="0.375rem"
                  placeholder={t('admin.placeholder.enter_email')}
                  withClearButton
                  error={emailError}
                  id="admin-email"
                  type="email"
                  autoComplete="email"
                  errorTranslationParams={{ adminEmail }}
                />
                <CustomInput
                  label={t('admin.accs_number')}
                  gap="0.375rem"
                  width="9rem"
                  withClearButton
                  value={accsNumber}
                  placeholder={t('filter.pieces')}
                  onChange={handleSetAccsNumber}
                  pseudoAfterText={t('filter.pieces')}
                />
              </DoubleInputBlock>
              <ToggleBlock>
                <ToggleText>
                  <Label color={theme.colors.text.primary}>
                    {t('admin.with_domain')}
                  </Label>

                  <Icon iconType="info" data-tooltip-id="with-domain-info" />

                  <ReactTooltip
                    id="with-domain-info"
                    className="c-tooltip c-tooltip-base"
                    classNameArrow="c-tooltip-arrow"
                    place={'top'}
                  >
                    {t('admin.domain_hint')}
                  </ReactTooltip>
                </ToggleText>
                <CustomToggle
                  size="large"
                  type="grey"
                  value={isDomainActive}
                  onClick={() => setIsDomainActive((prev) => !prev)}
                />
              </ToggleBlock>
              {isDomainActive && (
                <CustomInput
                  label={t('admin.domain')}
                  gap="0.375rem"
                  width="100%"
                  withClearButton
                  placeholder={t('admin.placeholder.enter_domain')}
                  value={domain}
                  onChange={setDomain}
                />
              )}
              <DoubleInputBlock>
                <InputWrapper onClick={handleToggleCalendar}>
                  <CustomInput
                    label={`${t('admin.license_period')} *`}
                    pseudoBeforeText={t('filter.from_rus')}
                    placeholder={t('filter.from_rus')}
                    gap="0.375rem"
                    value={formatUtcDigitDateByFormat(
                      new Date(calendarValues[0]),
                      'dd.MM.yyyy'
                    )}
                    readOnly
                  />
                </InputWrapper>
                <InputWrapper onClick={handleToggleCalendar}>
                  <CustomInput
                    label={' '}
                    pseudoBeforeText={t('filter.to_rus')}
                    gap="0.375rem"
                    width="100%"
                    value={
                      calendarValues?.[1]
                        ? formatUtcDigitDateByFormat(
                            new Date(calendarValues[1]),
                            'dd.MM.yyyy'
                          )
                        : ''
                    }
                    placeholder={t('filter.to_rus')}
                    readOnly
                  />
                </InputWrapper>
              </DoubleInputBlock>
            </TopBlock>
            <CustomButton
              width="100%"
              type="accent"
              onClick={() => !loading && handleAddCompany()}
              size="big"
              disabled={!companyName || !calendarValues[1] || !!emailError}
            >
              {loading && <Icon size="1.25rem" iconType="loader" />}
              {!loading && (
                <TextButtonLarge color="inherit">
                  {t('filter.literature.add')}
                </TextButtonLarge>
              )}
            </CustomButton>
          </>
        )}
        {stage === 'success' && (
          <>
            <Icon iconType="successImg" size="4rem" />
            <TextBlock>
              <TitleTertiary color={theme.colors.text.primary}>
                {t('admin.company_added')}
              </TitleTertiary>
              <TextMedium color={theme.colors.text.secondary}>
                {t('admin.company_added_info')}
              </TextMedium>
            </TextBlock>
            <LinksBlock>
              <PseudoInput>
                <Label name="ellipsis">{item?.invite_token}</Label>
                <CustomButton
                  type="icon_primary"
                  defaultPadding="0.375rem"
                  width="1.75rem"
                  height="1.75rem"
                  borderRadius="0.5rem"
                  onClick={() => handleCopy('key', item?.invite_token)}
                >
                  <Icon iconType="copy" size="1rem" />
                </CustomButton>
              </PseudoInput>
              <CustomButton
                gap="0.25rem"
                type="accent"
                onClick={() => handleCopy('link', item?.invite_link)}
              >
                <Icon iconType="share" size="1rem" />{' '}
                <TextButtonMedium color="inherit" fontWeight={400}>
                  {t('admin.inviteLink')}
                </TextButtonMedium>
              </CustomButton>
            </LinksBlock>
            <CustomButton
              type="secondary"
              size="big"
              width="100%"
              onClick={() => handleOk(true)}
            >
              <TextButtonLarge color="inherit" fontWeight={400}>
                {t('feedback.ok')}
              </TextButtonLarge>
            </CustomButton>
          </>
        )}
      </DialogBody>
      {calendarOpen && (
        <RangeCalendar
          values={calendarValues}
          setValues={setCalendarValues}
          onClose={() => {
            setCalendarOpen(false)
          }}
        />
      )}
    </CustomDialog>
  )
}

const mapStateToProps = (state) => {
  return {
    addCompany: state.adminPanel.addCompany,
  }
}

export default connect(mapStateToProps)(AddCompanyDialog)
