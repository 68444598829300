/*eslint-disable*/
import { put, takeEvery, select, call, all, delay } from 'redux-saga/effects'
import {
  MMAP_3D_LOAD_FAIL,
  MMAP_3D_LOAD_INIT,
  MMAP_3D_LOAD_INIT_FETCHING,
  MMAP_ADD,
  MMAP_ADD_GENERATOR,
  MMAP_ADD_OPTIONS,
  MMAP_GENERATOR_DATA_INCOME,
  MMAP_LOAD_FAIL,
  MMAP_LOAD_SUCCESS,
  MMAP_TOGGLE_IS_3D_MAP_MODE,
  MMAP_UPDATE_GENERATOR,
  GENERATOR_CALC_INIT,
  MMAP_ADD_GEN,
  MMAP_CREATE_GENERATOR_LAYER,
  MMAP_CHANGE_GENERATOR_STATUS,
} from '../constants/mmap'
import { fetch, store, storeV2 } from '../../services/rest'
import { v4 as uuidv4, v4 } from 'uuid'
import { getIs3dMapMode, getLayers3d, getMMapLayers } from 'store/selectors'
import { ADD_NOTIFICATION } from 'store/constants/notifications'
import { getRandomColor } from 'utils/common/common'
import { generatorCreateLayer } from './utils/utils'

const defaultColors = [
  '#2196F3',
  '#F57C00',
  '#66BB6A',
  '#F44336',
  '#9C27B0',
  '#8D6E63',
  '#F06292',
  '#78909C',
  '#8BC34A',
  '#00BCD4',
]

const SYN_MAP_URL = '/molmap'

function* addGeneratorToMMap({ structures, name }) {
  try {
    const { data } = yield store('/mmap', { ids: structures })

    const color = getRandomColor({ withHash: true })

    const layer = data.map(({ id, x, y, smiles, structure }) => {
      return {
        id,
        coordinates: {
          x,
          y,
        },
        smiles,
        structure,
      }
    })

    const options = {
      id: v4(),
      name,
      size: 'sm',
      color,
      opacity: 1,
      visible: true,
    }

    yield put({
      type: MMAP_LOAD_SUCCESS,
      layer,
      options,
    })
  } catch (e) {
    yield put({
      type: MMAP_LOAD_FAIL,
    })
  }
}

function* loadMMap({ set, ids, name }) {
  try {
    let query = {}
    if (set) {
      query.set = set
    } else if (ids) {
      query.ids = ids
    }

    const storedOptions = yield select(getMMapOptions)

    const usedColorsStat = defaultColors.map(
      (c) => storedOptions.filter(({ color }) => color === c).length
    )
    const minValue = Math.min(...usedColorsStat)
    const minIndex = usedColorsStat.findIndex((v) => v === minValue)
    const color = defaultColors[minIndex]

    const options = {
      id: v4(),
      datasetId: set,
      name,
      size: 'sm',
      color,
      opacity: 1,
      visible: true,
      loading: true,
    }

    yield put({
      type: MMAP_ADD_OPTIONS,
      options,
    })

    const { data } = yield store(SYN_MAP_URL, query)
    const layer = data.map(({ id, x, y, smiles, structure }) => {
      return {
        id,
        coordinates: {
          x,
          y,
        },
        smiles,
        structure,
        set,
      }
    })

    yield put({
      type: MMAP_LOAD_SUCCESS,
      layer,
      options,
      datasetId: set,
    })
  } catch (e) {
    yield put({
      type: MMAP_LOAD_FAIL,
    })
  }
}

const getMMapOptions = (state) => state.mmap.options

const calculateMiddleColor = ({ color1, color2, ratio }) => {
  const hex = (color) => {
    const colorString = color.toString(16)
    return colorString.length === 1 ? `0${colorString}` : colorString
  }

  const r = Math.ceil(
    parseInt(color2.substring(0, 2), 16) * ratio +
      parseInt(color1.substring(0, 2), 16) * (1 - ratio)
  )
  const g = Math.ceil(
    parseInt(color2.substring(2, 4), 16) * ratio +
      parseInt(color1.substring(2, 4), 16) * (1 - ratio)
  )
  const b = Math.ceil(
    parseInt(color2.substring(4, 6), 16) * ratio +
      parseInt(color1.substring(4, 6), 16) * (1 - ratio)
  )

  return '#' + hex(r) + hex(g) + hex(b)
}

function* mmapGeneratorIncome({ status, progress, result, token }) {
  const options = yield select(getMMapOptions)

  const idx = options.findIndex(({ id }) => id === token)

  if (idx !== -1) {
    const [color1, color2] = options[idx].color

    const r = Object.entries(result).map(([key, value]) => {
      return { smiles: key, ...value }
    })

    const layer = r
      .sort(({ score: scorea }, { score: scoreb }) => scoreb - scorea)
      .slice(0, status === 'ok' ? undefined : 1000)
      .map(({ smiles, x, y, score, brutto, molmap, qed, ...etc }) => {
        const color = calculateMiddleColor({ color1, color2, ratio: score })

        return {
          id: uuidv4(),
          structure: 1,
          color,
          coordinates: {
            x,
            y,
          },
          smiles,
          params: { score, brutto, molmap, qed, ...etc },
        }
      })

    yield put({
      type: MMAP_UPDATE_GENERATOR,
      status,
      progress,
      layer,
      idx,
      token,
    })
  }
}

function* load3d() {
  const is3dMapMode = yield select(getIs3dMapMode)
  if (!is3dMapMode) return

  const layers3d = yield select(getLayers3d)
  const newLayers = layers3d.filter(({ status }) => status === 'new')
  if (!newLayers.length) return

  const options = yield select(getMMapOptions)
  const layers = yield select(getMMapLayers)

  yield put({
    type: MMAP_3D_LOAD_INIT_FETCHING,
    layers3d: newLayers,
  })

  try {
    const layersResult = yield all(
      newLayers.map(({ datasetId, generatorId }) => {
        let payload
        if (datasetId) {
          payload = {
            set: datasetId,
            map_type: '3d',
          }
        } else {
          const idx = options.findIndex(({ id }) => id === generatorId)
          const smiles = layers[idx].map(({ smiles }) => smiles)
          payload = {
            smiles,
            map_type: '3d',
          }
        }

        return call(store, SYN_MAP_URL, payload)
      })
    )

    yield put({
      type: MMAP_3D_LOAD_INIT,
      layers3d: layersResult.map(({ data }, i) => ({
        datasetId: newLayers[i].datasetId,
        generatorId: newLayers[i].generatorId,
        task_uuid: data.task_uuid,
      })),
    })
  } catch (e) {
    yield put({
      type: MMAP_3D_LOAD_FAIL,
      layers3d: newLayers,
    })

    const notify = {
      id: uuidv4(),
      name: 'notification.error',
      text: 'notifications.failed_to_load_3d_coordinates',
      notification_type: 'error',
      timeout: 5000,
      autoRemove: true,
    }

    yield put({
      type: ADD_NOTIFICATION,
      task: notify,
    })
  }
}

function* startGenerator({ props }) {
  const {
    name,
    genEpochs,
    optEpochs,
    generationProps,
    optimizationProps,
    filtration,
    isOptimization,
    isGeneration,
    isSelectedAllLayers,
  } = props
  let task_id

  try {
    const layers = yield select((state) => state.mmap.layers)
    const selected = yield select((state) => state.mmap.selected)

    const getParams = ({ isRequest }) => {
      const smiles_list = isSelectedAllLayers
        ? layers.flat().map((el) => el.smiles)
        : selected.map((el) => el.smiles)

      let params = {
        smiles_list,
        is_generate: isGeneration,
        is_opt: isOptimization,
        generate_steps: genEpochs,
        molopt_steps: optEpochs,
        generate_discriptors: generationProps,
        molopt_discriptors: optimizationProps,
        generate_filter: filtration / 100,
        method: 'synmap_gen',
      }
      if (isRequest) {
        if (!isOptimization) {
          delete params.molopt_steps
          delete params.molopt_discriptors
          delete params.generate_filter
        }
        if (!isGeneration) {
          delete params.generate_steps
          delete params.generate_discriptors
          delete params.generate_filter
        }
      }
      return params
    }

    const response = yield storeV2(
      '/run-synmap',
      getParams({ isRequest: true })
    )

    if (response?.status === 200 && response?.data?.task_uuid) {
      let status = 'running'
      task_id = response?.data?.task_uuid
      const options = generatorCreateLayer(task_id, name)
      yield put({ type: MMAP_CREATE_GENERATOR_LAYER, options })

      const id = uuidv4()
      const notify = {
        id,
        name: 'generator.sessionStarted',
        text: 'generator.sessionStartedSmall',
        translationNameParams: { name },
        translationTextParams: { name },
        notification_type: 'success',
        autoRemove: true,
        timeout: 5000,
      }
      yield put({
        type: ADD_NOTIFICATION,
        task: notify,
      })

      yield put({
        type: MMAP_ADD_GEN,
        generator: {
          ...getParams({ isRequest: false }),
          token: task_id,
          status: 'running',
        },
      })
      while (status === 'running') {
        try {
          const tasks = yield fetch(`/userTasksStatus`)
          const task = tasks?.data?.find((task) => task_id === task.uuid)
          if (task) {
            yield put({
              type: MMAP_GENERATOR_DATA_INCOME,
              status: task.status,
              token: task,
              progress: task.progress,
              result: [],
            })

            if (task?.status === 'ok') {
              status = 'ok'
              const { data } = yield fetch(
                `/userTasksStatus/${response.data.task_uuid}`
              )

              yield put({
                type: MMAP_GENERATOR_DATA_INCOME,
                status: data.status,
                token: data.uuid,
                progress: data.progress,
                result: data.result,
              })
              yield store('/cancel-task', {
                task_uuid: data.uuid,
              })
            }
          } else {
            throw new Error('error')
          }
          yield delay(2000)
        } catch (e) {
          status = 'error'
          const id = uuidv4()
          const notify = {
            id,
            name: 'generator.session_failed',
            notification_type: 'error',
            autoRemove: true,
            timeout: 5000,
            translationNameParams: { name },
          }
          yield put({
            type: ADD_NOTIFICATION,
            task: notify,
          })
          yield put({
            type: MMAP_CHANGE_GENERATOR_STATUS,
            status: 'error',
            token: task_id,
          })
        }
      }
    }
  } catch (error) {
    const id = uuidv4()
    const notify = {
      id,
      name: 'generator.session_failed',
      notification_type: 'error',
      translationNameParams: { name },
      autoRemove: true,
      timeout: 5000,
    }
    yield put({
      type: ADD_NOTIFICATION,
      task: notify,
    })
    yield put({
      type: MMAP_CHANGE_GENERATOR_STATUS,
      status: 'error',
      token: task_id,
    })
  }
}

export function* loadMMapWatcher() {
  yield takeEvery(MMAP_ADD, loadMMap)
}

export function* addMMapGeneratorWatcher() {
  yield takeEvery(MMAP_ADD_GENERATOR, addGeneratorToMMap)
}

export function* mmapGeneratorIncomeWatcher() {
  yield takeEvery(MMAP_GENERATOR_DATA_INCOME, mmapGeneratorIncome)
}

export function* toggleIs3dModeWatcher() {
  yield takeEvery(MMAP_TOGGLE_IS_3D_MAP_MODE, load3d)
}

export function* startGeneratorWatcher() {
  yield takeEvery(GENERATOR_CALC_INIT, startGenerator)
}
