import { useEffect } from 'react'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { push } from 'connected-react-router'
import { resetMolecule } from 'store/actions/molecule'
import LoadingOverlay from 'components/LoadingOverlay'
import { CustomDialog } from 'components/common/customDialog'
import Molecule from '../molecule'
import { Header, Content, HeaderBlock } from './index.style'
import { useLocation } from 'react-router-dom'
import { TitleSecondary } from 'components/common/text/index.style'

export const MoleculeDialog = ({
  id,
  molId,
  onClose,
  services,
  basketId,
  properties,
  categories,
  resetMolecule,
}) => {
  const { t } = useTranslation()
  const location = useLocation()
  const { loading: categoriesLoading } = categories
  const { loading: servicesLoading } = services
  const { loading } = properties
  const isReactionPage = location.pathname.includes('reactions')

  useEffect(() => {
    return () => resetMolecule()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (categoriesLoading || servicesLoading || loading) return <LoadingOverlay />

  return (
    <CustomDialog
      outboundExceptions={['context-menu', 'context-menu-item']}
      width={'68vw'}
      onClose={onClose}
      maxHeight={'88vh'}
      gap={'0.75rem'}
      withPortal={!!isReactionPage}
    >
      <Header>
        <HeaderBlock data-test="molecule-dialog-header-block">
          <TitleSecondary>{t('molviewer.molecule_fast_view')}</TitleSecondary>
          {/* <Applicability /> */}
        </HeaderBlock>
      </Header>
      <Content isFullMode={false}>
        <Molecule
          id={id}
          onClose={onClose}
          molId={molId}
          basketId={basketId}
          isFullMode={false}
          isDialog={true}
        />
      </Content>
    </CustomDialog>
  )
}

const mapStateToProps = (state) => {
  return {
    categories: state.molecule.categories,
    services: state.molecule.services,
    properties: state.molecule.properties,
  }
}

const mapDispatchToProps = {
  push,
  resetMolecule,
}

export default connect(mapStateToProps, mapDispatchToProps)(MoleculeDialog)
